import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "react-loader-spinner";
import { CaseHistoryItem } from "../../../models/Case/CaseHistory";
import { formatContent, getUpdateOwnerName, isNullOrUndefined } from "../../../utils/formatting";
import { MobilePagination, Pagination, Paginationbox, WebPagination } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import { CASE_ACTION_DETAIL } from "../../../ducks/redux/actionTypes";
interface CaseSelectActionProps extends RouteComponentProps<any> {
}
const CaseSelectActionComponent: React.FC<CaseSelectActionProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [caseHistoryDetails, setCaseHistoryDetails] = React.useState<CaseHistoryItem[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();
    const [caseHistoryDetailsData, setCaseHistoryDetailsData] = React.useState<CaseHistoryItem[]>([]);

    const caseData = useSelector(
        (state: any) => state?.userChoicesReducer?.caseData
    );

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            window.scrollTo(0, 0);
           const CaseHistory = await api.getCaseHistories(
            caseData.identifiers?.caseIdentifier, "Action"
        );
        setIsLoading(false);
                    setCaseHistoryDetailsData(CaseHistory?.historyItems);

        })();
    }, []);

    useEffect(() => {
        const queryParams = {
            limit: 10,
            skip: 0
        };
        getCaseHistoryData(queryParams);
     }, [caseHistoryDetailsData]);

    const getCaseHistoryData = async (queryParams: any) => {
        try {
            const allItems: any = [];
            let filteredData: any = [];
            if(caseHistoryDetailsData.length > 0){
                filteredData = caseHistoryDetailsData.filter((item: any) => item.metaData["status"].toLowerCase() != "completed" && item.metaData["status"].toLowerCase() !== "closed")
                setCaseHistoryDetails(filteredData);
                    let filteredResults = [];
                    if (filteredData.length >= 1) {
                        const sortedResults = filteredData.sort((a: any, b: any) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf());
                        const finalSortedResults = sortedResults.sort((a: any, b: any) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf());
                        filteredResults.push(finalSortedResults);
                    }

                    filteredResults.forEach((items: any) => {
                        items.forEach((eachItem: CaseHistoryItem) => {
                            allItems.push(eachItem);
                        });
                    });

                    if (allItems.length > 0) {
                        setTotalCount(allItems.length);
                        const filteredItems = allItems.filter(
                            (item: any) => item.type !== ""
                        );
                        const filteredHistorySummary = filteredItems.sort((a: any, b: any) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()).slice(queryParams.skip, (queryParams.skip + queryParams.limit));
                        setCaseHistoryDetails(filteredHistorySummary);
                    }
                    
            }
           
        } catch (e) {
            props.history.push("/genericerror");
        }
    }

    const backButtonHandler = () => {
        props.history.push("/Case360View");
    };

    const updateCaseHistoryData = (limit: any, skip: any) => {
        (async () => {
            const queryParams = {
                limit: limit,
                skip: skip
            };
            getCaseHistoryData(queryParams);
        })();
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
        setParamsAndUpdateHistoryData(resultsPerPage, resultsPerPage * page);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setCurrentPage(0);
        setParamsAndUpdateHistoryData((e.target as any).value, 0);
    };

    const fetchData = () => {
        setParamsAndUpdateHistoryData(caseHistoryDetailsData.length, 0);
    };

    const setParamsAndUpdateHistoryData = (resultsPerPage: any, skipLimit: any) => {
        setResultsPerPage(resultsPerPage);
        setSkip(skipLimit);
        updateCaseHistoryData(resultsPerPage, skipLimit);
    };
    const goToSelectAction = async (eachCaseUpdate: CaseHistoryItem) =>{
        dispatch({ type: CASE_ACTION_DETAIL, payload: eachCaseUpdate });
        props.history.push("/CaseUpdateAction");
    }

    return (
        <div>

            <div className="border-t border-grey bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
                <div className="container mx-auto pb-12 ctn-fht">
                    <div className="w-full px-2 lg:px-0 ">
                        <div className="mt-4 mb-8 ">
                            <p className="text-teal text-xl mt-4 mb-4 md:mb-12">
                                <a className="back-btn appGreenColor" data-testid="backButtonHandler" aria-hidden="true" onClick={backButtonHandler}><i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
                        </div>
                        <h1 className="sm:px-3 sm:font">{t("OpenActions")}</h1>
                        <div className="sm:px-3 w-3/4 md:text-left pt-2 pb-0 pl-1 global-txt">
                         {t("SelectActionMsg")}
                        </div>
                        <WebPagination>
                            <div className="w-full lg:px-0 global-txt">
                                <div className="border border-grey bg-white shadow-lg rounded-lg mb-8 mt-8">
                                    <div className="bg-brick-20 px-4 py-2 rounded-t-lg">
                                        <div className="flex flex-row items-center">
                                            <strong>{t("Case_Reference")} {formatContent(caseData?.caseReferenceNumber)}</strong>
                                        </div>
                                    </div>
                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                        <div className="mb-2">
                                            <strong>{t("CaseTitle")}</strong><br />{formatContent(caseData?.caseSubTypeCategory)}
                                        </div>
                                        <div className="mb-2">
                                            <strong>{t("CP085")}</strong><br />
                                            {getUpdateOwnerName(caseData?.owner?.name)}                     
                                        </div>
                                    </div>

                                </div>
                                {isLoading ? (
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={70}
                                        width={50}
                                        timeout={5000}
                                    />
                                ) : <>
                                    { !caseHistoryDetails?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t("NoOpenActionMsg")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    caseHistoryDetails.map(
                                        (eachCaseUpdate: CaseHistoryItem, key: any) => {
                                            return (
                                                <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                    <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <p className="my-0 text-base"><strong>{eachCaseUpdate.type}</strong></p>
                                                        </div>
                                                    </div>
                                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                        <div className="mb-2">
                                                            <strong>{t("Created_On")}</strong><br />  {moment(eachCaseUpdate.createdDate).format(
                                                                "DD-MM-YYYY, h:mm:ss A"
                                                            )}
                                                        </div>
                                                        {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["owner"]) && <div className="mb-2">
                                                            <strong>{t("CP085")}</strong><br />{eachCaseUpdate.metaData["owner"]}
                                                        </div>}
                                                        {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["createdBy"]) && <div className="mb-2">
                                                            <strong>{t("Created_By_CP115")}</strong><br />{eachCaseUpdate.metaData["createdBy"]}
                                                        </div>}
                                                        {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["status"]) && <div className="mb-2">
                                                            <strong>{t("CP086")}</strong>
                                                            <br />
                                                            {eachCaseUpdate.metaData["status"]}
                                                        </div>}
                                                    </div>
                                                    <div className="px-4 py-2 grid grid-cols-1 mt-3 global-txt">  
                                                        {eachCaseUpdate.type && eachCaseUpdate.type == "Appointment" && eachCaseUpdate.metaData && eachCaseUpdate.metaData["appointmentDate"] && <div className="mb-2">
                                                            <strong>{t("Appointment_Date")}</strong><br />  {moment(eachCaseUpdate.metaData["appointmentDate"]).format(
                                                                "DD-MM-YYYY, h:mm:ss A"
                                                            )}
                                                        </div>}
                                                       <div className="mb-4">
                                                            <strong>{t("actionType")}</strong><br />{eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["actionType"]) && eachCaseUpdate.metaData["actionType"] }
                                                        </div>
                                                        <div className="mb-2 cursor-pointer">
                                                          <a data-testid={"web" + eachCaseUpdate?.comment} className = "cursor-pointer appGreenColor" aria-hidden="true" onClick={() => goToSelectAction(eachCaseUpdate)}>{t("SelectAction")}</a>
                                                        </div>
                                                    </div>
                                                    
                                                </div>


                                            )
                                        }
                                    ) )
                                    }
                                    <div className="page-mar">
                                        <Paginationbox>
                                            <label>
                                                <Pagination
                                                    className=" ml-4 "
                                                    colSpan={7}
                                                    count={totalCount}
                                                    page={currentPage}
                                                    rowsPerPage={resultsPerPage}
                                                    rowsPerPageOptions={[10, 20, 100]}
                                                    onPageChange={onChangePage}
                                                    data-testid="WorkOrderPaginationChange"
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </label>
                                        </Paginationbox>
                                    </div>
                                </>
                                }
                            </div>
                        </WebPagination>
                        <MobilePagination>
                            <div
                                id="scrollableDiv"
                                data-testid="scrollableDiv" className="mob-view-only"
                                style={{
                                    height: 800,
                                    overflowY: "scroll",
                                    overflow: "auto",
                                    flexDirection: "column",
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={caseHistoryDetails?.length}
                                    next={fetchData}
                                    style={{ display: "flex", flexDirection: "column" }}
                                    hasMore={true}
                                    loader={<p></p>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <div className="w-full lg:px-0 global-txt">
                                        <div className="border border-grey bg-white shadow-lg rounded-lg mb-8 mt-8">
                                            <div className="bg-brick-20 px-4 py-2 rounded-t-lg">
                                                <div className="flex flex-row items-center">
                                                    <strong>{t("Case_Reference")} {formatContent(caseData?.caseReferenceNumber)}</strong>
                                                </div>
                                            </div>
                                            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                <div className="mb-2">
                                                    <strong>{t("CaseTitle")}</strong><br />{formatContent(caseData?.caseSubTypeCategory)}
                                                </div>
                                                <div className="mb-2">
                                                    <strong>{t("CP085")}</strong><br />{getUpdateOwnerName(caseData?.owner?.name)} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isLoading ? (
                                        <Loader
                                            type="ThreeDots"
                                            color="#00BFFF"
                                            height={70}
                                            width={50}
                                            timeout={5000}
                                        />
                                    ) : <>
                                        {  !caseHistoryDetails?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t("NoOpenActionMsg")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : ( 
                                    caseHistoryDetails.map(
                                            (eachCaseUpdate: CaseHistoryItem, key: any) => {
                                                return (
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
                                                            <div className="flex flex-row justify-between items-center">
                                                                <p className="my-0 text-base"><strong>{eachCaseUpdate.type}</strong></p>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("Created_On")}</strong><br />  {moment(eachCaseUpdate.createdDate).format(
                                                                    "DD-MM-YYYY, h:mm:ss A"
                                                                )}
                                                            </div>
                                                            {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["owner"]) && <div className="mb-2">
                                                                <strong>{t("CP085")}</strong><br />{eachCaseUpdate.metaData["owner"]}
                                                            </div>}
                                                            {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["createdBy"]) && <div className="mb-2">
                                                                <strong>{t("Created_By_CP115")}</strong><br />{eachCaseUpdate.metaData["createdBy"]}
                                                            </div>}
                                                            {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["status"]) && <div className="mb-2">
                                                                <strong>{t("CP086")}</strong>
                                                                <br />
                                                                {eachCaseUpdate.metaData["status"]}
                                                            </div>}
                                                        </div>
                                                        <div className="px-4 py-2 grid grid-cols-1 global-txt">
                                                            {eachCaseUpdate.type && eachCaseUpdate.type == "Appointment" && eachCaseUpdate.metaData && eachCaseUpdate.metaData["appointmentDate"] && <div className="mb-2">
                                                                <strong>{t("Appointment_Date")}</strong><br />  {moment(eachCaseUpdate.metaData["appointmentDate"]).format(
                                                                    "DD-MM-YYYY, h:mm:ss A"
                                                                )}
                                                            </div>}
                                                           <div className="mb-4">
                                                            <strong>{t("actionType")}</strong><br />{eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["actionType"]) && eachCaseUpdate.metaData["actionType"] }
                                                        </div>

                                                        </div>
                                                        <div className="mb-2 cursor-pointer padding-left22">
                                                          <a data-testid={"mobile" + eachCaseUpdate?.comment} className = "cursor-pointer appGreenColor" aria-hidden="true" onClick={() => goToSelectAction(eachCaseUpdate)}>{t("SelectAction")}</a>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        ))
                                        }
                                    </>
                                    }
                        </InfiniteScroll>
                    </div>
                </MobilePagination>
            </div>
        </div>
            </div >        
    </div >

        
    )
}

export default CaseSelectActionComponent;


