export const sortableFields = [
    {
      displayName: 'Best match',
      sortableKey: 'search.score() desc',
    },
    {
      displayName: 'Address: Ascending',
      sortableKey: 'address/addressLine1 asc',
    },
    {
      displayName: 'Address: Descending',
      sortableKey: 'address/addressLine1 desc',
    }
  ]
    export default sortableFields
    