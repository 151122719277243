export const PriorityFilter= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Emergency',
        value: 'Emergency'
    },
    {
        key: 'Next available',
        value: 'Next Available',
    }
]