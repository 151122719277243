import React, { useState } from "react";
import Modal from "./index";
import Button from "../../Button/Button";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BackModalContent: React.FC<ModalProps> = ({ back, btn_msg, SMS_back, SMS_msg }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <div
        id="backmodal-div1"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 md:mb-12">
          <a className="back-btn" onClick={() => setIsOpen(true)}>
            <i className="fas fa-angle-left mr-2"></i>Back
          </a>
        </p>
      </div>
      <Modal open={isOpen} cssClasses="modalPopUp">
        <div id="backmodal-div2" className="modalPopUpContainer container my-1">
          <div
            id="backmodal-div3"
            className="py-lg-0 py-sm-0 h5 my-5 question-font-size text-center global-txt"
          >
            {t(SMS_msg || "SSR043")}
          </div>
          <div
            id="backmodal-div3"
            className="modalButtonContainer button-positions flex flex-wrap text-center pad-bot pt-lg-0"
          >
            <Button
              id="backmodal-btn1"
              className="button-class SMS-class"
              onClick={() => setIsOpen(false)}
            >
              {t(btn_msg || "return_repair_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
            <Button id="backmodal-btn2" onClick={back} className="button-class SMS-class">
            {t(SMS_back || "go_back_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BackModalContent;
