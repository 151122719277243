export default class CustomerCardDetails {
  id?: string | undefined
  givenName?: string | undefined
  surname?: string | undefined
  email?: string | undefined
  mobilePhone?: string | undefined
  homePhone?: string | undefined
  tenancyModels?: any | undefined
  workPhone?: string | undefined
  dateOfBirth?: string | undefined
}