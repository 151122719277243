export enum SorErrorEnumCode {
    E28 = "E28",
    E29 = "E29",
    E30 = "E30",
    E31 = "E31",
    E32 = "E32",
    E8 = "E8",
    E4 = "E4",
    SSR037 = "SSR037",
    E21 = "E21",
    E23 = "E23",
    E25 = "E25",
    SSR005 = "SSR005",
    E9 = "E9",
    E22 = "E22",
    E24 = "E24",
    E26 = "E26",
    E35 = "E35"
}