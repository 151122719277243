export const VANDALISM_CASE_QUESTION =
  "Do you know who is responsible for the deliberate damage?";
export const COVID_CASE_QUESTION =
   "Is anyone in the customer's home isolating due to Covid-19?";
export const REFERENCE_CASE_QUESTION_1 = "Who should the reference be sent to?";
export const REFERENCE_CASE_QUESTION_2 =
  "How would you like to receive this document?";
export const REFERENCE_CASE_QUESTION_3 =
  "Please provide the name of the company or person and their contact details, phone number, email and address";
export const REFERENCE_CASE_QUESTION_4 = "Please enter your email address";
export const REFERENCE_CASE_QUESTION_5 = "Please enter your postal address";
export const TENANCY_AGREEMENT_CASE_QUESTION =
  "How would like receive your Tenancy Agreement?";

export const HOUSEHOLD_OR_SUBLETTING_TITLE =
  "Please advise the type of change that you would like to make to the residency or house-hold members?";

export const HOUSEHOLD_OR_SUBLETTING_GUEST =
  "Please advise details of the guest and the reasons for having a guest at your property. Include the estimate start and end dates for this change";
export const HOUSEHOLD_OR_SUBLETTING_LOADGER =
  "Please advise details of the lodger and the reasons for adding a lodger as a householder member.  Include the estimate start and end dates for this change";
export const HOUSEHOLD_OR_SUBLETTING_MEMBER =
  "We need you to provide information about the changes to your household and we will forward a form for you to complete. How would you like to receive the form?";
export const HOUSEHOLD_OR_SUBLETTING_LETTING =
  "Please provide details of your request to sublet your property";

export const RENT_CARD = "How many cards do you need to order?";
export const LEASEHOLD_OWNERSHIP = "Please select the option for you enquiry";
export const LEASEHOLD_OWNERSHIP_TEXT1 =
  "Please provide details of your enquiry";
export const LEASEHOLD_OWNERSHIP_TEXT2 =
  "Please provide details of your request to sublet your property";
export const LEASEHOLD_OWNERSHIP_RADIO =
  "How would you like to receive the sales pack?";
export const COMPLEX_CASE_SERVICE_COMPLAINT_Q1 = "Would you like to raise a formal complaint or a service recovery request?";
export const COMPLEX_CASE_SERVICE_COMPLAINT_Q2 = "Which service are you unhappy with?";
export const COMPLEX_CASE_SERVICE_COMPLAINT_Q3 = "How has that Service fallen below what you expect from Peabody?";
export const COMPLEX_CASE_SERVICE_COMPLAINT_Q4 = "Please provide the details of the issue you are having with as much information as possible";
export const CUSTOMER_NEEDS_TENANNCY_AGREEMENT = "Resident needs a reference or a copy of  tenancy agreement";
export const CUSTOMER_NEEDS_REFERENCE= "Customer needs a reference";
export const CUSTOMER_LEASEHOLD_OR_SHARED_OWNERSHIP = "Leasehold or shared ownership enquiry";
export const CUSTOMER_HOUSEHOLD_MEMBERS_OR_SUBLETTING = "Change to household members or subletting";
export const CUSTOMER_MEDICAL_REASONS = "Customer needs to move for medical reasons";
export const CUSTOMER_HOMEHUNT = "Enquire about a Homehunt application";
export const FOOTAGE_START_DATE = "CCTV Footage start Date & Time";
export const FOOTAGE_END_DATE = "CCTV Footage end Date & Time";
export const CCTV_Question1 = "Who is requesting CCTV?";
export const ESTIMATED_SERVICE_CHARGE_BUDGET = "Estimated Service Charge Budget";
export const RELATE_TO_ESTIMATES_OR_ACTUALS = "Does this relate to Estimates or Actuals?";