import {REFERRAL_ACCOUNT_BALANCE, CLEAR_REFERRAL_INFO, COMING_FROM_REFERRAL, REFERRAL_ADDITIONAL_DETAILS, REFERRAL_FOR, REFERRAL_INCLUSION_TEAM, REFERRAL_OTHER_DETAILS, REFERRAl_REASON, COMING_FROM_CASE, WO_REDIRECT_FROM_EMAIL } from "../actionTypes";

export const financialReferralFor = (financialReferral: string): any => {
  return { type: REFERRAL_FOR, payload: financialReferral };
};

export const FinancialReferralReasonRes = (referralReason:any[]): any => {
    return {type:REFERRAl_REASON, payload:referralReason}
}

export const FinancialInclusionTeam = (inclusionTeam:boolean):any => {
    return {type:REFERRAL_INCLUSION_TEAM, payload:inclusionTeam}
}


export const FinancialOtherDetails = (otherDetails:string):any => {
    return {type:REFERRAL_OTHER_DETAILS, payload:otherDetails}
}

export const clearReferralInformation = (data:any):any => {
    return {type: CLEAR_REFERRAL_INFO, payload:data}
}

export const comingFromReferralPage = (data:boolean):any => {
    return {type: COMING_FROM_REFERRAL, payload:data}
}

export const storeReferralAdditionalDetails = (data:any):any => {
    return {type:REFERRAL_ADDITIONAL_DETAILS, payload:data}
}

export const saveReferralAccountBalance = (data:string):any => {
    return {type:REFERRAL_ACCOUNT_BALANCE, payload:data}
} 

export const comingFromCase = (data:boolean):any => {
    return {type:COMING_FROM_CASE, payload:data}
}

export const woRedirectFromEmailStatus = (data:boolean):any=> {
    return {type:WO_REDIRECT_FROM_EMAIL, payload:data}
}