
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { customerList, customersData, getWorkOrderObject, userChosenBackButton, userChosenRaisedSince, userChosenRaisedUpto, userChosenSearchText, userChosenSearchType } from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import "./index.css";

const GenericError: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
 
  const isError = useSelector(
    (state: any) => state?.userChoicesReducer?.userIsError
  );
  const { t } = useTranslation();

  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );

  const exitButtonHandler = () => {
    dispatch(getWorkOrderObject(null));
    if (backValue !== null && backValue !== "" && backValue !== undefined) {

        if (isUndefinedNullOrEmpty(loggedInStatus)) {
          props.history.push("/Login")
        }
        else {
          props.history.push("/LandingView")
        }
    }
    else {
      dispatch(customersData(""));
      dispatch(userChosenBackButton(false));
      dispatch(userChosenSearchText(null));
      dispatch(userChosenSearchType(null));
      dispatch(userChosenRaisedSince(null));
      dispatch(userChosenRaisedUpto(null));
      dispatch(customerList(null));
      if (isUndefinedNullOrEmpty(loggedInStatus)) {
        props.history.push("/Login")
      }
      else {
        props.history.push("/LandingView")
      }
    }
  };



  return (
    <div className="fullscreenDiv mt-4 mt-perc sm:px-2 ctn-fht">
            {isError ? (
              <div className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t("IDEM_Error")}
              </div>
            ) : (
              <>
              
              <h2 className="mt-2 headerFont">Whoops!</h2> 
              
              <div className="genericError ml-2">
                <p className="errorFont"> {t("Generic_Error")}</p>
              </div>
          </>
            )}
         
      <div className="mt-2"
        id="repair-exit-btn">
          <button onClick={exitButtonHandler}
          className="button-class gen_error">
         {t("Generic_Error_btn")}
         <i className='fas fa-angle-right arrow'></i>
         </button>
       
       </div>
    </div>

  )

}
export default GenericError;