import React, { useEffect, useState } from "react";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import CustomerCardDetails from "../../models/Customer/CustomerCardDetail";
import CustomerAssetCard from "../CustomerAssetCard";
import { PremisesAddress } from "../../models/shared/Address/PremisesAddress";
import { useDispatch } from "react-redux";
import Preference from "../../models/Customer/Preference";
import { customerAdditionalAlertData, customerPreferenceData } from "../../ducks/redux/actions/userChoices";


interface WorkOrderCustomerCardProps extends RouteComponentProps<any> {
    customerId: string;
    propertyId: string;
    workOrderId: string;
    assetType: string;
    uprn?: string;
}
const WorkOrderCustomerCardComponent: React.FC<WorkOrderCustomerCardProps> = (props) => {
    const dispatch = useDispatch();
    let customerResponse: CustomerCardDetails[] = [];
    const [isLoading, setIsLoading] = useState(true);
    const [customerIdList, setIsCustomerIdList] = useState(customerResponse);



    useEffect(() => {
        (async () => {
            const uprn = props.uprn || "";
            getCustomers(props.customerId, props.propertyId, props.workOrderId, props.assetType, uprn);
        })();
    }, [props.customerId, props.propertyId, props.assetType, props.uprn]);

    const getCustomers = (customerId: string, propertyId: string, workOrderId: string, assetType: string, uprn: string) => {
        (async () => {
            try {
                let propertyCustomerList;
                if (!customerId || !propertyId) {
                    const workOrderData = await api.getWorkOrder(workOrderId);
                    if (workOrderData != null) {
                        if (workOrderData.identifiers && (workOrderData.identifiers.customerIdentifier || (workOrderData.identifiers.asset && workOrderData.identifiers.asset.assetIdentifier))) {
                            propertyCustomerList = await fillPropertyCustomerList(workOrderData.identifiers.customerIdentifier ?? '', workOrderData.identifiers.asset.assetIdentifier, assetType, uprn);
                        }
                    }
                }
                else {
                    propertyCustomerList = await fillPropertyCustomerList(customerId, propertyId, assetType, uprn);
                }
                if (propertyCustomerList != undefined) {
                    setIsCustomerIdList(propertyCustomerList);
                    setIsLoading(false);
                }

            }
            catch (e) {
                console.error(e);
                props.history.push("/GenericError");
            }

        })();
    }

    const fillPropertyCustomerList = async (customerId: string, propertyId: string, assetType: string, uprn: string) => {
        let propertycustomerList: CustomerCardDetails[] = [];

        if (uprn && uprn != "") {
            const queryParams = {
                searchText: "",
                limit: 10,
                skip: 0,
                customerType: "",
                orderBy: "",
                tenancyType: "",
                uprn: uprn
            };
            let customerCallResponse: any;
            try{
            customerCallResponse = await api.getCustomerData(queryParams);
            }
            catch(e) {
                console.error(e);
                props.history.push("/GenericError");
              }
            const data = customerCallResponse?.models;
            let customerPreferenceDataObj: Preference[] = [];
            if (assetType == 'Property') {
                data.map(async (element: CustomerCardDetails) => {
                    const check = propertycustomerList.filter(x => x.id == element.id)
                    if (check.length < 1) {
                        propertycustomerList.push(element);
                       if(element.id!=="" && element.id!==undefined && element.id!==null){
                        let custResponse: any;
                        try{
                            custResponse= await api.getCustomer(element.id);
                        }
                        catch(e) {
                            console.error(e);
                            props.history.push("/GenericError");
                          }
                        customerPreferenceDataObj = custResponse.preferences.concat(customerPreferenceDataObj);
                        let customerAdditionalAlertDataObj: Preference[] = [];
                        let customerPreferenceAlertObj: Preference[] = [];
                        customerAdditionalAlertDataObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type == 'additionalNeeds');
                        customerPreferenceAlertObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type != 'additionalNeeds');
                        if(customerPreferenceAlertObj.length>0){
                        dispatch(customerPreferenceData(customerPreferenceAlertObj))}
                        if(customerAdditionalAlertDataObj.length>0){
                         dispatch(customerAdditionalAlertData(customerAdditionalAlertDataObj))
                        }
                       }
                    }
                })
            }
        }

        if (propertycustomerList.length === 0 && customerId && customerId !== "") {
            const propertyCustomerData = await fillCustomerWhileNoResultThroughUprn(customerId);
            if (propertyCustomerData) {
                propertycustomerList.push(propertyCustomerData);
            }
        }
        return propertycustomerList;
    }

    const fillCustomerWhileNoResultThroughUprn = async (customerId:string) => {
        let customerResponse: any;
        try{
        customerResponse = await api.getCustomer(customerId);
        }
        catch(e) {
            console.error(e);
            props.history.push("/GenericError");
          }
        let customerCardDetail = new CustomerCardDetails();
        customerCardDetail.id = customerResponse["customer-identifier"];

        customerCardDetail.givenName = customerResponse.person.name.givenName || "";
        customerCardDetail.surname = customerResponse.person.name.surname || ""
        customerCardDetail.email = customerResponse?.addresses.digitalAddresses.find(
            (a:any) => a.digitalAddressType === "email"
        )?.address || ""
        customerCardDetail.homePhone = customerResponse?.addresses.telecomsAddresses.find(
            (a:any) => a.telecomsAddressType === "homePhone"
        )?.number || "";
        customerCardDetail.workPhone = customerResponse?.addresses.telecomsAddresses.find(
            (a:any) => a.telecomsAddressType === "workPhone"
        )?.number || "";
        customerCardDetail.mobilePhone = customerResponse?.addresses.telecomsAddresses.find(
            (a:any) => a.telecomsAddressType === "mobilePhone"
        )?.number || "";
        customerCardDetail.tenancyModels = customerResponse?.addresses?.postalAddresses[0] as PremisesAddress

        return customerCardDetail;
    }

    return (<>
        {!isLoading ? (
            <CustomerAssetCard customerAssetList={customerIdList} {...props} />) : (
                <div className="div-loader">
                    <div className="SpinnerWrapper">
                        <LoadingSpinner />
                    </div>
                </div>
            )}
    </>
    )
}

export default WorkOrderCustomerCardComponent;