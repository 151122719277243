import Address  from '../Customer/Address'
import {PremisesType } from '../Customer/Premises'
import { AdminUnitsResponse, RelatedPremises } from './AdminUnits'
import {
  EligibilityErrorCode,
  EligibilityOutcome,
  EligibilityResponse as EligibilityResponse_,
} from './Eligibility'
import {
  MaintenanceResponsibilitiesByPremisesType as MaintenanceResponsibilitiesByPremisesType_,
  MaintenanceResponsibility,
  MaintenanceResponsibilityType as MaintenanceResponsibilityType_,
} from './MaintenanceResponsibilities'

export default class Property {
  public id: string = ''
  public eTag: string = ''
  public uprn: string = ''
  public premisesTypeId: string = ''
  public premisesType: PremisesType = PremisesType.Property
  public neighbourhoodManager: string = ''
  public region: string = ''
  public address: Address = new Address()
  public maintenanceResponsibilities: MaintenanceResponsibility[] = []
  public isInDefects: boolean = false
}

export class PremisesDetails {
  public premises: Property = new Property()
  public parentPremises: Property | null = null
}

export class RelatedCustomer {
  public id: string = ''
  public name: string = ''
}

export {
  AdminUnitsResponse,
  EligibilityErrorCode,
  EligibilityOutcome,
  MaintenanceResponsibility,
  RelatedPremises,
}
export type EligibilityResponse = EligibilityResponse_
export type MaintenanceResponsibilityType = MaintenanceResponsibilityType_
export type MaintenanceResponsibilitiesByPremisesType = MaintenanceResponsibilitiesByPremisesType_
