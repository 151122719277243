export class CaseActionItem {
    type?: string
    identifiers: CaseIdentifier = new CaseIdentifier()
    metaData?: any
}
export class CaseIdentifier{
    caseIdentifier?: string
    actionIdentifier?: string
    

}