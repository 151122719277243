import React, { useEffect,  useState, lazy, Suspense } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { isNullOrUndefined } from "../../../utils/formatting";
import { BackValue } from "../../../models/shared/Enum";
import { CASE_COUNTER, CASE_CUSTOMER_SEARCH, CASE_TENANCY_ID, CONTACT_INFORMATION_VALUE, EDIT_BUTTON_CLICK } from "../../../ducks/redux/actionTypes";
import { userChosenContactDetails, userChosenContactName, userChosenContactOption, userChosenContactPhone, userChosenRelationDetails } from "../../../ducks/redux/actions/userChoices";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));

interface CaseContactInformationProps extends RouteComponentProps<any> { }

const CaseContactInformation: React.FC<CaseContactInformationProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [radioValue, setRadioValue] = useState("");
  const [showError, setShowError] = useState("");
  let updateList : string[] = [t("Contact1"), t("Contact2"), t("Contact3"), t("Contact4")]

  
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const contactInformationValue = useSelector(
    (state: any) => state.caseCreationReducer?.contactInformationValue
  );
  const editButtonCheck = useSelector(
    (state: any) => state?.caseCreationReducer?.editButtonClick
  );
  
  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
  );

  useEffect(() => {
    setRadioValue(contactInformationValue)
    if(editButtonCheck == false){
    dispatch({type: CASE_CUSTOMER_SEARCH})}
    dispatch({type: CASE_TENANCY_ID})
    
  }, );

  const handleRadioChange = (e: any) => {
    setRadioValue(e.target.value)
    dispatch({type: EDIT_BUTTON_CLICK, payload: false})
    dispatch({ type: CONTACT_INFORMATION_VALUE, payload: e.target.value });
  };

  const onContinueHandler = () => {
    dispatch(userChosenContactOption(radioValue))
    if(radioValue == t("Contact1")){
      props.history.push("/CaseSelectCustomer")
    }
    else if(isNullOrUndefined(radioValue)){
      setShowError(t("OPC036"))
    }
    else if(radioValue == t("Contact2") || radioValue == t("Contact3")) {
      if(!editButtonCheck) {
      dispatch(userChosenContactDetails(""))
    dispatch(userChosenContactName(""))
    dispatch(userChosenContactPhone(""))
    dispatch(userChosenRelationDetails(""))
      }
      props.history.push("/ContactDetails")
    }
    else{
      props.history.push("/CaseReview")
    }
  
  };

  const backButtonHandler = () => {
   dispatch({ type: CASE_COUNTER, payload: questionInfoData?.length - 1 });
   props.history.push("/CaseQuestionnaire")
  };
  
  const exitButtonHandler = () => {
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  return (
    <div className="container sm:mx-1 ctn-fht" id="reploc-container" role="main" data-testid="case-div111">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-8 lg:mb-4 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
        
          <div className="h5 sm:mx-1" id="repreason-div1">
            <h2
              className="text-purple-100 text-2xl mb-0 global-txt"
              data-testid="title"
            >
             {t("OPC035")}
            </h2>
          </div>
        
   
        <div>
         

          <RadioGroup
            data-testid="radioGroup"
            className="question-btn mb-4"
            value = {radioValue}
            defaultValue = {radioValue}
            onChange={handleRadioChange}
          >
            {updateList.map((radioOption: any) => {
                
                return (
            <FormControlLabel
              id={radioOption}
              value={radioOption}
              placeholder={radioOption}
              data-testid={radioOption}
              control={<Radio id="contact-radio1" />}
              label={radioOption}
              className="my-1 global-txt"
            />
                )})}
         

          </RadioGroup>
        </div>

        {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
        )}

        <div
          className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
          id="repreason-main-btn"
        >
          <ExitModalContent
          id="case-asset-exitmodal"
          exit={exitButtonHandler}
          contentMessage="Case_OPC001"
          btn_msg="Case_Return_Button"
        />
          <Button
            onClick={onContinueHandler}
            className="pad-bot"
            data-testid="btn_caseQ"
          >
            {t("continue")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Suspense>
    </div>
  );
};

export default CaseContactInformation;
