export const WorkOrderSortableFields = [
    {
      displayName: 'Newest to Oldest',
      sortableKey: 'raisedDate desc',
    },
    {
      displayName: 'Oldest to Newest',
      sortableKey: 'raisedDate asc',
    },
   
  ]
    export default WorkOrderSortableFields
    