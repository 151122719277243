import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useSelector,useDispatch } from "react-redux"
import { saveCustomerphoneNumber, selectedTenacyType } from "../../ducks/redux/actions/getAddress";
import { MaintenanceResponsibilityEnum} from "../../models/Property/MaintenanceResponsibilityEnum";
import { getHeatingType } from "../../models/Property/heatingElements";
import { formatDateWithSlashFromCalendar, isNullOrUndefined } from "../../utils/formatting";
import { backToCaseAsset, userChosentenancyGroup } from "../../ducks/redux/actions/userChoices";
import { USERCHOSEN_PROPERTY_ID } from "../../ducks/redux/actionTypes";
import * as api from "../../api";
import { PremisesType } from "../../models/Customer/Premises";
import { PermissionsList } from "../../models/Permissions";
import HighRise from "../HighRise";
import { hasHistory } from "@microsoft/applicationinsights-core-js";



interface AssetDetailCardProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerResponseDetails[];
}
const AsssetDetailCard: React.FC<AssetDetailCardProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [maintenanceResponsibility, setMaintenanceResponsibility] = useState('')
  const [heatingType, setHeatingType] = useState('')
  const [dropDown, setDropDown] = useState("none");
  const [showSMSBtn, setShowSMSBtn] = useState(false);
  const assetDataResponse = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataResponse
  );
  const userChosenNoUnit = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenNoUnit
  );
  const localAuthority = useSelector(
    (state: any) => state?.userChoicesReducer?.localAuthority
  );
  let assetStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataStatus
  );
  let selectedTenacy = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );
  const customerResponse = useSelector(
    (state: any) => state?.addresses?.saveCustomerDetails
);
const isActiveTenancy = useSelector(
  (state: any) => state?.userChoicesReducer?.isActiveTenancy
);

const eligibleSelectCustomer = useSelector(
  (state: any) => state?.caseCreationReducer?.eligibleSelectCustomer
);
const isCaseCheck = useSelector(
  (state: any) => state?.userChoicesReducer?.isCaseAllowed
);

const getPermissionsData = useSelector(
  (state: any) => state?.userChoicesReducer?.userProfilePermissions
);

useEffect(()=>{
  const showSMS = getPermissionsData?.indexOf(
    PermissionsList.create_asset360_block_sms
  );
  if (showSMS !== -1) {
    setShowSMSBtn(true);
  } else {
    setShowSMSBtn(false);
  }
},[])


  useEffect(() => {  
    (async () => {

    trackEvent("AssetDetailCard");
    if(assetDataResponse?.maintenanceResponsibilities?.length> 0){
      const maintenanceResponsibilityValue = assetDataResponse?.maintenanceResponsibilities.find(
        (a: { type: string; }) => a.type === "MAINTSRESP"
      )?.value;
      if(MaintenanceResponsibilityEnum.FMFULL == maintenanceResponsibilityValue ||  MaintenanceResponsibilityEnum.QLYES == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.DEFAULTED == maintenanceResponsibilityValue){
        setMaintenanceResponsibility(t("Peabody"))
      }
      else if(MaintenanceResponsibilityEnum.EMAFULL == maintenanceResponsibilityValue){
        setMaintenanceResponsibility(t("EMA"))
      }
      else if(MaintenanceResponsibilityEnum.FMPART == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.SINGLE == maintenanceResponsibilityValue){
        setMaintenanceResponsibility(t("PartiallyPeabody"))  
      }
      else if(MaintenanceResponsibilityEnum.QLNO == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.AGENT == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.OWNER == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.FREEHDFULL == maintenanceResponsibilityValue
        || MaintenanceResponsibilityEnum.FREEHOLD == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.LEASEHOLD == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.LEASEHDFULL == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.LEASHDFULL == maintenanceResponsibilityValue
        || MaintenanceResponsibilityEnum.LEASEHOLDR == maintenanceResponsibilityValue || MaintenanceResponsibilityEnum.NONE == maintenanceResponsibilityValue ){
            setMaintenanceResponsibility(t("NotPeabody")) 
        }
       const heatingData = assetDataResponse.maintenanceResponsibilities.find(
        (a: { type: string; }) => a.type.substring(0,4) == "HEAT"
      )
      if(heatingData !== undefined){
        let heatingType="";
        heatingType = getHeatingType(heatingData.type, heatingData.value);
        setHeatingType(heatingType);         
      }
    }
    })();
}, [assetDataResponse, localAuthority]);

const dropDownFunction = () => {
  if (dropDown === "none")
    setDropDown("block");
  else
    setDropDown("none");
}

const callPageHandler = () => {
  dispatch(selectedTenacyType(selectedTenacy));
  if(isNullOrUndefined(customerResponse?.addresses?.telecomsAddresses)) {
  dispatch(saveCustomerphoneNumber(null));
  }
  else {
    dispatch(saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses));
  }
  props.history.push("/RepairAsset");  
};

const callCaseHandler = async() => 
  {
    backToCaseAsset(false)
      if (assetDataResponse?.assetType == "Property" && eligibleSelectCustomer == true) {
          dispatch({ type: USERCHOSEN_PROPERTY_ID, payload: assetDataResponse.id });
          props.history.push("/SelectCustomer");
          //multiple customer
      }
      else
      {
        let tenancyGroup="ALL";
        if(!isNullOrUndefined(customerResponse)&& assetDataResponse?.assetType == "Property")
        {
          let id=customerResponse["customer-identifier"]
          if(id!=undefined)
          {
          const tenancyResponse  = await api.getTenancies(id);
          tenancyGroup= tenancyResponse[0].tenancyGroup
          }
        }
        if (assetDataResponse?.assetType === "Property") {
          dispatch(userChosentenancyGroup(tenancyGroup));
          props.history.push("/CaseType");

        }
       
        else if (assetDataResponse?.assetType !== "Property") {
          dispatch(userChosentenancyGroup("All"));
          props.history.push("/CaseType");
          //communal
        }
        
      }
  }
  const handleEmail = () => {
    props.history.push("/composeEmail");
  }


  const sendSMSFuction = () => {
    props.history.push("/composeSMS");
  }

  return (
    <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card">
      {
      <div className="py-2">
       <div className="px-4 border-b border-grey pb-2">
         <div className="flex flex-row d-flex bd-highlight items-center mt-2">
         <i className="fa fa-home text-teal text-2xl mr-6 bd-highlight aa"></i>
         <h2 className="my-0 text-purple-100 bd-highlight">{ assetDataResponse.assetType}</h2>
         {( (isActiveTenancy && (selectedTenacy === "Property")  )|| (assetDataResponse.assetType === "Property"  && (assetStatus === 'OCCP' || assetStatus === 'VOID')) || ((selectedTenacy ==="Divided Block") || (selectedTenacy ==="Block") || (selectedTenacy ==="Estate") ) )
 ? (
              <div className="menu-nav ms-auto bd-highlight">
                <div className="dropdown-container">
                  <div className="three-dots" data-testid="dots_menu" onClick={dropDownFunction}></div>
                  
                  <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                    { (selectedTenacy == "Property" && isActiveTenancy) || (selectedTenacy ==="Divided Block") || (selectedTenacy ==="Block") || (selectedTenacy ==="Estate") ? <a data-testid="raise_rep" className="text-black text-base no-underline global-txt" onClick={callPageHandler} onKeyDown={callPageHandler}><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{selectedTenacy == "Property" ? t("Create_repair") :  t("raise_comm") }</div></a>: null}
                    {isCaseCheck =='true'? <a data-testid="casepage" className="text-black text-base no-underline global-txt" onClick={callCaseHandler}><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("CP104") }</div></a> : null}
                    {selectedTenacy ==="Block" && showSMSBtn &&
                       (<>
                        <a data-testid="Email" className="text-black text-base no-underline global-txt" onClick={handleEmail} onKeyDown= {handleEmail}><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("send_Email") }</div></a>
                         <a data-testid="SMS" className="text-black text-base no-underline global-txt" onClick={sendSMSFuction}><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("send_SMS") }</div></a>  
                         </>)
                    }
                  </div>
                  
                    

                  
                </div>
              </div>): null}
         </div>
         </div>
         {(((assetStatus ==="VOID" || assetStatus ==="OCCP" || assetStatus ==="CLSD") && assetDataResponse.assetType == PremisesType.Property) || 
         assetDataResponse.assetType === PremisesType.DividedBlock ||
         assetDataResponse.assetType === PremisesType.Block ||
         assetDataResponse.assetType === PremisesType.Estate  )?
        (
        <div className="px-4" data-testid="parent">
         <div className="py-2 flex justify-between border-b border-grey mt-2">
                <div className="pr-4">{t("asset_uprn")}
                </div>
                <div className="text-right">  {assetDataResponse.uprn || "-"}</div>
              </div>
              {assetDataResponse.assetType == PremisesType.Block?<div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4" data-testid="noUnit">{t("No_of_units")}
                </div>
                <div className="text-right">  {userChosenNoUnit}</div>
              </div> : null}
              {assetDataResponse.assetType == "Property"?<div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("asset_status")}
                </div>
                <div className="text-right">  {assetDataResponse.assetStatus || "-"}</div>
              </div> : null}
              {assetDataResponse.assetType == "Property"?<div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("tenancy")}
                </div>
                <div className="text-right">  {assetDataResponse.tenancyType || "-"}</div>
              </div> : null}
              <div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("local_authority")}
                </div>
                <div className="text-right">  {isNullOrUndefined(localAuthority)? "-": localAuthority}</div>
              </div>
              <div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("Asbestos_status")}
                </div>
                <div className="text-right">  {isNullOrUndefined(assetDataResponse.asbestosStatus)? "-": assetDataResponse.asbestosStatus}</div>
              </div>
              <div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("maintenance_responsibility")}
                </div>
                <div className="text-right">  {maintenanceResponsibility || "-"}</div>
              </div>
              <div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("heating_type")}
                </div>
                <div className="text-right">  {heatingType || "-"}</div>
              </div>
              <div className="py-2 flex justify-between border-b border-grey">
                <div className="pr-4">{t("Last_stock_condition_survey")}
                </div>
                <div className="text-right">  {isNullOrUndefined(assetDataResponse.lastStockConditionSurveyDate)? "-": formatDateWithSlashFromCalendar(assetDataResponse.lastStockConditionSurveyDate)}</div>
              </div>
              {(assetDataResponse.assetType == "Property" || assetDataResponse.assetType == "Block") &&
                <HighRise {...props} />}
                  
                
              </div>
      
      ):(
        <div className="mt-2 px-4">{t("assets_search_no_results")}</div> 
         )}
          
       </div> }
    </div>
  )
};
export default AsssetDetailCard;