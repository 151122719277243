import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { formatDateWithFromCalendar } from "../../utils/formatting";
import { trackEvent } from "../../appInsights/TelemetryService";
import CustomerCardDetails from "../../models/Customer/CustomerCardDetail";
import { useDispatch, useSelector } from "react-redux";

interface PropertyAlertProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerCardDetails[];
}
const PropertyAlert: React.FC<PropertyAlertProps> = (props) => {
  const { t } = useTranslation();
   const [preferenceCount, setPreferenceCount] = useState(0)
  const [isActive, setIsActive] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const alertData = useSelector(
    (state: any) => state?.userChoicesReducer?.alertValue
  );
  
  useEffect(() => {
   trackEvent("PropertyAlert")
    if(alertData?.length !== undefined) {
    setPreferenceCount(alertData.length);
    let tempError;
    tempError =t("CPA005c").replace("@count", alertData?.length);
     setAlertMsg(tempError);      
    }
  }, [alertData]);
  const callPerferenceHandler = () => {
    if (isActive === false) {
      setIsActive(true);
    }
    else
      setIsActive(false);
  }
  return (
    <div>
      {preferenceCount!==null && preferenceCount!==undefined && preferenceCount === 1 ?
                <div className="flex items-center w-full px-4 py-2 cursor-pointer bg-blu-20 rounded-lg" data-testid="singlePreferenceHandler" onClick={callPerferenceHandler}>
                  <div className="px-4 pr-8">
                    <i className="w-6 h-6 fas fa-exclamation-triangle"></i>
                  </div>
                  <div className="w-full text-left my-2">
                    <strong>
                   {alertData[0].description}
                   </strong>
                  </div>
                  <div className="plus-sign mt-10">
                    {isActive ? "⨯" : "+"}
                  </div>
                </div> : preferenceCount > 1 ?

                  <div className="flex items-center w-full px-4 py-2 cursor-pointer bg-blu-20 rounded-lg" data-testid="multiplePreferenceHandler" onClick={callPerferenceHandler}>
                    <div className="px-4 pr-8">
                      <i className="w-6 h-6 fas fa-exclamation-triangle"></i>
                    </div>
                    
                      <div className="w-full text-left my-2">
                      <strong>
                        {alertMsg}
                        </strong>
                      </div>
                    <div className="plus-sign mt-10">
                      {isActive ? "⨯" : "+"}
                    </div>
                  </div> : null}

                  {(isActive && preferenceCount === 1) ? (
                <div className="border-blue-width-20 bg-white rounded-lg mt-4">
                  <div className="px-4 grid grid-cols-1 mt-3">
                    <div className="mb-4">
                      <strong>{t("Alert_description")}</strong><br />
                       {alertData[0].description}
                    </div>
                    <div className="mb-4">
                      <strong>{t("Date_Creation")}</strong><br />
                      {formatDateWithFromCalendar(alertData[0].createdDate)}
                    </div>
                  </div>
                </div>

                ) :  (isActive && preferenceCount > 1) ? (alertData.map((data: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; description: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; createdDate: string; })  => (
             <div className="border-blue-width-20 bg-white rounded-lg mt-4">
                <div className="bg-blu-20 px-4 py-2 rounded-t-lg">
                  <div className="flex flex-row justify-between items-center">
                    <p className="my-0 "><strong>{data.description}</strong></p>
                  </div>
                </div>

                <div className="px-4 py-2 grid grid-cols-1 mt-3">
                  <div className="mb-2">
                    <strong>{t("Alert_description")}</strong><br /> {data.description}
                  </div>
                  <div className="mb-2">
                    <strong>{t("Date_Creation")}</strong><br />{formatDateWithFromCalendar(data.createdDate)}
                  </div>
                </div>
              </div>
              ))) : null}
              </div>
  )
};
export default PropertyAlert;