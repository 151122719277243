import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { clearReferralInformation, saveReferralAccountBalance } from "../../../ducks/redux/actions/FinancialReferral";
import {  userChosenAccountNumber } from "../../../ducks/redux/actions/userChoices";
import Button from "../../../Button/Button";

interface ReferralConfirmation extends RouteComponentProps<any> {
  
}

const ReferralConfirmation: React.FC<ReferralConfirmation> = (props:any) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)


  const buttonClickHandler = () => {
    dispatch(clearReferralInformation({}))
    dispatch(saveReferralAccountBalance(""))
    dispatch(userChosenAccountNumber(""));
    props.history.push('/Customer360View');
  }

  
  return (
    <div id="repFinalConfirm-container" role="main" className="container sm:px-2 ctn-fht">
    <div
      id="repFinalConfirm-div1"
       className="h5 repair-text mt-5"
    >
      {" "}
      <h1 className="text-purple-100 text-2xl mb-2 global-txt">{t("Referral_Confirmation_FI011a")}</h1> 
    </div>
      <div className="repair-text">
      <div className="border border-grey rounded-lg px-6 mb-6 md:mb-12 global-txt">
          <p className="mt-4">
          {t("Referral_Confirmation_FI012a")}<br/><strong>{(data?.ReferralReason)?.join(', ')?? null}</strong>
          </p>
          <p>
          {t("Referral_FI012ba")}<br/><strong>{t('Financial_Inclusion_team')}</strong>
          </p>
        </div>
     </div>
     <div id="repFinalConfirm-div2">
        <p id="repFinalConfirm-p" className="repair-text global-txt">
        <strong>What happens next.</strong>
        </p> {t("Referral_FI012b")} <p>

        </p>
    </div>
        
    <div
      id="repFinalConfirm-main-btn"
      className="button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-2 btn-left"
    >
     <div></div> 
      <Button
        id="btn_repFinalConfirm_finish"
        data-testid="finishSMS"
        onClick={buttonClickHandler}
        className="button-class mt-8"
      >
        Finish
        <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
      </Button>
    </div>
  
  </div>   

  )
};
export default ReferralConfirmation;