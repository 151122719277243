import { AnyAction } from "redux";
import { CHANGE_REPAIR_PROPERTY_TRACK, UPDATE_FORM, FETCH_REPAIR_REASONS, FETCH_REPAIR_REASONS_IN_PROGRESS, USER_TYPED_REPAIR_REASONS,
  FETCH_REPAIR_REASONS_SELECT, SELECTED_REPAIR_REASON_OBJECT, ELIGIBILITY_RESPONSE, SELECTED_SOR_CODE, SOR_ERROR_CODE, SELECTED_SOR_VALUE,SURVEY_REASON,SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION, RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY} from "../actionTypes";

const initialState = {
  formState: {
    repairPropertyTrack: 0,
    tracks: {
      8: {
        selected: "No",
      },
    },
    fetchedRepairReasons: [],
    fetchedRepairReasonsSelect: [],
    userTypedRepairReasons: "",
    userTypedSurveyReasons: "",
    selectedRepairReasonObject: {},
    customerID: "",
    fetchRepairReasonsInProgress: false
  },
};

export const dashboardReducer = (
  state: any = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case SOR_ERROR_CODE:
      return {
        ...state,
        formState: {
          ...state.formState,
          sorErrorCode: action.payload,
        },
      };
    case SELECTED_SOR_CODE:
      return {
        ...state,
        formState: {
          ...state.formState,
          selectedSorCode: action.payload,
        },
      };
    case CHANGE_REPAIR_PROPERTY_TRACK:
      return {
        ...state,
        formState: {
          ...state.formState,
          repairPropertyTrack: action.payload,
        },
      };
      
    case UPDATE_FORM:
      return {
        ...state,
        formState: {
          ...state.formState,
          tracks: {
            ...state.formState.tracks,
            [state.formState.repairPropertyTrack]: action.payload,
          },
          customerID: action.payload,
        },
      };

    case FETCH_REPAIR_REASONS:
      return {
        ...state,
        formState: {
          ...state.formState,
          fetchedRepairReasons: action.payload,
        },
      };

      case FETCH_REPAIR_REASONS_IN_PROGRESS:
        return {
          ...state,
          formState: {
            ...state.formState,
            fetchRepairReasonsInProgress: action.payload,
          },
        };

    case FETCH_REPAIR_REASONS_SELECT:
      return {
        ...state,
        formState: {
          ...state.formState,
          fetchedRepairReasonsSelect: action.payload,
        },
      };

    case USER_TYPED_REPAIR_REASONS:
      return {
        ...state,
        formState: {
          ...state.formState,
          userTypedRepairReasons: action.payload,
        },
      };

      case SURVEY_REASON:
        return {
          ...state,
          formState: {
            ...state.formState,
            userTypedSurveyReasons: action.payload,
          },
        };
    case SELECTED_REPAIR_REASON_OBJECT:
      return {
        ...state,
        formState: {
          ...state.formState,
          selectedRepairReasonObject: action.payload,
        },
      };
      case ELIGIBILITY_RESPONSE:
        return {
            ...state,
            formState: {
                ...state.formState,
                eligibilityResponseObject: action.payload,
            },
        };
        case SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION:
          return {
            ...state,
            formState: {
              ...state.formState,
              selectedRepairReasonPriorityOverride: action.payload,
            },
          };
          case RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY:
            return {
              ...state,
              formState: {
                ...state.formState,
                resumeRepairReasonPriorityUpdatedToEmergency: action.payload,
              },
            };
          
        
    default:
      return state;
  }
};

export default dashboardReducer;