export default class AssetDataResponse{
    public id?: string;
    public address?: string;
    public assetType?: string;
    public uprn?: string;
    public addresses?: Address | null 

}
export class Address{
    public postalAddresses: PostalAddress[] = []
   }
   export class PostalAddress{
     public addressLine1?: string;
     public addressLine2?: string;
     public addressLine3?: string;
     public addressLine4?: string;
     public addressLine5?: string;
     public postcode?: string;
   }