import { RouteComponentProps } from "react-router-dom";
import React from "react";
import classes from "./styles.module.css";
import * as ReactBootstrap from "react-bootstrap";
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import { imgData } from "./StaticIconName";
import ExitModalContent from "../../Modal/ExitModalContent";

interface UploadMediaRowProps extends RouteComponentProps {
  mediaFileName: string;
  bgColour: string;
  type: string;
  deleteMediaFile: () => void;
}

const UploadMediaRow: React.FC<UploadMediaRowProps> = (props) => {
  const mediaType = props?.type?.split("/");

  if (mediaType && mediaType[1] === "quicktime") {
    mediaType[1] = "mov";
  }

  if (mediaType && mediaType[1] === "x-matroska") {
    mediaType[1] = "mkv";
  }

  return (
    <div className={props.bgColour} id="confirmation-div-table">
      <ReactBootstrap.Table striped className="container-new">
        <TableBody id="confirmation-table" className="w-full border">
          <th>
            <TableRow id={"confirmation-tr1"}>
              <TableCell id={"confirmation-tr1-td1"} align="left">
                {imgData.map((item: any, index: number) => {
                  if (
                    (mediaType && mediaType[1] === item.id) ||
                    (mediaType && mediaType[1] === item.id.toUpperCase())
                  ) {
                    return (
                      <div className={classes.imgName} key={index}>
                        <span>
                          <img
                            src={item.src}
                            alt={`thumbnail icon${Math.floor(
                              Math.random() * 20
                            )}`}
                            className={classes.thumbnail}
                          />
                        </span>
                        <span className={classes.thumbnailImgName}>
                          {props.mediaFileName}
                        </span>
                      </div>
                    );
                  }
                })}
              </TableCell>

              <TableCell id={"confirmation-tr1-td3"} align="right">
                <div>

                <ExitModalContent
                id="case-exitmodal"
                exit={props.deleteMediaFile}
                contentMessage = "Remove_Attachment_Text"
                btn_msg="return_case_button"
            />
                </div>
              </TableCell>
            </TableRow>
          </th>
        </TableBody>
      </ReactBootstrap.Table>
    </div>
  );
};

export default UploadMediaRow;
