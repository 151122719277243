export enum SearchType {
    Customer = "Customer",
    Property = "Property",
    Servicerequest  = "Service request",
    WorkOrder = "Work Order",
    CustomerWorkOrder = "Customer Work Order",
    CaseList = "CaseList"
}

export enum BackValue {
    Customer360View = "Customer360View",
    Asset360View = "Asset360View",
    WorkOrder360View = "WorkOrder360View",
    Case360View = "Case360View"
}

export enum AlertValue {
    Rapid = "RAPID"
}


export enum Description {
    Customer_Charge ="Customer has a question about their Service Charge",
    Customer_own = "Customer has a question about the home they own",
    Leasehold ="Leasehold or shared ownership enquiry",
    Customer_medical ="Customer needs to move for medical reasons",
    application="Enquire about a Homehunt application",
    Customer_home="Customer has a question regarding buying their home",
    Customer_reference ="Customer needs a reference",
    Customer_Agreement ="Customer needs a copy of their Tenancy Agreement",
    Customer_card ="Customer needs to order a rent card",
    Customer_them="Customer wants to request the data that Peabody hold for them",
    Change_subletting="Change to household members or subletting",
    access="Access is needed to the utility meters"
}

export enum AssetTypes {
    Property = "Property",
    Block = "Block",
    Estate = "Estate",
    DividedBlock = "Divided Block"
  };

  export enum SurveyTypes {
    Void_Survey = "Void Survey",
    Keysafe_Installation = "Keysafe Installation",
    VOID= "VOID",
    OCCP="OCCP",
    VOIDSURVEY="VOIDSURVEY",
    Survey= "Survey",
    survey= "survey"
  };  
 
export enum CustomerOrProperty {
    Customer = "Customer",
    Property = "Property"
}

export enum WOTypes {
    survey = "survey",
    repair = "repair"
};