import React, { useState, useEffect, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import {
    saveAssociatedAssests
} from "../../../ducks/redux/actions/getAddress";
import { userChosenPropertyAddress, userChosentenancyGroup } from "../../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../../utils/translation/helper";
import {
    CASE_EDIT,
    CASE_PROPERTY_TENANCY_GROUP,
    CASE_SELECTED_ANSWERS,
    CLEAR_CASE_STATE,
    USERCHOSEN_PROPERTY_ID,
} from "../../../ducks/redux/actionTypes";
import classes from "./style.module.css";
import { trackEvent } from "../../../appInsights/TelemetryService";
import { Container } from "@mui/material";
import Loader from "react-loader-spinner";
import { BackValue } from "../../../models/shared/Enum";
const Button = React.lazy(() => import("../../../Button/Button"));

const ExitModalContent = React.lazy(
    () => import("../../Modal/ExitModalContent")
);

interface SelectTenencyProps extends RouteComponentProps<any> { }
const SelectTenency: React.FC<SelectTenencyProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const array: any[] = [];

    const [isLoading, setIsLoading] = useState(false);
    const [AllAsset, SetAllAssetData] = useState(array);
    const [isError, setError] = useState("none");
    const backValue = useSelector(
        (state: any) => state?.userChoicesReducer?.backValue
      );
    const [buttondisable, setsetButtonDisable] = useState(false);
    const [getTenancyGroup, setTenancyGroup] = useState("");
    const [activeTenacyPremisesIds, setActiveTenacyPremisesIds] = useState<any>(
        []
    );

    const userChosenPropertyId = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyId
    );

    const radiovalue = useSelector(
        (state: any) => state.userChoicesReducer.userChosenPropertyAddress
    );

    const customerID = useSelector((state: any) => state.addresses?.customerid);


    const activeTenanciesSummaries = useSelector((state: any) => state?.addresses?.customertenancies);


    useEffect(() => {
        trackEvent("SelectTenancy");

        (async function () {
            try {
                setIsLoading(true);

                let residentialProperties: {
                    premisesid: string;
                    tenancygroup: string;
                    startdate: string | null;
                }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];

                let nonResidentialProperties: {
                    premisesid: string;
                    tenancygroup: string;
                    startdate: string | null;
                }[] = [{ premisesid: "", tenancygroup: "", startdate: "" }];
                // set work order to null for create new work order
                const tenancyResponse = await api.getTenancies(customerID);

                if (tenancyResponse?.length > 0 && activeTenanciesSummaries?.length > 1) {
                    tenancyResponse.map((itemTenancy) => {
                        activeTenanciesSummaries.map((itemActiveTenancy: any) => {
                            if (
                                itemTenancy.tenancyIdentifier ==
                                itemActiveTenancy.tenancySummary.tenancyId
                            ) {
                                const premiseid =
                                    itemActiveTenancy?.tenancySummary?.premisesIds[0];

                                const startdateValue =
                                    itemActiveTenancy?.tenancySummary.tenancyStartDate;

                                const groupTenancy = itemTenancy.tenancyGroup;

                                if (
                                    itemTenancy.tenancyGroup == "Non-residential" ||
                                    itemTenancy.tenancyGroup == "Commercial"
                                ) {
                                    nonResidentialProperties.push({
                                        premisesid: premiseid,
                                        tenancygroup: groupTenancy,
                                        startdate: startdateValue,
                                    });
                                } else {
                                    residentialProperties.push({
                                        premisesid: premiseid,
                                        tenancygroup: groupTenancy,
                                        startdate: startdateValue,
                                    });
                                }
                            }
                        });
                    });
                }
                const resAddAssestsArray: any = [];
                const promisesResidential = residentialProperties.map(
                    async (itemdata) => {
                        if (itemdata.premisesid !== "") {
                            const responseResidential: any =
                                await api.getAssociatedAssets(itemdata.premisesid);
                            resAddAssestsArray.push(responseResidential);

                            return responseResidential
                                .filter((data: any) => {
                                    return data.id === itemdata.premisesid;
                                })
                                .map((eData: any) => {
                                    return {
                                        ...itemdata,
                                        residential: true,
                                        formattedAddress:
                                            eData.addresses.postalAddresses[0].formattedAddress,
                                    };
                                });
                        }
                    }
                );
                const promisesNonResidential = nonResidentialProperties.map(
                    async (itemdata) => {
                        if (itemdata.premisesid !== "") {
                            const responseNonResidential: any =
                                await api.getAssociatedAssets(itemdata.premisesid);
                            resAddAssestsArray.push(responseNonResidential);

                            return responseNonResidential
                                .filter((data: any) => {
                                    return data.id === itemdata.premisesid;
                                })

                                .map((eData: any) => {
                                    return {
                                        ...itemdata,
                                        residential: false,
                                        formattedAddress:
                                            eData.addresses.postalAddresses[0].formattedAddress,
                                    };
                                });
                        }
                    }
                );

                SetAllAssetData(resAddAssestsArray);

                const parsedData = await Promise.all([
                    ...promisesResidential,
                    ...promisesNonResidential,
                ]);

                const resData = parsedData
                    .filter((e: any) => (e && e[0] ? true : false))
                    .flat()
                    .sort((a: any, b: any) =>
                        new Date(b.startdate).valueOf() -
                        new Date(a.startdate).valueOf()
                    );

                setActiveTenacyPremisesIds(resData);

                // ======  getting case tenancy group value ========

                resData.map((tenancy: any) => {
                    if (tenancy.formattedAddress === radiovalue) {
                        setTenancyGroup(tenancy.tenancygroup);
                    }
                });

                // ============== end ======================

                const selectedtData = (!isUndefinedNullOrEmpty(radiovalue)) ? resData.filter((data: any) => {
                    return data.formattedAddress === radiovalue;
                }) : resData.filter((eachData: any) => {
                    return eachData.premisesid === userChosenPropertyId;
                });

                callSelection(
                    selectedtData[0]?.formattedAddress,
                    selectedtData[0]?.premisesid,
                    resAddAssestsArray,
                    selectedtData[0]?.tenancygroup
                );
                //redirect to next page if single tenancy
                if (resData.length === 1) {
                    dispatch({
                        type: CASE_PROPERTY_TENANCY_GROUP,
                        payload: resData[0].tenancygroup,
                    });
                    dispatch(userChosentenancyGroup(resData[0].tenancygroup));
                    setIsLoading(false);
                    props.history.push("/CaseAsset");
                }

                setIsLoading(false);
                setError("none");
                setsetButtonDisable(false);
            } catch (e) {
                setError("block");
                setsetButtonDisable(true);
                props.history.push("/genericerror");
            }
        })();
    }, []);

    const backButtonHandler = () => {
        dispatch(userChosenPropertyAddress(""));
        if (backValue == BackValue.Customer360View) {
            props.history.push("/Customer360View");
          } else if (backValue == BackValue.Asset360View) {
            props.history.push("/Asset360View");
          } else if(backValue === BackValue.Case360View){
            props.history.push("/Case360View");
          }
    };

    const exitButtonHandler = () => {
        if (backValue == BackValue.Customer360View) {
            props.history.push("/Customer360View");
          } else if (backValue == BackValue.Asset360View) {
            props.history.push("/Asset360View");
        } else {
            props.history.push("/Case360View");
          }
    };

    const onContinueHandler = () => {
        dispatch({ type: CASE_PROPERTY_TENANCY_GROUP, payload: getTenancyGroup });
        dispatch(userChosentenancyGroup(getTenancyGroup));
        props.history.push("/CaseAsset");
    };

    const updateSelection = (
        value: string,
        premiseId: any,
        tenancygroup: any,
        arr: any = AllAsset
    ) => {
        setTenancyGroup(tenancygroup);

        let associatedAssets: any[] = [];
        arr.forEach((element: any) => {
            const resultF = element.filter((data: any) => data.id === premiseId);
            if (resultF !== null && resultF.length > 0) {
                associatedAssets.push(element);

                dispatch(saveAssociatedAssests(associatedAssets));
            }
        });
        dispatch(userChosenPropertyAddress(value));
        dispatch({ type: USERCHOSEN_PROPERTY_ID, payload: premiseId });
        // ==== If user is updating the property address, remove all the case related data from store ====
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
        dispatch({ type: CLEAR_CASE_STATE });
        dispatch({ type: CASE_EDIT, payload: true });
    };
    const callSelection = (
        value: string,
        premiseId: any,
        arr: any = AllAsset,
        tenancygroup: any
    ) => {
        let associatedAssets: any[] = [];
        arr.forEach((element: any) => {
            const resultF = element.filter((data: any) => data.id === premiseId);
            if (resultF !== null && resultF.length > 0) {
                associatedAssets.push(element);
                dispatch(saveAssociatedAssests(associatedAssets));
            }
        });
        dispatch(userChosenPropertyAddress(value));
        setTenancyGroup(tenancygroup);
    };

    return (
        <Container id="select-tenancy-container" data-testid="select-tenancy-container" className="ctn-fht">
            <Suspense
                fallback={
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={70}
                        width={50}
                        timeout={5000}
                    />
                }
            >
            <div
                id="SelectTenancy-back-div"
                className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
            >
                <p id="SelectTenancy-p" className="text-teal text-xl mt-4 mb-8">
                    <a
                        className="back-btn"
                        data-testid="backButtonHandler"
                        onClick={backButtonHandler}
                    >
                        <i className="fas fa-angle-left mr-2"></i>
                        {t("back")}
                    </a>
                </p>
            </div>
              {isLoading ? (
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={70}
                    width={50}
                    timeout={5000}
                />
                ) : (
                    <div id="repAddDet-main-div"
                        className="ml-sm-5 pl-sm-5 repair-text container">
                            <div
                                className="ml-sm-5 ml-lg-5 pl-sm-5 pt-0 lg:ml-10 md:ml-10 repair-text"
                                id="repair-main-div"
                            >
                                <div className="h5 question-font-size" id="repair-div1">
                                    <div className="mx-0 px-0 max-w-4xl" id="repair-h1">
                                        <h2 className="text-purple-100 text-2xl mb-8 global-txt">
                                            {t("Case_OPC003a")}
                                        </h2>
                                    </div>
                                </div>
                                <RadioGroup
                                    data-testid="case-radiogroup"
                                    name="radio-buttons-group"
                                    value={radiovalue}
                                    className="question-btn mx-0 px-0 max-w-4xl my-2 "
                                >
                                    {activeTenacyPremisesIds
                                        .filter((e: any) => {
                                            return e.residential;
                                        })
                                        .map((e: any) => {
                                            return (
                                                <FormControlLabel
                                                    data-testid="formControlResidential"
                                                    value={e.formattedAddress}
                                                    key={e.premisesid}
                                                    className="mb-4 global-txt card-text margin-lt"
                                                    control={
                                                        <Radio
                                                            key={e.premisesid}
                                                            id={"radio-id1"}
                                                            data-testid="button1"
                                                            name={e.formattedAddress}
                                                            className={`${classes.radioinput} global-txt`}
                                                            onChange={(
                                                                v: React.ChangeEvent<HTMLInputElement>
                                                            ): void => {
                                                                updateSelection(
                                                                    v.target.value,
                                                                    e.premisesid,
                                                                    e.tenancygroup
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={e.formattedAddress}
                                                />
                                            );
                                        })}
                                    {activeTenacyPremisesIds
                                        .filter((e: any) => {
                                            return !e.residential;
                                        })
                                        .map((e: any) => {
                                            return (
                                                <FormControlLabel
                                                    data-testid="formControlNonResidential"
                                                    value={e.formattedAddress}
                                                    key={e.premisesid}
                                                    className="mb-4 global-txt card-text margin-lt"
                                                    control={
                                                        <Radio
                                                            key={e.premisesid}
                                                            className={classes.radioinput}
                                                            id="radio-id"
                                                            data-testid="button2"
                                                            name={e.formattedAddress}
                                                            onChange={(
                                                                v: React.ChangeEvent<HTMLInputElement>
                                                            ): void => {
                                                                updateSelection(
                                                                    v.target.value,
                                                                    e.premisesid,
                                                                    e.tenancygroup
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={e.formattedAddress}
                                                />
                                            );
                                        })}
                                </RadioGroup>
                            </div>

                            <div
                                id="repAddDet-div5"
                                style={{ display: isError }}
                                className="alert alert-warning error-reason mar-desk"
                                role="alert"
                                placeholder="alert"
                            >
                                <span className="global-error-icon">
                                    <i className="fas fa-exclamation me-2"></i>
                                </span>
                                <span>{t("SSR001")}</span>
                            </div>

                            <div
                                className="flex justify-between items-center pb-4 md:pb-8"
                                id="repair-exit-btn"
                            >
                                <ExitModalContent
                                    id="repairproperty-exitmodal"
                                    exit={exitButtonHandler}
                                    contentMessage="Case_OPC001"
                                    btn_msg="Case_Return_Button"
                                />
                            <Button
                                id="caseproperty-btn"
                                onClick={onContinueHandler}
                                disabled={buttondisable}
                                className="button-class"
                                data-testid="btn_caseproperty"
                            >
                                {t("continue")}
                                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
                            </Button>
                            </div>
                        </div>
                    )}
            </Suspense>
        </Container>
    );
};

export default SelectTenency;
