import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import * as api from "../../api";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import SurveyReason from "../../compositions/SurveyReason/index";
import {
  userChoiceRepairReasons,
  backToRepairAsset,
  userChosenHighLevelDescription,
  userChosenRepairLocationId,
  userChosenSOR,
  userTypedSurveyReason,
} from "../../ducks/redux/actions/userChoices";
import {
  userTypedRepairReasons,
} from "../../ducks/redux/actions/dashboardReducer.actions";

import { RouteComponentProps } from "react-router-dom";
import {
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
} from "../../ducks/redux/actions/userChoices";
import { AssetTypes, BackValue } from "../../models/shared/Enum";
import{SurveyTypes} from "../../models/shared/Enum";
interface SurveyLocationProps extends RouteComponentProps<any> { }

const SurveyLocation: React.FC<SurveyLocationProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let referenceDataresponsed;
  const array: any[] = [];
  const [surveyTypeList, setSurveyTypeList] = useState(array);
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setdisabled] = useState(false);

  const GetuserTypedSurveyReasons = useSelector(
    (state: any) => state.dashboardReducer.formState.userTypedRepairReasons
  );
  const selectedTenacyType = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );
  const assetStatusVal=useSelector(
    (state: any) => state?.userChoicesReducer.assetDataStatus  
  )
  
  const [surveyLocation, setSurveyLocation] = useState((assetStatusVal==SurveyTypes.VOID && GetuserTypedSurveyReasons=="Void Survey")  ? SurveyTypes.Void_Survey:(assetStatusVal==SurveyTypes.VOID && GetuserTypedSurveyReasons=="")?SurveyTypes.Void_Survey:"");
  const [getSorcode, setSorcode] = useState(assetStatusVal==SurveyTypes.VOID ? SurveyTypes.VOIDSURVEY:'');

  let sorCode ="";

  const [isRecallValue, setIsRecallValue] = useState(false);
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
 
  const resumeDraftRepair = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );

  useEffect(() => {
    if (IsRecall) {
      setIsRecallValue(true)
    }
    else {
      setIsRecallValue(false)
    }
    if (GetuserTypedSurveyReasons) {
      setSurveyLocation(GetuserTypedSurveyReasons);
      setdisabled(true);
    }
  }, []);



  useEffect(() => {
    
    (async function () {
      try {
        const surveyTypes:string ='survey-types'
        referenceDataresponsed = await api.getServeyReferenceData(
          surveyTypes
        ); 

        let keyValuePairs: any = "";
        if (referenceDataresponsed) {
          keyValuePairs =referenceDataresponsed[0]?.namespace?.keyValues;
          setSurveyTypeList(keyValuePairs);
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
    if(surveyLocation==="Void Survey"){
      dispatch(userTypedRepairReasons(surveyLocation));
      dispatch(userChoiceRepairReasons(surveyLocation));
      dispatch(userChosenSOR('VOIDSURVEY'));
    }
  }, []);

  let fieldsArray: any[] = [];
  let surveyDetails: any = [];
  let arrayObject = Object.entries(surveyTypeList);
  arrayObject.map((eachLocation, key) => {
    fieldsArray.push(
      <option
        key={eachLocation[0]}
        className="block mt-1 width-css mt-1 text-black global-txt"
        value={eachLocation[1].description}
      >
        {eachLocation[1].description}
      </option>
    );
    surveyDetails.push(eachLocation[1]); 
  });

 

  const backButtonHandler = () => {
    if (!resumeDraftRepair) {
      if (!IsRecall) {
        dispatch(userChosenRepairLocationId(0));
        dispatch(userChosenHighLevelDescription(""));
      }
    }
    dispatch(backToRepairAsset(true));
    props.history.push("/SurveyAsset");
  };

  const handleChange = (e: any) => {
    setdisabled(true);
    
    surveyDetails.map((sor:any) => { 
      if(e.target.value === sor.description) {
        if(selectedTenacyType===AssetTypes.Property){
          sorCode=sor.propertySor;
          setSorcode(sorCode);
        }
        else {
          sorCode=sor.communalSor;
          setSorcode(sorCode);
        }
      }
    });
    dispatch(userChosenSOR(sorCode));
    
    if (surveyLocation != e.target.value) {     
      setSurveyLocation(e.target.value);
      dispatch(userTypedRepairReasons(e.target.value));
      dispatch(userChoiceRepairReasons(e.target.value));
      dispatch(userTypedSurveyReason(''));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      setdisabled(true);
    }
  };

  return (
    <Container id="reploc-container" role="main" className="ctn-fht ">
      <div
        id="RepairLocation-back-div"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="RepairLocation-p" className="text-teal text-xl mt-4 mb-8">
          <a
            className="back-btn"
            data-testid="backButtonHandler"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            {t("back")}
          </a>
        </p>
      </div>
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={7000} //3 secs
        />
      ) : (
        <div
          className="question ml-sm-5 pl-sm-5 repair-text global-txt container"
          id="reploc-main-div"
        >
          <div className="h5" id="reploc-div1">
            <h2 className="text-purple-100 text-2xl mb-3 global-txt ">
              {t("survey_create_heading")}
    
            </h2>
          </div>
          <select
            id="reploc-sel"
            data-testid="dropDownLocation"
            name="dropDownLocation"
            placeholder="dropDownLocation"
            value={surveyLocation}
            aria-label="reploc-sel"
            disabled={isRecallValue}
            onChange={handleChange}
            className="block width-css mt-1 text-black global-txt rounded border-grey"
          >
            <option
              value="No option selected"
              className="block mt-1 width-css mt-8 text-black global-txt"
              disabled={disabled}
            >
              Select
            </option>
            {fieldsArray} </select>
        </div>
      )}
     

      {<SurveyReason {...props} getSorcode={getSorcode}/>}

    </Container>
  );
};

export default SurveyLocation;
