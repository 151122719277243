import { Amount } from "."

export default class AccountDetails {
    public LiveBalance: string =''
    public LiveBalanceType:string | null = ''
    public AccountType:string | null  = ''
    public LastArrearsAction:string | null  = ''
    public AccountNumber:string | null = ''
    public Payref:string | null = ''
    public DisplayNumber:number = 0.00
    public transactDeatils: TransactDeatils[] = []
    public lastTransactionBalance: number =0.00
    public lastTransactionDate: string | null = ''
}
export  class TransactDeatils {
    public transactiondate: string | null = ''
    public LiveBalance: number =0.00
    public LiveBalanceType:string | null = ''
    public TransactionBalanceType:string | null = ''
}
