import React, { FC, ReactNode, useEffect, useState } from "react";
import * as api from "../../api";

interface Props {
  children?: ReactNode;
}

const IsCaseMediaAllowed: string = "IsCaseMediaAllowed";

export const FeatureFlags = React.createContext({});

export const FeatureFlagsProvider: FC<Props> = ({ children }) => {
  const [featureMedia, setFeatureMedia] = useState("false");

  const getMediaFlag = async () => {
    try {
      const data = await api.GetAppConfigToggleValue(IsCaseMediaAllowed);
      setFeatureMedia(data.value);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMediaFlag();
  }, []);

  return (
    <FeatureFlags.Provider value={{ featureMedia }}>
      {children}
    </FeatureFlags.Provider>
  );
};
