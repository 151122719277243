import { Typography } from '@material-ui/core';
import styled from 'styled-components'

export const StyledOverflowHidden = styled(Typography)({
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});




