
import CustomerAddresses from '../shared/Address/CustomerAddresses'
import Link from '../shared/Link'
import AssistanceRequirement from './AssistanceRequirement'
import Person from './Person'
import Preference from './Preference'
import Status from './Status'
import TenancySummary from './TenancySummary'

export default class CustomerResponseDetails {
  public 'customer-identifier': string = ''
  public eTag: string = ''
  public person: Person = new Person()
  public preferences: Preference[] = []
  public statuses: Status[] = []
  public assistanceRequirements: AssistanceRequirement[] = []
  public addresses: CustomerAddresses = new CustomerAddresses()
  public tenanciesSummaries: TenancySummary[] = []

  // tslint:disable-next-line: variable-name
  private _links: Link[] = []
}

