export const PropertyType= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Scheme',
        value: 'Scheme'
    },
    {
        key: 'Estate area',
        value: 'Estate Area',
    },
    {
        key: 'Estate',
        value: 'Estate',
    },
    {
        key: 'Block',
        value: 'Block',
    },
    {
        key: 'Divided block',
        value: 'Divided Block',
    },
    {
        key: 'Property',
        value: 'Property',
    }   
  ]