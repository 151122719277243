export const CasePriorityFilter= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Low',
        value: 'Low'
    },
    {
        key: 'Normal',
        value: 'Normal',
    },
    {
        key: 'High',
        value: 'High',
    }
]