export default class Customer {
    public id: string = ''
    public dateOfBirth: string = ''
    public email: string = ''
    public homePhone: string = ''
    public workPhone: string = ''
    public mobilePhone: string = ''
    public postalAddress: string = ''
    public customerType: string = ''
    public peabodyRegistration: string = ''
  }