import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../api";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TenancyDataTypes, UntidyTenancyTypes } from "../../models/UCC/UniversalCreditCard";
import { uCCTenancyModifiedData } from "../../ducks/redux/actions/userChoices";
import classes from "./styles.module.css";

interface UCCTenancyDetailsProps extends RouteComponentProps<any> { }
const UCCTenancyDetails: React.FC<UCCTenancyDetailsProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [UCCTenancyData, setUCCTenancyData] = useState<TenancyDataTypes>();
    const [copyStatusPaymentRefNo, setCopyStatusPaymentRefNo] = useState(t("UCCTenancy.UC001"));
    const [copyStatusJoinTenant, setCopyStatusJoinTenant] = useState(t("UCCTenancy.UC001"));
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    const customerID = useSelector((state: any) => state?.userChoicesReducer?.custommerID);
    const customerData = useSelector((state: any) => state?.userChoicesReducer?.customersData);
    const assetData = useSelector((state: any) => state?.userChoicesReducer?.assetDataResponse);
    const accountData = useSelector((state: any) => state?.userChoicesReducer?.customerAccountData);
    const assetsByIDData = useSelector((state: any) => state?.userChoicesReducer?.assetsByIDData);

    const tenantData: TenancyDataTypes = new TenancyDataTypes();
    const requestData: UntidyTenancyTypes = new UntidyTenancyTypes();
    const allJointTenantNames: any = [];

    useEffect(() => {
        (async () => {
            const assetProperty = assetData?.filter((eachAsset: any) => eachAsset.assetType === 'Property');
            const tenancySum = customerData?.tenanciesSummaries?.filter((eachSummary: any) => eachSummary.tenancySummary.uprn === assetProperty[0].uprn && eachSummary.tenancySummary.statuses[0].value === true);
            tenantData.paymentReference = tenancySum && tenancySum[0]?.tenancySummary?.paymentReference;
            tenantData.tenancyType = tenancySum && tenancySum[0]?.tenancySummary?.tenancyType;
            try {
                const response = await api.getSearchCustomer(tenancySum[0]?.tenancySummary?.uprn);
                if (response.models.length > 1) {
                    const filteredCustomers = response.models.filter((el: any) => el.id !== customerID);
                    filteredCustomers.map((el: any, key: number) => {
                        key !== filteredCustomers.length - 1 ? allJointTenantNames.push(`${el.givenName} ${el.surname},`) : allJointTenantNames.push(`${el.givenName} ${el.surname}`);
                    });

                    tenantData.joinTenant = filteredCustomers ? allJointTenantNames : ['-'];
                } else {
                    tenantData.joinTenant = ['No'];
                }
            } catch (error) { } 
            
            tenantData.NoOfBedrooms = assetsByIDData?.configuration?.numberOfBedRooms === null ? '-' : assetsByIDData?.configuration?.numberOfBedRooms;
            tenantData.isTenancyActive = tenancySum && tenancySum[0]?.tenancySummary?.statuses[0].value;

            const liveRentAccount = accountData?.filter((eachAccount: any) => eachAccount?.identifiers['pay-ref'].replace(/\D/g, '') === tenantData.paymentReference && (eachAccount['account-type'] === 'REN' || eachAccount['account-type'] === 'FD5') && eachAccount?.statuses['is-closed'] !== true);
            tenantData.NoOfLiveRentAccount = liveRentAccount?.length;
            tenantData.postalAddresses = assetData && assetData[0]?.addresses?.postalAddresses[0];
            tenantData.assetIdentifier = assetData && assetData[0]?.id;
            tenantData.customerIdentifier = customerID;

            requestData.tenancyId = tenancySum && tenancySum[0]?.tenancySummary?.tenancyId;
            requestData.uprn = tenancySum && tenancySum[0]?.tenancySummary?.uprn;
            requestData.noteType = 'UNTIDYTEN'; 

            try {
                const response = await api.getUntidyTenancy(requestData);
                tenantData.untidyTenancy = typeof response === 'object' && response !== null ? 'Yes' : 'No';
            } catch (error) { }

            setUCCTenancyData(tenantData);
            dispatch(uCCTenancyModifiedData(tenantData));

            const { innerWidth, innerHeight } = window;
            if (innerWidth >= 314 && innerWidth <= 1024) {
                setIsMobileDevice(true);
            } else {
                setIsMobileDevice(false);
            }
        })()
    }, [assetData, customerData?.tenanciesSummaries, window.innerWidth]);

    const copyText = (event: any) => {
        let copiedText: any;

        if (event.target.id === 'PaymentRefNo') {
            copiedText = UCCTenancyData?.paymentReference;
            setCopyStatusPaymentRefNo(t("UCCTenancy.UC002"));
        } else if (event.target.id === 'JointTenant') {
            copiedText = UCCTenancyData?.joinTenant.join(" ");
            setCopyStatusJoinTenant(t("UCCTenancy.UC002"));
        }

        navigator?.clipboard?.writeText(copiedText);
    }

    const showReminderHandler = () => {
        const newTenantData = {...UCCTenancyData, showCreateArrearsReminder: true}
        dispatch(uCCTenancyModifiedData(newTenantData));
    }

    return (
        <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4">
            < div className="px-4 py-2 mt-2 mb-2 border-b border-grey" >
                <div className=" flex flex-row d-flex bd-highlight items-center mt-2">
                    <i className="fa fa-credit-card text-teal text-2xl mr-6 bd-highlight"></i>
                    <h2 className="my-0 text-purple-100 bd-highlight">{t("UCCTenancy.TenancyDetails")}</h2>
                </div>
            </div >

            <div className="px-4 mb-8 global-txt" data-testid="tenancyDetailsContainer" onClick={showReminderHandler}>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCTenancy.PaymentRefNo")}</div>

                    {isMobileDevice ? <div id="PaymentRefNo2" data-testid="PaymentRefNoValue2">{UCCTenancyData?.paymentReference}</div> :
                        <div className={classes.tooltip} onClick={copyText} id="PaymentRefNo" data-testid="PaymentRefNoValue">{UCCTenancyData?.paymentReference}
                            <span className={classes.tooltiptext}>{copyStatusPaymentRefNo}</span>
                        </div>}
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCTenancy.TenancyType")}</div>
                    <div>{UCCTenancyData?.tenancyType}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCTenancy.JointTenant")}</div>
                    
                    {isMobileDevice ?
                        <ul className={classes.joinTenancyNames} data-testid="JointTenantValue">
                            {UCCTenancyData?.joinTenant.map((el, key) => <li id="JointTenant" key={key}>{el}</li>)}
                        </ul> :
                        <ul className={`${classes.tooltip} ${classes.joinTenancyNames}`} onClick={copyText} data-testid="JointTenantValue" id="JointTenant">
                            {UCCTenancyData?.joinTenant.map((el, key) => <li id="JointTenant" key={key}>{el}</li>)}
                            <span className={classes.tooltiptext}>{copyStatusJoinTenant}</span>
                        </ul>}
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCTenancy.NoOfBedrooms")}</div>
                    <div>{UCCTenancyData?.NoOfBedrooms}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCTenancy.UntidyTenancy")}</div>
                    <div>{UCCTenancyData?.untidyTenancy}</div>
                </div>

                {
                    UCCTenancyData?.NoOfLiveRentAccount !== undefined && UCCTenancyData?.NoOfLiveRentAccount > 1 &&
                    <div className="px-2 py-2 flex justify-between border-b border-grey">
                        <div className="pr-4">{t("UCCTenancy.No_of_live_rent_account")}</div>
                        <div>{UCCTenancyData?.NoOfLiveRentAccount}</div>
                    </div>
                }
            </div>
        </div >
    )
};
export default UCCTenancyDetails;
