import { EligibilityOutcome } from '../Property'
import { CurrentRepair } from '../Repair/Repair'
import TelecomsAddress from '../shared/Address/TelecomsAddress'
import { Person } from '../shared/Person'
import { RaisedBy } from '../shared/RaisedBy'
import { Statuses } from '../shared/Statuses'
import { Appointment } from './Appointment'
import { Asset } from './Asset'
import { Contractor } from './Contractor'
import { Customer } from './Customer'
import { Identifiers } from './Identifier'
import { Job } from './Job'
import { SorDeterminationResponse } from './SorDetermination'

export class WorksOrdersResponse {
  public worksOrders: WorksOrder[] = []
  public totalCount: number = 0
  statusCode: any

  constructor(worksOrdersResponse: any) {
    this.worksOrders = !!worksOrdersResponse ? worksOrdersResponse.worksOrders : []
    this.totalCount = !!worksOrdersResponse ? worksOrdersResponse.totalCount : 0
  }
}

export class WorksOrder {
  public statuses?: Statuses | null
  public identifiers?: Identifiers | null
  public job?: Job | null
  public appointments?: Appointment[] | null
  public raisedBy?: RaisedBy | null
  public contractor?: Contractor | null
  public asset?: Asset | null
  public reporter?: {
    person: Person | null
    addresses: {
      telecomsAddresses?: TelecomsAddress[]
    } | null
    reporterToBeContacted: boolean
    organisation: string
    reporterRelationship: {
      reportedById: number
      reportedBy: string
    }
    reportingMethod: {
      reportingMethodId?: number
      reportingMethod?: string
    }
  } | null
  public customer?: Customer | null
  public eligibilityOutcomes?: EligibilityOutcome[] = []
  public raisedDate?: string | null
  public completedDate?: string | null
  public eTag?: string = 'eTag'
  public channel?: string = ''

  constructor(currentRepair: CurrentRepair) {
    this.statuses = {
      isDraft: currentRepair.isDraft,
      isEmergency: currentRepair.isEmergency,
      isOutOfHours: currentRepair.isOutOfHours,
      isRiskToHumanLife: currentRepair.riskToHumanLife || null,
      status: currentRepair.status,
    }

    this.identifiers = {
      worksOrderIdentifier: currentRepair.id,
      customerIdentifier:currentRepair.customerIdentifier||null,
      originalWorkOrderIdentifier: currentRepair.originalWorkOrderIdentifier || null,
      originalWorkOrderJobId: currentRepair.originalWorkOrderJobId || null,
      jobNumber: currentRepair?.jobNo,
      contractorSiteIdentifier: currentRepair.contractor,
      crimeReferenceNumber: currentRepair.crimeReferenceNumber,
      asset: {
        assetIdentifier: currentRepair.premisesId,
        premisesUniqueReference: currentRepair.premisesUniqueReference,
      },
      documents: currentRepair.documents
    }
    this.job = {
      location: {
        locationId: currentRepair.repairLocationId,
        location: currentRepair.repairLocation,
      },
      trade: currentRepair.trade,
      cause: {
        cause: currentRepair.repairCause,
        causeId: currentRepair.repairCauseId,
      },
      sorCode: currentRepair.sorCode,
      description: currentRepair.description,
      repairReason: currentRepair.repairReason,
      surveyReason: currentRepair.surveyReason,
      priority: {
        priority: currentRepair.priority
      }, 
      repairType: currentRepair.repairType,
      additionalInformation: currentRepair.additionalInformation,
      workProgramme: currentRepair.workProgramme,
    }
    this.appointments = [
      {
        appointmentStartDate: currentRepair.appointmentStartDate,
        appointmentEndDate: currentRepair.appointmentEndDate,
      },
    ]
    this.raisedBy = new RaisedBy(
      currentRepair.raisedBy.person.name,
      currentRepair.raisedBy.address.digitalAddresses
    )
    this.contractor = {
      name: currentRepair.contractor,
      phoneNumber: '',
    }
    this.asset = {
      type: {
        assetTypeId: currentRepair.premisesTypeId,
        assetTypeName: '',
      },
      fullAddress: currentRepair.premisesAddress?.formattedAddress,
      isInDefect: currentRepair.isInDefect,
    }
    this.reporter = {
      person: {
        name: {
          title: '',
          givenName: currentRepair.reporterContactDetails?.name?.givenName || '',
          surname: '',
        },
      },
      addresses: {
        telecomsAddresses: [
          {
            number: currentRepair.reporterContactDetails?.contactNumber || '',
            deviceId: '',
            dialingCode: '',
            countryCode: '',
            telecomsAddressType: 'telephone',
            isPreferred: true,
          },
        ],
      },
      reporterToBeContacted: currentRepair.reporterToBeContacted,
      organisation: currentRepair.reporterContactDetails?.organisation || '',
      reporterRelationship: {
        reportedById: currentRepair.reportedById || 1,
        reportedBy: currentRepair.reportedBy,
      },
      reportingMethod: {
        reportingMethodId: currentRepair.reportingMethodId,
        reportingMethod: currentRepair.reportingMethod || '',
      },
    }
    this.customer = {
      person: {
        name: {
          title: currentRepair.customerName?.title,
          givenName: currentRepair.customerName?.givenName,
          surname: currentRepair.customerName?.surname,
        },
        chargeCustomer: currentRepair.chargeCustomer,
      },
      vulnerability: {   
        isVulnerable : currentRepair.isVulnerable,
        vulnerableResponseText : currentRepair.VulnerableResponseText
      } , 
      notification: {
        notifyAll: currentRepair.notifyAll,
        address: {
          digitalAddresses: currentRepair.staffContactDigitalAddresses,
        },
      },
    }

    this.raisedDate = currentRepair.raisedDate
    this.completedDate = currentRepair.completedDate
    this.eTag = currentRepair.eTag
    this.channel = currentRepair.channel
  }
}

export { SorDeterminationResponse }
