import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import {
    formatContent,
    formatCustomerName,
    formatDateWithFromCalendar, getUpdateOwnerName, isNullOrUndefined
} from "../../../utils/formatting";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import { trackEvent } from "../../../appInsights/TelemetryService";
import { useSelector } from "react-redux";
import { ComplexCaseTypes } from "../../../models/Case/ComplexCase";
import { ComplexCaseowningTeams } from "../../../models/Case/ComplexCaseowningTeams";
import { StyledOverflowHidden, StyledOverflowNonHidden } from "./component";
import { isNull } from "util";

interface CaseDetailsProps extends RouteComponentProps<any> {
}
const CaseDetailsComponent: React.FC<CaseDetailsProps> = (props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [overflowActive, setOverflowActive] = useState<boolean | null>(null);
    const overflowingText = useRef<HTMLParagraphElement | null>(null);
    const [activeCase, setActiveCase] = React.useState("");
    const [originalDescription, setOriginalDescription] = useState("");

const caseData = useSelector(
        (state: any) => state?.userChoicesReducer?.caseData
    );

    useEffect(() => {
        trackEvent("CaseDetails");
        (async () => {
            if (caseData?.description && caseData.description !== '') {
                setOriginalDescription(caseData.description);                
            }
        })();
    }, [caseData]);

    useEffect(() => {
        if (checkOverflow(overflowingText.current)) {
            setOverflowActive(true);
            return;
        }
        setOverflowActive(false);
    }, [overflowActive]);

    const viewDetailsCaseDescriptionHandler = (id: string, description: string) => {
        if (activeCase != "" && activeCase.indexOf(id) >= 0) {
            setActiveCase("");
        } else {
            setActiveCase(id);
        }
    };

    const onViewMediaHandler = (caseIdentifier: any) => {
        props.history.push({
          pathname: "/viewmedia",
          state: caseIdentifier,
        });
    };

    const checkOverflow = (textContainer: HTMLParagraphElement | null): boolean => {
        
        if (textContainer !== null) {            
            const isOverflowing = textContainer.clientHeight < textContainer.scrollHeight;
            return isOverflowing === true;
        }
        return false;
    };

    return (
        <>
            {!isLoading ? (
                <div className="w-full desk-view-only">
                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                        <div className="bg-peach px-4 py-2 rounded-t-lg">
                            <div className="flex flex-row justify-between items-center">
                            <div className="my-0 text-lg flex flex-row justify-between items-center mob-view">
                                    <div>
                                        <i className="fas fas fa-briefcase text-teal text-2xl mr-6 bd-highlight"></i>
                                    </div>
                                    <div>
                                        <strong>
                                            {t("OPC039_Case_Details")}
                                        </strong>
                                    </div>
                                    <div className="font-bold float-right ml-4 pl-4" data-testid="entitylevel">
                                        <strong>{`${!isNullOrUndefined(caseData?.entity) ? (caseData.entity === 'Contact' ? t("customer") : (caseData.entity === 'Property' ? formatContent(caseData?.asset?.type?.assetTypeName) : '-')) : '-'}  `}</strong>
                                    </div>
                                </div>

                                <div className="my-0 text-lg desk-view md:grid-cols-3 contents">
                                    <span className="col-md-03 ml-0">
                                        <i className="fas fas fa-briefcase text-teal text-2xl mr-6 bd-highlight"></i>
                                    </span>
                                    <span className="col-md-80">
                                        <strong>
                                            {t("OPC039_Case_Details")}
                                        </strong>
                                    </span>
                                    <span className="col-md-18 right-align" data-testid="entitylevel1">
                                        <strong className="font-bold">
                                            {`${!isNullOrUndefined(caseData?.entity) ? (caseData.entity === 'Contact' ? t("customer") : (caseData.entity === 'Property' ? formatContent(caseData?.asset?.type?.assetTypeName) : '-')) : '-'}  `}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                            <div className="mb-2">
                                <strong>{t("Case_Reference_Number")}</strong>
                                <br></br>
                                {formatContent(caseData?.caseReferenceNumber)}
                            </div>
                            <div className="mb-2">
                                <strong>{t("Case_Status")}</strong>
                                <br></br>
                                {formatContent(caseData?.status)}
                            </div>
                            <div className="mb-2">
                                <strong>{t("Case_Created_On")}</strong>
                                <br></br>
                                {formatDateWithFromCalendar(caseData?.createdDate)}
                            </div>

                            <div className="mb-2">
                                <strong>{t("caseType")}</strong>
                                <br></br>
                                {formatContent(caseData?.caseSubTypeCategory)}
                            </div>
                            <div className="mb-2">
                                <strong>{t("request_category")}</strong>
                                <br></br>
                                {`${isNullOrUndefined(caseData.caseCategory) ? t("no_request_category") : caseData.caseCategory}`}
                            </div>
                            <div className="mb-2">
                                <strong>{t("CP085")}</strong>
                                <br></br>
                                {getUpdateOwnerName(caseData?.owner?.name?.trim())}
                            </div>

                            <div className="mb-2">
                                <strong>{t("Case_Property")}</strong>
                                <br></br>
                                {formatContent(caseData?.asset?.addresses?.formattedAddress)}
                            </div>
                            <div className="mb-2">
                                <strong>{t("Case_Customer")}</strong>
                                <br></br>
                                {formatCustomerName(caseData?.customer?.name)}
                            </div>                            
                            <div className="mb-2">
                                <strong>{t("ContactDetails")}</strong>
                                <br></br>
                                {isNullOrUndefined(caseData?.contactDetails?.name) && isNullOrUndefined(caseData?.contactDetails?.relationId) && isNullOrUndefined(caseData?.contactDetails?.relation) && !isNaN(Number(caseData?.contactDetails?.formattedDetails)) && Number(caseData?.contactDetails?.formattedDetails) > 0 ? formatContent(caseData?.contactDetails?.formattedDetails) + ", " + t('Customer_Number') : formatContent(caseData?.contactDetails?.formattedDetails)}
                            </div>
                        </div>
                        <div className="accordion-item ">
                            <div className="accordion-title">
                               <div className="flex items-center w-full px-3 py-2 cursor-pointer bg-blue-20">
                                    <p className="w-full text-left my-2 global-txt mt-2">
                                    <strong>{t("Case_Description")}</strong>
                                    </p>                                
                                    {originalDescription !== '' && !isNullOrUndefined(overflowActive) && overflowActive === true && <div className="cursor-pointer">
                                        {activeCase === caseData?.identifiers?.caseIdentifier ? (<div
                                            id={"CaseAdditionalInfo-active" + caseData?.identifiers?.caseIdentifier}
                                            data-testid="CaseAdditionalInfo-active-multiply"
                                            className="plus-sign mt-10"
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(caseData?.identifiers?.caseIdentifier, "Desc")}
                                        >
                                            {"⨯"}
                                        </div>) : (<div
                                            id={"CaseAdditionalInfo-active" + caseData?.identifiers?.caseIdentifier}
                                            data-testid="CaseAdditionalInfo-active-plus"
                                            className="plus-sign mt-10"
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(caseData?.identifiers?.caseIdentifier, "Desc")}
                                        >
                                            {"+"}
                                        </div>)}
                                    </div> }
                            </div>
                            <div className="mb-2 px-3">                                
                                    <br></br>
                                    {activeCase === caseData?.identifiers?.caseIdentifier ? <StyledOverflowNonHidden ref={overflowingText}>{originalDescription}</StyledOverflowNonHidden>
                                        : <StyledOverflowHidden ref={overflowingText}>{originalDescription}</StyledOverflowHidden> }
                              
                                <div>
                                    {caseData && caseData?.identifiers && caseData?.identifiers?.documentIdentifiers && caseData?.identifiers?.documentIdentifiers?.length > 0 && (
                                        <a
                                        data-testid="btn_view_media"
                                        id="hisRepDet-btn1"
                                        className="rep-btn text-teal view-media a-tag mb-1"
                                        onClick={() => {
                                            onViewMediaHandler(caseData?.identifiers?.caseIdentifier);
                                        }}
                                        >
                                        {t("View media")}
                                        </a>
                                    )}
                                </div>
                            </div>
                           
                        </div>
                        </div>
                    
                    </div>
                </div>
            ) : (
                    <div className="div-loader">
                        <div className="SpinnerWrapper">
                            <LoadingSpinner />
                        </div>
                    </div>
                )}
        </>
    );
};

export default CaseDetailsComponent;
