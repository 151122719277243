export const CustomerStatus = [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Active',
        value: 'Active Customer'
    },
    {
        key: 'Former',
        value: 'Former Customer'
    }
]

export const TenancyType = [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Social',
        value: 'Social',
    },
    {
        key: 'Leasehold',
        value: 'Leasehold',
    },
    {
        key: 'Market',
        value: 'Market',
    },
    {
        key: 'Non-residential',
        value: 'Non-residential',
    },
    {
        key: 'Freehold',
        value: 'Freehold',
    },
    {
        key: 'Commercial',
        value: 'Commercial',
    },
    {
        key: 'Peabody Property',
        value: 'Peabody Property',
    }    
  ]