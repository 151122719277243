import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { Link, RouteComponentProps } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import * as api from "../../../api";
import "./styles.css";
import classes from "../UploadMedia/spinner.module.css";
import { imgData } from "../UploadMedia/StaticIconName";
import { isSafari, isMobileSafari, isIPad13 } from "react-device-detect";


interface ViewMediaProps extends RouteComponentProps<any> {}
const MpegAlertUserAgents = process.env.REACT_APP_MpegAlertSupportedBrowser;

const ViewMedia: React.FC<ViewMediaProps> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mediaFile, setMediaFile] = useState<{}[]>([]);
  const [isOverlay, setIsOverlay] = useState("none");
  const [getCaseId, setCaseId] = useState<any>();
  const [base64Data, setBase64Data] = useState("");
  const [contentType, setContentType] = useState([]);
  const [isProccessing, setIsProccessing] = useState(false);
  const [isError, setIsError] = useState("");
  const [selectedId, setSelectedId] = useState();
  const videoRef: any = useRef();
  const location = useLocation();
  const [isMpegSupportedBrowser, setMpegSupportedBrowser]: any =
    useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isError) {
          setIsOverlay("none");
        }
      }, [isError]);

    

    useEffect(() => {
        if(isSafari || isMobileSafari || isIPad13 ||/iPad|iPhone|iPod/.test(navigator.userAgent)) {
           setMpegSupportedBrowser(true);  
        }
    }, []);

    const fetchMediaData = async (id: any) => {
        setIsLoading(true);
        try {
          const response = await api.getMediaData(id);
          setMediaFile(response.documents);
        } catch (e: any) {
          setIsError("View_Media_Error");
        } finally {
          setIsLoading(false);
        }
    };

    useEffect(() => {
        setCaseId(location?.state);
        fetchMediaData(location?.state);
        console.log("location",location);
    }, [location]);

    const backButtonHandler = () => {
        props.history.push({
          pathname: "/Case360View",
          state: { viewMediaID: location.state },
        });
    };

    /* Displaying overlay on media click */
  const displayViewMediaDetails = async (mediaId: any, content: any) => {
    setIsError("");
    setIsProccessing(true);
    setIsOverlay("block");
    setContentType(content.split("/"));
    setSelectedId(mediaId);

    try {
      const response = await api.getViewMediaDetail(getCaseId,mediaId);
      
      const data1 = response.media.replace('"', "");

      let selectedMedia = `data:${content};base64,${data1}`;

      setBase64Data(selectedMedia);

      setIsProccessing(false);

      // istanbul ignore next Displaying pdf alert and closing overlay
      if (content.split("/")[1] === "pdf") {
        setIsOverlay("none");
        const byteCharacters = atob(data1);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: content });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      }
    } catch (e: any) {
      setIsProccessing(false);
      setIsError("View_Media_Error");
    }
  };

  const closeOverLay = () => {
    setIsOverlay("none");
    if (contentType[1] !== "mpeg" && contentType[0] === "video") {
      videoRef?.current?.pause();
    }
  };

  const displayErrorMessage = useCallback(() => {
    return (
      mediaFile.length === 0 &&
      isError && (
        <div className="error mt-2 mb-2" data-testid="apierror">
          <div className="w-full px-4 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t(isError)}
              </p>
            </div>
          </div>
        </div>
      )
    );
  }, [isError]);
  
  return (
    <Container id="supp-main-container" className = "ctn-fht1" role="main">

      <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>
      {isLoading ? (
        <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
      ) : (
        <>
          <div className="flex justify-between items-center pb-4 container">
            <h4>{t("View Media")}</h4>
          </div>
          <div className="border border-grey rounded-lg mb-4">
              <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
          <div className="flex flex-row justify-between items-center">
          <div className="my-0 text-lg">
                      <b> {t("MediaAttached")}</b>
                      </div></div></div>
                      
          <div className="">
            
              {mediaFile?.map((data: any) => {
                const fileNameMetadata = data?.metadata?.find((res: any) => res.key === "filename");
                const fileName = fileNameMetadata?.value || ""; // Extracting the file name
              
                return data?.metadata?.map((res: any) => {
                  if (res.key === "content-type") {
                    const matchData = res.value.split("/");
                    return imgData.map((item: any, index: number) => {
                      if (
                        matchData[1] === item.id ||
                        matchData[1] === item.id.toUpperCase()
                      ) {
                        return (
                          <>  
                          <div className="py-2 flex justify-between border-b border-grey">
                    <div className="pr-4 imgClass mb-2">
                     
                              <img
                                src={item.src}
                                alt={`thumbnail icon${Math.floor(
                                  Math.random() * 20
                                )}`}
                              />
                              <span className="ms-10 imgName">
                                {fileName}
                              </span>
                              <div className="text-capitalize text-right">
                              <a data-testid="displayMediaDetails" className="back-btn" onClick={() =>displayViewMediaDetails(data.id, res.value) }>{t("view")}</a>
                              </div>
                             
                    </div>
                </div>
                
                            {isError && selectedId === data.id && (
                              <div className="error mt-2 mb-2">
                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                  <div className="px-4 pr-8">
                                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                                  </div>
                                  <div className="flex items-center text-black">
                                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                      {t(isError)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    });
                  }
                });
              })}
            
            </div>
            {displayErrorMessage()}
          </div>
        </>
      )}

      <div className="overlay" style={{ display: isOverlay }}>
        {isProccessing ? (
          <div id="UploadingOverlay">
            <div className={classes.overlayContainer}>
              <div className={classes.overlayContent}>
                <div className={classes.spinner}></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mediaViewOverlay">
            <span
              className="deleteIcon"
              onClick={closeOverLay}
              data-testid="overlay"
            >
              X
            </span>
            <div className="mediaView">
              {contentType[0] === "video" && contentType[1] !== "mpeg" && (
                <video
                  controls
                  autoPlay
                  className="enlargeImgView"
                  ref={videoRef}
                >
                  <source src={base64Data} type="video/mp4"></source>
                </video>
              )}

              {!isMpegSupportedBrowser &&
                contentType[0] === "video" &&
                contentType[1] === "mpeg" && (
                  <>
                    <video
                      controls
                      autoPlay
                      className="enlargeImgView"
                      // style={{ backgroundColor: "#333333", height: "99%" }}
                    >
                      <source src={base64Data} type="video/mp4"></source>
                    </video>
                    <div className="error overlayMpeg">
                      <div className="w-full px-4 py-2 bg-yellow flex items-center">
                        <div className="px-1 pr-8">
                          <i className="fas fa-exclamation-triangle text-brick-70 w-8 h-6"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            Your browser doesn't support playing MPEG videos,
                            please click{" "}
                            <a download="video.mpeg" href={base64Data}>
                              here
                            </a>{" "}
                            to download the video instead.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {contentType[0] === "video" &&
                contentType[1] === "mpeg" &&
                isMpegSupportedBrowser && (
                  <>
                    <video
                      controls
                      autoPlay
                      className="enlargeImgView"
                      style={{ backgroundColor: "#333333", height: "99%" }}
                    >
                      <source src={base64Data} type="video/mp4"></source>
                    </video>
                    <div className="error overlayMpeg">
                      <div className="w-full px-4 py-2 bg-yellow flex items-center">
                        <div className="px-1 pr-8">
                          <i className="fas fa-exclamation-triangle text-brick-70 w-8 h-6"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            {t("Media_MPEG_Apple")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {contentType[0] === "image" && (
                <img
                  src={base64Data}
                  alt={`thumbnail icon${Math.floor(Math.random() * 20)}`}
                  className="enlargeImgView"
                />
              )}
              {/* {contentType[0] === "application" && (
                <object
                  data={base64Data}
                  type="application/pdf"
                  className="enlargeImgView"
                />
              )} */}
            </div>
          </div>
        )}
      </div>
    </Container>
  );

};

export default ViewMedia;
