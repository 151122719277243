import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import * as api from "../../api";
import React from "react";
import { isNullOrUndefined, titleCase } from "../../utils/formatting";
import { useSelector } from "react-redux";
import AssetDetails from "../../models/Customer/AssetDetails";
import {AssetTypes} from "../../models/shared/Enum";


interface KeyContactProps extends RouteComponentProps<any> {
}
const KeyContact: React.FC<KeyContactProps> = (props) => {
    const { t } = useTranslation();
    const [lettingsOfficer, setLettingsOfficer] = useState("");
    const [incomesOfficer, setIncomesOfficer] = useState("");
    const [neighManager, setNeighManager] = useState("");
    const [empOfficer, setEmpOfficer] = useState("");
    const [regionalHousingManager, setRegionalHousingManager] = useState("");

    let PropertyId = useSelector(
        (state: any) => state?.userChoicesReducer?.userChosenPropertyId
    );
    const backValue = useSelector(
        (state: any) => state?.userChoicesReducer?.backValue
      );
    const caseData = useSelector(
        (state: any) => state?.userChoicesReducer?.caseData
    );  
    useEffect(() => {
        trackEvent("KeyContact");
        getKeyContactData();
    }, [PropertyId]);

    const getKeyContactData = () => {
        (async () => {
            try {
                if (!isNullOrUndefined(PropertyId)) {
                    let contactData;
                    if((backValue==="Case360View")){
                        if((caseData.assignee === "NM" || caseData.assignee === "Incomes officer") && !isNullOrUndefined(caseData.identifiers.customerIdentifier) && !isNullOrUndefined(caseData.identifiers.tenancyIdentifier)) {
                            const customerResponse = await api.getCustomer(caseData.identifiers.customerIdentifier);
                            let keyContactId: any;
                              customerResponse.tenanciesSummaries.map(async (assetid: any) => {
                                    if (assetid.tenancySummary.tenancyId === caseData.identifiers.tenancyIdentifier) {
                                        keyContactId = assetid.tenancySummary.premisesIds[0];
                                    }
                            });
                           contactData = await api.getKeyContact(keyContactId); 
                        }
                        else if(((caseData.assignee === "NM" || caseData.assignee === "Incomes officer") && caseData.asset.type.assetTypeName !== "Property") || caseData.assigneeType==="Team"){
                            const associatedAssetsResponse = await api.getAssociatedAssetsKey(caseData.identifiers.assetIdentifier);
                            const associatedAssetData = associatedAssetsResponse.filter(
                                  (x) => x.assetType === AssetTypes.Property
                            );
                            if(associatedAssetData.length > 0){
                                contactData = await api.getKeyContact(associatedAssetData[0].id);
                                }
                        }
                        else{
                            //property type from Case360
                            contactData = await api.getKeyContact(PropertyId); 
                        }
                    }
                    else if(backValue==="Customer360View")  {
                         contactData = await api.getKeyContact(PropertyId); //associate
                    }
                    let regionalHousingManagergivenName = !isNullOrUndefined(contactData?.associates?.regionalHousingManager?.person?.name?.givenName) ? contactData?.associates?.regionalHousingManager?.person?.name?.givenName : ""
                    let regionalHousingManagerSurname = !isNullOrUndefined(contactData?.associates?.regionalHousingManager?.person?.name?.surname) ? contactData?.associates?.regionalHousingManager?.person?.name?.surname : "";
                    let regionalHousingManagerName = regionalHousingManagergivenName + " " + regionalHousingManagerSurname
                    setRegionalHousingManager((!isNullOrUndefined(regionalHousingManagerName.trim())) ? regionalHousingManagerName : "-");
                    let empOfficergivenName = !isNullOrUndefined(contactData?.associates?.employmentOfficer?.person?.name?.givenName) ? contactData?.associates?.employmentOfficer?.person?.name?.givenName : ""
                    let empOfficergivenNameSurname = !isNullOrUndefined(contactData?.associates?.employmentOfficer?.person?.name?.surname) ? contactData?.associates?.employmentOfficer?.person?.name?.surname : "";
                    let empOfficerName = empOfficergivenName + " " + empOfficergivenNameSurname
                    setEmpOfficer((!isNullOrUndefined(empOfficerName.trim())) ? empOfficerName : "-");
                    let incomesOfficerManagergivenName = !isNullOrUndefined(contactData?.associates?.incomesOfficer?.person?.name?.givenName) ? contactData?.associates?.incomesOfficer?.person?.name?.givenName : ""
                    let incomesOfficerManagerNamesurname = !isNullOrUndefined(contactData?.associates?.incomesOfficer?.person?.name?.surname) ? contactData?.associates?.incomesOfficer?.person?.name?.surname : "";
                    let incomesOfficerManagerName = incomesOfficerManagergivenName + " " + incomesOfficerManagerNamesurname
                    incomesOfficerManagerName=titleCase(incomesOfficerManagerName);
                    let lettingsOfficerManagerNamegivenName = !isNullOrUndefined(contactData?.associates?.lettingsOfficer?.person?.name?.givenName) ? contactData?.associates?.lettingsOfficer?.person?.name?.givenName : ""
                    let lettingsOfficerManagerNamesurname = !isNullOrUndefined(contactData?.associates?.lettingsOfficer?.person?.name?.surname) ? contactData?.associates?.lettingsOfficer?.person?.name?.surname : "";
                    let lettingsOfficerManagerName = lettingsOfficerManagerNamegivenName + " " + lettingsOfficerManagerNamesurname
                    lettingsOfficerManagerName = titleCase(lettingsOfficerManagerName);
                    setIncomesOfficer((!isNullOrUndefined(incomesOfficerManagerName.trim())) ? incomesOfficerManagerName : "-");
                    setLettingsOfficer((!isNullOrUndefined(lettingsOfficerManagerName.trim())) ? lettingsOfficerManagerName : "-");
                    let neighbourhoodManagerNamegivenName = !isNullOrUndefined(contactData?.associates?.neighbourhoodManager?.person?.name?.givenName) ? contactData?.associates?.neighbourhoodManager?.person?.name?.givenName : ""
                    let neighbourhoodManagerNamesurname = !isNullOrUndefined(contactData?.associates?.neighbourhoodManager?.person?.name?.surname) ? contactData?.associates?.neighbourhoodManager?.person?.name?.surname : "";
                    let neighbourhoodManagerName = neighbourhoodManagerNamegivenName + " " + neighbourhoodManagerNamesurname
                    neighbourhoodManagerName= titleCase(neighbourhoodManagerName);
                    setNeighManager(!isNullOrUndefined(neighbourhoodManagerName.trim()) ? neighbourhoodManagerName : "-");           
                }
            } catch (e) {
                console.error(e);
                const timerId = setTimeout(() => {
                    props.history.push("/GenericError");
                }, 1000);
            }
        })();

        function titleCase(str: string) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
              );
        }
    };

    return (

        <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card">

            <div className="px-4 py-2 mt-2 mb-2 border-b border-grey">
                <div className=" flex flex-row d-flex bd-highlight items-center mt-2">
                    <i className="fas fa-solid fa-key text-teal text-2xl mr-6 bd-highlight"></i>
                    <h2 className="my-0 text-purple-100 bd-highlight">{t("Key_contacts")}</h2>
                </div>
            </div>

            <div className="px-4 mb-8 global-txt">
                {backValue =="Case360View"? <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("regionalHousingManager")}</div>
                    <div className="text-capitalize text-right">{titleCase(regionalHousingManager)}</div>
                </div> : null}
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("neighbourhood")}</div>
                    <div className="text-capitalize text-right">{titleCase(neighManager)}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("lettings")}</div>
                    <div className="text-capitalize text-right">{titleCase(lettingsOfficer)}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("incomes")}</div>
                    <div className="text-capitalize text-right">{titleCase(incomesOfficer)}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("employment")}</div>
                    <div className="text-capitalize text-right">{titleCase(empOfficer)}</div>
                </div>

            </div>

        </div>
    )
};
export default KeyContact;