import Address from '../Customer/Address'
import { PremisesType } from '../Customer/Premises'

export class RelatedPremises {
  public premisesTypeId?: string = ''
  public premisesId: string = ''
  public premisesType: PremisesType = PremisesType.Property
}

export class AdminUnitsResponse {
  public id: string = ''
  public eTag: string = ''
  public uprn: string = ''
  public premisesType: string = ''
  public premisesTypeId: string = '' // should be number?
  public tenancyType: string | null = ''
  public isResidential: boolean = false
  public neighbourhoodManager: string = ''
  public premisesAddress: Address = new Address()
  public adminUnitIds: RelatedPremises[] = []
}

export class RelatedPremisesType {
  public premisesTypeId: string = ''
  public premisesType: PremisesType = PremisesType.Property
}

export class AssocitaedAssetsType {
  public key: string = ''
  public value: string = ''
}
