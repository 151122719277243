import "./style.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import * as api from "../../api";
import { uuid } from "uuidv4";
import {
  CreateAppointmentParams,
  getAvailabilityForAppointment,
  postAppointment,
} from "../../api/appointmentApi";
import { RawAvailablity } from "../../models/Appointment"
import {
  getContractorName,
  getPrefferenceNumber,
  getReferenceNumber,
  saveAvailabilityResponse,
  workOrderRepairObject
} from "../../ducks/redux/actions/getAddress";
import { isNullOrUndefined } from "../../utils/formatting";
import { userChosenPropertyType } from "../../ducks/redux/actions/userChoices";

const AppointmentScheduler: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const range = 5;
  const [track, setTrack] = useState(0);
  const [error, setError] = useState("");
  const [selectedSlot, setSelectedSlot] = React.useState<any>(null);
  const history = useHistory();
  const [schedules, setSchedules] = useState<any>({
    availabilities: null,
  });
  const [buttondisable, setButtonDisable] = React.useState(true);
  const availabilityData = useSelector(
    (state: any) => state.addresses?.contractorAvailabilityResponse
  );
  const preferenceno = useSelector(
    (state: any) => state.addresses?.preferenceno
  );
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const repairHistoryData = useSelector(
    (state: any) => state.addresses?.repairHistoryData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPrefferenceNumber(true));
    GetJobNumber();
  }, []);
  const [calendarSelect, setCalendarSelect]: any = useState([[]]

  );
  const [markSelectedSlot, setMarkSelectedSlot]: any = useState({
    objects: [[{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
    [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }]]
  })

  useEffect(() => {
    setSchedules({
      availabilities: availabilityData,
    });
  }, [availabilityData]);

  const [selectedId, setSelectedId] = useState("");
  const tableActive = (dateIndex: any, scheduleIndex: any, data: any) => {
    let calVar;
    for (let i = 0; i <= 4; i++) {
      for (let j = 0; j < 5; j++) {
        if (i == dateIndex && j == scheduleIndex) {
          calVar = markSelectedSlot.objects[dateIndex][scheduleIndex];
          setCalendarSelect(markSelectedSlot.objects[dateIndex][scheduleIndex]);
          setSelectedId("click-calander");
          setButtonDisable(false)
        }
      }
    }
  }
  const calBackground = (dateIndex: number, scheduleIndex: number) => {
    if (markSelectedSlot.objects[dateIndex][scheduleIndex] == calendarSelect) {
      return "click-calander sc-sched-slots cal-btn color-new error-span-remove";
    }
    else {
      return "sc-sched-slots cal-btn color-new error-span-remove"
    }
  }

  const maxRangeAvailable = schedules.availabilities
    ? schedules.availabilities[0].slots.length - 1
    : null;

  const changeRange = (back: boolean) => {
    setCalendarSelect(null);
    const maxLength = schedules.availabilities[0].slots.length;
    if (back) {
      const nextTrack = track - range;
      nextTrack < 0 ? setTrack(0) : setTrack(nextTrack);
    } else {
      const nextTrack = track + range;
      if (nextTrack <= maxLength) setTrack(nextTrack);
    }
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const { t } = useTranslation();

  const rangeStartDate = schedules.availabilities
    ? new Date(schedules.availabilities[0].slots[track].date).toDateString()
    : "";

  const rangeEndDate = schedules.availabilities
    ? new Date(
      schedules.availabilities[0].slots[
        //@ts-ignore
        track + range <= maxRangeAvailable ? track + range : maxRangeAvailable
      ].date
    ).toDateString()
    : "";

  const dateFormatter = (date: string) => {
    return `${date.slice(0, 3)}, ${date.slice(7, 10)} ${date.slice(4, 7)}`;
  };
  const msgError = t("CPR049");

  const callPageHandler = () => {
    if (
      typeof selectedSlot === "undefined" ||
      selectedSlot === null ||
      selectedSlot === ""
    ) {
      setError(msgError);
    } else {
      setButtonDisable(true);
      createAppointmentonFinalStep();
    }
  };

  const callNextHandler = () => {
    history.push("/nosuitableappointment");
  };
  interface GetAvailabilityData {
    availabilities: RawAvailablity[];
    isPreferences: boolean;
    hasSlaAvailability: boolean;
    earliestAvailabilityType: string;
    title: string;
    statusCode: number;
  }

  let operationKey = "";
  let isPreferencesValue = true;

  const GetJobNumber = async () => {
    setIsLoading(true);
    const noOfHits = process.env.REACT_APP_API_HITS;
    const timeoutValue = process.env.REACT_APP_API_TIMEOUT;
    const noOfHitsValue = Number(noOfHits);
    const noOfTimeoutValue = Number(timeoutValue);
    let i = 0;
    let jobNumber = "";
    let workOrderId = !isNullOrUndefined(workOrderObject?.id) ? workOrderObject?.id : "";   
    if (!isNullOrUndefined(repairHistoryData)) {
      workOrderId = repairHistoryData.repairHistoryWorkOrderID;
      if (repairHistoryData.repairHistoryContractor !== undefined) { dispatch(getContractorName(repairHistoryData.repairHistoryContractor)) }
    }
    let interval = setInterval(async () => {
      if (i < noOfHitsValue && (isNullOrUndefined(jobNumber) || jobNumber == "") && !isNullOrUndefined(workOrderId)) {
        i++;
        try {
        
          const repair = await api.getRepair(workOrderId);
          dispatch(userChosenPropertyType(repair.premisesType));
          dispatch(workOrderRepairObject(repair));
          jobNumber = repair.jobNo;     

        } catch (e) {
          console.log(e);
          setIsLoading(false);
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        }
      } else {
        setIsLoading(false);
        clearInterval(interval);
        if (isNullOrUndefined(isNullOrUndefined(workOrderId) || jobNumber) ||jobNumber == "") {
          console.log("Job Number not created");
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        } else {
            clearInterval(interval);
            operationKey = uuid();
            try {
              getAvailabilityForAppointment(
                workOrderId,
                jobNumber,
                operationKey
              ).then((payload: GetAvailabilityData) => {

                const data = payload?.availabilities,
                  availabilityStatusCode = payload.statusCode;
                isPreferencesValue = payload?.isPreferences;

                if(availabilityStatusCode === 404 || payload?.title === "Cannot book appointment") {
                  setIsLoading(false);
                  setButtonDisable(false);
                  props.history.push("/NoAppointment");
                  return;
                } else if (availabilityStatusCode != 200 && availabilityStatusCode != undefined) {
                  setIsLoading(false);
                  const timerId = setTimeout(() => {
                    props.history.push("/GenericError");
                  }, 1000);
                  return;
                } else if (data != undefined && data?.length > 0) {
                  dispatch(saveAvailabilityResponse(data));
                  dispatch(getReferenceNumber(jobNumber));
                  setIsLoading(false);
                  setButtonDisable(false);
                  props.history.push("/AppointmentScheduler");
                  dispatch(getPrefferenceNumber(isPreferencesValue));
                  return;
                }
              });
            } catch (e) {
              console.log("no appointment", e);
              setIsLoading(false);
              props.history.push("/NoAppointment");
            }
        }
      }
    }, noOfTimeoutValue);
    return () => clearInterval(interval);
  };
  const selectedCalendarData = {
    appointmentDate: new Date("2020-12-10"),
    availabilityType: "Morning",
    hasAvailabilityInsideSla: true,
    isInsideSla: true,
    isPreferences: false,
    referenceNumber: "2056531/1",
  };
  const tConv24 = (time24: string) => {
    let ts = time24;
    let H = +ts.substr(0, 2);
    let h: any = H % 12 || 12;
    h = h < 10 ? +h : h;
    let ampm = H < 12 ? "am" : "pm";
    ts = h + ampm;
    return ts;
  };
  const createAppointmentonFinalStep = async () => {
    const createAppointmentParams: CreateAppointmentParams = {
      isPreferences: isPreferencesValue,
      jobNumber: referenceNumberCode,
      diaryCode: selectedSlot.availabilityType,
      appointmentFromDate: selectedSlot.date,
      availabilityType: selectedSlot.availabilityType,
      isInsideSla: selectedSlot.insideSla,
      reasonCode: "NEW_APPOINTMENT",
      remarks: "",
      appointmentToDate: selectedSlot.date,
      externalCreateDateTime: selectedSlot.date,
      externalReferenceId: ""
    };
    setIsNextLoading(true);
    let workOrderId = "";

    if (!isNullOrUndefined(repairHistoryData) && repairHistoryData?.repairHistoryWorkOrderID != undefined) {
      workOrderId = repairHistoryData.repairHistoryWorkOrderID;
    }
    else if (!isNullOrUndefined(workOrderObject)) {
      workOrderId = workOrderObject.id;
    }

    try {
      const result = await postAppointment(
        workOrderId,
        createAppointmentParams,
        operationKey
      );
      history.push("/AppointmentConfirmation?", selectedSlot);
      setIsNextLoading(false);
      setButtonDisable(false);
    } catch (e) {
      console.log("createAppointment", e);
      const timerId = setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      console.log("final called ");
    }
  };
  return (
    <div role="main" className="container sm:px-2 ctn-fht">
      <div id="Cal-main-div" className="py-2 h5 my-2">
        <h2 className="text-purple-100 text-2xl mb-2 global-txt">
          {!preferenceno ? t("Scheduler_Heading") : t("CPR049")}
        </h2>
      </div>

      {isLoading ? (
        <div>
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
          //timeout={25000}
          />
          <p className="global-txt">{t("CPR052")}</p>
        </div>
      ) : (
        <div>
          <div id="Cal-main-div1" className="my-2">
            <p className="mb-2 global-txt"> {!preferenceno ? t("CPR050") : t("CPR051")} </p>
          </div>
          {schedules?.availabilities && (
            <div className="apScheduler">
              <div className="flex justify-between items-center pb-2 md:pb-8 space-right">
                <div>
                  {track === 0 ? (
                    <a></a>
                  ) : (
                    <a
                      className="text-teal mouse-pointer"
                      onClick={() => changeRange(true)}
                    >
                      <i className="fas fa-chevron-left"></i>
                      {t("Previous")}
                    </a>
                  )}
                </div>
                <div>
                  {track + range > schedules.availabilities[0].slots.length ? (
                    <a></a>
                  ) : (
                    <a
                      className="text-teal mouse-pointer"
                      onClick={() => changeRange(false)}
                    >
                      {t("Next")}
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  )}
                </div>
              </div>
              <div className="sc-wrapper">
                {/* @ts-ignore */}
                {schedules.availabilities[0].slots
                  .slice(track, track + range)
                  .map(({ date, insideSla }: any, dateIndex: any) => {
                    const dateInfo = new Date(date).toISOString();
                    return (
                      <div className="sc-schedules">
                        <div className="sc-sched-date sc-mob-date margin-rt">
                          <p className="wd-2">
                            {new Date(date).toDateString().slice(0, 3)}
                            <br></br>
                            {dateInfo.slice(8, 10)}/{dateInfo.slice(5, 7)}
                          </p>
                        </div>
                        <div className="flex flex-row wt1 flex-wrap mt-top row">
                          {schedules.availabilities.map(
                            (
                              {
                                availabilityDetails: {
                                  start,
                                  end,
                                  availabilityTypeLabel,
                                  availabilityType,
                                },
                              }: any,
                              scheduleIndex: any
                            ) => {
                              return schedules.availabilities[scheduleIndex]
                                .slots[track + dateIndex].capacity ==
                                "Not Available" ? (
                                <p className="sc-sched-slots cal-btn disabled-p color_black error-span-remove">
                                  -
                                </p>
                              ) : (
                                <button
                                  onClick={() => {
                                    setError("");
                                    setSelectedSlot({
                                      start,
                                      end,
                                      availabilityTypeLabel,
                                      date,
                                      insideSla,
                                      availabilityType,
                                    });
                                    tableActive(dateIndex, scheduleIndex, "setSelectedSlot" + dateIndex + scheduleIndex);

                                  }} className={calBackground(dateIndex, scheduleIndex)}
                                  data-testid={`setSelectedSlot` + dateIndex + scheduleIndex}
                                >
                                  <p className="wd-1 text-black">{`${tConv24(
                                    start
                                  )} - ${tConv24(end)}`}</p>
                                </button>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex justify-between items-center pb-2 md:pb-8">
        <span>
          {!preferenceno && (
            <div className="mt-30 ml-10 pl-3">
              <a
                id="btn_nosuitableappoinment"
                className="text-teal cursor-pointer min-w-38 md:min-w-50 global-txt "
                onClick={callNextHandler}
              >
                {t("No_Suitable_Appointment")}
                {/* <i className="fas fa-angle-right relative  pointer-events-none calender-arrow"></i> */}
              </a>
            </div>
          )}
        </span>

        <span className="book-btn">
          <Button
            id="btn_bookappointment"
            disabled={buttondisable}
            onClick={callPageHandler}
            className="button-class"
          >
            {t("Book_Appointment")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </span>
      </div>

      <div>
        <span>
          {isNextLoading ? (
            <div>
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={50}
                width={50}
                timeout={5000}
              />
            </div>
          ) : (
            <div></div>
          )}
        </span>
      </div>
      {error && (
        <div className="error mb-8" id="custDetails-div5" role="alert">
          <div className="w-full px-2 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {error}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentScheduler;
