import React, { useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_CREATION_QUESTIONS,
  CASE_CREATION_QUESTIONIDS_ANSWERS,
} from "../../../ducks/redux/actionTypes";

import { RouteComponentProps } from "react-router-dom";
import {
  FOOTAGE_START_DATE,
  FOOTAGE_END_DATE
} from "./Question";
import { BackValue } from "../../../models/shared/Enum";
const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));

interface DateTimeTypeQuestion extends RouteComponentProps<any> { }

const DateTimeTypeQuestion: React.FC<DateTimeTypeQuestion> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let title: any;

  const dispatch = useDispatch();
  const [valueDate, setValueDate] = React.useState("");
  const [isEndDateInvalid, setIsEndDateInvalid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showStaticAlert, setShowStaticAlert] = useState("");
  const [showStaticAlertYellow, setShowStaticAlertYellow] = useState("");

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );

  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const [questionAnswerArray, setQuestionAnswerArray] = useState<{
    questionText: string;
    questionId: string;
    answer?: string;
  }[]>(() => {
    if (caseSelectedQuestionIdsAnswers && caseSelectedQuestionIdsAnswers.length > 0) {
      return caseSelectedQuestionIdsAnswers;
    } else {
      return [{ questionText: "", questionId: "", answer: "" }];
    }
  });

  let count = useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationAdditionalInfo
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  if (questionInfoData && questionInfoData[count]?.label === FOOTAGE_START_DATE) {
    title = t("footage_start_date");
  }
  if (questionInfoData && questionInfoData[count]?.label === FOOTAGE_END_DATE) {
    title = t("footage_end_date");
  }

  const assignedSelectedAnswersValue = () => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        setValueDate(fetchAnswer[index]);
      }
    });
  };

  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    const newData = {
      questionText: questionInfoData[count]?.label,
      questionId: questionInfoData[count]?.questionId,
      answer: "",
    }
    if (questionAnswerArray.length > count + 1) {
      questionAnswerArray.splice(count + 1, questionAnswerArray.length);
    }
    if (questionAnswerArray.length == 0 || (!questionAnswerArray[count+1])) {
      setQuestionAnswerArray(prevArray => [...prevArray, newData]);
    }
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
  }, [count]);

  const backButtonHandler = () => {
    clearCaseDataFromRedux();
    if (count >= 1) {
      count = count - 1;
      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });
      history.push("/CaseQuestionnaire");
    } else {
      history.push("/CaseType");
    }
  };
  const clearCaseDataFromRedux = () => {
    if (fetchAnswer.length > count) {
      const ans = fetchAnswer;
      const question = fetchQuestion;
      ans.pop();
      question.pop();
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: ans });
      dispatch({ type: CASE_CREATION_QUESTIONS, payload: question });
    }
  };
  const onContinueHandler = () => {
    setShowStaticAlert("");
    setShowStaticAlertYellow("");
    if (valueDate === "") {
      setShowError(true);
    } else {
      setShowError(false);
      count = count + 1;
      const updatedQuestionAnswerArray = [...questionAnswerArray];
      updatedQuestionAnswerArray[count].answer = valueDate;

      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
      dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
      dispatch({ type: CASE_CREATION_QUESTIONIDS_ANSWERS, payload: updatedQuestionAnswerArray });
      setValueDate("");
      history.push("/caseQuestionnaire");
    }

  };


  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if (backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    } else {
      props.history.push("/Case360View");
    }
  };

  useEffect(() => {
    if (
      questionInfoData &&
      questionInfoData[count]?.alertMessageCode !== null
    ) {
      if (questionInfoData[count]?.alertMessageCode?.message) {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.message);
      }
      else {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.code);
      }
    }

    if (questionInfoData[count]?.alertMessageCodeYellow?.message) {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.message);
    }
    else {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.code);
    }
  }, [questionInfoData]);

  const isValidDate = (dateString: any) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(dateString);
  };
   
  const handleStartDate = (value: any) => {
    setShowError(false);
    setIsEndDateInvalid(false);
    const startDateString = new Date(fetchAnswer[count - 1]);
    if (!isValidDate(startDateString)) {
      const startDate = new Date(startDateString);
      const selectedEndDate = new Date(value);
      selectedEndDate.setHours(0, 0, 0, 0);
      startDate.setHours(0, 0, 0, 0);
      if (selectedEndDate < startDate) {
        setIsEndDateInvalid(true);
        setShowError(true);
        return;
      }
    }
    if (value !== ansArray[count]) {
      //Removing further answers ans questions
      fetchQuestion && fetchQuestion.splice(count, fetchQuestion.length);
      fetchAnswer && fetchAnswer.splice(count, fetchAnswer.length);
      dispatch({ type: CASE_EDIT, payload: true });
    }
    setValueDate(value);
    setAnsArray([...ansArray, value]);
    // ==== If user is changing the answer for the same question, need to get index and replace the value ===
    let newArr = [...ansArray];
    newArr[count] = value;
    setAnsArray(newArr);
  };

  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="reploc-container" role="main">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler} onKeyDown={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>
        <div className="h5 sm:mx-1" id="repreason-div1">
          <h1
            className="text-purple-100 text-2xl mb-0 global-txt"
            data-testid="title"
          >
            {descData}
          </h1>
        </div>
        <br />

        <div
          className="sm:mx-1"
          key={questionInfoData && questionInfoData[count]?.questionId}
        >
          <label className="block">
            <p className="text-base my-0 global-txt" data-testid = "case-q1" id="case-q1">
              {t(questionInfoData && questionInfoData[count]?.label)}
            </p>
            <br />
            <div className="flex items-center sm:w-full">
              <label className=" flex items-center md:pr-2 sm:w-full">
                {/* <span className="pr-1  text-date md:text-base pl-1 pt-2 sm:pl-4 sm:w-mid">{title}</span> */}
                <input data-testid="fromDate" id="fromdate" value={valueDate} max="9999-12-31" defaultValue={""} type="datetime-local" onChange={e => handleStartDate(e.target.value)} className="border-grey w-34 md:w-full text-sm md:text-base p-2 md:p-4 md:w-mid"></input>
              </label>
            </div>
          </label>
        </div>
        <br />
        {/* ==== Showing error ==== */}
        {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {isEndDateInvalid ? t("DateRangeError"): t("DateError")}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === End error ==== */}

        {/* ==== Display purple alert warning === */}
        {showStaticAlert && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t(showStaticAlert)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === Close purple warning === */}

        {/* ==== Display Yellow static alert === */}
        {showStaticAlertYellow && (
          <div
            data-testid="alert-warning"
            className="error mb-8"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {t(showStaticAlertYellow)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === Close Yellow static alert  === */}

        <div
          className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
          id="repreason-main-btn"
        >
          <ExitModalContent
            data-testid="case-exitmodal"
            exit={exitButtonHandler}
            btn_msg="return_case_button"
          />
          <Button
            onClick={onContinueHandler}
            className="pad-bot"
            data-testid="btn_caseQ"
          >
            {t("continue")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Suspense>
    </div>
  );
};

export default DateTimeTypeQuestion;
