import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { formatDateWithFromCalendar, formathourminutes, isNullOrUndefined } from "../../../utils/formatting";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import { MobilePagination, Pagination, Paginationbox, WebPagination } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import {CaseSortableFields} from "../../../models/Case/CaseSortableFields"
import { useDispatch, useSelector } from "react-redux";
import CaseSearchData, { Links } from "../../../models/Case/CaseResponse";
import { caseData, caseList } from "../../../ducks/redux/actions/userChoices";
interface CaseResultProps extends RouteComponentProps<any> {
    searchText?: string;
    searchTextCount?: number;
    handleSort?: any;
    sortValue?: any;
    status?: any;
    propertyType?: any;
    setShowFilter?: boolean;
    raisedSince?: any;
    raisedUpto?: any;
    isCaseAssignedToMe?:boolean;
}
const CaseResultComponent: React.FC<CaseResultProps> = (props) => {
    const arrayCases: CaseSearchData = {
        cases: [],
        "total-count": 0,
        skip: 0,
        limit: 0,
        _links: new Links(),
      };
    const [caseListData, setCaseList] = useState(arrayCases);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] = useState(isNullOrUndefined(props.sortValue) ? "createdDate desc" : props.sortValue);
    const searchTextCountValue = !props.searchTextCount ? 0 : props.searchTextCount;
    const searchTextValue = !props.searchText ? '' : props.searchText;
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const timeValue = formathourminutes();
    const raisedSinceDate  = !props.raisedSince ? '': props.raisedSince + 'T0'+ timeValue[0] + ':' + timeValue[1] + ':00Z';
    const raisedUptoDate  = !props.raisedUpto? '': props.raisedUpto + 'T'+ (23- timeValue[0]) +':' + timeValue[2] + ':00Z';
    
    const caseListReducerData= useSelector(
        (state: any) => state?.userChoicesReducer?.caseList
    );
    useEffect(() => {
        (async () => {
            if (!checkSearchCriteria()) {
                setIsLoading(false);
                return;
            }
            if (!props.setShowFilter ) {
            setIsLoading(true);
            const queryParams = {
                limit: 10,
                skip: 0,
                searchText: props.searchText?.trim(),
                orderBy: defaultSortOrder,
                status: props.status,
                assetType: props.propertyType,
                raisedSince: raisedSinceDate,
                raisedUpto: raisedUptoDate,
                ownerEmailId: props.isCaseAssignedToMe == true ? sessionStorage.getItem('email') :""
              };
              getCaseData(queryParams);
            }
            else
            {
                setCaseList(caseListReducerData);
                setTotalCount(caseListReducerData["total-count"]);
                setIsLoading(false);
            }
            
        })();
    }, []);

    const getCaseData = (queryParams: any) => {
        (async () => {
            try {
                  const data = await api.getCaseData(queryParams);
                  dispatch(caseList(data))
                  setCaseList(data)
                  setTotalCount(data["total-count"]);
                  setIsLoading(false);
            
           
            } catch (e) {
                setIsLoading(false);
                console.error(e);
                props.history.push("/GenericError");
            }
        })();
    }

    const handleChangeSortOrder = (e: { target: { value: string } }) => {
        props.handleSort(e.target.value);
        setDefaultSortOrder(e.target.value);
        updateCaseData(props.searchText, 10, skip, e.target.value);
    }

    const updateCaseData = (searchText: any, limit: any, skip: any, orderBy: any ) => {
        (async () => {
            setIsLoading(true);
            const queryParams = {
                propertiesIds: '',
                searchText: searchText.trim(),
                limit: limit,
                skip: skip,
                orderBy: orderBy,
                status: props.status,   
                assetType: props.propertyType,
                raisedSince: raisedSinceDate,
                raisedUpto: raisedUptoDate,
                ownerEmailId: props.isCaseAssignedToMe == true ? sessionStorage.getItem('email') :""
            };

            getCaseData(queryParams);
        })();
    }

    const getValidationMessage = () => {
        if (isNullOrUndefined(props.searchText))
            return "blank_search_criteria";

        if ((/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1))))
            return "invalid_search_criteria";

        if (props.searchText?.includes('*')
            && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue)
            return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

        if (searchTextValue?.length < searchTextCountValue)
            return t("minimum_number_of_characters");
        
        if(props.isCaseAssignedToMe)
            return "case_search_no_results_assignedto";
        
        return "case_search_no_results";
    }

    const checkSearchCriteria = () => {
        if (isNullOrUndefined(props.searchText) || (/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1)))
            || (props.searchText?.includes('*') && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue) || searchTextValue?.length < searchTextCountValue)
            return false;
        return true;
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setCurrentPage(page);
        setSkip(resultsPerPage * page);
        updateCaseData(props.searchText, resultsPerPage, resultsPerPage * page, defaultSortOrder);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setResultsPerPage((e.target as any).value);
        setSkip(0);
        setCurrentPage(0);
        updateCaseData(props.searchText, (e.target as any).value, 0, defaultSortOrder);
    };

    const fetchData = () => {
        let skipResult=0;
        let totalValue=0;
        if(skip===0 && resultsPerPage<998)
        {
            skipResult=0
            totalValue=999
            setSkip(0);
        }
        else
        {
            skipResult=resultsPerPage
            totalValue=999+skipResult
            setSkip(skipResult);
        }
        setResultsPerPage(totalValue);
        updateCaseData(props.searchText, totalValue, skipResult, defaultSortOrder);
    };

    const callcase360 = (caseSelectedData: any) => {
        dispatch(caseData(caseSelectedData))
        props.history.push("/Case360View");
    }
    
   
    return (
        <div className="ctn-fht">
            <WebPagination>
                <div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr global-txt" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto">

                                {!caseListData?.cases?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t(getValidationMessage())}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <div>
                                            <div className="flex flex-col md:flex-row justify-between items-center">
                                                {/* ====== <!-- count --> ==== */}
                                                <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left ml-1"><strong>{totalCount}</strong> {t("total")}</div>
                                                </div>
                                                
                                                <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                    <select data-testid="webcaseSearchSort"
                                                        value={defaultSortOrder}
                                                        id="case-search-sort1"
                                                        className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                        onChange={handleChangeSortOrder}
                                                        aria-label="case Search"
                                                    >
                                                    {CaseSortableFields.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e.sortableKey}>
                                                                    {e.displayName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {caseListData?.cases.map((caseData) => (
                                                
                                                <div className="w-full desk-view-only">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                    <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                          <div className="flex flex-row justify-between items-center">
                                                          <div className="my-0 text-lg cursor-pointer">
                                                                {
                                                            <a data-testid={"case360Link" + caseData.caseReferenceNumber} className="my-0 text-lg text-black cursor-pointer" onClick = {() =>callcase360(caseData)}> <strong>  {`${caseData.caseReferenceNumber}`}</strong>
                                                            </a>
                                                            }
                                                            </div>                                                            
                                                            </div>
                                                        </div>
                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("address_txt")}</strong>{" "}
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData?.asset?.addresses?.formattedAddress) ? "" : caseData?.asset?.addresses?.formattedAddress}  `}
                                                            </div>
                                                            
                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("caseType")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.caseSubTypeCategory) ? "" : caseData.caseSubTypeCategory}`}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("status_txt")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.status) ? "" : caseData.status}`}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("Date_Creation")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(caseData.createdDate || '')}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("request_category")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.caseCategory) ? t("no_request_category") : caseData.caseCategory}`}
                                                            </div>
                                                            </div>

                                                        <div className="px-4 md:flex md:justify-between">
                                                         </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="page-mar">
                                                <Paginationbox>
                                                    <label>
                                                        <Pagination
                                                            className=" ml-4 "
                                                            colSpan={7}
                                                            count={totalCount}
                                                            page={currentPage}
                                                            rowsPerPage={resultsPerPage}
                                                            rowsPerPageOptions={[10, 20, 100]}
                                                            onPageChange={onChangePage}
                                                            data-testid="casePaginationChange"
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </label>
                                                </Paginationbox>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ) : (
                                <div className="div-loader">
                                    <div className="SpinnerWrapper">
                                        <LoadingSpinner />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </WebPagination>
            <MobilePagination>
                <div
                    id="scrollableDiv"
                    data-testid="scrollableDiv" className="mob-view-only"
                    style={{
                        height: 800,
                        overflowY: "scroll",
                        overflow: "auto",
                        flexDirection: "column",
                    }}
                >
                    <InfiniteScroll
                        dataLength={caseListData?.cases?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                        <div className="w-full global-txt">
                            <br></br>
                            <div className="padd-lr global-txt" role="main">
                                {!isLoading ? (
                                    <div className="container mx-auto px-3">
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left ml-1"><strong>{totalCount}</strong> {t("Total")}</div>
                                            </div>

                                            <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="md:w-auto pr-4 md:text-left">{" "}{t("assets_search_sort_label")}{" "}</div>
                                                <select data-testid="mobilecaseSearchSort"
                                                    value={defaultSortOrder}
                                                    id="case-search-sort1"
                                                    className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                    onChange={handleChangeSortOrder}
                                                    aria-label="case Search"
                                                >
                                                 {CaseSortableFields.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e.sortableKey}>
                                                                    {e.displayName}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        {!caseListData?.cases?.length ? (
                                            <div className="w-full mt-4">
                                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                                    <div className="px-4 pr-8">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                            {t(getValidationMessage())}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                                caseListData?.cases.map((caseData) => (
                                                    <div className="w-full desk-view-only">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                    <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                          <div className="flex flex-row justify-between items-center">
                                                          <div className="my-0 text-lg cursor-pointer">
                                                                {
                                                            <a data-testid={caseData.caseReferenceNumber} className="my-0 text-lg text-black cursor-pointer" onClick = {() =>callcase360(caseData)}> <strong>  {`${caseData.caseReferenceNumber}`}</strong>
                                                            </a>
                                                            }
                                                            
                                                            </div>
                                                           
                                                            
                                                            </div>
                                                            
                                                        </div>
                                                       

                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("address_txt")}</strong>{" "}
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData?.asset?.addresses?.formattedAddress) ? "" : caseData?.asset?.addresses?.formattedAddress}  `}
                                                               
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("Date_Creation")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(caseData.createdDate || '')}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("caseType")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.caseSubTypeCategory) ? "" : caseData.caseSubTypeCategory}`}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("request_category")}</strong>
                                                                <br></br>
                                                                {`${isNullOrUndefined(caseData.caseCategory) ? t("no_request_category") : caseData.caseCategory}`}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("status_txt")}</strong>
                                                                <br></br>
                                                               
                                                                {`${isNullOrUndefined(caseData.status) ? "" : caseData.status}`}
                                                            </div>
                                                        </div>

                                                        <div className="px-4 md:flex md:justify-between">
                                                         </div>
                                                    </div>
                                                </div>
                                            ))
                                            )}
                                    </div>
                                ) : (
                                        <div className="div-loader">
                                            <div className="SpinnerWrapper">
                                                <LoadingSpinner />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            </MobilePagination>
        </div>
    )
}

export default CaseResultComponent;