import React, { useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_CREATION_QUESTIONS,
  CASE_CREATION_QUESTIONIDS_ANSWERS,
} from "../../../ducks/redux/actionTypes";

import { RouteComponentProps } from "react-router-dom";
import {
  HOUSEHOLD_OR_SUBLETTING_GUEST,
  HOUSEHOLD_OR_SUBLETTING_LETTING,
  HOUSEHOLD_OR_SUBLETTING_LOADGER,
  REFERENCE_CASE_QUESTION_3,
  REFERENCE_CASE_QUESTION_4,
  LEASEHOLD_OWNERSHIP_TEXT1,
  COMPLEX_CASE_SERVICE_COMPLAINT_Q4,
  CUSTOMER_NEEDS_TENANNCY_AGREEMENT,
  CUSTOMER_NEEDS_REFERENCE,
  CUSTOMER_LEASEHOLD_OR_SHARED_OWNERSHIP,
  CUSTOMER_HOUSEHOLD_MEMBERS_OR_SUBLETTING,
  CUSTOMER_MEDICAL_REASONS,
  CUSTOMER_HOMEHUNT,
  REFERENCE_CASE_QUESTION_5
} from "./Question";
import { BackValue } from "../../../models/shared/Enum";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));

interface TextTypeProps extends RouteComponentProps<any> {}

const TextTypeQuestion: React.FC<TextTypeProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let referenceCase: any = "0";
  let leasehold: any;
  const format = /^(?!.*--)[^><&*!%]*$/;
  let complexcase: any;
  let emailPrefilled:boolean = false;

  
  const dispatch = useDispatch();

  const [textValue, setTextValue] = useState("");
  const [showError, setShowError] = useState("");
  const [showStaticAlert, setShowStaticAlert] = useState("");  
  let householdMemberOrSublettingCase: any;
  let TenantCase: any;

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );
  
  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const [questionAnswerArray, setQuestionAnswerArray] = useState<{
    questionText: string;
    questionId: string;
    answer?: string;
  }[]>(() => {
    if (caseSelectedQuestionIdsAnswers && caseSelectedQuestionIdsAnswers.length > 0) {
      return caseSelectedQuestionIdsAnswers;
    } else {
      return [{ questionText: "", questionId: "", answer: "" }];
    }
  });

  const currentPageCounter =  useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  let count = currentPageCounter;

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const caseTypeId = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationId
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  const customerData = useSelector(
    (state: any) => state.userChoicesReducer?.customersData
  );

  if(descData === CUSTOMER_NEEDS_TENANNCY_AGREEMENT || descData === CUSTOMER_NEEDS_REFERENCE || descData === CUSTOMER_LEASEHOLD_OR_SHARED_OWNERSHIP || descData === CUSTOMER_HOUSEHOLD_MEMBERS_OR_SUBLETTING || descData === CUSTOMER_MEDICAL_REASONS || descData === CUSTOMER_HOMEHUNT){
    if(questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4){
      emailPrefilled = true;
    }
  }


  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_3
  ) {
    referenceCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4 &&
    caseTypeId === "14"
  ) {
    referenceCase = "2";
  }
  else if (questionInfoData && questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4 && caseTypeId === "35") {
    TenantCase = "1";
  }
  else if (questionInfoData &&  questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_5 && caseTypeId === "35") {
    TenantCase = "2";
    }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_GUEST
  ) {
    householdMemberOrSublettingCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_LOADGER
  ) {
    householdMemberOrSublettingCase = "2";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_LETTING
  ) {
    householdMemberOrSublettingCase = "3";
  }

  // Validating leasehold ownership
  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_4 &&
    caseTypeId === "16"
  ) {
    leasehold = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP_TEXT1
  ) {
    leasehold = "2";
  }

  if(questionInfoData && questionInfoData[count]?.label === COMPLEX_CASE_SERVICE_COMPLAINT_Q4 ) {
    complexcase = true;
  }

  const assignedSelectedAnswersValue = () => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        setTextValue(fetchAnswer[index]);
      }
    });
  };

  useEffect(() => {
    if(emailPrefilled){
      setTextValue(customerData?.addresses?.digitalAddresses[0]?.address);
    }
  },[emailPrefilled]);

  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    
    const newData = {
      questionText: questionInfoData[count]?.label,
      questionId: questionInfoData[count]?.questionId,
      answer: "",
    }
    if (questionAnswerArray.length > count + 1) {
      questionAnswerArray.splice(count + 1, questionAnswerArray.length);
    }
    if (questionAnswerArray.length == 0 || (!questionAnswerArray[count+1])) {
      setQuestionAnswerArray(prevArray => [...prevArray, newData]);
    }
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
  }, [count]);

   useEffect(() => {
    if (
      questionInfoData &&
      questionInfoData[count]?.alertMessageCode !== null
    ) {
      if (questionInfoData[count]?.alertMessageCode?.message) {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.message);
      }
      else {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.code);
      }    
    }
  }, [questionInfoData]);

  const backButtonHandler = () => {
    clearCaseDataFromRedux();
    if (count >= 1) {

      count = count - 1;

      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });

      history.push("/CaseQuestionnaire");
    } else {
      history.push("/CaseType");
    }
  };
  const clearCaseDataFromRedux = () => {
    if(fetchAnswer.length > count){
    const ans = fetchAnswer;
    const question = fetchQuestion;
    ans.pop();
    question.pop();
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: ans });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: question });
    }
  };
  const onContinueHandler = () => {
    setShowStaticAlert("");    
    if (textValue === "" ) {
      setShowError(t("Case_Question_Error"));
    } else {
      if(questionInfoData[count]?.label == "Please enter your email address"){
        if(isValidEmail(textValue)){
          validation();
        }
        else{
          setShowError(t("Invalid_Email_Msg"))
        }
      }
      else
      {
        validation();
      }
     
    }
  };

  const validation = () =>{
    count = count + 1;
    const updatedQuestionAnswerArray = [...questionAnswerArray];
    updatedQuestionAnswerArray[count].answer = textValue;
  
    dispatch({
      type: CASE_COUNTER,
      payload: count,
    });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
    dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
    dispatch({ type: CASE_CREATION_QUESTIONIDS_ANSWERS, payload: updatedQuestionAnswerArray });
    setTextValue("");
    history.push({
      pathname: "/CaseQuestionnaire",  
      state: { previousPageCounter: currentPageCounter }  
    });
  }

  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  const blurInputHandler = (e: any) => {
    let txtInput = e.target.value.replace(/\n/g, " ");
      if (txtInput !== ansArray[count]) {
        //Removing further answers ans questions
        fetchQuestion && fetchQuestion.splice(count, fetchQuestion.length);
        fetchAnswer && fetchAnswer.splice(count, fetchAnswer.length);
        dispatch({ type: CASE_EDIT, payload: true });
      }
      setTextValue(txtInput);
      setAnsArray([...ansArray, txtInput]);
      // ==== If user is changing the answer for the same question, need to get index and replace the value ===
      let newArr = [...ansArray];
      newArr[count] = txtInput ;
      setAnsArray(newArr);
  };

  const onChange = (e: any) => {
    if (format.test(e.target.value)) {
      setShowError("");
      setTextValue(e.target.value);
    } else {
      setShowError( t("Case_Input_Error"));
    }
  }
  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="reploc-container" role="main">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
        <div className="h5 sm:mx-1" id="repreason-div1">
          <h2
            className="text-purple-100 text-2xl mb-0 global-txt"
            data-testid="title"
          >
            {descData}
          </h2>
        </div>
        <br />

        <div
          className="sm:mx-1"
          key={questionInfoData && questionInfoData[count]?.questionId}
        >
          <label className="block">
            <p className="text-base my-0 global-txt" id="case-q1">
              {t(questionInfoData && questionInfoData[count]?.label)}
            </p>
            <br />
            <div
              id="repAddDet-div3"
              className="global-txt mt-1"
              data-testid="repairdetails"
            >
              <textarea
                data-testid="Case_question_details"
                id="outlined-basic"
                className="textarea-mob mt-2 border-dark-grey global-txt border-grey vul-teal"
                placeholder=""
                value={textValue}
                style={{marginLeft: 0}}
                onChange={(e) => onChange(e)}
                onBlur={blurInputHandler}
              />
            </div>
          </label>
        </div>
        <br />
        {/* ==== Showing error ==== */}
        {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === End error ==== */}

        {/* ==== Display purple alert warning === */}
        {showStaticAlert && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t(showStaticAlert)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === Close purple warning === */}
        <div
          className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
          id="repreason-main-btn"
        >
          <ExitModalContent
            data-testid="case-exitmodal"
            exit={exitButtonHandler}
            btn_msg="return_case_button"
          />
          <Button
            onClick={onContinueHandler}
            className="pad-bot"
            data-testid="btn_caseQ"
          >
            {t("continue")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Suspense>
    </div>
  );
};

export default TextTypeQuestion;
