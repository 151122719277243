import React, { useEffect, useCallback, useState, lazy, Suspense } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_CREATION_QUESTIONS,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_SUBTYPE,
  CASE_SUBTYPE_CATEGORY,
  CASE_CREATION_QUESTIONIDS_ANSWERS
} from "../../../ducks/redux/actionTypes";
import { RouteComponentProps } from "react-router-dom";
import {
  VANDALISM_CASE_QUESTION,
  REFERENCE_CASE_QUESTION_1,
  REFERENCE_CASE_QUESTION_2,
  TENANCY_AGREEMENT_CASE_QUESTION,
  HOUSEHOLD_OR_SUBLETTING_MEMBER,
  LEASEHOLD_OWNERSHIP_RADIO,
  COVID_CASE_QUESTION,
  COMPLEX_CASE_SERVICE_COMPLAINT_Q3,
  ESTIMATED_SERVICE_CHARGE_BUDGET,
  RELATE_TO_ESTIMATES_OR_ACTUALS,
} from "./Question";
import { BackValue } from "../../../models/shared/Enum";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));

interface radioProps extends RouteComponentProps<any> { }

const RadioTypeQuestion: React.FC<radioProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let errorCode = "";
  let val = "";
  const dispatch = useDispatch();

  const [blockingIsVisible, setBlockingIsVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [showError, setShowError] = useState("");
  const [blockingAlert, setBlockingAlert] = useState("");
  const blockingArray: any[] = [];
  const [vandalism, setVandalism] = useState("false");
  let reportVandalism: any;
  let referenceCase: any;
  const [reference, setReference] = useState(0);
  let tenancyAgreement: any;
  let householdMemberOrSublettingCase: any;
  let leasehold: any;
  let skipComplexCaseQuestion: any;
  const [tenancyAgree, setTenancyAgree] = useState(false);
  const [sublettingCase, setSublettingCase] = useState(false);
  const [leaseholdOwnership, setLeaseholdOwnership] = useState("");
  const [isDisabled, setButtonDisabled] = useState(false);
  const [showStaticAlert, setShowStaticAlert] = useState("");
  const [showStaticAlertYellow, setShowStaticAlertYellow] = useState("");
  const [skipComplexCaseques,setskipComplexCaseques] = useState(false);
  const [radioValueId, setRadioValueId] = useState("");

  enum DependsOnAttributes {
    PreviousQuestionID = "q2_1"
  }

  enum CasesAttributes {
    subType = "subType",
    subTypeCategory = "subTypeCategory"
  }


  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );
  
  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const [questionAnswerArray, setQuestionAnswerArray] = useState<{
    questionText: string;
    questionId: string;
    answer?: string;
  }[]>(() => {
    if (caseSelectedQuestionIdsAnswers && caseSelectedQuestionIdsAnswers.length > 0) {
      return caseSelectedQuestionIdsAnswers;
    } else {
      return [{ questionText: "", questionId: "", answer: "" }];
    }
  });

  const currentPageCounter =  useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  let count = currentPageCounter;

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
  );
  
  if (questionInfoData && questionInfoData[count]?.blocking !== null) {
    blockingArray.push(questionInfoData[count]?.blocking);
    errorCode = questionInfoData[count]?.blocking?.errorCode;
  }
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  // === Validating vandalism ===

  if (
    questionInfoData &&
    questionInfoData[count]?.label === VANDALISM_CASE_QUESTION
  ) {
    reportVandalism = "false";
  }

  //=== Validating reference ===

  if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_1
  ) {
    referenceCase = "1";
  } else if (
    questionInfoData &&
    questionInfoData[count]?.label === REFERENCE_CASE_QUESTION_2
  ) 
  {
    referenceCase = "1";
  }

  // Validating tenancy agreement question

  if (
    questionInfoData &&
    questionInfoData[count]?.label === TENANCY_AGREEMENT_CASE_QUESTION
  ) {
    tenancyAgreement = "1";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_MEMBER
  ) {
    householdMemberOrSublettingCase = "1";
  }

  // Leasehold and ownership member

  if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP_RADIO
  ) {
    leasehold = "1";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === COMPLEX_CASE_SERVICE_COMPLAINT_Q3
  ) {
    skipComplexCaseQuestion = true;
  }

  const assignedSelectedAnswersValue = useCallback(() => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        questionInfoData[index]?.options?.map((opt: any, id: number) => {
          if (opt.id === fetchAnswer[index]) {
            setRadioValue(opt.value);
            setRadioValueId(opt.id);         
          }
        });
      }
    });
  }, [count]);

  const assignedCounterComesFromEdit = () => {
    if (referenceCase === "1" && ansArray[count] === "1") {
      setReference(1);
    } else if (referenceCase === "1" && ansArray[count] === "2") {
      setReference(2);
    } else if (referenceCase === "2" && ansArray[count] === "1") {
      setReference(3);
    } else if (referenceCase === "2" && ansArray[count] === "2") {
      setReference(4);
    }
  };

  const assignVandalismComesFromEdit = () => {
    if (reportVandalism === "false" && ansArray[count] === "2") {
      setVandalism("true");
    } else if (reportVandalism === "false" && ansArray[count] === "1") {
      setVandalism("false");
    }
  };

  const assignSublettingCaseComesFromEdit = () => {
    if (householdMemberOrSublettingCase === "1" && ansArray[count] === "1") {
      setSublettingCase(true);
    } else {
      setSublettingCase(false);
    }
  };

  const assignLeaseholdOwnership = () => {
    if (leasehold === "1" && ansArray[count] === "1") {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && ansArray[count] === "2") {
      setLeaseholdOwnership("2");
    }
  };

  const assignSkipQuestionValue = () => {
    if (skipComplexCaseQuestion) {
      setskipComplexCaseques(true);
    }
  }

  const defaultSelected = () => {
    if ( questionInfoData && questionInfoData[count]?.label === RELATE_TO_ESTIMATES_OR_ACTUALS ) 
      { 
        questionInfoData[0]?.options.map((data: any, index: number) => { 
        if(data.value === ESTIMATED_SERVICE_CHARGE_BUDGET && data.id === ansArray[0] )
         {         
            questionInfoData[count]?.options?.map((opt: any, id: number) => {
            if (opt.hasOwnProperty("defaultSelected") && opt.defaultSelected === "true" ) {
              setRadioValue(opt.value); 
              setRadioValueId(opt.id); 
              setAnsArray([...ansArray, opt.id]); 
            }
          });             
         }
       });
      }
    }

  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    const newData = {
      questionText: questionInfoData[count]?.label,
      questionId: questionInfoData[count]?.questionId,
      answer: "",
    }
    if (questionAnswerArray.length > count + 1) {
      questionAnswerArray.splice(count + 1, questionAnswerArray.length);
    }
   if (questionAnswerArray.length == 0 || (!questionAnswerArray[count+1])) {
      setQuestionAnswerArray(prevArray => [...prevArray, newData]);
    }
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    if (questionInfoData[count]?.alertMessageCode?.message) {
      setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.message);
    }
    else {
      setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.code);
    }

    if (questionInfoData[count]?.alertMessageCodeYellow?.message) {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.message);
    }
    else {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.code);
    }
    defaultSelected();
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
    assignedCounterComesFromEdit();
    assignSublettingCaseComesFromEdit();
    assignVandalismComesFromEdit();
    assignLeaseholdOwnership();
    assignSkipQuestionValue();

  }, [count]);

  const handleRadioChange = (e: any) => {
    setButtonDisabled(false);
    setRadioValue(e.target.value);
    setRadioValueId(e.target.name);
    setShowError("");

    if (e.target.name !== ansArray[count] && ansArray[count] != undefined) {
      //Removing further answers ans questions
      fetchQuestion.splice(count, fetchQuestion.length);
      fetchAnswer.splice(count, fetchAnswer.length);
      dispatch({ type: CASE_EDIT, payload: true });      
    }    
      setAnsArray([...ansArray, e.target.name]);
      // ==== If user is changing the answer for the same question, need to get index and replace the value ===
      let newArr = [...ansArray]; // copying the old datas array
      newArr[count] = e.target.name; // replace e.target.value with new value
      setAnsArray(newArr);
      
    setShowError("");
     // ==== Validate not to display next question, if user select dependent options ====

    if (reportVandalism === "false" && e.target.name === "2") {
      setVandalism("true");
    } else if (reportVandalism === "false" && e.target.name === "1") {
      setVandalism("false");
    }

    // === Validating reference case condition here ===

    if (referenceCase === "1" && e.target.name === "1") {
      setReference(1);
    } else if (referenceCase === "1" && e.target.name === "2") {
      setReference(2);
    } else if (referenceCase === "2" && e.target.name === "1") {
      setReference(3);
    } else if (referenceCase === "2" && e.target.name === "2") {
      setReference(4);
    
    }

    // Validating tenancy agreement

    if (tenancyAgreement === "1" && e.target.name === "2") {
      setTenancyAgree(true);
    }

    // Validating Subletting house member

    if (householdMemberOrSublettingCase === "1" && e.target.name === "1") {
      setSublettingCase(true);
    } else {
      setSublettingCase(false);
    }

    // Validating leasehold question

    if (leasehold === "1" && e.target.name === "1") {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && e.target.name === "2") {
      setLeaseholdOwnership("2");
    }

    if (blockingArray.length > 0) {
      if (blockingArray[0].dependsOn[0].responseId === e.target.name) {
        setBlockingAlert(t(errorCode));
        setBlockingIsVisible(false);
      } else {
        setBlockingAlert("");
      }
    }
  };


  const setCaseTypeAndSubTypeCategory = () => {
    if (questionInfoData && questionInfoData[count]?.options != null) {
      questionInfoData[count]?.options?.map((optionValues: any) => {
        if (optionValues?.value === radioValue) {
          optionValues?.dependsOnAttribute?.map((dependsOnValues: any) => {
            if(dependsOnValues.key === CasesAttributes.subType){
              dispatch({ type: CASE_SUBTYPE, payload: dependsOnValues.value });
            }
            else if(dependsOnValues.key === CasesAttributes.subTypeCategory) {
              dispatch({ type: CASE_SUBTYPE_CATEGORY, payload: dependsOnValues.value});
            }
          })
        }
      })
    }
  }
  
  const onContinueHandler = () => {
    setShowStaticAlert("");
    setShowStaticAlertYellow("");
    setCaseTypeAndSubTypeCategory();
    if (
      questionInfoData &&
      questionInfoData[count]?.label === COVID_CASE_QUESTION &&
      radioValue === ""
    ) {
      setShowError(t("Case_Covid_CC007"));
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
      if (
        vandalism === "true" ||
        tenancyAgree === true ||
        leaseholdOwnership === "1" ||
        skipComplexCaseques ||
        reference ||
        sublettingCase ||
        householdMemberOrSublettingCase ||
        leaseholdOwnership
      ) {
        val = "1";
      }
        count = count + 1;
      
      if (!blockingAlert) {
        if (radioValue === "") {
          setShowError(t("Case_Question_Error"));
        } else {
          const updatedQuestionAnswerArray = [...questionAnswerArray];
          updatedQuestionAnswerArray[count].answer = radioValueId || "";
          setAnsArray([...ansArray, radioValueId]);
          dispatch({
            type: CASE_COUNTER,
            payload: count,
          });
          dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
          dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
          dispatch({ type: CASE_CREATION_QUESTIONIDS_ANSWERS, payload: updatedQuestionAnswerArray });
          setRadioValue("");
          setRadioValueId("");
          history.push({
            pathname: "/CaseQuestionnaire",  
            state: { previousPageCounter: currentPageCounter }  
          });
        }
      } else {
        setBlockingIsVisible(true);
      }
    }
  };

  const backButtonHandler = () => {
    clearCaseDataFromRedux();
    if (count >= 1) {
      count = count - 1;
      dispatch({
        type: CASE_COUNTER,
        payload: count,
      });
      history.push("/CaseQuestionnaire");
    } else {
      history.push("/CaseType");
    }
  };
  const clearCaseDataFromRedux = () => {
    if(fetchAnswer.length > count){
    const ans = fetchAnswer;
    const question = fetchQuestion;
    ans.pop();
    question.pop();
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: ans });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: question });
    }
  };
  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="reploc-container" role="main" data-testid="case-div111">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-8 lg:mb-4 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
        {!COVID_CASE_QUESTION && (
          <div className="h5 sm:mx-1" id="repreason-div1">
            <h2
              className="text-purple-100 text-2xl mb-0 global-txt"
              data-testid="title"
            >
              {descData}
            </h2>
          </div>
        )}
   
        <div key={questionInfoData && questionInfoData[count]?.questionId}>
          {COVID_CASE_QUESTION ? (
            <div className="h5 sm:mx-1" id="repreason-div1">
              <h2
                className="text-purple-100 text-2xl mb-0 global-txt"
                data-testid="label"
              >
                {t(questionInfoData && questionInfoData[count]?.label)}
              </h2>
            </div>
          ) : (
            <p className="text-base my-0 global-txt" data-testid="case-q1">
              {t(questionInfoData && questionInfoData[count]?.label)}
            </p>
          )}

          <RadioGroup
            data-testid="radioGroup"
            key={questionInfoData && questionInfoData[count]?.questionId}
            defaultValue={radioValue}
            className="question-btn mb-4"
            value={radioValue}
            onChange={handleRadioChange}
          >
            {questionInfoData &&
              questionInfoData[count]?.options?.map((radioOption: any) => {
                if (radioOption?.dependsOnAttributes?.length > 0) {
                  return radioOption?.dependsOnAttributes?.map((dependsOnAttr: any) => {
                    if (dependsOnAttr.key === DependsOnAttributes.PreviousQuestionID) {
                      if (dependsOnAttr.value === ansArray[count - 1]) {
                        return (
                          <FormControlLabel
                            id={radioOption.id}
                            key={radioOption.id}
                            value={radioOption.value}
                            data-testid="formControl"
                            className="mb-2 global-txt card-text margin-lt"
                            control={
                              <Radio
                                id={`covidQues-radio1`}
                                placeholder="radioLabel"
                                name={radioOption.id}
                              />
                            }
                            label={radioOption.value}
                          />
                        );
                      }
                    }
                  });
                } else {
                  return (
                    <FormControlLabel
                      id={radioOption.id}
                      key={radioOption.id}
                      value={radioOption.value}
                      data-testid="formControl"
                      className="mb-2 global-txt card-text margin-lt"
                      control={
                        <Radio
                          id={`covidQues-radio1`}
                          placeholder="radioLabel"
                          name={radioOption.id}
                        />
                      }
                      label={radioOption.value}
                    />
                  );
                }
              })}
          </RadioGroup>
        </div>

        {/* ==== Showing error ==== */}
        {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === End error ==== */}

        {/* ==== Blocking alert ===== */}

        {blockingAlert && (
          <div
            data-testid="alert-warning"
            className={
              blockingIsVisible
                ? "warning mb-8 unblockingMessage"
                : "blockingMessage"
            }
            role="alert"
            placeholder="alertNo"
          >
            <div className="w-full px-2 py-2 bg-red-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t(blockingAlert)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* ==== End blocking alert ==== */}

        {/* ==== Display purple static alert === */}
        {showStaticAlert && (
          <div
            data-testid="alert-warning"
            className="error mb-8"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t(showStaticAlert)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === Close purple static alert  === */}

         {/* ==== Display Yellow static alert === */}
         {showStaticAlertYellow && (
          <div
            data-testid="alert-warning"
            className="error mb-8"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {t(showStaticAlertYellow)}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* === Close Yellow static alert  === */}


        <div
          className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
          id="repreason-main-btn"
        >
          <ExitModalContent
            data-testid="case-exitmodal"
            exit={exitButtonHandler}
            btn_msg="return_case_button"
          />
          <Button
            onClick={onContinueHandler}
            className="pad-bot"
            data-testid="btn_caseQ"
            disabled={isDisabled}
          >
            {t("continue")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Suspense>
    </div>
  );
};

export default RadioTypeQuestion;
