import React, { useEffect, useState } from "react";
import peabody from "../../assets/img/peabody-logo-teal.png";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { v4 } from "uuid";
import { useLocation } from "react-router-dom";
import * as api from "../../api/index";
import { getWorkOrderObject, assetDataResponse, woChronologyTabState, assetDataStatus, customerList, customersData, keyContact, localAuthority, loginStatus, userChoicesPermissions, userChosenAccountData, userChosenAccountNumber, userChosenBackButton, userChosenRaisedSince, userChosenRaisedUpto, userChosenSearchText, userChosenSearchType, userChosentenancyGroup, userChosenBackValue, userChosenCustomerID, componentInformation, hierarchyOfPremises, userChosenAlertValue, propertyListData, workList, transactionInformation, userChosenDuplicateEntity, userChosenCurrentValue, checkForLandlord, createCasePermissions, createEnhancedHubRepairPermissions, isRepairMediAllowed, appConfigVandalismToggle, searchTextCountValue, isCaseAllowed} from "../../ducks/redux/actions/userChoices";
import { getReferenceNumber, saveCustomerDetails, workOrderRepairObject, appConfigCovidToggle } from "../../ducks/redux/actions/getAddress";
import Hotjar from '@hotjar/browser'
import { PermissionsList } from "../../models/Permissions";
import { isNullOrUndefined } from "../../utils/formatting";
import { IS_CASEMANAGEMENT_TOGGLE, IS_UPDATEANACTION_TOGGLE } from "../../ducks/redux/actionTypes";

const adLoginUrl: string = process.env.REACT_APP_CSS_AD_LOGIN_URL || "";
const adLogoutUrl: string = process.env.REACT_APP_CSS_AD_LOGOUT_URL || "";
const siteId = process.env.REACT_APP_SITE_ID || ''; const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION || ''; 

type HeaderProps = {
};
const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [navBarFun, setNavBarFun] = useState("none");
  const [isVisibleSignIn, setIsVisibleSignIn] = useState("block");
  const [isVisibleSignOut, setIsVisibleSignOut] = useState("none");
  const [userName, setUserName] = useState('');
  const queryStringSearch = useLocation().search;

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  
  const userChoicesReducer = useSelector(
    (state: any) => state?.userChoicesReducer
  );

  const caseCreationReducer = useSelector(
    (state:any) => state?.caseCreationReducer
  );

  const addressesReducer = useSelector(
    (state: any) => state?.addresses
  );

  const navBarFunction = () => {
    if (navBarFun === "none")
      setNavBarFun("block");
    else
      setNavBarFun("none");
  }
  const goToHome = () => {
    if (history.location.pathname === "/LandingView" || history.location.pathname === "/" || history.location.pathname === "/Login")
      window.location.reload();
    else
      history.push("/LandingView");
  }
  const RedirectToIDAMLoginPage = () => {
    dispatch(userChosenBackButton(false));
    window.location.href = adLoginUrl;
  }
  const RedirectToLogoutPage = () => {
    (async function () {
      try {
        dispatch(loginStatus(false));
        dispatch(workOrderRepairObject(null));
        dispatch(workList(null));
        dispatch(propertyListData(null));
        dispatch(getReferenceNumber(null))
        dispatch(userChosenAccountNumber(""));
        dispatch(userChosentenancyGroup(""));
        dispatch(saveCustomerDetails(""));
        dispatch(
          userChosenAccountData("")
        );
        dispatch(getWorkOrderObject(null));
        dispatch(customersData(""));
        dispatch(userChosenBackButton(false));
        dispatch(userChosenSearchText(null));
        dispatch(userChosenSearchType(null));
        dispatch(userChosenRaisedSince(null));
        dispatch(userChosenRaisedUpto(null));
        dispatch(customerList(null));
        dispatch(assetDataStatus(""))
        dispatch(assetDataResponse(null));
        dispatch(localAuthority(""));
        dispatch(keyContact(null))
        dispatch(userChosenBackValue(""));
        dispatch(userChosenDuplicateEntity(""));
        dispatch(userChosenCurrentValue(""));
        dispatch(userChosenCustomerID(""));
        dispatch(componentInformation(""));
        dispatch(transactionInformation(""));
        dispatch(hierarchyOfPremises(null));
        dispatch(woChronologyTabState(""));
        dispatch(userChosenAlertValue(null));
        dispatch(checkForLandlord(false));
        dispatch(createEnhancedHubRepairPermissions(null))
        await api.clearSession();
        localStorage.clear();
        sessionStorage.clear();
        setUserName('');
        window.location.href = adLogoutUrl;
        history.push('/Login');
      }
      catch (e) {
        console.log(e);
        setTimeout(() => {
          history.push("/GenericError");
        }, 1000);
      }
    })();
  }
  const RedirectToPermissionPage = () => {
    (async function () {
      try {
        dispatch(loginStatus(false));
        dispatch(workOrderRepairObject(null));
        dispatch(workList(null));
        dispatch(propertyListData(null));
        dispatch(userChosenAccountNumber(""));
        dispatch(userChosentenancyGroup(""));
        dispatch(getReferenceNumber(null))
        dispatch(saveCustomerDetails(""));
        dispatch(
          userChosenAccountData("")
        );
        dispatch(getWorkOrderObject(null));
        dispatch(customersData(""));
        dispatch(userChosenBackButton(false));
        dispatch(userChosenSearchText(null));
        dispatch(userChosenSearchType(null));
        dispatch(userChosenRaisedSince(null));
        dispatch(userChosenRaisedUpto(null));
        dispatch(customerList(null));
        dispatch(assetDataStatus(""))
        dispatch(assetDataResponse(null));
        dispatch(localAuthority(""));
        dispatch(keyContact(null))
        dispatch(userChosenBackValue(""));
        dispatch(userChosenCurrentValue(""));
        dispatch(userChosenCustomerID(""));
        dispatch(componentInformation(""));
        dispatch(transactionInformation(""));
        dispatch(hierarchyOfPremises(null));
        dispatch(userChosenAlertValue(null));
        dispatch(checkForLandlord(false));
        localStorage.clear();
        sessionStorage.clear();
        setUserName('');
        history.push('/PermissionError');
      }
      catch (e) {
        console.log(e);
        setTimeout(() => {
          history.push("/GenericError");
        }, 1000);
      }
    })();
  }
  const hotjarScriptCall = async () => {
    const appConfigHotjarToggleValue = await api.GetAppConfigToggleValue(
     "IsHotjarEnabledColleague"
   );
    if(appConfigHotjarToggleValue.value.toLowerCase()  == 'true'){  
     Hotjar.init(Number(siteId), Number(hotjarVersion));
    }

 }

 let toggleLogin = async (loggedInStatus:Boolean) => {
   if (!loggedInStatus) {
     setIsVisibleSignIn("block");
     setIsVisibleSignOut("none");
   }
   else {
     setIsVisibleSignIn("none");
     setIsVisibleSignOut("block");
     hotjarScriptCall();

     
     await retrievePermissions(localStorage.getItem('agent') || "");
     retrieveAppConfigs();
   }
 }

  let retrievePermissions = async (xAgentIdentifier: string) => {

    if(xAgentIdentifier != "" && isNullOrUndefined(getPermissionsData)) {
      // Retrieve Permissions only if XAgentIdentifier exists in Local Storage + Permission Data is not available in Redux store
      let permissionsResponded = await api.getpermissions(xAgentIdentifier as string);
          
      if (permissionsResponded?.permissions[0] != undefined) {
        dispatch(userChoicesPermissions(permissionsResponded?.permissions));
        dispatch(loginStatus(true));
        const isCreateEnhancedHubRepairPermission : any = createEnhancedHubRepairPermissions(permissionsResponded?.permissions?.indexOf(
          PermissionsList.workorder_create_enhanced_hub_repair_journey))
        dispatch(createEnhancedHubRepairPermissions(isCreateEnhancedHubRepairPermission?.payload >= 0))
      }
      else {
        dispatch(userChoicesPermissions(null));
        dispatch(createEnhancedHubRepairPermissions(null))
        setNavBarFun("none")
        RedirectToPermissionPage();
        return
      }
  
      if (permissionsResponded?.casePermissions?.restrictedCreateSubCategoriesList?.length > 0) {
        dispatch(createCasePermissions(permissionsResponded?.casePermissions?.restrictedCreateSubCategoriesList));
      }
    }
  }

  let retrieveAppConfigs = async () => {

    var requiredAppConfigs = [];
    requiredAppConfigs.push(
      {key: "SearchTextCount", reduxReducerKey: userChoicesReducer?.searchTextCountValue},
      {key: "isRepairMediaAllowed", reduxReducerKey: userChoicesReducer?.isRepairMediaAllowed},
      {key: "isVandalismQuestionColleagueRequired", reduxReducerKey: userChoicesReducer?.vandalismtoggleValue},
      {key: "isCovidQuestionColleagueRequired", reduxReducerKey: addressesReducer?.covidtoggleValue},
      {key: "isCaseAllowed", reduxReducerKey: userChoicesReducer?.isCaseAllowed},
      {key: "isCaseManagement", reduxReducerKey: caseCreationReducer?.isCaseManagementToggle},
      {key: "IsUpdateAnActionToggle", reduxReducerKey: caseCreationReducer?.isUpdateAnActionToggle});

    var requiredAppConfigCalls = requiredAppConfigs.map(
        (requiredConfig) => 
          {
            if(isNullOrUndefined(requiredConfig.reduxReducerKey)) {
              return api.GetAppConfigToggleValue(requiredConfig.key)
            }
          }
      );

    Promise.allSettled(requiredAppConfigCalls).then(appConfigResponses => {
      appConfigResponses.forEach(appConfigResponse => {
        if(appConfigResponse?.status == "fulfilled" && !isNullOrUndefined(appConfigResponse.value?.value)) {
          switch(appConfigResponse.value?.key) {
            case "SearchTextCount": 
              dispatch(searchTextCountValue(appConfigResponse.value?.value));
              break;
            case "isRepairMediaAllowed": 
              dispatch(isRepairMediAllowed(appConfigResponse.value?.value));
              break;
            case "isVandalismQuestionColleagueRequired": 
              dispatch(appConfigVandalismToggle(appConfigResponse.value?.value));
              break;
            case "isCovidQuestionColleagueRequired":
              dispatch(appConfigCovidToggle(appConfigResponse.value?.value));
              break;
            case "isCaseAllowed": 
              dispatch(isCaseAllowed(appConfigResponse.value?.value));
              break;
            case "isCaseManagement": 
              dispatch({ type: IS_CASEMANAGEMENT_TOGGLE, payload: appConfigResponse.value?.value.toLowerCase() === "true" ? true : false });
              break;
            case "IsUpdateAnActionToggle": 
              dispatch({ type: IS_UPDATEANACTION_TOGGLE, payload: appConfigResponse.value?.value.toLowerCase() === "true" ? true : false });
              break;
            default:
              break;
          }
        }
      });
    });
  }

  const authCode = new URLSearchParams(queryStringSearch).get("code") || "";
  useEffect(() => {
    window.scrollTo(0,0);
    let tokenDataResponded;
    if (!isUndefinedNullOrEmpty(authCode)) {
      localStorage.setItem('authCode', authCode);
      localStorage.setItem('login', 'true');
      if (localStorage.getItem('userName') === "" || localStorage.getItem('userName') === null || localStorage.getItem('userName') === undefined) {
        localStorage.setItem('sessionId',v4());
        localStorage.setItem('corelationId', v4());
        dispatch(userChosenBackButton(false));
        dispatch(userChosenSearchText(null));
        dispatch(userChosenSearchType(null));
        dispatch(userChosenRaisedSince(null));
        dispatch(userChosenRaisedUpto(null));
        (async function () {
            tokenDataResponded = await api.GetUserName(authCode as string);
            localStorage.setItem('agent', tokenDataResponded.id);
            localStorage.setItem('userName', tokenDataResponded.name);
            localStorage.setItem('email', tokenDataResponded.email);
            localStorage.setItem('user_department', tokenDataResponded.department);
            localStorage.setItem('user_role', tokenDataResponded.roles);
            toggleLogin(true);
            
            await retrievePermissions(tokenDataResponded.id);
            retrieveAppConfigs();

            setUserName(tokenDataResponded.name);
            history.push('/LandingView');
        })()
      }
    } else {
        toggleLogin(localStorage.getItem('login') ? true: false);
    }

  }, []);


  return (
    <nav className="navbar navbar-expand-lg navbar-light py-2 border-bottom">
      <div className="container">
        <a data-testid="peabody_logo" className="navbar-brand cursor-pointer" onClick={goToHome}>
          <img className="img-fluid w-3/4 md:w-auto pl-1" src={peabody} alt="Peabody Logo" />
        </a>
        <button className="hm-menu navbar-toggler pl-nav navbar-toggler-right mr-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" data-testid="mobile-menu" onClick={navBarFunction} >
          <span className="navbar-toggler-icon icon-css"></span></button>
        <div className="collapse navbar-collapse global-txt" id="navbarResponsive"
          style={{ display: navBarFun }} >
          <ul className="navbar-nav ms-auto my-2 my-lg-0 px-2">
            <li className="nav-item cursor-pointer" style={{ display: isVisibleSignOut }}
            ><span className="nav-link"><i className="fas fa-user text-teal pr-2"></i>{userName ? userName : localStorage.getItem('userName')}</span></li>
            <li className="nav-item cursor-pointer" style={{
              display: isVisibleSignOut
            }} onClick={RedirectToLogoutPage} ><span className="nav-link"><i className="fa fa-sign-out text-teal pr-2"></i>{t("Logout")}</span></li>
            <li className="nav-item cursor-pointer"
              style={{ display: isVisibleSignIn }}
              onClick={RedirectToIDAMLoginPage}
            ><span className="nav-link"><i className="fa fa-sign-in text-teal pr-2"></i>{t("login_button")}</span></li>
          </ul>
        </div>
      </div>
    </nav>
  )
};
export default Header;

