import React, { useState, useEffect, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import {
    getCustomerId
} from "../../../ducks/redux/actions/getAddress";
import { backToCaseSelectCustomer, userChosenCustomerID, userChosentenancyGroup } from "../../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../../utils/translation/helper";
import classes from "./style.module.css";
import { trackEvent } from "../../../appInsights/TelemetryService";
import { Container } from "@mui/material";
import Loader from "react-loader-spinner";
import { BackValue } from "../../../models/shared/Enum";
import { CASE_CUSTOMER_NAME, CASE_PROPERTY_TENANCY_GROUP, CASE_TENANCY_ID,CLEAR_CASE_STATE } from "../../../ducks/redux/actionTypes";
import { isNullOrUndefined } from "../../../utils/formatting";

const Button = React.lazy(() => import("../../../Button/Button"));

const ExitModalContent = React.lazy(
    () => import("../../Modal/ExitModalContent")
);

interface SelectCustomerProps extends RouteComponentProps<any> { }
const SelectCustomer: React.FC<SelectCustomerProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setError] = useState("none");
    const [buttondisable, setsetButtonDisable] = useState(false);
    const [radioValue, setRadioValue] = useState("");
    const [activeCustomers, setActiveCustomers] = useState<any>(
        []
    );

    const customerID = useSelector(
        (state: any) => state.addresses?.customerid
      )
    const userChosenPropertyId = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyId
    );
    const backValue = useSelector(
        (state: any) => state?.userChoicesReducer?.backValue
    );
    const caseCustomerName = useSelector(
        (state: any) => state.caseCreationReducer.caseCustomerName
      );

    useEffect(() => {
        trackEvent("SelectCustomer");
        dispatch(backToCaseSelectCustomer(false));
        (async function () {
            try {
                setIsLoading(true);
                let customerProperties: {
                    id: string;
                    lastFirstName: string;
                    fullName: string;
                }[] = [];

                const customerResponse = await api.getCustomerAssetApi(userChosenPropertyId);
                if (customerResponse?.length > 0) {                    
                    customerResponse.map((customer) => {                        
                        let fullname = "";
                        let lastfirstname = ""; 
                        if (customer.surname != null && customer.surname != "") {
                            lastfirstname += " " + customer.surname                           
                        }                      
                        if (customer.title != null && customer.title != "") { fullname += customer.title + " "; }
                        fullname += customer.givenName;
                        lastfirstname += customer.givenName;

                        if (customer.surname != null && customer.surname != "") {                           
                            fullname += " " + customer.surname
                        }
                        customerProperties.push({
                            id: customer.id,
                            lastFirstName: lastfirstname,
                            fullName: fullname
                        });
                    });
                }
                if (radioValue !== "") {
                    setsetButtonDisable(false);
                }
                else {
                    setsetButtonDisable(true);
                }
                const customerData = customerProperties
                    .sort((a, b) => a.lastFirstName.localeCompare(b.lastFirstName)
                );

                setActiveCustomers(customerData);
                
                const selectedtData = (!isUndefinedNullOrEmpty(customerID)) ? customerData.filter((data: any) => {
                    return data.id === customerID;
                }) : customerData;
                
                if (selectedtData.length > 0) {
                    if(!isNullOrUndefined(caseCustomerName)){
                        updateSelection(selectedtData[0].fullName,selectedtData[0].id, false)
                    }
                   
                    await getTenancyData(selectedtData[0].id);
                }
                
                //redirect to next page if single customer
                if (customerData.length === 1) {                    
                    setIsLoading(false);
                    props.history.push("/CaseType");
                }

                setIsLoading(false);
                setError("none");
                
            } catch (e) {
                setError("block");
                setsetButtonDisable(true);
                props.history.push("/genericerror");
            }
        })();
    }, []);

    const backButtonHandler = () => {
        dispatch(getCustomerId(""));
        dispatch(userChosenCustomerID(""));
        if(backValue === BackValue.Asset360View){
        props.history.push("/Asset360View");
        }
        else {
            props.history.push("/Case360View");
        }
    };

    const exitButtonHandler = () => {
        if (backValue == BackValue.Asset360View) {
            props.history.push("/Asset360View");
        }else{
            props.history.push("/Case360View");
          }
    };

    const onContinueHandler = () => {
        dispatch(backToCaseSelectCustomer(true));
        props.history.push("/CaseType");
    };

    const updateSelection = (
        fullName:any,
        customerId: any,
        selectionCheck: any
    ) => {
        dispatch(getCustomerId(customerId));
        dispatch(userChosenCustomerID(customerId));
        setRadioValue(fullName + customerId);
        dispatch({
            type: CASE_CUSTOMER_NAME,
            payload: fullName,
        });
        setsetButtonDisable(false);
        getTenancyData(customerId);
        if(selectionCheck){
            dispatch({ type: CLEAR_CASE_STATE });
         }
    };

    const getTenancyData = async (customerid: any) => {
        try {
            const tenancyGroupResponse = await api.getTenanciesGroup(customerid, userChosenPropertyId);
            if (tenancyGroupResponse?.length > 0) {
                dispatch({
                    type: CASE_PROPERTY_TENANCY_GROUP,
                    payload: tenancyGroupResponse[0].tenancyGroup,
                });
                dispatch(userChosentenancyGroup(tenancyGroupResponse[0].tenancyGroup));
                dispatch({ type: CASE_TENANCY_ID, payload: tenancyGroupResponse[0].tenancyIdentifier })
            }
        } catch (e) {
            setError("block");
            setsetButtonDisable(true);
            props.history.push("/genericerror");
        }
    }

    return (
        <Container id="select-customer-container" data-testid="select-customer-container" className="ctn-fht">
            <Suspense
                fallback={
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={70}
                        width={50}
                        timeout={5000}
                    />
                }
            >
            <div
                id="SelectCustomer-back-div"
                className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
            >
                    <p id="SelectCustomer-p" className="text-teal text-xl mt-4 mb-8">
                    <a
                        className="back-btn"
                        data-testid="backButtonHandler"
                        onClick={backButtonHandler}
                    >
                        <i className="fas fa-angle-left mr-2"></i>
                        {t("back")}
                    </a>
                </p>
            </div>
              {isLoading ? (
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={70}
                    width={50}
                    timeout={5000}
                />
                ) : (
                    <div id="repAddDet-main-div"
                        className="ml-sm-5 pl-sm-5 repair-text container">
                            <div
                                className="ml-sm-5 ml-lg-5 pl-sm-5 pt-0 lg:ml-10 md:ml-10 repair-text"
                                id="repair-main-div"
                            >
                                <div className="h5 question-font-size" id="repair-div1">
                                    <div className="mx-0 px-0 max-w-4xl" id="repair-h1">
                                        <h2 className="text-purple-100 text-2xl mb-8 global-txt">
                                            {t("OPC003b")}
                                        </h2>
                                    </div>
                                </div>
                                <RadioGroup
                                    data-testid="case-radiogroup"
                                    name="radio-buttons-group"
                                    value={radioValue}
                                    className="question-btn mx-0 px-0 max-w-4xl my-2 "
                                >
                                    {activeCustomers
                                        .map((e:any) => {
                                            return (
                                                <FormControlLabel
                                                    data-testid={e.id}
                                                    value={e.fullName + e.id}
                                                    key={"key"+ e.id}
                                                    className="mb-4 global-txt card-text margin-lt"
                                                    control={
                                                        <Radio
                                                            key={e.id}
                                                            id={"radio-id1"}
                                                            data-testid="button1"
                                                            name={e.fullName}
                                                            className={`${classes.radioinput} global-txt`}
                                                            onChange={(
                                                                v: React.ChangeEvent<HTMLInputElement>
                                                            ): void => {
                                                                updateSelection(
                                                                    e.fullName,
                                                                    e.id,
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={e.fullName}
                                                />
                                            );
                                        })}                                    
                                </RadioGroup>
                            </div>

                            <div
                                id="repAddDet-div5"
                                style={{ display: isError }}
                                className="alert alert-warning error-reason mar-desk"
                                role="alert"
                                placeholder="alert"
                            >
                                <span className="global-error-icon">
                                    <i className="fas fa-exclamation me-2"></i>
                                </span>
                                <span>{t("SSR001")}</span>
                            </div>

                            <div
                                className="flex justify-between items-center pb-4 md:pb-8"
                                id="repair-exit-btn"
                            >
                                <ExitModalContent
                                    id="repairproperty-exitmodal"
                                    exit={exitButtonHandler}
                                    contentMessage="Case_OPC001"
                                    btn_msg="Case_Return_Button"
                                />
                            <Button
                                id="caseproperty-btn"
                                onClick={onContinueHandler}
                                disabled={buttondisable}
                                className="button-class"
                                data-testid="btn_caseproperty"
                            >
                                {t("continue")}
                                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
                            </Button>
                            </div>
                        </div>
                    )}
            </Suspense>
        </Container>
    );
};

export default SelectCustomer;
