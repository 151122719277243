import { PremisesAddress } from "../shared/Address/PremisesAddress";
import { isNullOrUndefined } from "../../utils/formatting";
import CustomerCardDetails from "./CustomerCardDetail";

export default class CustomerResponse {
  public models: CustomerCardDetails[] = [];
  public customerData: CustomerData[] = [];
  public totalCount: number = 0;

  constructor(customerResponse: any, customerStatus: string, tenancyType: string) {
    this.mapCustomerResponseWithStatus(customerResponse, customerStatus, tenancyType);
    this.totalCount = !!customerResponse ? customerResponse?.totalCount : 0
  }

  mapCustomerResponseWithStatus = (data: CustomerResponse, customerStatus: string, tenancyType: string) => {
    // when no filter applied
    if (isNullOrUndefined(customerStatus) && isNullOrUndefined(tenancyType)) {
        data?.models?.map((model: CustomerCardDetails) => {
        this.addRecord(model, 0);
      });
    }
    // when only Customer Status filter applied
    else if (!isNullOrUndefined(customerStatus) && isNullOrUndefined(tenancyType)) {
        data?.models?.map((model: CustomerCardDetails) => {
        let index = 0;
        while (index < model?.tenancyModels?.length) {
          const status = model?.tenancyModels[index].isTenancyActive ? "Active Customer" : "Former Customer"
          if (status === customerStatus) {
            this.addRecord(model, index);
            break;
          }
          index++;
        }
      });
    }
    // when only Tenancy Type filter applied
    else if (isNullOrUndefined(customerStatus) && !isNullOrUndefined(tenancyType)) {
        data?.models?.map((model: CustomerCardDetails) => {
        let index = 0;
        while (index < model?.tenancyModels?.length) {
          if (model?.tenancyModels[index].customerTenancyTypeGroup === tenancyType) {
            this.addRecord(model, index);
            break;
          }
          index++;
        }
      });
    }
    // when both Customer Status and Tenancy Type filter applied
    else {
        data?.models?.map((model: CustomerCardDetails) => {
        let index = 0;
        while (index < model?.tenancyModels?.length) {
          const status = model?.tenancyModels[index].isTenancyActive ? "Active Customer" : "Former Customer"
          if (model?.tenancyModels[index].customerTenancyTypeGroup === tenancyType && status === customerStatus) {
            this.addRecord(model, index);
            break;
          }
          index++;
        }
      });
    }
  }

    addRecord = (data: CustomerCardDetails, index: number) => {
    
    const customerModel: CustomerData = {
      id: data?.id,
      name: data?.givenName + ' ' + data?.surname,
      dateOfBirth: data?.dateOfBirth,
      tenancyModel: data?.tenancyModels[index],
      email: data?.email,
      mobilePhone: data?.mobilePhone
    };
    if (data?.tenancyModels.length > 1) {
      const tenancyList: TenancyModel[] = [];
      for (var i = 0; i < data?.tenancyModels.length; i++) {
        if (i != index && i<4) {
          const tenancyModel: TenancyModel = {
            customerTenancyTypeGroup: data?.tenancyModels[i].customerTenancyTypeGroup,
            isTenancyActive: data?.tenancyModels[i].isTenancyActive,
            premisesAddress: data?.tenancyModels[i].premisesAddress
          };
          tenancyList.push(tenancyModel)
        }

      }
      
      customerModel.tenancyModelsChild=tenancyList;
    }
    customerModel.tenancyCount=data?.tenancyModels.length;
    this.customerData.push(customerModel);
  }
}

export class CustomerModel {  
  public givenName?: string 
  public surname?: string 
  public dateOfBirth?: any
  public tenancyModels: TenancyModel[] = []
  public email?: string
  public mobilePhone?: string
  public id?: string
  public homePhone?: string
  public workPhone?: string
}

export class TenancyModel {
  public customerTenancyTypeGroup?: string
  public isTenancyActive?: boolean
  public premisesAddress?: PremisesAddress | null
}

export class CustomerData {
  public id?: string
  public name?: string
  public dateOfBirth?: any
  public tenancyModel?: TenancyModel | null 
  public email?: string
  public mobilePhone?: string
  public tenancyModelsChild?: TenancyModel[] = []
  public tenancyCount?: number;
  public givenName?: string;
  public surname?: string;
  public tenancyModels?: any
  public homePhone?: string;
  public workPhone?: string;
}