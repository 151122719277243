import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useSelector, useDispatch } from "react-redux";
import Property from "../../models/Property";
import { PremisesType } from "../../models/Customer/Premises";
import * as api from "../../api";
import { assetData, saveAssociatedAssests, saveCustomerphoneNumber, selectedTenacyType } from "../../ducks/redux/actions/getAddress";
import { PermissionsList } from "../../models/Permissions";
import { Address, Assets, PostalAddress } from "../../models/Property/PropertyResponse";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";
import { hierarchyOfPremises } from "../../ducks/redux/actions/userChoices";
import { BackValue } from "../../models/shared/Enum";


interface AssetCardAsset360Props extends RouteComponentProps<any> {
}
const AssetCardAsset360: React.FC<AssetCardAsset360Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
   

    let PropertyId = useSelector(
        (state: any) => state?.userChoicesReducer?.userChosenPropertyId
    );
    let assetStatus = useSelector(
        (state: any) => state?.userChoicesReducer?.assetDataStatus
    );
    const customerResponse = useSelector(
        (state: any) => state?.addresses?.saveCustomerDetails
    );
    const isActiveTenancy = useSelector(
        (state: any) => state?.userChoicesReducer?.isActiveTenancy
    );
    const array: Property[] = [];
    const [propertyList, setPropertyList] = useState(array);
    const [propertyLength, setPropertyLength] = useState(0);
    const [isRepair, setIsRepair] = useState(false);
    const [dropDown, setDropDown] = useState("none");
    const [assetType, setAssetType] = useState("");
    const getPermissionsData = useSelector(
        (state: any) => state?.userChoicesReducer?.userProfilePermissions
    );
    const assetHierarchy360 = useSelector(
        (state: any) => state?.userChoicesReducer?.assetHierarchy360
      );
      

    useEffect(() => {
        (async () => {
            trackEvent("AssetCardAsset360");
            let associtaedAssets: any[] = [];
            const showRepair = getPermissionsData?.indexOf(PermissionsList.create_asset360_asset_hierachy_raise_a_repair);
            if (showRepair != -1) {
                setIsRepair(true)
            }
            else {
                setIsRepair(false)
            }
            const premisesList: Property[] = [];
            try {
            const associatedAssetsResponse = await api.getAssociatedAssets(PropertyId);
            associtaedAssets.push(associatedAssetsResponse);
            dispatch(saveAssociatedAssests(associtaedAssets));
            if (associatedAssetsResponse !== null && associatedAssetsResponse !== undefined) {
                associatedAssetsResponse?.forEach((element: any) => {
                    if (element.assetType !== PremisesType.Scheme && element.assetType !== PremisesType.EstateArea && element.assetType !== PremisesType.Default) {
                        if (element.id === PropertyId) {
                            if (element.assetType === PremisesType.Property) {
                                dispatch(selectedTenacyType(element.assetType));
                                setAssetType(element.assetType);
                                associatedAssetsResponse?.forEach((el: any) => {
                                    const premisesEntry: Property = new Property();
                                    premisesEntry.premisesType = el.assetType as PremisesType;
                                    premisesEntry.uprn = el.uprn;
                                    premisesEntry.id = el.id;
                                    premisesEntry.address = el?.addresses?.postalAddresses[0];
                                    if (el.assetType === PremisesType.DividedBlock || el.assetType === PremisesType.Block || el.assetType === PremisesType.Estate) {
                                        premisesList.push(premisesEntry);
                                    }

                                });
                            }
                            else if (element.assetType === PremisesType.DividedBlock) {
                                dispatch(selectedTenacyType(element.assetType));
                                setAssetType(element.assetType);
                                associatedAssetsResponse?.forEach((el: any) => {
                                    const premisesEntry: Property = new Property();
                                    premisesEntry.premisesType = el.assetType as PremisesType;
                                    premisesEntry.uprn = el.uprn;
                                    premisesEntry.id = el.id;
                                    premisesEntry.address = el?.addresses?.postalAddresses[0];
                                    if (el.assetType === PremisesType.Block || el.assetType === PremisesType.Estate) {
                                        premisesList.push(premisesEntry);
                                    }

                                });
                            }
                            else if (element.assetType === PremisesType.Block) {
                                dispatch(selectedTenacyType(element.assetType));
                                setAssetType(element.assetType);
                                associatedAssetsResponse?.forEach((el: any) => {
                                    const premisesEntry: Property = new Property();
                                    premisesEntry.premisesType = el.assetType as PremisesType;
                                    premisesEntry.uprn = el.uprn;
                                    premisesEntry.id = el.id;
                                    premisesEntry.address = el?.addresses?.postalAddresses[0];
                                    if (el.assetType === PremisesType.Estate) {
                                        premisesList.push(premisesEntry);
                                    }

                                });
                            }
                            else if (element.assetType === PremisesType.Estate) {
                                dispatch(selectedTenacyType(element.assetType));
                                setAssetType(element.assetType);
                            }
                        }
                    }
                });
                
                setPropertyList(premisesList);
                dispatch(hierarchyOfPremises(premisesList));
            }
            setPropertyLength(premisesList?.length);  
            }
            catch (e) {
                console.error(e);
                const timerId = setTimeout(() => {
                  props.history.push("/GenericError");
                }, 1000);
              }
        })();
    }, [PropertyId]);

    const callProperty360 = (propertyData: Property) => {
        const assetEntry: Assets = new Assets();
        assetEntry.assetType = propertyData?.premisesType
        assetEntry.uprn = propertyData?.uprn
        assetEntry.id = propertyData?.id
        const addressEntry: Address = new Address();
        const postalAddressEntry: PostalAddress = new PostalAddress();
        postalAddressEntry.addressLine1 = propertyData?.address?.addressLine1
        postalAddressEntry.addressLine2 = propertyData?.address?.addressLine2 != null ? propertyData?.address?.addressLine2 : ""
        postalAddressEntry.addressLine3 = propertyData?.address?.addressLine3 != null ? propertyData?.address?.addressLine3 : ""
        postalAddressEntry.addressLine4 = propertyData?.address?.addressLine4 != null ? propertyData?.address?.addressLine4 : ""
        postalAddressEntry.addressLine5 = propertyData?.address?.addressLine5 != null ? propertyData?.address?.addressLine5 : ""
        postalAddressEntry.postcode = propertyData?.address?.postcode
        postalAddressEntry.id = propertyData?.address?.id
        addressEntry.postalAddresses.push(postalAddressEntry);
        assetEntry.addresses = addressEntry
        dispatch(assetData(assetEntry))
        
        window.location.reload();
        
    }

    const dropDownFunction = () => {
        if (dropDown === "none")
            setDropDown("block");
        else
            setDropDown("none");
    }
    const callPageHandler = () => {
      dispatch(selectedTenacyType(assetType));
      if(isNullOrUndefined(customerResponse?.addresses?.telecomsAddresses)) {
      dispatch(saveCustomerphoneNumber(null));
      }
      else {
        dispatch(saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses));
     } 
        props.history.push("/RepairAsset");
    };

    return (

        <div>

            <div className="px-4 py-2 border-b border-grey">
                <div className="flex flex-row d-flex bd-highlight items-center mt-2">
                    <i className="fa fa-home text-teal text-2xl mr-6 bd-highlight"></i>
                    <h2 className="my-0 text-purple-100 bd-highlight">{t("Asset_hierarchy")}</h2>
                   
                    
                    {   ( (isActiveTenancy && (assetType === "Property") ) || ((assetType ==="Divided Block") || (assetType ==="Block") || (assetType ==="Estate") ) )  ? (
                                <div className="menu-nav ms-auto bd-highlight">
                                    <div className="dropdown-container">
                                        <div className="three-dots" data-testid="dropFunction" onClick={dropDownFunction}></div>

                                        <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                                            <a onClick={callPageHandler} data-testid="callHandler" className="text-black text-base no-underline global-txt"><div className="py-2 cursor-pointerpy-2 cursor-pointer text-black text-base no-underline dropdown-item">{assetType === "Property" ? t("Create_repair") :  t("raise_comm") }</div></a>
                                        </div>
                                    </div>
                                </div>
                            
                        ) : null
                    }
                   
                </div>
            </div>

            {propertyLength ? (<>
                {propertyList && propertyList.length > 0 && propertyList?.map((property) => (
                    <div className="px-4 mt-4">
                    <div className="border border-grey rounded-lg mb-4 global-txt">
                        <div className="bg-peach px-4 py-2 rounded-t-lg">
                            <div className="flex flex-row justify-between items-center ">
                                <div className="my-0 text-lg ">
                                    <a data-testid="callProperty" onClick={() => callProperty360(property)} className="text-dark cursor-pointer">
                                        <strong>{t("UPRN")} {property?.uprn}</strong></a></div>
                                <div className="my-0 text-lg"><strong>{property?.premisesType}</strong></div>
                            </div>
                        </div>

                        <div className="px-4 py-2 flex justify-between border-b border-grey">
                            <div className="pr-4">{property?.premisesType !== PremisesType.Property ? (
                                t("Name")) : (t("Address"))
                            }</div>
                            <div className="text-right">{property?.premisesType !== PremisesType.Property ?
                                (property?.address?.addressLine1) : (property?.address?.formattedAddress)}</div>
                        </div>
                    </div>
                    </div>
                ))
                }
            </>) : <div className="px-4 py-4">{t("CP116")}</div>
            
            }
        </div>
    )
};
export default AssetCardAsset360;