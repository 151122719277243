import gifImg from "../Cases/ViewMedia/images/gif.svg";
import pngImg from "../Cases/ViewMedia/images/png.svg";
import jpegImg from "../Cases/ViewMedia/images/jpeg.svg";
import movImg from "../Cases/ViewMedia/images//mov.svg";
import mpegImg from "../Cases/ViewMedia/images/mpeg.png";
import pdfImg from "../Cases/ViewMedia/images/pdf.svg";
import mp4Img from "../Cases/ViewMedia/images/mp4.svg";

export const imgData = [
  {
    id: "gif",
    src: gifImg,
  },
  {
    id: "mp4",
    src: mp4Img,
  },
  {
    id: "png",
    src: pngImg,
  },
  {
    id: "jpeg",
    src: jpegImg,
  },
  {
    id: "jpg",
    src: jpegImg,
  },
  {
    id: "tiff",
    src: jpegImg,
  },
  {
    id: "psd",
    src: jpegImg,
  },
  {
    id: "eps",
    src: jpegImg,
  },
  {
    id: "bmp",
    src: jpegImg,
  },
  {
    id: "erf",
    src: jpegImg,
  },
  {
    id: "x-matroska",
    src: movImg,
  },
  {
    id: "mkv",
    src: movImg,
  },
  {
    id: "wmv",
    src: movImg,
  },
  {
    id: "avi",
    src: movImg,
  },
  {
    id: "webm",
    src: movImg,
  },
  {
    id: "hevc",
    src: movImg,
  },
  {
    id: "flv",
    src: movImg,
  },
  {
    id: "mov",
    src: movImg,
  },

  {
    id: "quicktime",
    src: movImg,
  },
  {
    id: "pdf",
    src: pdfImg,
  },
  {
    id: "mpeg",
    src: mpegImg,
  },
];
