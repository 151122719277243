import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../api/index";
import CustomerResultComponent from "../CustomerResult";
import '../../main.css';
import PropertyResultComponent from "../PropertyResult"
import { useTranslation } from "react-i18next";
import { Search } from "../CustomerResult/component";
import "./style.css";
import FilterComponenent from "../Filter";
import { SearchType } from "../../models/shared/Enum";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { formatDateTime, isNullOrUndefined } from "../../utils/formatting";
import {
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenIsReporter,
  userChosenIsError,
  userChosenSearchText,
  userChosenBackButton,
  userChosenSearchType,
  userChosenRaisedSince,
  userChosenRaisedUpto,
  isSearch,
  customerPreferenceData,
  userChosenBackValue,
  loadDuplicateRepairPage,
  userCreatedNewRepair,
    userChosenResumeRepair,
    userChosenCurrentValue,
    checkForLandlord,
    userChosenNotifyAll,
    userChosenPropertyId
} from "../../ducks/redux/actions/userChoices";
import { PermissionsList } from "../../models/Permissions";
import { appConfigCovidToggle, 
  selectedTenacyType, 
  workOrderRepairObject, 
  appConfigToggle, saveAvailabilityResponse, repairHistoryData,
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload, } from "../../ducks/redux/actions/getAddress";
  
import WorkOrderResultComponent from "../WorkOrderResult";
import moment from "moment";
import CaseResultComponent from "../Cases/CaseResult";

interface LandingViewProps extends RouteComponentProps<any> { }

const LandingView: React.FC<LandingViewProps> = (props) => {
 
  const customerData = useSelector(
    (state: any) => state?.userChoicesReducer?.customersData
  );
  const dispatch = useDispatch();
  const array: any[] = [];
  const [addresses, setAddresses] = useState(array);
  const SearchValue = useSelector(
    (state: any) => state?.userChoicesReducer?.SearchType
  );
  const SearchText = useSelector(
    (state: any) => state?.userChoicesReducer?.SearchText
  );
  const BackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.BackButton
  );
  const RaisedSince = useSelector(
    (state: any) => state?.userChoicesReducer?.RaisedSince
  );
  const RaisedUpto = useSelector(
    (state: any) => state?.userChoicesReducer?.RaisedUpto
  );
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  const searchTextCount = useSelector(
    (state: any) => state.userChoicesReducer?.searchTextCountValue
  );

  const isCaseCheck = useSelector(
    (state: any) => state?.userChoicesReducer?.isCaseAllowed
  );

  const [isSearchDropdown, setIsSearchDropdown] = useState(customerData?.searchText ? true : false);
  const [searchText, setSearchText] = useState(isNullOrUndefined(customerData?.searchText) ? '' : customerData?.searchText);
  const [searchType, setSearchType] = useState(isNullOrUndefined(customerData?.searchType) ? 'Select' : customerData?.searchType);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [customerStatus, setCustomerStatus] = useState(isNullOrUndefined(customerData?.customerType) ? '' : customerData?.customerType);
  const [tenancyType, setTenancyType] = useState(isNullOrUndefined(customerData?.tenancyType) ? '' : customerData?.tenancyType);
  const [sortValue, setSortValue] = useState(isNullOrUndefined(customerData?.sortOrder) ? '' : customerData?.sortOrder);
  const [propertyType, setPropertyType] = useState('');
  const [propertySortValue, setPropertySortValue] = useState('');
  const [disableSearchType, setDisableSearchType] = useState(isNullOrUndefined(customerData?.searchType) ? true : false);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(isNullOrUndefined(customerData?.currentPage) ? 0 : customerData?.currentPage)
  const [resultsPerPage, setResultsPerPage] = useState(isNullOrUndefined(customerData?.resultsPerPage) ? 10 : customerData?.resultsPerPage)
  const [skip, setSkip] = useState(isNullOrUndefined(customerData?.skip) ? 0 : customerData?.skip)
  const [workOrderPropertyType, setWorkOrderPropertyType] = useState('');
  const [statusWorkOrderType, setStatusWorkOrderType] = useState('');
  const [priorityWorkOrder, setPriorityWorkOrder] = useState('');
  const defaultfromdate = formatDateTime(moment(new Date()).subtract(6, "months").toString())
  const [raisedSince, setRaisedSince] = useState(defaultfromdate);
  const defaultTodate = formatDateTime(moment(new Date()).toString())
  const [raisedUpto, setRaisedUpto] = useState(defaultTodate);
  const [isDateValid, setIsDateValid] = useState(true);
  const [dateErrorMesage, setDateErrorMessage] = useState('');
  const [caseSortValue, setCaseSortValue] = useState('');
  const [caseAssetType, setCaseAssetType] = useState('');
  const [caseStatusType, setCaseStatusType] = useState('');
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  let raisedSinceDate: any;
  let raisedUptoDate: any;
  
  function getCustomerSearchTextParam() {
    var landingViewHref = window.location.href,
        regexForcustomerSearchText = new RegExp(/customerSearchText=([a-zA-Z0-9+-\\']*)/g),
        regexForcustomerSearchTextResult = regexForcustomerSearchText.exec(landingViewHref);

    return regexForcustomerSearchTextResult && regexForcustomerSearchTextResult[1] ?
            decodeURIComponent(regexForcustomerSearchTextResult[1].replace(/\+/g, ' ')) :
            undefined;
  }

  const getReferenceData = () => {
    if (!isNullOrUndefined(localStorage.getItem('userName') )) {
      let referenceDataresponsed;
      (async function () {
        try {
          
          var customerSearchText = getCustomerSearchTextParam()

          dispatch(isSearch(true));
          dispatch(userChosenBackValue(null));
          dispatch(userChosenCurrentValue(null));
          dispatch(workOrderRepairObject(null));
          dispatch(loadDuplicateRepairPage(false));
          dispatch(userChosenResumeRepair(false));
          dispatch(userCreatedNewRepair(null));
          const namespacenames = "search-types";
          referenceDataresponsed = await api.getReferenceDataRepair(
            namespacenames as string
          );
          if (BackButton== true) {
            setIsSearchDropdown(true);
            setDisableSearchType(false);
            if(SearchValue===SearchType.WorkOrder)
            {
              setRaisedSince(RaisedSince);
             setRaisedUpto(RaisedUpto);
            }
            setSearchType(SearchValue);
            setSearchText(SearchText);
            setCurrentPage(0);
            setResultsPerPage(10);
            setSkip(0);
          }
          else
          {
            setSearchType("")
            setSearchText("");
          }

          if(customerSearchText!= undefined && getPermissionsData?.indexOf(PermissionsList.view_open_crm) != -1){
            // IVR Search mechanism for autoloading customer search with details of incoming customer call
            // Feature enabled only for customer hub agents
            
            handleChangeSearchType(SearchType.Customer);
            handleSubmit(customerSearchText);
            const queryParams = new URLSearchParams();
            props.history.replace({
              search: queryParams.toString(),
            })
          }

          setAddresses(referenceDataresponsed[0]?.namespace.keyValues);
          
          setIsLoading(true);
        } catch (e) {
          console.log(e);
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        }
      })();
    }
    else {
      const timerId = setTimeout(() => {
        getReferenceData();
      }, 5000);

    }
  }
  useEffect(() => {    
    setIsLoading(false);

    if (localStorage.getItem('authCode') !== "" && localStorage.getItem('authCode') !== undefined && localStorage.getItem('authCode') !== null) {
      if (!isNullOrUndefined(localStorage.getItem('userName'))) {
        const timerId = setTimeout(() => {
          getReferenceData();
        }, 5000);
      }
    }
    else {
      setIsLoading(false);
      props.history.push('/Login');
    }
    clearReduxStore();
  }, [localStorage.getItem('userName')]);

  const clearReduxStore = () => {
    dispatch(userChosenNotifyAll(null));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion("No"));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(userChosenIsReporter(false));
    dispatch(selectedTenacyType(""));
    dispatch(userChosenIsError(false));
    dispatch(customerPreferenceData(""));
    dispatch(checkForLandlord(false));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
  };
  let fieldsArray: any[] = [];
  let arrayObject = Object.entries(addresses);
  arrayObject.map((eachLocation) => {
    if(isCaseCheck == 'false' && eachLocation[1] == 'Case' ){
     return;
    }
    else{
   fieldsArray.push(
     <option key={eachLocation[0]} value={eachLocation[1]}>
       {eachLocation[1]}
     </option>)
     }
 })


  const handleChangeSearchTypeEvent = (e: { target: { value: any } }) => {
    handleChangeSearchType(e.target.value);
  }
  const handleChangeSearchType = (searchType: any) => {
    setRaisedSince(defaultfromdate);
    setRaisedUpto(defaultTodate);
    setIsSearchDropdown(false);
    setSearchType(searchType);
    dispatch(userChosenSearchType(searchType));
    setPropertyType('');
    setTenancyType('');
    setCustomerStatus('');
    setSearchText('');
    setStatusWorkOrderType('');
    setPriorityWorkOrder('');
    setWorkOrderPropertyType('');
    setCaseAssetType('');
    setCaseStatusType('');
    setIsDateValid(true);
    setIsAssignedToMe(false);
    if (searchType === t("select"))
      setDisableSearchType(true);
    else
      setDisableSearchType(false);
  }

  const handleSubmit = (search: string) => {
    setIsSearchDropdown(true);
    setSearchText(search);
    setCurrentPage(0);
    setResultsPerPage(10);
    setSkip(0);
    dispatch(userChosenBackButton(true));
    dispatch(userChosenSearchText(search));
  }

  const handleReset = () => {
    setRaisedSince(defaultfromdate);
    setRaisedUpto(defaultTodate);
    setSortValue('');
    setPropertyType('');
    setPropertySortValue('');
    setCustomerStatus('');
    setTenancyType('');
    setStatusWorkOrderType('');
    setWorkOrderPropertyType('');
    setPriorityWorkOrder('');
    setCaseSortValue('')
    setCaseStatusType('');
    setCaseAssetType('');
    setIsDateValid(true);
    setDateErrorMessage('');
    setIsAssignedToMe(false);
  }

  const handleSort = (sortValue: any) => {
    setSortValue(sortValue);
  }
  const handleCaseSort = (sortValue: any) => {

    setCaseSortValue(sortValue)
  }
  const handlePropertySort = (sortValue: any) => {

    setPropertySortValue(sortValue)
  }
  const RenderSearchComponents = () => {
    let component: any;
    switch (searchType) {
      case SearchType.Customer:
        component = (<CustomerResultComponent searchText={searchText} searchTextCount={Number(searchTextCount)}
          tenancyType={tenancyType} customerStatus={customerStatus} handleSort={handleSort}
          sortValue={sortValue} currentPage={currentPage} resultsPerPage={resultsPerPage}
          skip={skip} setShowFilter={showFilter}
          {...props}>
        </CustomerResultComponent>)
        break;
      case SearchType.Property:
        component = (<PropertyResultComponent searchText={searchText} 
          searchTextCount={Number(searchTextCount)} propertyType={propertyType}
           handleSort={handlePropertySort} sortValue={propertySortValue}
           setShowFilter={showFilter}
          {...props}>
        </PropertyResultComponent>)
        break;
      case SearchType.WorkOrder:
        if (isDateValid) {
          component = (<WorkOrderResultComponent searchText={searchText} 
            searchTextCount={Number(searchTextCount)} handleSort={handlePropertySort} 
            sortValue={propertySortValue} priority={priorityWorkOrder} 
            status={statusWorkOrderType} raisedSince={raisedSince} 
            raisedUpto={raisedUpto} propertyType={workOrderPropertyType}
            setShowFilter={showFilter}
            {...props}>
          </WorkOrderResultComponent>)
        }
        else
          component = null;
        break;
        case SearchType.Servicerequest:
        if (isDateValid) {
          component = (<CaseResultComponent searchText={searchText} 
            searchTextCount={Number(searchTextCount)} handleSort={handleCaseSort} propertyType = {caseAssetType} status = {caseStatusType} isCaseAssignedToMe={isAssignedToMe}
            sortValue={caseSortValue} setShowFilter = {showFilter} raisedSince={raisedSince} 
            raisedUpto={raisedUpto}
            {...props}>
          </CaseResultComponent>)
        }
        else
          component = null;
        break;
      default:
        component = null
    }

    return component;
  }

  const handleFilterSubmit = (customerStatus: any, tenancyType: any, propertyType: any, workOrderPropertyType: any, statusWorkOrder: any, priorityWorkOrder: any, customerWorkOrderAssetType: any, casePropertyType: any, caseStatusType: any, isCaseAssignedToMe: any) => {
    setPropertyType(propertyType);
    setTenancyType(tenancyType);
    setCustomerStatus(customerStatus);
    setWorkOrderPropertyType(workOrderPropertyType);
    setStatusWorkOrderType(statusWorkOrder);
    setPriorityWorkOrder(priorityWorkOrder);
    setCaseAssetType(casePropertyType);
    setCaseStatusType(caseStatusType);
    setShowFilter(false);
    setIsAssignedToMe(isCaseAssignedToMe);
  };

  const handelShowFilter = () => {
    dispatch(isSearch(true));
    if (searchType !== t("select")) setShowFilter(true);
  }

  const handleClose = () => {
    setShowFilter(false);
  }

  const handleResetSubmit = () => {
    setRaisedSince(defaultfromdate);
    setRaisedUpto(defaultTodate);
    setIsSearchDropdown(false);
    setPropertyType('');
    setTenancyType('');
    setCustomerStatus('');
    setWorkOrderPropertyType('');
    setStatusWorkOrderType('');
    setPriorityWorkOrder('');
  }
  const handleDateFrom = (targetValue: any) => {
    dispatch(userChosenRaisedSince(targetValue));
    setRaisedSince(targetValue);
    setIsDateValid(false);
    raisedSinceDate = targetValue;

    dateValidation();
  }
  const handleDateTo = (targetValue: any) => {
    setRaisedUpto(targetValue);
    dispatch(userChosenRaisedUpto(targetValue));
    setIsDateValid(false);
    raisedUptoDate = targetValue;
    dateValidation();
  }

  const dateValidation = () => {
    if (isNullOrUndefined(raisedSinceDate)) {
      raisedSinceDate = raisedSince;
    }
    if (isNullOrUndefined(raisedUptoDate)) {
      raisedUptoDate = raisedUpto;
    }
    const todayDate = formatDateTime(moment(new Date()).toString())
    if (raisedSinceDate > todayDate) {
      setDateErrorMessage(t("start_date_validation"))
      setIsDateValid(false);
    }
    else if (raisedUptoDate > todayDate) {
      setDateErrorMessage(t("end_date_validation"))
      setIsDateValid(false);
    }

    else if (raisedSinceDate > raisedUptoDate && raisedUptoDate != "") {
      setDateErrorMessage(t("valid_date_range"))
      setIsDateValid(false);
    }

    else {
      setRaisedSince(raisedSinceDate)
      setRaisedUpto(raisedUptoDate);
      setIsDateValid(true);
      setDateErrorMessage("");
    }
  }
  return (
    <div>
      {isLoading ? (
        <div className="border-grey bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed mt-1 min-ht">
          <div className="container ctn-fht mx-auto pb-12 my-5">
            <div className="w-full lg:px-0">
              <h1 className="sm:px-3 sm:font">{t("colleague_digital_Platform")}</h1>

              <div className="sm:px-3 w-3/4 md:text-left pt-2 pb-0 pl-1 global-txt">{t("pick_search_type")}</div>
              <div className=" flex flex-col md:flex-row justify-between items-center mt-2">
                <div className="w-full sm:px-2 md:w-3/12 lg:w-3/12 flex items-center mb-2 mr-2">
                  <label className="w-full">
                    <select id="dropDownLand" className="block w-full mt-1 rounded-lg border border-grey mx-1 sm:mx-0"
                      value={searchType} onChange={handleChangeSearchTypeEvent} data-testid="dropDownLocation" >
                      <option>{t("select")} </option>
                      {fieldsArray}
                    </select>
                  </label>
                </div>

                <div className="w-full sm:pr-2 md:w-6/12 lg:w-6/12 lg:mx-4 flex items-center mb-2 ">
                  <div id="custom-search-input" className="block w-full mt-1 sm-mr-1 mx-1 text-grey sm:pr-22">
                    <div className="input-group w-full" id="searchId">
                      <label className="w-full">
                        <Search className=" rounded-lg-search w-full"
                          defaultValue={searchText}
                          datatestid="search-text-button"
                          onSubmit={handleSubmit}
                          placeholder={t("seach_placeholder_txt")}
                          onClear={handleResetSubmit}
                          isDisabled={disableSearchType}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-3/12 lg:w-3/12 flex items-center mb-2 sm:mt-4">
                  <div className="pr-4 mt-0"><a className="text-teal no-underline hover:underline cursor-pointer a-style"><span className=" md:visible" onClick={handelShowFilter} data-testid="handle-show-filter">{t("filter_txt")}</span> <i className="fas fa-filter"></i></a></div>
                  <div className="pr-4 mt-0">
                    <a className="text-teal no-underline hover:underline cursor-pointer a-style" data-testid="handle-reset" onClick={handleReset}>
                      <span className=" md:visible">{t("reset_txt")}</span>
                      <i className="fas fa-sync pl-1"></i>
                    </a>
                  </div>
                </div>
                <br></br>
              </div>
              
              <div className="border-b border-grey pb-4">
              {searchType === SearchType.WorkOrder || searchType === SearchType.Servicerequest?
                <div className="md:w-2/3 sm:w-full flex items-center lg:mb-0 global-txt sm:flex-col">
                  <div className="md:w-2/3 flex items-center sm:w-full">
                    <label className="md:w-2/3 flex items-center md:pr-2 sm:w-full">
                      <span className="pr-1 md:w-1/2 text-date md:text-base pl-1 pt-2 sm:pl-4 sm:w-mid">{t("Created_from")}</span>
                      <input data-testid="fromDate" id="fromdate" value={raisedSince} max="9999-12-31" defaultValue={defaultfromdate} type="date" onChange={e => handleDateFrom(e.target.value)} className="border-grey w-fc md:w-1/2 text-sm md:text-base p-2 md:p-4"></input>
                    </label>
                  </div>

                  <div className="md:w-2/3 flex items-center sm:w-full md:pl-4">
                    <label className="md:w-2/3 flex items-center md:pr-2 sm:w-full">
                      <span className="pr-1 md:w-1/2 text-date md:text-base pl-1 pt-2 sm:pl-4 sm:w-mid">
                        {t("Created_to")} </span>
                      <input data-testid="toDate" id="todate" type="date" value={raisedUpto} max="9999-12-31" defaultValue={defaultTodate} onChange={e => handleDateTo(e.target.value)} className="border-grey w-fc md:w-3/5 text-sm md:text-base p-2 md:p-4"></input>
                    </label>
                  </div>
                </div>

                : null}
                {!isDateValid ?
                <div className="w-full mt-4">
                  <div className="w-full px-4 py-2 bg-yellow flex items-center global-txt">
                    <div className="px-4 pr-8">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                    </div>
                    <div className="flex items-center">
                      <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                        {dateErrorMesage}
                      </p>
                    </div>
                  </div>
                </div>
                : null}
            </div>

             
                {/* < -- Result view --> */}
                {isSearchDropdown ? <RenderSearchComponents /> : null}

               {/* < -- Side Filter view --> */}
               {showFilter ? <FilterComponenent setFilter={handleFilterSubmit} handleClose={handleClose}
                searchType={searchType} selectedCustomerStatus={customerStatus} selectedTenencyType={tenancyType} selectedPropertyType={propertyType} selectedWorkOrderPropertyType={workOrderPropertyType}
                selectedStatusWorkOrderType={statusWorkOrderType} selectedPriorityWorkOrder={priorityWorkOrder} selectedCasePropertyType= {caseAssetType} selectedCaseStatusFilter = {caseStatusType} selectedIsCaseAssignedToMe={isAssignedToMe}
                {...props} /> : null}

               </div>
                </div>
                </div>

                ) : (
       
       
                <div className="SpinnerWrapper">
                  <LoadingSpinner />
                 </div>
      )
      }
    </div>
  )
}

export default LandingView