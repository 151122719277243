export enum PermissionsList {
  //Customer360
  view_customer360_customer_details_card = 'view_customer360_customer_details_card',
  create_customer360_customer_raise_a_repair = 'create_customer360_customer_raise_a_repair',
  view_customer360_customer_raise_a_repair = 'view_customer360_customer_raise_a_repair',
  view_customer360_asset_summary_card = 'view_customer360_asset_summary_card',
  create_customer360_asset_raise_a_repair = 'create_customer360_asset_raise_a_repair',
  view_customer360_asset_raise_a_repair = 'view_customer360_asset_raise_a_repair',
  view_customer360_wo_summary_card = 'view_customer360_wo_summary_card',
  create_customer360_wo_raise_a_repair = 'create_customer360_wo_raise_a_repair',
  view_customer360_wo_raise_a_repair = 'view_customer360_wo_raise_a_repair',
  view_customer360_multi_account_summary_card = 'view_customer360_multi_account_summary_card',
  view_customer360_rent_account_summary_card = 'view_customer360_rent_account_summary_card',
  view_customer360_key_contacts_summary_card = 'view_customer360_key_contacts_summary_card',
  view_customer360_customer_dob = 'view_customer360_customer_dob',
  view_customer360_caution_alerts_banner = 'view_customer360_caution_alerts_banner',
  view_customer360_property_alerts_banner = 'view_customer360_property_alerts_banner',
  view_customer360_accounts_full_view_list = 'view_customer360_accounts_full_view_list',
  view_customer360_rent_account_only_view_account = 'view_customer360_rent_account_only_view_account',
  view_customer360_rent_account_only_summary_card = 'view_customer360_rent_account_only_summary_card',
  view_customer360_customer_UCC_Card = 'view_customer360_customer_UCC_Card',
  create_customer360_customer_UCC_arrearsaction = 'create_customer360_customer_UCC_arrearsaction',

  //Asset360
  view_asset360_asset_property_details_card = 'view_asset360_asset_property_details_card',
  view_asset360_asset_divided_block_details_card = 'view_asset360_asset_divided_block_details_card',
  view_asset360_asset_block_details_card = 'view_asset360_asset_block_details_card',
  view_asset360_asset_estate_details_card = 'view_asset360_asset_estate_details_card',
  view_asset360_asset_hierachy_summary_card = 'view_asset360_asset_hierachy_summary_card',
  view_asset360_wo_summary_card = 'view_asset360_wo_summary_card',
  view_asset360_cases_summary_card = 'view_asset360_cases_summary_card',
  view_asset360_customer_summary_card = 'view_asset360_customer_summary_card',
  view_asset360_key_contacts_summary_card = 'view_asset360_key_contacts_summary_card',
  create_asset360_asset_property_raise_a_repair = 'create_asset360_asset_property_raise_a_repair',
  create_asset360_asset_hierachy_raise_a_repair = 'create_asset360_asset_hierachy_raise_a_repair',
  create_asset360_wo_raise_a_repair = 'create_asset360_wo_raise_a_repair',
  view_asset360_caution_alerts_banner = 'view_asset360_caution_alerts_banner',
  create_asset360_block_sms = 'create_asset360_block_sms',
  create_customer360_financial_inclusion_referral = 'create_customer360_financial_inclusion_referral',



  //WorkOrder360
  view_workorder360_work_order_details_card = 'view_workorder360_work_order_details_card',
  view_workorder360_asset_hierarchy_summary_card = 'view_workorder360_asset_hierarchy_summary_card',
  view_workorder360_customer_summary_card = 'view_workorder360_customer_summary_card',
  view_workorder360_key_contacts_summary_card = 'view_workorder360_key_contacts_summary_card',
  view_asset360_property_alerts_banner = "view_asset360_property_alerts_banner",
  view_workorder360_property_alerts_banner = "view_workorder360_property_alerts_banner",
  view_workorder360_caution_alerts_banner = "view_workorder360_caution_alerts_banner",
  //Create enhanced hub reapir journey
  workorder_override_duplicate_repairs = "override_duplicate_repairs",
  workorder_no_triage_forheatingandhotwaterrepairs = "no_triage_forheatingandhotwaterrepairs",
  workorder_choose_priority_of_repair = "choose_priority_of_repair",
  workorder_create_enhanced_hub_repair_journey = "create_enhanced_hub_repair_journey",
  workoder_vulnerable_hide_permission = "hide_vulnerable_page",
  workorder_review_triage_repair = "review_triage_repair",


  //Digital Journey
  view_digital_journey_appoint_a_repair = 'view_digital_journey_appoint_a_repair',
  view_digital_journey_recall_a_repair = 'view_digital_journey_recall_a_repair',

  //Create Surveys
  create_p360_surveying1 = 'create_p360_surveying1',
  view_p360_surveying1 = 'view_p360_surveying1',

  //CRM Open
  view_open_crm = 'view_open_crm',

}