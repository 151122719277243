import { combineReducers } from "redux";
import addressReducer from "./addressReducer";
import dashboardReducer from "./dashboardReducer";
import userChoices from "./userChoices";
import caseCreation from "./caseCreationReducer"
import { FinancialReferralReducer } from "./FinancialReferral";


const rootReducer = combineReducers({
    addresses: addressReducer,
  dashboardReducer: dashboardReducer,
  userChoicesReducer: userChoices,
  caseCreationReducer: caseCreation,
  financialReferralReducer: FinancialReferralReducer,
});

export default rootReducer;
