import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useLocation } from "react-router-dom";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RentDataTypes } from "../../models/UCC/UniversalCreditCard";
import { PermissionsList } from "../../models/Permissions";
import { uCCRentModifiedData } from "../../ducks/redux/actions/userChoices";
import { formatDateWithFromCalendar } from "../../utils/formatting";
import classes from "../UCCTenancyDetails/styles.module.css";

interface UCCRentDetailsProps extends RouteComponentProps<any> {
}
const UCCRentDetails: React.FC<UCCRentDetailsProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getPermissionsData = useSelector((state: any) => state?.userChoicesReducer?.userProfilePermissions);
    const uCCTenancyData = useSelector((state: any) => state?.userChoicesReducer?.uCCTenancyModifiedData);
    const customerAccountData = useSelector((state: any) => state?.userChoicesReducer?.customerAccountData);
    const accountData = useSelector((state: any) => state?.userChoicesReducer?.accountData);

    const [UCCRentData, setUCCRentData] = useState<RentDataTypes>()
    const [showCreateArrearsLink, setShowCreateArrearsLink] = useState(false);
    const [copyStatusRentAmount, setCopyStatusRentAmount] = useState(t("UCCTenancy.UC001"));
    const [copyStatusServiceChargeAmount, setCopyStatusServiceChargeAmount] = useState(t("UCCTenancy.UC001"));
    const [showCreateArrearsReminder, setShowCreateArrearsReminder] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    const rentData: RentDataTypes = new RentDataTypes();
    const location = useLocation<any>();
    const cardTopRef = useRef<null | HTMLDivElement>(null); 

    useEffect(() => {
        if (accountData) {
            const { 'charge-frequency': chargeFrequency, 'rent-amount': rentAmount, 'service-charge-amount': serviceChargeAmount } = accountData['rent-details'];

            const currencySymbol = rentAmount.currency || serviceChargeAmount.currency === 'GBP' ? '£' : serviceChargeAmount.currency;
            let formattedAmount: any;

            const amountFormatter = (amount: string) => {
                if (amount.charAt(0) === '-') {
                    const slicedAmount = amount.toString().slice(1);
                    return formattedAmount = `-${currencySymbol}`.concat(slicedAmount);
                } else {
                    return formattedAmount = `${currencySymbol}${amount}`;
                }
            }
            
            rentData.AccountId = accountData['account-identifier'];
            rentData.RentEffectiveFrom = accountData.dates['rent-effective-date'] ? formatDateWithFromCalendar(accountData.dates['rent-effective-date']) : '-';
            rentData.RentAmount = rentAmount.value === '0.00' || rentAmount.value === '0' || rentAmount.value == null ? t("UCCRent.UC011") : amountFormatter(rentAmount.value);
            rentData.RentFrequency = chargeFrequency && chargeFrequency
            rentData.ServiceCharges = serviceChargeAmount.value === '0.00' || serviceChargeAmount.value === '0' || serviceChargeAmount.value == null ? 'No' : 'Yes';
            rentData.ServiceChargeAmount = serviceChargeAmount.value === '0.00' || serviceChargeAmount.value === '0' || serviceChargeAmount.value == null ? t("UCCRent.UC016") : amountFormatter(serviceChargeAmount.value);

            const UCFlag = accountData['other-fields'] && accountData['other-fields'].filter((el: any) => el.key === "UC_FLAG");
            rentData.UCFlagExist = UCFlag[0]?.value === 'Y' ? 'Yes' : 'No';
        }
        setUCCRentData(rentData);
        dispatch(uCCRentModifiedData(rentData));

        const updatePermit = getPermissionsData?.indexOf(PermissionsList.create_customer360_customer_UCC_arrearsaction);
        if (updatePermit !== -1) {
            setShowCreateArrearsLink(true);
        } else {
            setShowCreateArrearsLink(false);
        }

        const { innerWidth, innerHeight } = window;
        if (innerWidth >= 314 && innerWidth <= 1024) {
            setIsMobileDevice(true);
        } else {
            setIsMobileDevice(false);
        }

        if (location?.state?.fromUCCCreateArrearsAction) {
            scrollToCard();
        }
    }, [accountData, window.innerWidth]);

    const callCreateArrearsAction = () => {
        props.history.push('/CreateArrearsAction');
    }

    const copyText = (event: any) => {
        let copiedText: any;

        if (event.target.id === 'RentAmount') {
            copiedText = UCCRentData?.RentAmount;
            setCopyStatusRentAmount(t("UCCTenancy.UC002"));
        } else if (event.target.id === 'ServiceChargeAmount') {
            copiedText = UCCRentData?.ServiceChargeAmount;
            setCopyStatusServiceChargeAmount(t("UCCTenancy.UC002"));
        }

        navigator?.clipboard?.writeText(copiedText);
    }

    const showReminderHandler = () => {
        setShowCreateArrearsReminder(true);
    }

    const scrollToCard = () => {
        cardTopRef?.current?.offsetTop && window.scrollTo({ top: cardTopRef?.current?.offsetTop - 21, behavior: "smooth", });
    }

    return (
        <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4" ref={cardTopRef}>
            <div className="px-4 py-2 mt-2 mb-2 border-b border-grey">
                <div className=" flex flex-row d-flex bd-highlight items-center mt-2">
                    <i className="fa fa-credit-card text-teal text-2xl mr-6 bd-highlight"></i>
                    <h2 className="my-0 text-purple-100 bd-highlight">{t("UCCRent.RentDetails")}</h2>
                </div>
            </div>
            <div className="px-4 mb-8 global-txt" data-testid="rentDetailsContainer" onClick={showReminderHandler}>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.RentFreeWeeks")}</div>
                    <div>{UCCRentData?.RentFreeWeeks}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.RentEffectiveFrom")}</div>
                    <div>{UCCRentData?.RentEffectiveFrom}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.RentAmount")}</div>

                    {isMobileDevice ? <div id="RentAmount2" data-testid="RentAmountValue2">{UCCRentData?.RentAmount}</div> :
                        <div className={classes.tooltip} onClick={copyText} id="RentAmount" data-testid="RentAmountValue">{UCCRentData?.RentAmount}
                            <span className={classes.tooltiptext}>{copyStatusRentAmount}</span>
                        </div>}
                </div>

                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.RentFrequency")}</div>
                    <div id="RentFrequency" data-testid="RentFrequencyValue">{UCCRentData?.RentFrequency}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.ServiceCharges")}</div>
                    <div>{UCCRentData?.ServiceCharges}</div>
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.ServiceChargeAmount")}</div>

                    {isMobileDevice ? <div id="ServiceChargeAmount2" data-testid="ServiceChargeAmountValue2">{UCCRentData?.ServiceChargeAmount}</div> :
                        <div className={classes.tooltip} onClick={copyText} id="ServiceChargeAmount" data-testid="ServiceChargeAmountValue">{UCCRentData?.ServiceChargeAmount}
                            <span className={classes.tooltiptext}>{copyStatusServiceChargeAmount}</span>
                        </div>}
                </div>
                <div className="px-2 py-2 flex justify-between border-b border-grey">
                    <div className="pr-4">{t("UCCRent.UCFlagExist")}</div>
                    <div>{UCCRentData?.UCFlagExist}</div>
                </div>

                {(showCreateArrearsReminder || uCCTenancyData?.showCreateArrearsReminder) && !isMobileDevice && <div className="w-full px-2 py-2 mt-8 bg-purple-20 flex items-center">
                    <div className="px-4 pr-8">
                        <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
                    </div>
                    <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("UCCRent.UC003")}</p>
                    </div>
                </div>}

            </div>
            {showCreateArrearsLink &&
                <div className="w-full px-4 py-4 flex justify-between absolute bottom-0">
                    <div><a className="global-txt text-teal no-underline hover:underline cursor-pointer" data-testid="createArrearsActionLink" onClick={callCreateArrearsAction}>{t("UCCRent.CreateArrearsAction")} &gt;</a></div>
                </div>
            }
        </div>
    )
};
export default UCCRentDetails;