import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_CREATION_QUESTIONS,
  CASE_CREATION_QUESTIONIDS_ANSWERS,
} from "../../../ducks/redux/actionTypes";
import { RouteComponentProps } from "react-router-dom";
import {
  HOUSEHOLD_OR_SUBLETTING_TITLE,
  LEASEHOLD_OWNERSHIP,
  RENT_CARD,
  COMPLEX_CASE_SERVICE_COMPLAINT_Q3,
  COMPLEX_CASE_SERVICE_COMPLAINT_Q2
} from "./Question";
import { BackValue } from "../../../models/shared/Enum";
import { backToCaseAsset } from "../../../ducks/redux/actions/userChoices";

const Loader = lazy(() => import("react-loader-spinner"));
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));

interface DropdownProps extends RouteComponentProps<any> {}

const DropdownQuestion: React.FC<DropdownProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let errorCode = "";
  let val = "";
  const [optionKeyValue, setOptionKeyValue] = useState("");
  const [showError, setShowError] = useState("");
  let householdMemberOrSubletting: any;
  let rentCard: any;
  let leasehold: any;
  let tenancyType: string;
  let skipComplexCaseQuestion: any;
  let skipComplexCaseQuesBasedOnValue: any;

  enum DependsOnAttributes {
    TenancyType = "TenancyType",
    PostCode ="PostCode",
    PreviousQuestionID = "q2_1",
    QuestionID1 = "q1_1",
  }

  const [isLoading, setIsLoading] = useState(false);
  const [blockingIsVisible, setBlockingIsVisible] = useState(false);
  const [blockingAlert, setBlockingAlert] = useState("");
  const blockingArray: any[] = [];
  const [disabled, setdisabled] = useState(false);
  const [sublettingSelection, setSublettingSelection] = useState(0);
  const [rent, setRent] = useState(false);
  const [leaseholdOwnership, setLeaseholdOwnership] = useState("");
  const [skipComplexCaseques,setskipComplexCaseques] = useState(false);
  const [skipComplexCasequesBasedOnValue,setskipComplexCasequesBasedOnValue] = useState(false);
  const [showStaticAlert, setShowStaticAlert] = useState("");
  const [showStaticAlertYellow, setShowStaticAlertYellow] = useState("");

  const fetchQuestion = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestions
  );

  const [questionArray, setQuestionArray] = useState(
    fetchQuestion?.length > 0 ? fetchQuestion : []
  );

  const fetchAnswer = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedAnswers
  );
  

  const [ansArray, setAnsArray] = useState(
    fetchAnswer?.length > 0 ? fetchAnswer : [undefined]
  );

  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const [questionAnswerArray, setQuestionAnswerArray] = useState<{
    questionText: string;
    questionId: string;
    answer?: string;
  }[]>(() => {
    if (caseSelectedQuestionIdsAnswers && caseSelectedQuestionIdsAnswers.length > 0) {
      return caseSelectedQuestionIdsAnswers;
    } else {
      return [{ questionText: "", questionId: "", answer: "" }];
    }
  });

  const descData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationDescription
  );

  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
  );

  let count = useSelector(
    (state: any) => state.caseCreationReducer?.counter || 0
  );

  
  const selectedTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyId
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const userTenancy =
    useSelector((state: any) => state?.addresses?.customertenancies) || [];

  userTenancy.map((data: any) => {
    if (data?.tenancySummary?.premisesIds[0] === selectedTenancy) {
      tenancyType = data?.tenancySummary.tenancyType;
    }
  });

  const tenancy = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );

  const customerAssociatedAssets = useSelector(
    (state: any) => state?.addresses?.customerassociatedassets[0]
  );

  const postCode = (() => {
    let code = "";
    if (customerAssociatedAssets && customerAssociatedAssets.length > 0) {
      for (const asset of customerAssociatedAssets) {
        if (asset.assetType === tenancy && asset.addresses && asset.addresses.postalAddresses.length > 0) {
          code = asset.addresses.postalAddresses[0].postcode;
          break;
        }
      }
    }
    return code.split(" ")[0];
  })();

  if (
    questionInfoData &&
    questionInfoData[count]?.label === HOUSEHOLD_OR_SUBLETTING_TITLE
  ) {
    householdMemberOrSubletting = "1";
  }

  if (questionInfoData && questionInfoData[count]?.label === RENT_CARD) {
    rentCard = "1";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === LEASEHOLD_OWNERSHIP
  ) {
    leasehold = "1";
  }

  if (
    questionInfoData &&
    questionInfoData[count]?.label === COMPLEX_CASE_SERVICE_COMPLAINT_Q3
  ) {
    skipComplexCaseQuestion = true;
  }

  if(questionInfoData && questionInfoData[count]?.label === COMPLEX_CASE_SERVICE_COMPLAINT_Q2){
    skipComplexCaseQuesBasedOnValue = true;
  }


  const onContinueHandler = () => {
    setShowStaticAlert("");
    setShowStaticAlertYellow("");
    if (
      skipComplexCaseques ||
      rent ||
      sublettingSelection ||
      skipComplexCasequesBasedOnValue ||
      leaseholdOwnership
    ){
      val = "1"
    }
    count = count + 1;
    if (!blockingAlert) {
      if (optionKeyValue === "") {
        setShowError(t("Case_Question_Error"));
      } else {
        const updatedQuestionAnswerArray = [...questionAnswerArray];
	      updatedQuestionAnswerArray[count].answer = optionKeyValue;
  
        dispatch({ type: CASE_COUNTER, payload: count });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: ansArray });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: questionArray });
        dispatch({ type: CASE_CREATION_QUESTIONIDS_ANSWERS, payload: updatedQuestionAnswerArray });

        setOptionKeyValue("");
        history.push("/CaseQuestionnaire");
      }
    } else {
      setBlockingIsVisible(true);
    }
  };

  const exitButtonHandler = () => {
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    history.push("/customer360View");
    dispatch(backToCaseAsset(false));
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  if (questionInfoData && questionInfoData[count]?.blocking !== null) {
    blockingArray.push(questionInfoData[count]?.blocking);
    errorCode = questionInfoData[count]?.blocking?.errorCode;
  }

  const assignedSelectedAnswersValue = useCallback(() => {
    fetchAnswer?.map((data: any, index: number) => {
      if (count == index) {
        questionInfoData[index]?.options?.map((listOpt: any) => {
          if (listOpt.id === fetchAnswer[index]) {
            setOptionKeyValue(listOpt.id);
            setdisabled(true);
          }
        });
      }
    });
  }, [count]);

  const assignRentCardComesFromEdit = () => {
    if (rentCard === "1" && ansArray[count] === "1") {
      setRent(true);
    }
  };

  const assignedCounterComesFromEdit = () => {
    if (householdMemberOrSubletting === "1" && ansArray[count] === "1") {
      setSublettingSelection(1);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "2") {
      setSublettingSelection(2);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "3") {
      setSublettingSelection(3);
    } else if (householdMemberOrSubletting === "1" && ansArray[count] === "4") {
      setSublettingSelection(4);
    }
  };

  const assignLeaseholdOwnership = () => {
    if (
      leasehold === "1" &&
      (ansArray[count] === "1" || ansArray[count] === "2")
    ) {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && ansArray[count] === "3") {
      setLeaseholdOwnership("2");
    } else if (
      leasehold === "1" &&
      (ansArray[count] === "4" || ansArray[count] === "5")
    ) {
      setLeaseholdOwnership("3");
    }
  };

  const skipComplexQuestion = () => {
    if(skipComplexCaseQuestion){
      setskipComplexCaseques(true);
    }
  }

  useEffect(() => {
    setQuestionArray([
      ...questionArray,
      questionInfoData && questionInfoData[count]?.label,
    ]);
    const newData = {
      questionText: questionInfoData[count]?.label,
      questionId: questionInfoData[count]?.questionId,
      answer: "",
    }
    if (questionAnswerArray.length > count + 1) {
      questionAnswerArray.splice(count + 1, questionAnswerArray.length);
    }
    if (questionAnswerArray.length == 0 || (!questionAnswerArray[count+1])) {
      setQuestionAnswerArray(prevArray => [...prevArray, newData]);
    }
    // Saving same question if user is editing the page
    let newQArr = [...questionArray];
    newQArr[count] = questionInfoData && questionInfoData[count]?.label;
    setQuestionArray(newQArr);
    assignedSelectedAnswersValue();
    assignedCounterComesFromEdit();
    assignRentCardComesFromEdit();
    assignLeaseholdOwnership();
    skipComplexQuestion();  
  }, [count]);

  useEffect(() => {
    if (
      questionInfoData &&
      questionInfoData[count]?.alertMessageCode !== null
    ) {
      if (questionInfoData[count]?.alertMessageCode?.message) {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.message);
      }
      else {
        setShowStaticAlert(questionInfoData[count]?.alertMessageCode?.code);
      }
    }

    if (questionInfoData[count]?.alertMessageCodeYellow?.message) {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.message);
    }
    else {
      setShowStaticAlertYellow(questionInfoData[count]?.alertMessageCodeYellow?.code);
    }
  }, [questionInfoData]);

  const dropDownHandler = (e: any) => {  
    setdisabled(true); 
    setBlockingIsVisible(false);
    if (e.target.value == "") {
      setShowError(t("Case_Question_Error"));
    } else {
      if (e.target.value !== ansArray[count]) {
        //Removing further answers ans questions
        fetchQuestion.splice(count, fetchQuestion.length);
        fetchAnswer.splice(count, fetchAnswer.length);
        dispatch({ type: CASE_EDIT, payload: true });
      }
      setAnsArray([...ansArray, e.target.value]);
      setOptionKeyValue(e.target.value);
      setShowError(t(""));

      // ==== If user is changing the answer for the same question, need to get index and replace the value ===
      let newArr = [...ansArray];
      newArr[count] = e.target.value;
      setAnsArray(newArr);
    }

    // Validating household and subletting case
    if (householdMemberOrSubletting === "1" && e.target.value === "1") {
      setSublettingSelection(1);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "2") {
      setSublettingSelection(2);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "3") {
      setSublettingSelection(3);
    } else if (householdMemberOrSubletting === "1" && e.target.value === "4") {
      setSublettingSelection(4);
    }

    if (rentCard === "1" && e.target.value === "1") {
      setRent(true);
    } else {
      setRent(false);
    }

    if(skipComplexCaseQuesBasedOnValue === true && e.target.value === "5"){
      setskipComplexCasequesBasedOnValue(true);
    }else {
      setskipComplexCasequesBasedOnValue(false);
    }

    if (
      leasehold === "1" &&
      (e.target.value === "1" || e.target.value === "2")
    ) {
      setLeaseholdOwnership("1");
    } else if (leasehold === "1" && e.target.value === "3") {
      setLeaseholdOwnership("2");
    } else if (
      leasehold === "1" &&
      (e.target.value === "4" || e.target.value === "5")
    ) {
      setLeaseholdOwnership("3");
    }

    if (blockingArray.length > 0) {
      if (blockingArray[0]?.dependsOn[0]?.responseId === e.target.value) {
        setBlockingAlert(t(errorCode));
        setBlockingIsVisible(true);
      } else {
        setBlockingAlert("");
      }
    }
  };

  const backButtonHandler = () => {
    clearCaseDataFromRedux();
    if (count >= 1) {
      dispatch({ type: CASE_COUNTER, payload: count - 1 });
      history.push("/CaseQuestionnaire");
    } else {
      history.push("/CaseType");
    }
  };
  const clearCaseDataFromRedux = () => {
    if(fetchAnswer.length > count){
    const ans = fetchAnswer;
    const question = fetchQuestion;
    ans.pop();
    question.pop();
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: ans });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: question });
    }
  };

  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="reploc-container" role="main" data-testid="case-div1">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <>
             <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
            <div className="h5 sm:mx-1" id="repreason-div1">
              <h2
                className="text-purple-100 text-2xl mb-0 global-txt"
                data-testid="title"
              >
                {descData}
              </h2>
            </div>
            <br />
            <div className="sm:mx-1" key={questionInfoData && questionInfoData[count]?.questionId}>
              <p className="text-base my-0 global-txt" data-testid="case-q1">
                {t(questionInfoData && questionInfoData[count]?.label)}
              </p>
              <br />
              <label className="lg:w-3/4 flex items-center mb-4 global-txt">
                <select
                  data-testid="case-key-selection"
                  value={optionKeyValue}
                  key={questionInfoData && questionInfoData[count]?.questionId}
                  className="block width-css mt-1 text-black global-txt rounded border-grey"
                  onChange={dropDownHandler}
                >
                  <option
                    value="Select"
                    className="block mt-1 width-css mt-8 text-black global-txt"
                    disabled={disabled}
                  >
                    Select
                  </option>
                  {questionInfoData &&
                    questionInfoData[count]?.options?.map((option: any) => {
                      if (option?.dependsOnAttributes?.length > 0) {
                        return option?.dependsOnAttributes?.map((dependsOnAttr: any) => {
                          if (dependsOnAttr.key === DependsOnAttributes.TenancyType) {
                            if (dependsOnAttr.value === tenancyType) {
                              return (
                                <option
                                  className="block mt-1 width-css mt-8 text-black global-txt "
                                  key={option.id}
                                  id={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </option>
                              );
                            }                           
                          }else if(dependsOnAttr.key === DependsOnAttributes.PreviousQuestionID){
                            if(dependsOnAttr.value === ansArray[count-1]){
                              return (
                                <option
                                  className="block mt-1 width-css mt-8 text-black global-txt "
                                  key={option.id}
                                  id={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </option>
                              );
                            }
                          }
                          else if (dependsOnAttr.key === DependsOnAttributes.PostCode ) {
                            if (dependsOnAttr.value === postCode ) {
                              return (
                                <option
                                  className="block mt-1 width-css mt-8 text-black global-txt "
                                  key={option.id}
                                  id={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </option>
                              );
                            }
                          }
                          else if (dependsOnAttr.key ===  DependsOnAttributes.QuestionID1 && dependsOnAttr.value ===  ansArray[count-1] ) {
                            return (
                                <option
                                  className="block mt-1 width-css mt-8 text-black global-txt "
                                  key={option.id}
                                  id={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </option>
                              );
                            } 
                        });
                      } else if (ansArray[count-1] != "8" && ansArray[count-1] != "12"){
                        return (
                          <option
                            className="block mt-1 width-css mt-8 text-black global-txt "
                            key={option.id}
                            id={option.id}
                            value={option.id}
                          >
                            {option.value}
                          </option>
                        );
                      }
                    })}
                </select>
              </label>
            </div>

            {/* ==== Showing error ==== */}
            {showError && (
              <div
                data-testid="alert-warning"
                className="error mb-8 sm:mx-1"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      {" "}
                      {t(showError)}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* === End error ==== */}

              {/* ==== Display purple alert warning === */}
              {showStaticAlert && (
                <div
                  data-testid="alert-warning"
                  className="error mb-8 sm:mx-1"
                  role="alert"
                  placeholder="alert-message"
                >
                  <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
                    </div>
                    <div className="flex items-center text-black">
                      <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                        {t(showStaticAlert)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* === Close purple warning === */}

              {/* ==== Display Yellow static alert === */}
              {showStaticAlertYellow && (
                <div
                  data-testid="alert-warning"
                  className="error mb-8"
                  role="alert"
                  placeholder="alert-message"
                >
                  <div className="w-full px-2 py-2 bg-yellow flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                    </div>
                    <div className="flex items-center">
                      <p
                        data-testid="locatioAlert_0"
                        className="w-full text-left my-2 font-AvantGardeGothic-Md"
                      >
                        {t(showStaticAlertYellow)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* === Close Yellow static alert  === */}


            {/* ==== Blocking alert ===== */}

            {blockingAlert && (
              <div
                data-testid="alert-warning"
                className={
                  blockingIsVisible
                    ? "warning mb-8 unblockingMessage sm:mx-1"
                    : "blockingMessage sm:mx-1"
                }
                role="alert"
                placeholder="alertNo"
              >
                <div className="w-full px-2 py-2 bg-red-20 flex items-center sm:mx-1">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
                  </div>
                  <div className="div-txt-newline flex items-center text-black">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {blockingAlert}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* ==== End blocking alert ==== */}

            <div
              className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
              id="repreason-main-btn"
            >
              <ExitModalContent
                data-testid="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
              />
              <Button
                onClick={onContinueHandler}
                className="button-class"
                data-testid="btn_caseQ"
                disabled={blockingIsVisible}
              >
                {t("continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
          </>
        )}
      </Suspense>
    </div>
  );
};

export default DropdownQuestion;
