export enum MaintenanceResponsibilityEnum {
    FMFULL = "FMFULL",
    QLYES = "QLYES",
    DEFAULTED = "DEFAULTED",
    EMAFULL = "EMAFULL",
    FMPART = "FMPART",
    SINGLE = "SINGLE",
    QLNO = "QLNO",
    AGENT = "AGENT",
    OWNER = "OWNER",
    FREEHDFULL = "FREEHDFULL",
    FREEHOLD = "FREEHOLD",
    LEASEHOLD = "LEASEHOLD",
    LEASEHDFULL = "LEASEHDFULL",
    LEASHDFULL = "LEASHDFULL",
    FREEHOLDER = "FREEHOLDER",
    LEASEHOLDR = "LEASEHOLDR",
    NONE = "NONE"
}
