import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useDispatch, useSelector } from "react-redux";
import AccountDetails from '../../models/Accounts/AccountDetails';
import { BalanceType } from '../../models/Accounts/BalanceType';
import { AccountTypeList, getAccountType } from '../../models/Accounts/AccountType';
import { formatDateWithFromCalendar } from '../../utils/formatting';
import { PermissionsList } from "../../models/Permissions";
import { transactionInformation, userChosenAccountNumber, userChosenAccountType, userChosenBalance, userChosenBalanceType } from "../../ducks/redux/actions/userChoices";
import PermissionError from "../PermissionError";
import React from "react";
interface AccountResultProps extends RouteComponentProps<any> {
}
const AccountResult: React.FC<AccountResultProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerResponse = useSelector(
        (state: any) => state.addresses?.saveCustomerDetails
    );
    const customerAccountData = useSelector(
        (state: any) => state.userChoicesReducer?.customerAccountData
    );
    const userChosenAddress = useSelector(
        (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
    );
    const propertyId = useSelector(
        (state: any) => state?.userChoicesReducer?.userChosenPropertyId
    );

    const customerData = useSelector(
        (state: any) => state.userChoicesReducer?.customersData
    );

    const isActiveTenancy = useSelector(
        (state: any) => state?.userChoicesReducer?.isActiveTenancy
    );
    const getPermissionsData = useSelector(
        (state: any) => state?.userChoicesReducer?.userProfilePermissions
    );
    const [isTransaction, setIsTransaction] = useState(false)
    const [accMsg, setAccMsg] = useState("");
    const array: AccountDetails[] = [];
    const [accountList, setaccountdetails] = useState(array);

    useEffect(() => {
        window.scrollTo(0, 0);
        (async () => {
            trackEvent("AccountResult");
            const showTransaction = getPermissionsData?.indexOf(PermissionsList.view_customer360_accounts_full_view_list);

            if (showTransaction != -1) {
                setIsTransaction(true)
            }
            else {
                setIsTransaction(false)
            }
            callgetAccountByCustomerApi();
        })();
    }, [propertyId]);

    function accountMsg() {
        let tempError;
        tempError = t("CP036b").replace("@address", userChosenAddress);
        setAccMsg(tempError);
    }

    function callgetAccountByCustomerApi() {
        (async () => {
            try {
                const accountCustomerResponse = customerAccountData;
                const tenanSummaries = customerResponse?.tenanciesSummaries.map(
                    (ts: any) => (ts)
                );
                //only id from tenancySummary Array

                let objFinal: any;
                let payRef;
                tenanSummaries?.map(async (assetid: any) => {
                    assetid?.tenancySummary.premisesIds?.map(async (id: any) => {
                        if (id === propertyId) {
                            objFinal = assetid?.tenancySummary;
                        }
                    })
                })

                payRef = objFinal?.paymentReference;
                if (payRef != undefined && payRef != null)
                    getAccountList(payRef, accountCustomerResponse);
            }
            catch (e) {
                console.error(e);
                const timerId = setTimeout(() => {
                    props.history.push("/GenericError");
                }, 1000);
            }
        })()
    }

    const getAccountList = (accountid: string, accountResponse: any) => {
        const accountDetailsList: AccountDetails[] = [];
        let orderAccountDetailsList: AccountDetails[] = [];

        accountResponse?.forEach((accountBalance: any) => {
            const accountDetails: AccountDetails = new AccountDetails();

            if (accountBalance.identifiers['pay-ref'].replace(/[^0-9]/g, '') == accountid) {
                accountDetails.LastArrearsAction = accountBalance["last-arrears-action"]?.desccription;
                if (accountBalance['account-type'] === AccountTypeList.REN) {
                    // Preference of showing type
                    accountDetails.DisplayNumber = 1;
                    accountDetails.AccountType = getAccountType(AccountTypeList.REN, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref'];
                }
                else if (accountBalance['account-type'] === AccountTypeList.FD5) {
                    accountDetails.DisplayNumber = 2;
                    accountDetails.AccountType = getAccountType(AccountTypeList.FD5, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                else if (accountBalance['account-type'] === AccountTypeList.MJW) {
                    accountDetails.DisplayNumber = 3;
                    accountDetails.AccountType = getAccountType(AccountTypeList.MJW, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                else if (accountBalance['account-type'] === AccountTypeList.GRD) {
                    accountDetails.DisplayNumber = 4;
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                    accountDetails.AccountType = getAccountType(AccountTypeList.GRD, accountBalance['tenancy-type']);
                }
                else if (accountBalance['account-type'] === AccountTypeList.DEP) {
                    accountDetails.DisplayNumber = 5;
                    accountDetails.AccountType = getAccountType(AccountTypeList.DEP, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                } else if (accountBalance['account-type'] === AccountTypeList.HOM) {
                    accountDetails.DisplayNumber = 6;
                    accountDetails.AccountType = getAccountType(AccountTypeList.HOM, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                accountDetails.AccountNumber = accountBalance['account-identifier'];
                accountDetails.lastTransactionBalance = accountBalance['last-transaction']?.amount?.gross?.value != null ?
                    accountBalance['last-transaction']?.amount?.gross?.value : 0.00;
                accountDetails.lastTransactionDate = accountBalance['last-transaction']?.dates['created-date'];
                accountBalance['balance'].forEach((balance: any) => {
                    if (balance['balance-type'] == BalanceType.Livebalanced) {
                        let liveBalanced = balance['monetary-amount']['value'];
                        const accountBalanceValue = parseInt(liveBalanced);
                        accountDetails.LiveBalanceType = accountBalanceValue != null ? accountBalanceValue > 0 ? "In arrears" : "In credit" : "";
                        let number = liveBalanced != null ? Math.abs((liveBalanced.toString().replace(/,/g, ''))) : 0.00;
                        accountDetails.LiveBalance = number.toFixed(2);
                        if (liveBalanced == 0) {
                            accountDetails.LiveBalanceType = "";
                        }
                        if (isActiveTenancy) {
                            accountDetailsList.push(accountDetails);
                        }
                        else if (!isActiveTenancy && liveBalanced > 0) {
                            accountDetailsList.push(accountDetails);
                        }
                    }
                })
            }


        })
        orderAccountDetailsList = accountDetailsList.filter(x => x.DisplayNumber !== 0);
        setaccountdetails(orderAccountDetailsList.sort((a, b) => {
            return a.DisplayNumber - b.DisplayNumber;
        }).slice(0, 6));
        accountMsg();
    }
    const callPageHandler = async (AccountNumber: string | null, Payref: string | null, AccountType: string | null, LiveBalance: string, LiveBalanceType: string | null) => {
        let LiveBalanceValue = "£" + LiveBalance
        dispatch(userChosenBalanceType(LiveBalanceType));
        dispatch(userChosenAccountNumber(Payref));
        dispatch(userChosenBalance(LiveBalanceValue));
        dispatch(userChosenAccountType(AccountType));
        dispatch(transactionInformation("acc"));
        props.history.push("/TransactionDetails");
    };
    const backButtonHandler = () => {
        props.history.push("/Customer360View");

    };

    return (
        <div>
            {isTransaction ? (
                <div>
                    <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 global-txt" >
                        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
                            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
                                <i className="fas fa-angle-left mr-2"></i>
                                <span>{t("back")}</span>

                            </a>
                        </p>
                    </div>
                    <div className="container sm:px-2">
                        <div className="relative px-4 pb-8 border border-grey bg-white shadow-lg rounded-lg cust-card">
                            <div className="mt-8 ml-2 mb-8 w-full text-lg flex flex-col md:flex-row md:justify-between global-txt">
                                <div className="pb-2 md:pb-0"><strong>
                                    {customerData?.person.name.title} {customerData?.person.name.givenName} {customerData?.person.name.surname}
                                    {" "} -   {userChosenAddress}
                                </strong>
                                </div>
                            </div>

                            {!accountList?.length ? (

                                <div
                                    id="div_apilength"
                                    className="alertpayment alert-warningpayment error-reason mar-left global-txt"
                                    role="alert"
                                >
                                    {t("account_Down_CP089")}
                                </div>
                            ) : (
                                accountList?.map(e => (
                                    <div className="w-full global-txt">
                                        <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                <div className="mb-2">
                                                    <strong>{t("balance")}</strong>{" "}
                                                    <br></br>
                                                    £{e?.LiveBalance} {e?.LiveBalanceType}
                                                </div>
                                                <div className="mb-2 md:ml-4">
                                                    <strong>{t("last_pay_amount")}</strong>
                                                    <br></br>
                                                    £{e?.lastTransactionBalance}
                                                </div>
                                                <div className="mb-2">
                                                    <strong>{t("account_type")}</strong>
                                                    <br></br>
                                                    {e?.AccountType}
                                                </div>
                                                <div className="mb-2 md:ml-4">
                                                    <strong>{t("last_pay_date")}</strong>
                                                    <br></br>
                                                    {!(e?.lastTransactionDate) ? '-' : (formatDateWithFromCalendar(e?.lastTransactionDate))}{" "}
                                                </div>
                                                <div className="mb-2">
                                                    <strong>{t("pay_ref")}</strong>
                                                    <br></br>
                                                    {e?.Payref}
                                                </div>
                                                <div className="mb-2 md:ml-4">
                                                    <strong>{t("last_arrears_action")}</strong>
                                                    <br></br>
                                                    {!(e?.LastArrearsAction) ? '-' : e?.LastArrearsAction} </div>
                                                {isTransaction ? (
                                                    <div>
                                                        <a data-testId="View_Transaction" onClick={(event) => callPageHandler(e?.AccountNumber, e?.Payref, e?.AccountType, e?.LiveBalance, e?.LiveBalanceType)} className="text-teal no-underline hover:underline cursor-pointer" >{t("View_Transaction")} &gt;</a>
                                                    </div>) : null}
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                            }

                        </div>
                    </div>
                </div>
            ) : (
                <PermissionError {...props}></PermissionError>
            )
            }
        </div>


    )
};
export default AccountResult;