import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  backToRepairAsset,
  loadDuplicateRepairPage,  
  userChosenResumeRepair,
  userCreatedNewRepair,
  workOrderData,
  userChosenEmergencyQuestion,
  userChosenCustomerID,
  userChosenIsSurvey,
} from "../../ducks/redux/actions/userChoices";
import { userTypedRepairReasons } from "../../ducks/redux/actions/dashboardReducer.actions";
import { isNullOrUndefined } from "../../utils/formatting";
import * as api from "../../api";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import Loader from "react-loader-spinner";
import { AssetTypes, BackValue, SurveyTypes } from "../../models/shared/Enum";

interface SurveyFinalPageProps extends RouteComponentProps<any> { }
const SurveyFinalPage: React.FC<SurveyFinalPageProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [jobNo, setJobNo] = useState("");
  let [voidOrKeySafe, setVoidOrKeySafe] = useState("");

  
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );
  const userChosenSurveyReason = useSelector(
    (state: any) => state.dashboardReducer?.formState?.userTypedRepairReasons
  );
  const customerID = useSelector((state: any) => state?.userChoicesReducer?.custommerID);
  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  let assetStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataStatus
);
const getContractorname = useSelector(
  (state: any) => state.addresses.contractorname
);
const backValue = useSelector(
  (state: any) => state?.userChoicesReducer?.backValue
);

  useEffect(() => {   
    if (isNullOrUndefined(referenceNumberCode)) {
      getJobNo();
    }
    else {
      setJobNo(referenceNumberCode)
    }
    if(userChosenSurveyReason === SurveyTypes.Void_Survey) {
      setVoidOrKeySafe("void");
    }
    else if(userChosenSurveyReason === SurveyTypes.Keysafe_Installation) {
      setVoidOrKeySafe("key");
    }
    else {
      setVoidOrKeySafe("");
    }
  }, []);

  const getJobNo = async () => {
    const noOfHits = process.env.REACT_APP_API_HITS;
    const timeoutValue = process.env.REACT_APP_API_TIMEOUT;
    const noOfHitsValue = Number(noOfHits);
    const noOfTimeoutValue = Number(timeoutValue);
    let i = 0, woJobNumber = "", workOrderId = workOrderObject?.id;
    let interval = setInterval(async () => {
      if (i < noOfHitsValue && (isNullOrUndefined(woJobNumber) || woJobNumber == "") && !isNullOrUndefined(workOrderId)) {
        i++;
        try {
          const repair = await api.getRepair(workOrderId);
          woJobNumber = repair?.jobNo;
          setJobNo(woJobNumber);
        }
        catch (e) {
          console.log(e);
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        }
      }
      else {
        clearInterval(interval);
        if (isNullOrUndefined(woJobNumber) || woJobNumber == "") {
          console.log("Job Number not created");
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        }
      }
    }, noOfTimeoutValue);
    return () => clearInterval(interval);
  }

  const callPageHandler = () => {
    dispatch(userChosenCustomerID(customerID));
    dispatch(userChosenIsSurvey(false));
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenSOR(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(backToRepairAsset(false))
    dispatch(userChosenResumeRepair(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userCreatedNewRepair(null));
     const worksOrder = new WorksOrderRequest(workOrderObject);
     dispatch(workOrderData(worksOrder))
    if((backValue===BackValue.Customer360View) || (selectedproperty === AssetTypes.Property && assetStatus===SurveyTypes.OCCP)){
      props.history.push("/Customer360View");
    }
    else {
      props.history.push("/Asset360View");
    }
  };

  return (
  <div>
    <div className="container sm:px-2 ctn-fht" id="surveyfinal-container" role="main">
      <div id="surveyfinal-main-div" className="h5 my-3 repair-text">
      {voidOrKeySafe === "key" ? 
      <h2 id="surveyfinal-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("Survey.Survey_Thanks_KeySafe")}</h2> :
      <h2 id="surveyfinal-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("Survey.Survey_Thanks_Text")}</h2>
     }
      </div>
      <div id="surveyfinal-div1" className="repair-text global-txt">
      {voidOrKeySafe === "key" ? 
      <p id="surveyfinal-p">{t("Survey.Survey_Peabody_KeySafe")}</p> :
      <p id="surveyfinal-p">{t("Survey.Survey_Peabody_Text")}</p>
     }
      </div>

      <div
        id="surveyfinal-div2"
        className="border border-grey rounded-lg mar-left mb-4 global-txt py-4"
      >
        <div id="surveyfinal-div1" className="repair-text global-txt font-bold">
          <span id="surveyfinal-p" className="pl-2">{t("Appointment_Confirm_Reference")}</span><br />
      {!jobNo &&
      <p className="ms-4"><Loader
         type="ThreeDots"
         color="#00BFFF"
         height={50}
         width={50}
         timeout={700000} //3 secs
       /></p>} {jobNo && <strong className="pl-4"><b>{jobNo}</b></strong>}
        </div>
        <br />
        <span id="surveyfinal-span1" className="pl-2 font-bold">{t("Repair_Appointment_Text2")}</span> 
        <br />
        <span id="surveyfinal-span2" className="mt-2">
        <span> <strong className="text-purple-100">{getContractorname}</strong></span>
          </span>
      </div>

      <div
        id="surveyfinal-main-btn"
        className="button-positions flex justify-end pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-3 btn-left"
      >
        <Button
          id="surveyfinal-finshsurvey"
          data-testid="finshsurvey"
          onClick={callPageHandler} disabled={!jobNo}
          className="button-class sm:mt-4"
        >
          {t("Survey.Survey_Finish")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </div>
  </div>
  );
};
export default SurveyFinalPage;
