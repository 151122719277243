export const StatusFilter= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Draft',
        value: 'In Diagnosis'
    }, 
    {
        key: 'Under review',
        value: 'Under review'
    },

    {
        key: 'Assigned to contractor',
        value: 'Unappointed,Reported to EMA',
    },
    {
        key: 'Appointed',
        value: 'Appointed',
    },
    {
        key: 'On hold',
        value: 'On Hold',
    },
    {
        key: 'In progress',
        value: 'In Progress',
    },
    {
        key: 'Pending approval',
        value: 'Pending Approval',
    },
    {
        key: 'Cancelled',
        value: 'Cancelled',
    },
    {
        key: 'Completed',
        value: 'Completed',
    },   
    { 
        key: 'Catalyst Defect Triage',
        value: 'Catalyst Defect Triage',
    },
    {
        key: 'Catalyst EMA Triage',
        value: 'Catalyst Managing Agent Triage',
    },
    {
        key: 'Catalyst Tier 2 Triage',
        value: 'Catalyst Tier 2 Triage',
    },
    {
        key: 'Assigned to surveyor',
        value: 'Unappointed',
    }
  ]