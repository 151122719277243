
export enum CaseOwnerMatrix {
    'New Homes (Development)' = 'New Homes Team',
     'NM' = 'Neighbourhood Manager',
    'Estate Services Enquiries' = 'Estate Services Team',
    'Specialist Case Management' = 'Specialist Case Management Team',
    'Allocations and Lettings' = 'Allocations & Lettings Team',
    'Leasehold Team' = 'Leasehold Compliance Team',
    'Private Rent Team' = 'Private Rent Team',
    'Homeownership Team' = 'Homeownership Team',
    'Leasehold Compliance Team' = 'Leasehold Compliance Team',
    'Resale And Staircasing Team' = 'Resale and Staircasing Team',
    'Homeowner Sales Services' = 'Homeownership Services Team',
    'Incomes officer' = 'Incomes officer',
    'Property Accounts' = 'Property Accounts',
    'Rehousing Services' = 'Rehousing Team',
    'Rehousing and Lettings' = 'Rehousing and Lettings Team',
    'SAR Team' = 'Subject Access Request Team',
    'Business Support' = 'Business Support',
    'Property Accounts Team' = 'Property Accounts',
    'Env Services South' = 'Env Services South Team',
    'MyPeabody Hub Complaints' = 'MyPeabody Hub Complaints'
}






