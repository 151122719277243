import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import { RouteComponentProps } from "react-router-dom";
import "./index.css";
import {
  CASE_COUNTER,
  CASE_CREATION_DESCRIPTION,
  CASE_CREATION_MASTER_DATA,
  CASE_CREATION_QUESTIONS,
  CASE_DESCRIPTION_DATA_INFO,
  CASE_EDIT,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import { backToCaseAsset, clearMediaFiles, userChosenUploadedMediaIdentifiers } from "../../../ducks/redux/actions/userChoices";
import { BackValue,Description } from "../../../models/shared/Enum";
import { comingFromCase } from "../../../ducks/redux/actions/FinancialReferral";

const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../Button/Button"));
const ExitModalContent = React.lazy(
  () => import("../../Modal/ExitModalContent")
);

const TextField = React.lazy(() => import("@mui/material/TextField"));
const Autocomplete = React.lazy(() => import("@mui/material/Autocomplete"));

const matchcaseTypeInputs = /^[\s'|A-Za-z ]*$/;

interface CaseTypeProps extends RouteComponentProps<any> { }

const CaseType: React.FC<CaseTypeProps> = (props) => {
  const assetDataDetails = useSelector((state: any) => state?.addresses?.assetData);
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const caseTypeData: { description: string; keywords: [] }[] = [];

  let caseDescriptionArray: any[] = [];
  let timerId: any;
  let actualRefData: any = [];

  const [isLoading, setIsLoading] = useState(true);
  let suggesstionArray;
  let keyValuePairs: any = "";

  const [inputValue, setInputValue] = useState("");
  const [warningAlert, setWarningAlert] = useState("");
  let keywordPosArr: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [userInput, setUserInput] = useState("");
  const [mappingData, setMappingData] = useState(caseTypeData);

  const [noCaseTypeSelected, setnoCaseTypeSelected] = useState(false);
  let getDescriptionArr: any[] = [];
  const defaultSelection = useSelector(
    (state: any) => state?.caseCreationReducer?.caseCreationDescription
  );

  const getDescriptionData = useSelector(
    (state: any) => state?.caseCreationReducer?.caseDescriptionDataInfo
  );
  if (getDescriptionData) {
    getDescriptionArr = [...getDescriptionData];
  }


  const getTenancyGroup = useSelector(
    (state: any) => state.caseCreationReducer.caseTenancyGroup
  );
  const backToCaseSelectCustomer = useSelector(
    (state: any) => state?.userChoicesReducer?.backToCaseSelectCustomer
  );
  const tenancyCount = useSelector(
    (state: any) => state?.userChoicesReducer?.tenancyCount
  );
  
  const tenancyGroup = useSelector(
    (state: any) => state?.userChoicesReducer?.tenancyGroup
  );

  const getCaseCreationPermissions = useSelector(
      (state: any) => state?.userChoicesReducer?.createCasePermissions
  );

  const caseCreatedBy = useSelector(
    (state: any) => state?.userChoicesReducer?.caseCreatedBy
  );
  
  const getAPIMasterData = async () => {
      try {
      const completeRefData: any = [];
      const responseData = await api.getQuestionnaireMasterData();
      if (responseData) {      
        keyValuePairs = responseData[0]?.namespace?.keyValues;
        for (const data of Object.entries(keyValuePairs)) {
          const [key, value] = data;
          if (typeof value === "object") {
              completeRefData.push(value);
          }
        }
      }
          actualRefData = getCaseCreationPermissions !== null && getCaseCreationPermissions !== undefined ? completeRefData.filter(
              (data: any) => getCaseCreationPermissions.indexOf(data.subTypeCategory) < 0
          ) : completeRefData;

      // Pushing description and keywords in an array and binding in autocomplete feild
      actualRefData?.map((data: any) => {
        //Validating with case tenancy group;
        if (
          data.tenancyGroups?.length === 1 &&
          (data.tenancyGroups[0] === tenancyGroup ||
            data.tenancyGroups[0] === "All") &&
          data.isVisibleForColleagueSelfServe === true
          && data.isRoleRestricted === false
        ) {
          if((data.description === Description.Customer_Charge || data.description === Description.Customer_own || data.description === Description.Leasehold || data.description === Description.Customer_medical || data.description === Description.application || data.description === Description.Customer_home || data.description === Description.Customer_reference || data.description === Description.Customer_Agreement || data.description === Description.Customer_card || data.description === Description.Customer_them || data.description === Description.Change_subletting || data.description === Description.access))
          {
            if(data.availableToCreateFrom.includes(caseCreatedBy) || data.availableToCreateFrom.includes(caseCreatedBy) ){
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);
        }
        }
        else {
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);

        }
        } else if (
          (data.tenancyGroups[0] === tenancyGroup ||
            data.tenancyGroups[1] === tenancyGroup ||
            data.tenancyGroups[0] === "All") &&
          data.isVisibleForColleagueSelfServe === true
          && data.isRoleRestricted === false
        ) {
          if((data.description === Description.Customer_Charge || data.description === Description.Customer_own || data.description === Description.Leasehold || data.description === Description.Customer_medical || data.description === Description.application || data.description === Description.Customer_home || data.description === Description.Customer_reference || data.description === Description.Customer_Agreement || data.description === Description.Customer_card || data.description === Description.Customer_them || data.description === Description.Change_subletting || data.description === Description.access))
          {
            if(data.availableToCreateFrom.includes(caseCreatedBy) || data.availableToCreateFrom.includes(caseCreatedBy) ){
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);
        }
        }
        else {
          caseTypeData.push({
            description: data.description,
            keywords: data.keywords,
          });
          caseDescriptionArray.push(data.description);
        }
      }
      });
      setMappingData(caseTypeData);

      dispatch({ type: CASE_CREATION_MASTER_DATA, payload: actualRefData });
      dispatch({
        type: CASE_DESCRIPTION_DATA_INFO,
        payload: caseDescriptionArray,
      });
    } catch (e) {
      console.log(e);
      props.history.push("/GenericError");
    }
    setIsLoading(false);
    setUserInput(defaultSelection);
    setInputValue(defaultSelection);
  };

  useEffect(() => {
    if (defaultSelection) {
      setUserInput(defaultSelection);
      setInputValue(defaultSelection);
    }
    getAPIMasterData();
    return () => clearTimeout(timerId);
  }, []);

  const [fetchData, setFetchData] = useState<any>([]);

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    clearCaseDataFromRedux();
    if(backValue == BackValue.Customer360View){
      dispatch(backToCaseAsset(true));
      props.history.push("/CaseAsset")
    }
    else if (backToCaseSelectCustomer != null && backToCaseSelectCustomer != undefined && backToCaseSelectCustomer == true) {
      props.history.push("/SelectCustomer");
    }
    else if (assetDataDetails?.assetType !== "Property" || (assetDataDetails?.assetType === "Property" && tenancyCount===1 )) {
      if (backValue == BackValue.Customer360View) {
        props.history.push("/Customer360View");
      } else if (backValue == BackValue.Asset360View) {
        props.history.push("/Asset360View");
      } else if (backValue == BackValue.WorkOrder360View) {
        props.history.push("/WorkOrder360View");
      }else if (backValue == BackValue.Case360View) {
        props.history.push("/Case360View");
      }
    }
  };

  const exitButtonHandler = () => {
    clearCaseDataFromRedux();
    dispatch(backToCaseAsset(false));

    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    } else if (backValue == BackValue.Case360View) {
      props.history.push("/Case360View");
    }
  };

  const onContinueHandler = () => {
    dispatch(comingFromCase(true))
    if (inputValue === "") {
      setnoCaseTypeSelected(false);
      setWarningAlert("Case_Warning");
    } else {
      dispatch({ type: CASE_COUNTER, payload: 0 });
      props.history.push("/CaseQuestionnaire");
    }
  };

  const suggestionClickHandler = (e: any, value: any) => {
    if (value) {
      if (matchcaseTypeInputs.test(value)) {
        if (value.indexOf(" ") !== 0) {
          getDescriptionArr.filter((s) => {
            const matchingVal =
              s.includes(value.charAt(0).toUpperCase().trim() + value.slice(1)) ||
              s.includes(value.trim()) ||
              s.toLowerCase() === (value.toLowerCase().trim()) ||
              s.includes(value.charAt(0).toLowerCase().trim() + value.slice(1));
            if (matchingVal) {
              caseDescriptionArray.push(s);
              let MatcherVal = caseDescriptionArray.sort().filter((c, index) => {
                return caseDescriptionArray.indexOf(c) === index;
              });
              setFetchData(MatcherVal);
              setWarningAlert("");
            } else if (caseDescriptionArray?.length <= 0) {
              setFetchData([]);
              setnoCaseTypeSelected(true);
              setWarningAlert("OPC005b");
            }
          });
        } else {
          setFetchData([]);
          setnoCaseTypeSelected(true);
          setWarningAlert("OPC005b");
        }
      } else {
        setFetchData([]);
        setnoCaseTypeSelected(true);
        setWarningAlert("OPC005b");
      }

      setUserInput(value);

      // === Validating keywords here ====
      mappingData?.map((data, index: number) => {
        data.keywords?.map((keywords: string) => {
          keywords?.match(/\b\w+?\b/g)?.map((keyword: any) => {
            if (value.indexOf(" ") !== 0) {
              suggesstionArray = value.split(" ");
              for (const matchValue of suggesstionArray) {
                if (matchValue.replace(/\s/g, '').length !== 0) {
                  if (keyword.trim().toLowerCase().includes(matchValue.trim().toLowerCase()) || keyword.trim() === matchValue.trim()) {
                    keywordPosArr[index] = keywordPosArr[index] + 1;
                    if (keywordPosArr[index] > 2) {
                      caseDescriptionArray.splice(1, 0, data.description);
                    }
                    if (keywordPosArr[index] > 1) {
                      caseDescriptionArray.unshift(data.description);
                    } else {
                      caseDescriptionArray.push(data.description);
                    }

                    let uniqueMatcher = caseDescriptionArray.filter(
                      (c, index) => {
                        return caseDescriptionArray.indexOf(c) === index;
                      }
                    );

                    if (uniqueMatcher) {
                      setFetchData(uniqueMatcher);
                      setWarningAlert("");
                    } else {
                      setFetchData([]);
                      setWarningAlert("Case_Warning");
                    }

                  }
                }
              }
            }
          });
        });
      });
    } else {
      setUserInput("");
      setWarningAlert("");
      setFetchData([]);
    }
    setInputValue("");
  };

  const inputTextChangeHandler = (event: any, newValue: any) => {
    if (newValue) {
      if (newValue !== defaultSelection) {
        dispatch({ type: CASE_CREATION_DESCRIPTION, payload: newValue });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
        dispatch({ type: CASE_CREATION_QUESTIONS, payload: [] });
        dispatch({ type: CASE_EDIT, payload: true });
        dispatch({ type: CASE_COUNTER, payload: 0 });
      }
      setWarningAlert("");
      setInputValue(newValue);
      dispatch(clearMediaFiles([]));
      dispatch(userChosenUploadedMediaIdentifiers([]))
    } else {
      setWarningAlert("Case_Warning");
    }
  };

  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="caseType-container" role="main">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>

        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <div className="sm:mx-1">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
                {t("Case_Type_Title")}
              </h1>
            </div>

            <p className="div-txt-newline text-base my-0 global-txt" id="repreason-p">
              {t("OPC004a")}
            </p>
            <br />

            <Autocomplete
              className="pt-01 width-css global-txt pb-4 border-color"
              data-testid="high-level-case"
              value={userInput}
              onChange={inputTextChangeHandler}
              inputValue={userInput}
              onInputChange={suggestionClickHandler}
              id="high-level-case"
              freeSolo
              disableClearable
              filterOptions={(options) => fetchData}
              options={fetchData}
              size="small"
              renderInput={(params) => (
                <label className="w-full">
                  {window.innerWidth <= 820 ? <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.code === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                    multiline={true}
                    rows={1}
                    size="small"
                    margin="normal"
                    id="case-text"
                    className="high-level-input pt-01 global-txt "
                    placeholder={t("Case_Type_Placeholder")}
                    data-testid="autocomplete-input"
                  /> : <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}

                    margin="normal"
                    id="case-text"
                    className="high-level-input pt-01 global-txt"
                    placeholder={t("Case_Type_Placeholder")}
                    data-testid="autocomplete-input"
                  />}
                </label>
              )}
            />

            {warningAlert && (
              <div
                data-testid="alert-warning"
                className="error mb-8"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      {" "}
                      {t(warningAlert)}
                      {" "}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* === End alert ==== */}


            <div
              className="flex justify-between items-center pb-4 md:pb-8"
              id="case-btn"
            >
              <ExitModalContent
                id="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
              />
              <Button
                id="btn_case"
                type="button"
                data-testid="btncontinue"
                name="btncontinue"
                onClick={onContinueHandler}
                placeholder="btncontinue"
                value="Continue"
                className="button-class"
              >
                {t("continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
};

export default CaseType;
