export class RepairDetails {
  public premisesAddress?: any = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    addressLine5: '',
    postcode: '',
  }
  public customerName?: any = {
    title: '',
    givenName: '',
    surname: '',
  }
  public repairLocationId: string = ''
  public description: string = ''
  public isEmergency: boolean = false
  public premisesTypeId: string = ''
  public additionalInformation: string = ''
  public premisesUniqueReference: string = ''
  public sorCode?: string
  public completedStep?: number
  public assetIdentifier: string = ''
 
}

export class CreatedRepair {
  public chargeCustomer?: boolean = false
  public repairCauseId?: number = 0
  public riskToHumanLife?: boolean = false
  public crimeReferenceNumber?: string | null = null 
  public completedStep?: number
}

export class RepairContact {
  public id: string = ''
  public reportedById?: string
  public notifyAll?: boolean
  public reporterContactDetails?: {
    name: null
    contactNumber: null
    organisation: null
    reporterToBeContacted: true
  }
  public staffContactEmails?: string[]
  public reportingMethodId?: string
}

export enum RepairReporterId {
  Customer = 1,
  FriendOrfamily = 2,
  MemberOfStaff = 3,
  SupportWorker = 4,
  Other = 5,
}

export enum RepairPriority {
  NextAvailable = "Next Available",
  Emergency = "Emergency",
  EmergencyOOH = "Emergency (Out of hours)"  
}