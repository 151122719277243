import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { ExtendedRepairNote, RepairNote } from "../../models/Repair/Repair";
import { useTranslation } from "react-i18next";
import { formatContent } from "../../utils/formatting";
import { trackEvent } from "../../appInsights/TelemetryService";


interface WorkOrderNotesProps extends RouteComponentProps<any> {
    repairNotesList: RepairNote[]
}

const WorkOrderNotesComponent: React.FC<WorkOrderNotesProps> = (props) => {
    const { t } = useTranslation();   
    const [repairNotesListIncludingTitle, setRepairNotesListIncludingTitle] = useState([]);


    useEffect(() => {
        (async () => {
            trackEvent("WorkOrderNotes");
            const repairNoteListWithTitle:any = [];
            props.repairNotesList?.map((note) => {
                let createdBy = "Colleague";
                if (note.author.toLowerCase().startsWith("rep") || (note.type.toLowerCase() == "contractor" || note.type.toLowerCase().indexOf("out of hours") > -1)) {
                    if (note.author.toLowerCase().startsWith("repcon") || note.type.toLowerCase() == "contractor") {
                        createdBy = "Contractor";
                    }
                    else if (note.author.toLowerCase().startsWith("repcal") || note.author.toLowerCase().startsWith("repcap") || note.type.toLowerCase().indexOf("out of hours") > -1) {
                        createdBy = "Out of Hours Provider";
                    }
                }


                const repairNote: ExtendedRepairNote = {
                    type : note.type + " Note",
                    creationDate: note.creationDate,
                    text: note.text.replace(" Created by: ",""),
                    author: note.author,
                    createdBy: createdBy,
                    title:""
                }
                repairNote.title = GetNoteTitle(repairNote);
                repairNoteListWithTitle.push(repairNote);
            });
            setRepairNotesListIncludingTitle(repairNoteListWithTitle);
        })();
    }, []);

    const GetNoteTitle = (repairNote: ExtendedRepairNote) => {
        let title = "";
        if (repairNote.type == "")
        { title = repairNote.createdBy == "Contractor" ? "Contractor Note" : "Other Note - " + repairNote.author; }
        else {
            if (repairNote.createdBy == "Colleague") {
                title = repairNote.type + " - " + repairNote.author;
            }
            else
            { title = repairNote.createdBy == "Out of Hours Provider" ? "Out of Hours Note" : "Contractor Note"; }
        }
        return title;
    }

    return (        
                   <>
            {repairNotesListIncludingTitle && repairNotesListIncludingTitle.length > 0 ?
                repairNotesListIncludingTitle.map(
                    (eachRepairUpdate: ExtendedRepairNote, key: any) => {
                        return (
                            <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                            <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
                                                <div className="flex flex-row justify-between items-center">
                                        <p className="my-0 text-base"><strong>{formatContent(eachRepairUpdate.title)}</strong></p>
                                                </div>
                                            </div>

                                            <div className="px-4 py-2 grid grid-cols-1 mt-3">
                                                <div className="mb-4">
                                                    <strong>{t("date")}</strong><br />  {moment(eachRepairUpdate.creationDate).format(
                                                        "DD-MM-YYYY, h:mm:ss A"
                                                    )}
                                                </div>
                                                <div className="mb-4 workorder-notes-text">
                                        <strong>{t("comment")}</strong><br />{formatContent(eachRepairUpdate.text)}
                                                </div>

                                            </div>
                                        </div>


                        )
                    }
                ) : 
                (
                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                       <p className=" text-left my-2 ml-4 global-txt text-wrap">
                            { t("No_WorkOrder_Note")}
                        </p>
                    </div>
                            )
                            }
                   </>
           )
}

export default WorkOrderNotesComponent;