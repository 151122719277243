import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";

interface NoSuitableAppointmentProps extends RouteComponentProps<any> {}
const NoSuitableAppointment: React.FC<NoSuitableAppointmentProps> = (props) => {
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const { t } = useTranslation(); 

  const callPageHandler = () => {
       props.history.push("/NoSuitableAppointmentConfirmation");
  };
  const callfinishcreatePageHandler = () => {
    props.history.push("/AppointmentScheduler");
  }; 
  useEffect(() => {
    if (customerID === null || customerID === undefined || customerID === "") {
      props.history.push("/LandingView");
      return;
    }
  }, []);

  return (
    <div className="container sm:px-2 ctn-fht" id="noAppoint-container" role="main">
      <div id="noAppoint-main-div" className="py-2 my-3">
      <p id="noAppoint-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("CPR054")}</p> 
      </div>    

      <div
        id="noAppoint-main-btn"
        className="button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-3 btn-left"
      >
        <a
          id="noAppoint-btn1"
          data-testid="finishandcreate"
          onClick={callfinishcreatePageHandler}
          className="float-left finish"
        >
          <span className="mr-2 ml-0 cursor-pointer sm:ml-nosuit">{t("Check_Appointments_Button")}</span>
          <i className="fas fa-angle-right relativetext-white pointer-events-none mt-1"></i>
        </a>
       
        <Button
          id="noAppoint-btn2"
          data-testid="finshrepair"
          onClick={callPageHandler}
          className="button-class sm:mt-4"
        >
          {t("continue")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
     
      </div>
    </div>
  );
};
export default NoSuitableAppointment;
