import { RouteComponentProps } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { CustomerStatus, TenancyType } from "../../models/Customer/CustomerFilter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isNullOrUndefined } from "../../utils/formatting";
import { SearchType } from "../../models/shared/Enum";
import { PropertyType } from "../../models/Property/PropertyFilter";
import { StatusFilter } from "../../models/WorksOrders/StatusFilter";
import { PriorityFilter } from "../../models/WorksOrders/PriorityFilter";
import { WorkOrderPropertyFilter } from "../../models/WorksOrders/WorkOrderPropertyFilter"
import { useDispatch, useSelector } from "react-redux";
import { RelatedPremisesType } from "../../models/Property/AdminUnits";
import { isSearch } from "../../ducks/redux/actions/userChoices";
import { CaseStatusFilter } from "../../models/Case/CaseStatusFilter"
import { CasePriorityFilter } from "../../models/Case/CasePriorityFilter";
import { CaseAssociatedTypeFilter } from "../../models/Case/CaseAssociatedTypeFilter";

interface IFilterComponent extends RouteComponentProps<any> {
  setFilter?: any;
  searchType?: any;
  handleClose?: any;
  selectedCustomerStatus?: any;
  selectedTenencyType?: any;
  selectedPropertyType?: any;
  selectedWorkOrderPropertyType?: any;
  selectedStatusWorkOrderType?: any;
  selectedPriorityWorkOrder?: any;
  selectedCustomerWorkOrderPropertyType?: any;
  selectedCasePropertyType?: any;
  selectedCaseStatusFilter?: any
  selectedPriorityCase?: any;
  selectedAssociatedType?: any;
  customerAssociatedTypeRequired?: any;
  selectedIsCaseAssignedToMe?: any;
}

const FilterComponenent: React.FC<IFilterComponent> = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [customerStatus, setCustomerStatus] = useState(isNullOrUndefined(props.selectedCustomerStatus) ? '' : props.selectedCustomerStatus);
  const [tenancyType, setTenancyType] = useState(isNullOrUndefined(props.selectedTenencyType) ? '' : props.selectedTenencyType);
  const [propertyType, setPropertyType] = useState(isNullOrUndefined(props.selectedPropertyType) ? '' : props.selectedPropertyType);
  const [workOrderPropertyType, setWorkOrderPropertyType] = useState(isNullOrUndefined(props.selectedWorkOrderPropertyType) ? '' : props.selectedWorkOrderPropertyType);
  const [statusWorkOrderType, setStatusWorkOrderType] = useState(isNullOrUndefined(props.selectedStatusWorkOrderType) ? '' : props.selectedStatusWorkOrderType);
  const [priorityWorkOrder, setPriorityWorkOrder] = useState(isNullOrUndefined(props.selectedPriorityWorkOrder) ? '' : props.selectedPriorityWorkOrder)
  const [customerWorkOrderPropertyType, setCustomerWorkOrderPropertyType] = useState(isNullOrUndefined(props.selectedCustomerWorkOrderPropertyType) ? '' : props.selectedCustomerWorkOrderPropertyType);
  const [casePropertyType, setCasePropertyType] = useState(isNullOrUndefined(props.selectedCasePropertyType) ? '' : props.selectedCasePropertyType);
  const [caseStatusFilterType, setCaseStatusFilterType] = useState(isNullOrUndefined(props.selectedCaseStatusFilter) ? '' : props.selectedCaseStatusFilter);
  const [priorityCase, setPriorityCase] = useState(isNullOrUndefined(props.selectedPriorityCase) ? '' : props.selectedPriorityCase)
  const [caseAssociatedType, setCaseAssociatedType] = useState(isNullOrUndefined(props.selectedAssociatedType) ? '' : props.selectedAssociatedType);
  const caseCustomerFilterRequired = isNullOrUndefined(props.customerAssociatedTypeRequired) ? false : props.customerAssociatedTypeRequired
  const [isCaseAssignedToMe, setIsCaseAssignedToMe] = useState(isNullOrUndefined(props.selectedIsCaseAssignedToMe) ? false : props.selectedIsCaseAssignedToMe);
  const { t } = useTranslation();

  const premisesTypeList = useSelector(
    (state: any) => state.userChoicesReducer?.premisesTypeList
  );
  const premisesList: RelatedPremisesType[] = premisesTypeList;
  const handleChangeTenancyType = (e: { target: { value: any } }) => {
    setTenancyType(e.target.value);
  }

  const handleChangeCustomerStatus = (e: { target: { value: any } }) => {
    setCustomerStatus(e.target.value);
  }
  const handleChangeStatusWorkOrderType = (e: { target: { value: any } }) => {
    setStatusWorkOrderType(e.target.value);
  }

  const handleFilterSubmit = () => {
    props.setFilter(customerStatus, tenancyType, propertyType, workOrderPropertyType, statusWorkOrderType, priorityWorkOrder, customerWorkOrderPropertyType, casePropertyType, caseStatusFilterType, isCaseAssignedToMe, priorityCase, caseAssociatedType);
  };

  const handleClose = () => {
    props.handleClose();
    dispatch(isSearch(false));
    setShow(false);
  }
  const handleChangePropertyType = (e: { target: { value: any } }) => {

    setPropertyType(e.target.value);
  }
  const handlePriorityWorkOrder = (e: { target: { value: any } }) => {

    setPriorityWorkOrder(e.target.value);
  }
  const handleChangeWorkOrderPropertyType = (e: { target: { value: any } }) => {

    setWorkOrderPropertyType(e.target.value);
  }
  const handleChangeCustomerWorkOrderPropertyType = (e: { target: { value: any } }) => {

    setCustomerWorkOrderPropertyType(e.target.value);
  }
  const handleChangeStatusCaseAssignedToMe = (e: { target: { checked: any } }) => {
    setIsCaseAssignedToMe(e.target.checked);
  }
  const handleChangeCasePropertyType = (e: { target: { value: any } }) => {

    setCasePropertyType(e.target.value);
  }
  const handleChangeCaseStatusFilter = (e: { target: { value: any } }) => {

    setCaseStatusFilterType(e.target.value);
  }
  const handlePriorityCase = (e: { target: { value: any } }) => {

    setPriorityCase(e.target.value);
  }
  const handleChangeCaseAssociatedType = (e: { target: { value: any } }) => {

    setCaseAssociatedType(e.target.value);
  }
  const RenderFilterBody = () => {
    let body: any;

    switch (props.searchType) {
      case SearchType.Customer:
        body = (<div>
          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("customer_status")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="customer-status_filter" value={customerStatus}
                id="customer-status_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangeCustomerStatus} >
                {CustomerStatus.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("tenancy_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="customer_tenancy_type_filter" value={tenancyType}
                id="customer_tenancy_type_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handleChangeTenancyType} >
                {TenancyType.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

        </div>
        )
        break;

      case SearchType.Property:
        body = (<div>
          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("property_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="property-status_filter" value={propertyType}
                id="customer-status_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangePropertyType} >
                {PropertyType.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

        </div>
        )
        break;
      case SearchType.WorkOrder:
        body = (<div>
          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("property_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_property_status_filter" value={workOrderPropertyType}
                id="property-status_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangeWorkOrderPropertyType} >
                {WorkOrderPropertyFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("status_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_status_filter" value={statusWorkOrderType}
                id="workorder_status_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handleChangeStatusWorkOrderType} >
                {StatusFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("Priority_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_priority_filter" value={priorityWorkOrder}
                id="workorder_priority_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handlePriorityWorkOrder} >
                {PriorityFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

        </div>
        )
        break;
      case SearchType.CustomerWorkOrder:
        body = (<div>
          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("property_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_property_status_filter" value={customerWorkOrderPropertyType}
                id="property-status_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangeCustomerWorkOrderPropertyType} >
                <option>Select</option>
                {premisesList.map((e, key) => {
                  return (
                    <option key={key} value={e.premisesType}> {e.premisesType} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("status_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_status_filter" value={statusWorkOrderType}
                id="workorder_status_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handleChangeStatusWorkOrderType} >
                {StatusFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("Priority_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="workorder_priority_filter" value={priorityWorkOrder}
                id="workorder_priority_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handlePriorityWorkOrder} >
                {PriorityFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

        </div>
        )
        break;

      case SearchType.Servicerequest:
        body = (<div>
          <div className="col-md-12 ">
            <label className="w-full inline-flex my-4">
              <input type="checkbox" data-testid="cases_assigned_filter" checked={isCaseAssignedToMe} id="cases_assigned_filter" className="block mt-1 border border-grey" onChange={handleChangeStatusCaseAssignedToMe} />
              <div className="ml-2 ">
                <strong>{t("cases_assigned_txt")}</strong>
              </div>
            </label>
          </div>

          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("property_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="case_property_filter" value={casePropertyType}
                id="case_property_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangeCasePropertyType} >
                {WorkOrderPropertyFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("status_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="case_status_filter" value={caseStatusFilterType}
                id="case_status_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handleChangeCaseStatusFilter} >
                {CaseStatusFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        )
        break;
      case SearchType.CaseList:
        body = (<div>
          <div className="col-md-12 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{caseCustomerFilterRequired == true ? t("Associated_Type") : t("property_type")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="case_associatedType_filter" value={caseAssociatedType}
                id="case_associatedType_filter" className="block w-full mt-1 rounded-lg border border-grey "
                onChange={handleChangeCaseAssociatedType} >
                {caseCustomerFilterRequired == true ? CaseAssociatedTypeFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                }) : WorkOrderPropertyFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("status_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="case_status_filter" value={caseStatusFilterType}
                id="case_status_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handleChangeCaseStatusFilter} >
                {CaseStatusFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="col-md-12 mt-8 ">
            <div className="pr-12 mr-8 wd-25 ">
              <strong>{t("Priority_txt")}</strong>
            </div>
            <label className="w-full">
              <select data-testid="case_priority_filter" value={priorityCase}
                id="case_priority_filter" className="block w-full mt-1 rounded-lg border border-grey"

                onChange={handlePriorityCase} >
                {CasePriorityFilter.map((e, key) => {
                  return (
                    <option key={key} value={e.value}> {e.key} </option>
                  );
                })}
              </select>
            </label>
          </div>

        </div>
        )
        break;
      default:
        body = null;
    }

    return body;
  }

  return (
    <Offcanvas placement="end" show={show} onHide={handleClose}>
      <div id="mySidenav" className=" shadow-lg">
        <a onClick={handleClose} className="closebtn pl-2 pt-2 cursor-pointer a-style"
          data-testid="handle-close">
          &times;
        </a>
        <div className="container ">
          <div className="row px-4 global-txt">
            <Offcanvas.Header>
              <div className="col-md-12 mt-8">
                <h2 className="text-teal border-b border-grey">
                  {" "}
                  {t("Filter")}{" "}
                </h2>
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {<RenderFilterBody />}
              <div className="col-md-12 mt-8 ">
                <button
                  onClick={handleFilterSubmit}
                  data-testid="handle-filter-button"
                >
                  {t("search_txt")}
                  <i className="fas fa-search relative left-5 text-white pointer-events-none mt-1 float-right"></i>
                </button>
              </div>
            </Offcanvas.Body>
          </div>
        </div>
      </div>
    </Offcanvas>
  )
}

export default FilterComponenent