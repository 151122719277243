import React, { useEffect, useState } from "react";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import CustomerWorkOrderResult from "../CustomerWorkOrderResult";

interface AssetWorkOrderResultProps extends RouteComponentProps<any> {
}
const AssetWorkOrderResult: React.FC<AssetWorkOrderResultProps> = (props) => {
    
    return (
        <CustomerWorkOrderResult {...props} isVisibleForAsset={true} />)
}

export default AssetWorkOrderResult;