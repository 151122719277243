interface ApiErrorParams {
  status: number
  title?: string
}

export class ApiError extends Error {
  private static genericMessages: Record<number | 'unknown', string> = {
    400: 'Bad Request',
    401: 'Unauthorised',
    403: 'Forbidden',
    404: 'Not Found',
    409: 'Conflict',
    500: 'Server Error',
    unknown: 'Unknown Error',
  }

  public status: number
  public messages: any

  constructor(params: ApiErrorParams, messages?: any, ...rest: any) {
    super(...rest)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.status = params.status
    this.messages = messages || {}

    // TODO improve this
    const title = ApiError.genericMessages[params.status] ?? ApiError.genericMessages.unknown
    this.message = `${this.status} ${title}`
  }
}
