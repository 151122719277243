
  export default class PropertyResponse{
    constructor(propertyResponse: any) {
        this.assets = !!propertyResponse ? propertyResponse.assets : []
        this.totalCount = !!propertyResponse ? propertyResponse.totalCount : 0        
      }
    public assets: Assets[] = [];
    public totalCount: number = 0;
  }
  export class Assets {  
    public addresses?: Address | null 
    public uprn?: string;
    public assetType?: string;
    public id?: string;
  }
  export class Address{
   public postalAddresses: PostalAddress[] = []
  }
  export class PostalAddress{
    public addressLine1?: string;
    public addressLine2?: string;
    public addressLine3?: string;
    public addressLine4?: string;
    public addressLine5?: string;
    public postcode?: string;
    public id?: string;
  }
  
