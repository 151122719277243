import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import ExitModalContent from "../../Modal/ExitModalContent";
import Button from "../../../Button/Button";
import { clearReferralInformation, comingFromReferralPage, storeReferralAdditionalDetails } from "../../../ducks/redux/actions/FinancialReferral";


interface AssetDetailCardProps extends RouteComponentProps<any> {
  
}

const AdditionalReferralDetails: React.FC<AssetDetailCardProps> = (props:any) => {
  var specials = /[&*%!<>]/;
  const { t } = useTranslation();
  
  const dispatch = useDispatch()
  
  const [error, setError] = useState("");
  const data = useSelector((state:any) => state.financialReferralReducer?.referralDetails)
  const reasonData = useSelector((state:any) => state.financialReferralReducer?.financialReferralData)
  
  let [message, setMessage] =  useState(data || "");
  const [countCharacter, setCountCharacter] = useState(data?.length || 0);

  
  const backButtonHandler = () => {
    props.history.push('/referralreason');   
  }

  useEffect(()=>{
    if(reasonData?.ReferralReason?.length === 0){
      props.history.push("/referralreason")
    }
  },[])
 
  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/Customer360View');
  }

  const validateComposedSMS = (e:any) => {
    if(e.target.value.trim() !== ""){
        setMessage(e.target.value)
    }else {
       // setError("SMS_BC008")
    }
    
  }

  const onTextChangeHandler = (e:any) => { 
    setError("")
    let  test = e.target.value.replace(/((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/g,'');
  if (specials.test(test)) {
      setError("SMS_BC009")
      e.target.value = e.target.value.replace(/[&*%!<>]/g,'');
    }else {
        setCountCharacter(e.target.value.length)
        setTimeout(()=>{
            setMessage(e.target.value)
        }, 1000)
    }
  }

  const onContinueHandler = async() => {
    if(message !== "" ) {
        if(countCharacter<=1500) {        
          dispatch(storeReferralAdditionalDetails(message))
          dispatch(comingFromReferralPage(true))
          props.history.push("/ContactDetails")   
        }  
    }else {
        setError("Financial_FI005a")
    }

    
  }
  
  return (
    <div className="container sm:mx-1 ctn-fht">
        {/* Back Button */}
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 px-2" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler} >
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>

        
        {/* Main Page Content */}
        <div className="sm:mx-1">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
              {t("Financial_Additional_Referral")}
              </h1>
            </div>
           
        </div>
        <p className="py-2 flex justify-between mt-2">
            {t("Financial_Additional_Point1")}
         </p>
         <p className="py-2 flex justify-between mt-2">
            {t("Financial_Additional_Point2")}
         </p>
      
      
        <div className="contentPosition py-2 px-2 ">
            <textarea name="postSMS" rows={8} cols={40} spellCheck="true"
             data-testid='textarea'
             className="w-alert text-sm md:text-base h-28" aria-label="composeSMS" 
             maxLength={1500} placeholder={t("Financial_FI003")}
             defaultValue={message? message :""}
             onChange={onTextChangeHandler } onBlur={validateComposedSMS} />    
             
        </div>
    
        <p className="characterCount py-2">{`${countCharacter}/1500`}</p>
         {/* Yellow Alert */}
         { (error !== "" || countCharacter >=1500) && <div    
                data-testid="alert-warning"
                className="error mb-8 py-4"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-alert px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      { countCharacter>= 1500 && t("Financial_FI009")}
                      {error && t(`${error}`)}
                      {" "}
                      
                    </p>
                  </div>
                </div>
         </div>}
        {/* Exit and Send SMS button */}
        <div
              className="flex justify-between items-center pb-4 md:pb-8 px-2"
              id="sms-btn"
            >
              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg="Return to my Referral"
              />
              <Button
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={onContinueHandler}
                placeholder="send-sms"
                value="Continue"
                className="button-class"
                disabled = { countCharacter > 1500 }
              >
                {t("Continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
        </div>
       
    </div>    

  )
};
export default AdditionalReferralDetails;