import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/combinedReducers";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

const persistConfig = {
	key: 'root',
	storage:storageSession
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
	let store = createStore(
		persistedReducer,
		composeEnhancers(applyMiddleware(thunkMiddleware))
	);	
	let persistorstore = persistStore(store)
	return { store, persistorstore }
}