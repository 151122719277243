export enum heatingAttr {
  ELEC = "Night Storage Economy 7 Heating",
  FGCH = "Full Gas Central Heating",
  GRP = "Communal/Group Heating",
  ELECBOIL = "Electric Boiler",
  HEATELECNONE = "No Electrical Heating At Property",
  NSE7 = "Night Storage Economy 7",
  PUMPS = "Heat Pumps",
  UNKNOWN = "Unknown Electric Heating Source",
  UNVENTED = "Electric Heating Source",
  CARCASS = "Carcass",
  COMGAS = "Communal Gas Boiler (Commercial Contract)",
  DOMGAS = "Communal Gas Boiler (Domestic Contract)",
  ELECBOLIER = "Electric Boiler",
  HEATONLY = "Gas Heating Only",
  NONE = "NONE",
  PGCH = "Partial Gas Central Heating",
  HEATGASUNKNOWN = "Unknown Gas Heating",
  HEATOILOIL = "No Oil Heating At Property",
  OIL = "Oil Heating Source"
}

export const getHeatingType = (heatingType: string, heatingValue: string) :any => {
    let updatedValue='';
    if(heatingType!=="UNKNOWN" && heatingValue!=="UNKNOWN"){
        if(heatingValue==="ELEC"){
          updatedValue=heatingAttr.ELEC;
        }
        else if(heatingValue==="FGCH"){
          updatedValue=heatingAttr.FGCH;
        }
        else if(heatingValue==="GRP"){
          updatedValue=heatingAttr.GRP;
        }
        else if(heatingValue==="ELECBOIL"){
          updatedValue=heatingAttr.ELECBOIL;
        }
        else if(heatingType==="HEATELEC" && heatingValue==="NONE"){
          updatedValue=heatingAttr.HEATELECNONE;
        }
        else if(heatingValue==="NSE7"){
          updatedValue=heatingAttr.NSE7;
        }
        else if(heatingValue==="PUMPS"){
          updatedValue=heatingAttr.PUMPS;
        }
        else if(heatingValue==="UNVENTED"){
          updatedValue=heatingAttr.UNVENTED;
        }
        else if(heatingValue==="CARCASS"){
          updatedValue=heatingAttr.CARCASS;
        }
        else if(heatingValue==="COMGAS"){
          updatedValue=heatingAttr.COMGAS;
        }
        else if(heatingValue==="DOMGAS"){
          updatedValue=heatingAttr.DOMGAS;
        }
        else if(heatingValue==="ELECBOLIER"){
          updatedValue=heatingAttr.ELECBOLIER;
        }
        else if(heatingType==="HEATOIL" && heatingValue==="NONE"){
          updatedValue=heatingAttr.HEATOILOIL;
        }
        else if(heatingValue==="OIL"){
          updatedValue=heatingAttr.OIL;
        }
       }  
        else if(heatingType!=="UNKNOWN" && heatingValue==="UNKNOWN"){
          if(heatingType==="HEATELEC" && heatingValue==="UNKNOWN"){
            updatedValue=heatingAttr.UNKNOWN;
          }
          else if(heatingType==="HEATGAS" && heatingValue==="UNKNOWN"){
            updatedValue=heatingAttr.HEATGASUNKNOWN;
          }
          else{
            updatedValue=heatingType;
          }
        }
        else if(heatingType==="UNKNOWN" && heatingValue!=="UNKNOWN"){
        updatedValue=heatingValue;
        }
        else if(heatingType==="UNKNOWN" && heatingValue==="UNKNOWN"){
        updatedValue="";
        }
    return updatedValue;
  }