import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import {
  formatDateWithFromCalendar,
} from "../../utils/formatting";
import { trackEvent } from "../../appInsights/TelemetryService";
import CustomerCardDetails from "../../models/Customer/CustomerCardDetail";
import { useDispatch, useSelector } from "react-redux";

interface AdditionalNeedAlertProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerCardDetails[];
}
const AdditionalNeedAlert: React.FC<AdditionalNeedAlertProps> = (props) => {
  const { t } = useTranslation();
  const [preferenceCount, setPreferenceCount] = useState(0);
  const [isActive, setIsActive] = useState(false);
  let customerAdditionalAlertData = useSelector(
    (state: any) => state?.userChoicesReducer?.customerAdditionalAlertData
  );
  useEffect(() => {
    trackEvent("AdditionalNeedAlert");
    if (customerAdditionalAlertData?.length != undefined)
      setPreferenceCount(customerAdditionalAlertData.length);
  }, [customerAdditionalAlertData]);

  const dispatch = useDispatch();

  const callPerferenceHandler = () => {
    if (isActive == false) {
      setIsActive(true);
    } else setIsActive(false);
  };
  return (
    <div>
      {preferenceCount === 1 ? (
        <div
          className="flex items-center w-full px-4 py-2 cursor-pointer bg-yellow rounded-lg"
          data-testid="singlePreferenceHandler"
          onClick={callPerferenceHandler}
        >
          <div className="px-4 pr-8">
            <i className="text-brick-100 w-6 h-6 fas fa-exclamation-triangle"></i>
          </div>
          <div className="w-full text-left my-2">
            <strong>
            {customerAdditionalAlertData[0].preference.value}
             </strong>
          </div>
          <div className="plus-sign mt-10">{isActive ? "⨯" : "+"}</div>
        </div>
      ) : preferenceCount > 1 ? (
        <div
          className="flex items-center w-full px-4 py-2 cursor-pointer bg-yellow rounded-lg"
          data-testid="multiplePreferenceHandler"
          onClick={callPerferenceHandler}
        >
          <div className="px-4 pr-8">
            <i className="text-brick-100 w-6 h-6 fas fa-exclamation-triangle"></i>
          </div>

          <div className="w-full text-left my-2">
            <strong>
            {t("Caution_alert_1") +
              customerAdditionalAlertData.length +
             t("additionalNeed")}
              </strong>
          </div>
          <div className="plus-sign mt-10">{isActive ? "⨯" : "+"}</div>
        </div>
      ) : null}
      {isActive && preferenceCount == 1 ? (
        <div className="border-yellow-width-20 bg-white rounded-lg mt-4">
          <div className="px-4 grid grid-cols-1 mt-3">
            <div className="mb-4">
              <strong>{t("Alert_description")}</strong>
              <br />{" "}
              {t(
                customerAdditionalAlertData[0].preference.value
                  .replace(/ /g, "")
                  .toLowerCase()
              )}
            </div>
            <div className="mb-4">
              <strong>{t("Date_Creation")}</strong>
              <br />
              {formatDateWithFromCalendar(
                customerAdditionalAlertData[0].preference.createdDate
              )}
            </div>
          </div>
        </div>
      ) : isActive && preferenceCount > 1 ? (
        customerAdditionalAlertData.map(
          (preference: { preference: { value: string; createdDate: any } }) => (
            <div className="border-yellow-width-20 bg-white rounded-lg mt-4">
              <div className="bg-yellow px-4 py-2 rounded-t-lg">
                <div className="flex flex-row justify-between items-center">
                  <p className="my-0 ">
                    <strong>{preference.preference.value}</strong>
                  </p>
                </div>
              </div>

              <div className="px-4 py-2 grid grid-cols-1 mt-3">
                <div className="mb-2">
                  <strong>{t("Alert_description")}</strong>
                  <br />{" "}
                  {preference.preference.value != null &&
                  preference.preference.value != undefined
                    ? t(
                        preference.preference.value
                          .replace(/ /g, "")
                          .toLowerCase()
                      )
                    : null}
                </div>
                <div className="mb-2">
                  <strong>{t("Date_Creation")}</strong>
                  <br />
                  {formatDateWithFromCalendar(preference.preference.createdDate)}
                </div>
              </div>
            </div>
          )
        )
      ) : null}
    </div>
  );
};
export default AdditionalNeedAlert;
