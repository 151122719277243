import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import * as api from "../../api";
import { useSelector } from "react-redux";
import Customer from "../../models/Customer/Customer";
import { PremisesAddress } from "../../models/shared/Address/PremisesAddress";
import { addressToString, formatCustomerEditDateTime, formatDateTime, formatDateTimeSlash, formatDateWithFromCalendar } from "../../utils/formatting";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import moment from "moment";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
interface CustomerEditProfileProps extends RouteComponentProps<any> {
}
const CustomerEditProfile: React.FC<CustomerEditProfileProps> = (props) => {

  const { t } = useTranslation();
  const customerData = useSelector(
    (state: any) => state?.userChoicesReducer?.customersData
  );
  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const customerId = useSelector(
    (state: any) => state?.addresses?.customerid
  );
  const [customerContact, setCustomerContact] = useState<Customer>();
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerDOB, setCustomerDOB] = useState('');
  const [customerMobilePhone, setCustomerMobilePhone] = useState('');
  const [customerHomePhone, setCustomerHomePhone] = useState('');
  const [customerWorkPhone, setCustomerWorkPhone] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [homePhoneValid, setHomePhoneValid] = useState(false);
  const [workPhoneValid, setWorkPhoneValid] = useState(false);
  const [mobilePhoneValid, setMobilePhoneValid] = useState(false);
  const [eTag, seteTag] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFails, setIsFails] = useState(false);
  const [dobValidation, setDobValidation] = useState(false);
  const [dob, setDob] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      trackEvent("Customer Profile Card"); 
      const customerResponse: CustomerResponseDetails = customerData;
      const customerContactParamsEntry: Customer = new Customer();
      setCustomerName(customerResponse.person.name.givenName + ' ' + customerResponse.person.name.surname)
      customerContactParamsEntry.dateOfBirth = customerResponse?.person?.dateOfBirth || ""
      customerContactParamsEntry.customerType = customerResponse?.statuses?.find(
        (a) => a.type === "isCustomerActive"
      )?.value === true ? t("active_txt") : t("former_txt") || "";
      customerContactParamsEntry.workPhone =
        customerResponse?.addresses?.telecomsAddresses.find(
          (a) => a.telecomsAddressType === "workPhone"
        )?.number || "";
      customerContactParamsEntry.postalAddress = addressToString((customerResponse?.addresses?.postalAddresses[0] as PremisesAddress))
      customerContactParamsEntry.mobilePhone =
        customerResponse?.addresses.telecomsAddresses.find(
          (a) => a.telecomsAddressType === "mobilePhone"
        )?.number || "";
      customerContactParamsEntry.homePhone =
        customerResponse?.addresses.telecomsAddresses.find(
          (a) => a.telecomsAddressType === "homePhone"
        )?.number || "";
      customerContactParamsEntry.email =
        customerResponse?.addresses.digitalAddresses.find(
          (a) => a.digitalAddressType === "email"
        )?.address || "";
      setCustomerContact(customerContactParamsEntry);
      //sey default values
      
      setCustomerHomePhone(customerContactParamsEntry?.homePhone)
      setCustomerWorkPhone(customerContactParamsEntry?.workPhone)
      setCustomerMobilePhone(customerContactParamsEntry?.mobilePhone)
      setCustomerDOB(formatDateWithFromCalendar(customerContactParamsEntry?.dateOfBirth || ''));
      setDob(formatDateWithFromCalendar(customerContactParamsEntry?.dateOfBirth || ''));
      seteTag(customerResponse.eTag);
    })();
  }, [isActiveTenancy]);

  const updateCustomerCall = async () => {
    var date;
    if (customerDOB) {
      date = formatCustomerEditDateTime(customerDOB)
    }
    else {
      date = formatCustomerEditDateTime(customerDOB)
    }
    
    const allData = {
      emailAddress: customerEmail ? customerEmail : customerContact?.email,
      mobilePhone: customerMobilePhone ,
      homePhone: customerHomePhone,
      workPhone: customerWorkPhone,
      dateOfBirth: date
    }
    await api.putEditProfile(
      customerId,
      allData,
      eTag
    ).then(() => {
      setIsFails(false);
      setIsUpdate(true);
      const timerId = setTimeout(() => {
        props.history.push("/customer360View");
      }, 3000);
    }
    ).catch(e => {
      setIsFails(true);
    }
    )
  }
  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  const isValidNumber = (number: any) => {
    const re = /^[+0-9]+$/;
    return re.test(number);
  };
  const callSaveButton = () => {
    updateCustomerCall();
  }
  const callCancelButton = () => {
    props.history.push("/customer360View");
  }
  const emailChangeHandler = (e: any) => {
    if (isValidEmail(e.target.value)) {
      setValidEmail(false);
      setCustomerEmail(e.target.value);
    }
    else {
      setValidEmail(true);
    }

  }
  const mobilePhoneHandler = (e: any) => {
    if (e.target.value != "") {
      if (isValidNumber(e.target.value)) {
        setMobilePhoneValid(false);
        setCustomerMobilePhone(e.target.value);
      }
      else {
        setMobilePhoneValid(true);
      }
    }
    else {
      setCustomerMobilePhone(e.target.value);
    }

  }
  const homePhoneHandler = (e: any) => {
    
    if (e.target.value != "") {
      if (isValidNumber(e.target.value)) {
        setHomePhoneValid(false);
        setCustomerHomePhone(e.target.value);
      }
      else {
        setHomePhoneValid(true);
      }
    }
    else {
      setCustomerHomePhone(e.target.value)
    }

  }
  const workPhoneHandler = (e: any) => {
    if (e.target.value !== "") {
      if (isValidNumber(e.target.value)) {
        setWorkPhoneValid(false);
        setCustomerWorkPhone(e.target.value);
      }
      else {
        setWorkPhoneValid(true);
      }
    }
    else {
      setCustomerWorkPhone(e.target.value)
    }
  }
  const DOBHandler = (e: any) => {
    const todayDate = formatDateTime(moment(new Date()).toString())
    if (e.target.value > todayDate) {
      setDobValidation(true);
    }
    else {
      setDobValidation(false);
      setCustomerDOB(e.target.value)
    }
  }


  return (
    <div className="border-t border-grey pb-12 md:pb-12 lg:pb-12 bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 md:gap-8 mt-8 px-2">
          <div className="flex flex-row d-flex bd-highlight items-center">
            <h2 className="my-0 text-purple-100 bd-highlight sm-pl-1">Profile </h2>

          </div>
          {isUpdate ? <div data-testid="info-message" className="alert mb-8" role="alert" placeholder="alert">
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("Update_Successful_Message")}</p>
              </div>
            </div>
          </div> : null}
          {isFails ? <div data-testid="error-message" className="error mb-8" role="alert">
            <div className="w-full px-2 py-2 bg-red-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-circle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("Update_Fail_Message")}</p>
              </div>
            </div>
          </div> : null}
          <div className="relative px-4 pb-4 border border-grey bg-white shadow-lg rounded-lg">
            <div className=" py-4">
              <div className="w-full text-lg text-teal flex flex-col md:flex-row md:justify-between">
                <div className=" md:pb-0"><strong>{t("Edit_Customer_Details")}</strong></div>
                <div>
                  <a className="btn text-teal no-underline hover:underline mr-6 cursor-pointer" data-testid="cancelButton" onClick={callCancelButton}>
                    {t("cancel")}
                  </a>

                  {(mobilePhoneValid || validEmail || homePhoneValid || workPhoneValid) ? (<a className="btn opacity-25 text-teal disabled no-underline hover:underline cursor-pointer" area-disabled="true">
                    {t("save")}
                  </a>) :
                    (<a className="btn text-teal no-underline hover:underline cursor-pointer" data-testid="saveButton" onClick={callSaveButton}>
                      {t("save")}</a>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="border border-grey rounded-lg mb-4">
              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("customer_name")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6">
                    <input aria-label="customer name" type="text" className="w-text w-full text-sm md:text-base border-greyy" disabled defaultValue={customerName}></input>
                  </div>
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Date_of_birth")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6">

                    <input aria-label="dob" type="text" data-testid="dateOfBirth" className=" w-text w-full text-sm md:text-base border-greyy" disabled value={dob} onChange={DOBHandler}></input>
                  </div>
                  {dobValidation ? (<div className="text-brick-70 text-sm mt-2">
                    {t("dob_validation")}</div>) : null}
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Email")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6 mob-pl mob-pll">
                    <input type="email" aria-label="email" data-testid="email" className="border-grey w-full text-sm md:text-base" defaultValue={customerContact?.email} onChange={emailChangeHandler}></input>
                  </div>
                  {validEmail ? <div className="text-brick-70 text-sm mt-2">
                    {t("Invalid_Email_Msg")}</div> : null}
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Mobile")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6">
                    <input type="text" aria-label="mobile phone" data-testid="mboilePhone" className="border-grey w-full text-sm md:text-base" defaultValue={customerMobilePhone} onInput={mobilePhoneHandler} ></input>
                  </div>
                  {mobilePhoneValid ? <div className="text-brick-70 text-sm mt-2">
                    {t("Invalid_Date_Msg")}</div> : null}
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Home")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6">
                    <input type="text" aria-label="home phone" data-testid="homePhone" className="border-grey w-full text-sm md:text-base" defaultValue={customerHomePhone} onInput={homePhoneHandler}></input>
                  </div>
                  {homePhoneValid ? <div className="text-brick-70 text-sm mt-2">
                    {t("Invalid_Date_Msg")}</div> : null}
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Business")}</div>
                <div className="col-md-6 pr-4">
                  <div className="col-md-12 col-sm-6">
                    <input type="text" aria-label="work phone" data-testid="businessPhone" className="border-grey w-full text-sm md:text-base" defaultValue={customerWorkPhone} onInput={workPhoneHandler}></input>
                  </div>
                  {workPhoneValid ? <div className="text-brick-70 text-sm mt-2">
                    {t("Invalid_Date_Msg")}</div> : null}
                </div>
              </div>

              <div className="px-2 py-2 flex justify-between border-b border-grey">
                <div className="pr-4 col-md-5 ml-2 py-2">{t("Postal_address")}</div>
                <div className="col-md-6 col-sm-6">
                  <div className="text-pad">
                    <textarea aria-label="address" className="w-text border-greyy w-full text-sm md:text-base h-24 mt-2" disabled defaultValue={customerContact?.postalAddress} ></textarea>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>

      </div>
    </div>
  )
};
export default CustomerEditProfile;