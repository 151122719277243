import React, { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import * as api from "../../../api";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../storybook/LoadingSpinner";

const Button = React.lazy(() => import("../../../Button/Button"));



const CaseUpdate = () => {
  const { t } = useTranslation();
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [textBoxInput, settextBoxInput] = useState("");
  const history = useHistory();
  const caseData = useSelector(
    (state: any) => state?.userChoicesReducer?.caseData
  );

  useEffect(() => {
    window.scrollTo(0,0);
  },[])
  const addUpdate = () => {
    setIsLoading(true);
    const dataPass = {
      "type": "update",
      "identifiers": {
        "caseIdentifier": caseData.identifiers?.caseIdentifier,
      },
      "metadata":[
        {"key" : "subject", "value": userInput},
        {"key" : "description", "value": textBoxInput},
        {"key" : "status", "value":"completed"}
      ]
    }
   
    try {
      setIsLoading(true);
      api.addAnUpdate(
        caseData.identifiers?.caseIdentifier,
        dataPass
      );
    } catch (e) {
      history.push("/genericerror");
      history.push("/Case360View");
    } finally {
      setIsLoading(false);
      history.push("/Case360View");
    }
  }

  const handleChange = (e:any) => setUserInput(e.target.value);
  const handleTextBoxInput = (e:any) => settextBoxInput(e.target.value);

  return (
    <div className="container sm:mx-1 ctn-fht" id="caseType-container" role="main">
        {/* <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={ () => history.push("/Case360View") }>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div> */}
         
          {isLoading ?  (
                                <div className="div-loader">
                                    <div className="SpinnerWrapper">
                                        <LoadingSpinner />
                                    </div>
                                </div>
                            ) : (<>
         <div className="sm:mx-1">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-6 text-purple-100 text-2xl mb-4 global-txt">
                {t("UpdateService")}
              </h1>
            </div>
            <p className="div-txt-newline text-base my-0 global-txt" id="repreason-p">
              {t("subject")}
            </p>
            <input
                              className="subject border-dark-grey global-txt ml-0 border-grey vul-teal"
                              type="text"
                              placeholder="Enter your subject"
                              value={userInput}
                              aria-label="Enter your subject"
                              onChange={handleChange} />
             <div
              id="repAddDet-div3"
              className="global-txt mt-1"
              data-testid="repairdetails"
            >
              <textarea
                data-testid="Case_question_details"
                className="textarea-mob mt-2 border-dark-grey textbox global-txt ml-0 border-grey vul-teal"
                placeholder="Add update details"
                aria-label="Add update details"
                value={textBoxInput}
                onChange={handleTextBoxInput}
              />
            </div>

            <div
              className="flex justify-between items-center pb-4 md:pb-8"
              id="case-btn"
            >

              <div><a id="id" onClick={ () => history.push("/Case360View")} aria-hidden="true" className=" buttonCancel text-teal exit-btn a-style cursor-pointer">{t("cancel")}</a></div>
              <Button
                id="btn_case"
                type="button"
                onClick={() => addUpdate()}
                data-testid="btncontinue"
                name="btncontinue"
                placeholder="btncontinue"
                value="Continue"
                className="buttonCreate"
                disabled= {userInput === "" || textBoxInput === "" ? true : false}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </>)}
    </div>
  );
};

export default CaseUpdate;
