import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useDispatch, useSelector } from "react-redux";
import Property from "../../models/Property";
import { PremisesType } from "../../models/Customer/Premises";
import { PermissionsList } from "../../models/Permissions";
import { assetData } from "../../ducks/redux/actions/getAddress";
import { Address, Assets, PostalAddress } from "../../models/Property/PropertyResponse";
import {assetPropertyId, backToCaseAsset, userChosenAssetValue,userChosenUPRN } from "../../ducks/redux/actions/userChoices";
import { BackValue } from "../../models/shared/Enum";


interface AssetCardProps extends RouteComponentProps<any> {
}
const AssetCard: React.FC<AssetCardProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assets = (state: any) => state?.addresses?.customerassociatedassets;
  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const associatedAssets = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenAssociatedAssets
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const isCaseCheck = useSelector(
    (state: any) => state?.userChoicesReducer?.isCaseAllowed
  );

  const array: Property[] = [];
  const addressesData = useSelector(assets);
  const [propertyList, setPropertyList] = useState(array);
  const [dropDown, setDropDown] = useState("none");
  const [isRepair, setIsRepair] = useState(false);
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  

  useEffect(() => {
    (async () => {
      trackEvent("AssetCard");
      const showRepair = getPermissionsData?.indexOf(PermissionsList.create_customer360_asset_raise_a_repair);
      if (showRepair != -1) {
        setIsRepair(true)
      }
      else {
        setIsRepair(false)
      }
      const premisesList: Property[] = [];
      if (addressesData !== null && addressesData !== undefined) {
        addressesData[0]?.forEach((element: any) => {
          const premisesEntry: Property =
            new Property();

          if (element.assetType !== PremisesType.Scheme && element.assetType !== PremisesType.EstateArea) {
            premisesEntry.premisesType = element.assetType as PremisesType;
            premisesEntry.uprn = element.uprn;
            premisesEntry.id=element.id;
            premisesEntry.address = element?.addresses?.postalAddresses[0]
            premisesList.push(premisesEntry);
          }
        });
        dispatch(userChosenAssetValue(premisesList[0]?.premisesType));
        dispatch(userChosenUPRN(premisesList[0].uprn));
        setPropertyList(premisesList);
      }

    })();
  }, [addressesData]);
  const callProperty360 = (propertyData: Property) => {
    const assetEntry: Assets = new Assets();
    assetEntry.assetType = propertyData?.premisesType
    assetEntry.uprn = propertyData?.uprn
    assetEntry.id = propertyData?.id
    const addressEntry: Address = new Address();
    const postalAddressEntry: PostalAddress = new PostalAddress();
    postalAddressEntry.addressLine1 = propertyData?.address?.addressLine1
    postalAddressEntry.addressLine2 = propertyData?.address?.addressLine2 != null ? propertyData?.address?.addressLine2 : ""
    postalAddressEntry.addressLine3 = propertyData?.address?.addressLine3 != null ? propertyData?.address?.addressLine3 : ""
    postalAddressEntry.addressLine4 = propertyData?.address?.addressLine4 != null ? propertyData?.address?.addressLine4 : ""
    postalAddressEntry.addressLine5 = propertyData?.address?.addressLine5 != null ? propertyData?.address?.addressLine5 : ""
    postalAddressEntry.postcode = propertyData?.address?.postcode
    postalAddressEntry.id = propertyData?.address?.id
    addressEntry.postalAddresses.push(postalAddressEntry);
    assetEntry.addresses = addressEntry
    dispatch(assetData(assetEntry))
    dispatch(assetPropertyId(""))
    props.history.push("/Asset360View");

  }
  const dropDownFunction = () => {
    if (dropDown === "none")
      setDropDown("block");
    else
      setDropDown("none");
  }
  const callPageHandler = () => {
    props.history.push("/RepairAsset");
  };
  const callCaseHandler = () => {
    backToCaseAsset(false)
    if (associatedAssets.length === 1) {
      props.history.push("/CaseAsset");
    }
    else if (associatedAssets.length > 1) {
      props.history.push("/SelectTenancy");
    }
  }

  return (

    <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4">

      <div className="py-2 px-4 border-b border-grey">
        <div className="flex flex-row d-flex bd-highlight items-center mt-2">
          <i className="fa fa-home text-teal text-2xl mr-6 bd-highlight aa"></i>
          <h2 className="my-0 text-purple-100 bd-highlight">{t("Asset_hierarchy")}</h2>
          {
             
            (backValue !=BackValue.Case360View && (((isActiveTenancy) &&
            propertyList[0]?.premisesType === "Property") ||
            propertyList[0]?.premisesType === "Divided Block" ||
            propertyList[0]?.premisesType === "Block" ||
            propertyList[0]?.premisesType === "Estate" ))? (
              
                <div className="menu-nav ms-auto bd-highlight">
                  <div className="dropdown-container">
                    <div className="three-dots" 
                     data-bs-toggle="dropdown"
                      data-testid="dropFunction" 
                      onClick={dropDownFunction}></div>

                    <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                    {isRepair && backValue != BackValue.Case360View  ? (
                      <a onClick={callPageHandler} data-testid="callHandler" className="text-black text-base no-underline global-txt">
                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                        {propertyList[0]?.premisesType === "Property" ? t("Create_repair") : t("raise_comm")}
                      </div></a>): null}
                      {backValue != BackValue.WorkOrder360View  && isCaseCheck == 'true'?
                      <a data-testid="casepage" onClick={callCaseHandler} className="text-black text-base no-underline global-txt">
                          <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("CP104")}
                      </div></a> : null}
                    </div>
                   
                  </div>
                </div>
                
            ) : null
          }
        </div>
      </div>
      {propertyList.map((property) => (
       <div className="px-4 py-2 ">
       <div className="border border-grey rounded-lg mt-4">
          <div className="bg-peach px-4 py-2 rounded-t-lg">
            <div className="flex flex-row justify-between items-center">
              <div className="my-0 text-lg">
                <a data-testid={property.id}  onClick={() => callProperty360(property)} 
                className="text-dark global-txt cursor-pointer">
                  <strong>{t("UPRN")} {property?.uprn}</strong></a></div>
              <div className="my-0 text-base global-txt"><strong>{property?.premisesType}</strong></div>
            </div>
          </div>

          <div className="px-4 py-2 flex justify-between border-b border-grey">
            <div className="pr-4 global-txt">{property?.premisesType !== PremisesType.Property ? (
              t("Name")) : (t("Address"))
            }</div>
            <div className="text-right global-txt">{property?.premisesType !== PremisesType.Property ?
              (property?.address?.addressLine1) : (property?.address?.formattedAddress)}</div>
          </div>
        </div>
</div>

      ))
      }
    </div>
  )
};
export default AssetCard;