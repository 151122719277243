import React, { useState, useEffect, Suspense, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { isUndefinedNullOrEmpty } from "../../../utils/translation/helper";
import {
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenReporterId,
} from "../../../ducks/redux/actions/userChoices";
import {
  CASE_COUNTER,
  CASE_EDIT,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import { CaseReporterIdWithPerpoty } from "../../../models/Case/Case";
import { CaseReporterId } from "../../../models/Case/Case";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { BackValue } from "../../../models/shared/Enum";
import { FeatureFlags } from "../../contexts/FeatureFlags";
import { isNullOrUndefined } from "../../../utils/formatting";
import { clearReferralInformation } from "../../../ducks/redux/actions/FinancialReferral";
const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../Button/Button"));
const ExitModalContent = React.lazy(
  () => import("../../Modal/ExitModalContent")
);

interface ContactDetailsProps extends RouteComponentProps<any> { }
const ContactDetails: React.FC<ContactDetailsProps> = (props) => {

  const { t } = useTranslation();
  const customerPhone = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber
  );
  const assetValue = useSelector(
    (state: any) => state?.userChoicesReducer?.assetValue
  );
  const contactOption = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenContactOption
  );
  const referral = useSelector( (state: any) => state?.financialReferralReducer?.isComingFromReferral)
  const caseFlag = useSelector( (state: any) => state?.financialReferralReducer?.isComingFromCase)

  const [inputState, setInputState] = useState<any>(t("Customer_Option"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [userInput, setUserInput] = useState("");
  var errorArray: any[] = [];
  const [errorArrayState, seterrorArrayState] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberInput, setNumberInput] = useState("");
  const { featureMedia }: any = useContext(FeatureFlags);
  const [radiovalue, setRadioValue] = useState(
    !isNullOrUndefined(customerPhone) ?
      customerPhone[0]?.number
        ? `${customerPhone[0].number}@0`
        : t("Case_Provide_Number")
      : t("Case_Provide_Number")
  );
  const radioText = t("Case_Provide_Number");
  const isinputdisabled = (assetValue === "Property" || (!isNullOrUndefined(contactOption) && contactOption === t("Contact1"))) && radiovalue != radioText
  const userChosenContactDetailsBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactDetails
  );
  const userChosenContactNameBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );
  const userChosenContactPhoneBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );

  const userChosenRelationSelect = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );

  const userChosenIsReporter = useSelector(
    (state: any) => state.userChoicesReducer.userChosenIsReporter
  );

  const isEdit = useSelector(
    (state: any) => state?.caseCreationReducer?.isCaseEdit
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const contactInformationValue = useSelector(
    (state: any) => state.caseCreationReducer?.contactInformationValue
  );

  const isCaseMediaAllowed = useSelector(
    (state:any) => state?.userChoicesReducer?.isCaseMediaAllowed
  );
  const isCaseMedia = useSelector(
    (state:any) => state?.caseCreationReducer?.isCaseMediaCheck
  );
  const questionInfoData = useSelector(
    (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
  );

  const [buttondisable, setsetButtonDisable] = useState(false);
  useEffect(() => {
    if(!referral && !caseFlag){
      props.history.push('/Customer360View')
    }
    
    if (props.history.action === "POP" || props.history.action === "REPLACE" || props.history.action === "PUSH") {
      if(userChosenContactDetailsBackButton){
        setRadioValue(userChosenContactDetailsBackButton);   
        }
        setUserInput(userChosenContactNameBackButton);
    }
    if (!userChosenRelationSelect) {
      if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
        dispatch(userChosenRelationDetails(t("Colleague_Option")));
      }
      else {
        dispatch(userChosenRelationDetails(t("Customer_Option")));
      }

    }
    if (
      userChosenContactNameBackButton?.trim().length > 0 &&
      isEdit === false
    ) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
    } else if (userChosenIsReporter) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
      if (userChosenRelationSelect === "Friend or family") {
        dispatch(userChosenRelationDetails("Friend or Family"));
      } else {
        dispatch(userChosenRelationDetails(userChosenRelationSelect));
      }
    } else if (
      !userChosenContactDetailsBackButton &&
      !userChosenContactPhoneBackButton && !isNullOrUndefined(customerPhone)
    ) {
      if (customerPhone !== null && customerPhone !== undefined) {
        dispatch(userChosenContactDetails(`${customerPhone[0]?.number}@0`));
      }
      else {
        dispatch(userChosenContactDetails(t("Case_Provide_Number")));
      }
      dispatch(userChosenRelationDetails(""));

    } else if (userChosenContactNameBackButton && isEdit === false) {
      setRadioValue(t("Case_Provide_Number"));
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
    } else {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
    }

    if (userChosenRelationSelect) {
      setInputState(userChosenRelationSelect);
    }
  }, []);

  const updateSelection = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value != t("Case_Provide_Number")) {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      setNumberInput("");
      setUserInput("");
      seterrorArrayState([]);
      errorArray = [];
    } else if (e.target.value == t("Case_Provide_Number")) {
      if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
        dispatch(userChosenRelationDetails(t("Colleague_Option")));
      }
      else {
        dispatch(userChosenRelationDetails(t("Customer_Option")));
      }
    }
    setRadioValue(e.target.value);
    dispatch(userChosenContactPhone(e.target.value));
    dispatch(userChosenContactDetails(e.target.value));
  };
  const inputChangeHandler = (event: any) => {
    const re = /^[+0-9]+$/;
    // if value is not blank, then test the regex
    seterrorArrayState([]);
    errorArray = [];
    if (event.target.value === "" || re.test(event.target.value)) {
      //  @ts-ignore
      setNumberInput(event.target.value.toString().slice(0, 11));
      dispatch(
        userChosenContactPhone(event.target.value.toString().slice(0, 11))
      );
    }
  };

  const numberValidate = () => {
    if (isinputdisabled) {
      if (radiovalue != t("Case_Provide_Number")) {
        dispatch(
          userChosenContactPhone(
            radiovalue.toString().slice(0, 11)
          )
        );
      } else {
        dispatch(
          userChosenContactPhone(numberInput.length > 0 ? numberInput : null)
        );
      }
      if(referral){
        dispatch(userChosenContactPhone(radiovalue));
        props.history.push("/ReferralReview");
      }else {
        props.history.push("/CaseReview");
      }
      if (radiovalue != t("Case_Provide_Number")) {
        dispatch(userChosenContactName(""));
        dispatch(userChosenRelationDetails(""));
        dispatch(userChosenContactName(""));
        dispatch(userChosenReporterId(""));
      }
      return;
    }
    const phoneEmpty = referral? t('Referral_Contact_FI016c') :  t("OPC010a")
    const phoneInvalid = t("OPC009");

    const ukCountryCode = "0";
    if (!numberInput) {
      errorArray.push(phoneEmpty);
      seterrorArrayState(errorArray);
      textvalidate();
      return;
    }
    if (numberInput.slice(0, 1) != ukCountryCode || numberInput.length < 11) {
      errorArray.push(phoneInvalid);
      seterrorArrayState(errorArray);
      textvalidate();
      return;
    }
    textvalidate();
    errorArray = [];
  };
  const nameError = t("OPC011");
  const textvalidate = () => {
    if (!userInput || (userInput && userInput.length <= 0)) {
      errorArray.push(nameError);
      seterrorArrayState(errorArray);
      return;
    }
    if (errorArray.length == 0) {
      if (inputState === t("Customer_Option")) {
        if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
          dispatch(
            userChosenReporterId(
              CaseReporterId["Colleague" as keyof typeof CaseReporterId]
            )
          );
        }
        else {
          dispatch(
            userChosenReporterId(
              CaseReporterIdWithPerpoty["Customer" as keyof typeof CaseReporterIdWithPerpoty]
            )
          );
        }
      }
      if (inputState === t("Colleague_Option")) {
        if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
          dispatch(
            userChosenReporterId(
              CaseReporterId["Colleague" as keyof typeof CaseReporterId]
            )
          );
        }
        else {
          dispatch(
            userChosenReporterId(
              CaseReporterIdWithPerpoty["Customer" as keyof typeof CaseReporterIdWithPerpoty]
            )
          );
        }
      }
      dispatch({ type: CASE_EDIT, payload: false });
      if (!userChosenRelationSelect) {
        if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
          dispatch(userChosenRelationDetails(t("Colleague_Option")));
        }
        else {
          dispatch(userChosenRelationDetails(t("Customer_Option")));
        }
      }
    if(referral){
        props.history.push("/ReferralReview");
    }else {
      props.history.push("/CaseReview");
    }

    }
  };

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    if(referral){
      dispatch(userChosenContactPhone(radiovalue));
      props.history.push('/referraladditionaldetails');
    }else {
    if (backValue == BackValue.Asset360View && contactInformationValue == t("Contact1")) {
      props.history.push("/CaseSelectCustomer")
    }
    else if (backValue == BackValue.Asset360View && ((contactInformationValue == t("Contact2") || contactInformationValue == t("Contact3") || contactInformationValue == t("Contact4")))) {
      props.history.push("/CaseContactInformation")
    }
    else {
      if(isCaseMedia === "true" && isCaseMediaAllowed === true){
        props.history.push("/uploadmedia");
      } else {
        dispatch({ type: CASE_COUNTER, payload: questionInfoData?.length - 1 });
        props.history.push("/CaseQuestionnaire");
      }
    }
   } 
  };

  const onExitHandler = () => {
    if(referral){
      dispatch(clearReferralInformation(null))
      props.history.push("/Customer360View");
    }else {
    clearCaseDataFromRedux();
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if (backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    } else {
      props.history.push("/Case360View");
    }
  }
  };

  const nameInputChangeHandler = (e: any) => {
    seterrorArrayState([]);
    const re = /^[\s|A-Za-z]*$/;
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      setUserInput(e.target.value);
      dispatch(userChosenContactName(e.target.value));
    }
  };

  const onContinueHandler = () => {
    if (userInput === "") {
      const element = document.getElementById("custDetails-text2") as HTMLInputElement || null;
      const nameValue: any = element?.value || "";
      if (nameValue === "") {
        setUserInput(nameValue);
        dispatch(userChosenContactName(nameValue));
      }
    }
    seterrorArrayState([]);
    numberValidate();
  };

  const handleChange = (e: { target: { value: any } }) => {
    if (inputState != e.target.value) {
      if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
        const state: string = CaseReporterId[e.target.value];
      }
      else {
        const state: string = CaseReporterIdWithPerpoty[e.target.value];
      }
    }
    setInputState(e.target.value);
    dispatch(userChosenRelationDetails(e.target.value));
    if (assetValue !== "Property" && !isNullOrUndefined(contactOption) && contactOption !== t("Contact1")) {
      dispatch(

        userChosenReporterId(
          CaseReporterId[e.target.value as keyof typeof CaseReporterId]
        )
      );
    }
    else {
      dispatch(

        userChosenReporterId(
          CaseReporterIdWithPerpoty[e.target.value as keyof typeof CaseReporterIdWithPerpoty]
        )
      );
    }

  };
  let errorfieldsArray: any[] = [];
  errorArrayState?.forEach((err: any) => {
    errorfieldsArray.push(
      <div className="error">
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center text-black">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
              {err}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <Container id="conDetails-container" role="main" className="ctn-fht">
      <Suspense
        fallback={
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={5000}
          />
        }
      >
        <div
          id="custDetails-back-div"
          className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
        >
          <p id="custDetails-p" className="text-teal text-xl mt-4 mb-8">
            <a
              className="back-btn"
              data-testid="custDetailsBackBtn"
              onClick={backButtonHandler}
            >
              <i className="fas fa-angle-left mr-2"></i>
              {t("back")}
            </a>
          </p>
        </div>

        <div id="custDetails-main-div" className="container">
          {assetValue === "Property" || (!isNullOrUndefined(contactOption) && contactOption === t("Contact1")) ?
            (
              <h1
                id="custDetails-h1"
                className="my-4 text-purple-100 mb-2 global-txt"
              >
               {referral? t('Referral_Contact'): t("OPC008a")}
              </h1>
            ) :
            <h1
              id="custDetails-h1"
              className="my-4 text-purple-100 mb-2 global-txt"
            >
             {referral? t('Referral_Contact'):t("OPC008b")}
            </h1>
          }
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              timeout={5000}
            />
          ) : (
            <fieldset>
              <legend>
                <div
                  className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3 d-flex flex-row repair-text"
                  id="options"
                >{!isNullOrUndefined(customerPhone) && (assetValue === "Property" || (!isNullOrUndefined(contactOption) && contactOption === t("Contact1"))) ?
                  <RadioGroup
                    id="custDetails-radiogroup"
                    data-testid="radioGroup"
                    onChange={updateSelection}
                    value={radiovalue}
                  >
                    {customerPhone?.map((number: any, index: number) => {
                      return (
                        number.number && (
                          <FormControlLabel
                            id="custDetails-label1"
                            className="cust-rad global-txt"
                            key={"customerPhoneNumber_" + index}
                            control={
                              <Radio
                                id="conDetails-radio1"
                                key="checkboxPhoneNumber"
                                name="checkboxPhoneNumber"
                                data-testid="checkboxPhoneNumber"
                              />
                            }
                            label={number.number}
                            value={`${number.number}@${index}`}
                          />
                        )
                      );
                    })}
                    <FormControlLabel
                      id="custDetails-label2"
                      className="cust-rad global-txt"
                      control={
                        <Radio
                          id="conDetails-radio1"
                          key="phoneNumberforRepair"
                          name="phoneNumberforRepair"
                          data-testid="phoneNumberforRepair"
                        />
                      }
                      label={t("Case_Provide_Number")}
                      value={t("Case_Provide_Number")}
                    />{" "}
                  </RadioGroup>
                  : null} </div>

                <div id="custDetails-div1" className="row  pb-4 global-txt">
                  <div
                    id="custDetails-div2"
                    className="col-lg-4 col-sm-12 pt-2 pt-lg-0"
                  >
                    <input
                      id="custDetails-text1"
                      data-testid="inputPhoneNumber"
                      aria-label="phone number"
                      type="text"
                      disabled={isinputdisabled}
                      value={numberInput}
                      style={{ width: "100%" }}
                      className="form-control mt-10 textinput-border"
                      placeholder={referral? 'Please enter a contact phone number':"Please enter a phone number"}
                      onChange={(e) => inputChangeHandler(e)}
                    />
                  </div>
                  <div
                    id="custDetails-div3"
                    className="col-lg-4 col-sm-12 pt-2 pt-lg-0"
                  >
                    <input
                      id="custDetails-text2"
                      type="text"
                      aria-label="customer name"
                      data-testid="customerName"
                      disabled={isinputdisabled}
                      value={userInput}
                      placeholder="Name"
                      style={{ width: "100%" }}
                      className="form-control mt-10 textinput-border"
                      onChange={nameInputChangeHandler}
                    />
                  </div>

                  <div
                    id="custDetails-div4"
                    className="col-lg-4 col-sm-12 pt-2 pt-lg-0 mt-2"
                  >
                    {assetValue === "Property" || (!isNullOrUndefined(contactOption) && contactOption === t("Contact1")) ?
                      (
                        <select
                          data-testid="inputState"
                          id="inputState"
                          disabled={isinputdisabled}
                          className="form-select"
                          value={userChosenRelationSelect ?? inputState}
                          onChange={handleChange}
                          aria-label="Input State"
                        >

                          <option
                            id="custDetails-opt1"
                            data-testid="Customer_Option"
                            value={t("Customer_Option") as string}
                          >
                            {t("Customer_Option")}
                          </option>
                          <option
                            id="custDetails-opt2"
                            value={t("Friend_Option") as string}
                          >
                            {t("Friend_Option")}
                          </option>
                          <option
                            id="custDetails-opt3"
                            value={t("Other_Option") as string}
                          >
                            {t("Other_Option")}
                          </option>

                        </select>
                      ) : (
                        <select
                          data-testid="inputState"
                          id="inputState"
                          disabled={isinputdisabled}
                          className="form-select"
                          value={userChosenRelationSelect ?? inputState}
                          onChange={handleChange}
                          aria-label="Input State"
                        >
                          <option
                            id="custDetails-opt1"
                            data-testid="Colleague_Option"
                            value={t("Colleague_Option") as string}
                          >
                            {t("Colleague_Option")}
                          </option>
                          <option
                            id="custDetails-opt2"
                            value={t("Third_party") as string}
                          >
                            {t("Third_party")}
                          </option>
                          <option
                            id="custDetails-opt3"
                            value={t("Non_Resident") as string}
                          >
                            {t("Non_Resident")}
                          </option>
                          <option
                            id="custDetails-opt4"
                            value={t("Myself") as string}
                          >
                            {t("Myself")}
                          </option>
                        </select>
                      )}
                  </div>
                </div>
              </legend>
            </fieldset>
          )}
          {errorfieldsArray?.length > 0 && (
            <div
              id="custDetails-div5"
              data-testid="errorDiv"
              className="alert alert-warning error-reason mar-left global-txt error-left-padd p-0"
              role="alert"
              placeholder="alert"
            >
              {errorfieldsArray}
            </div>
          )}
        </div>
        <div
          id="custDetails-main-btn"
          className="flex justify-between items-center pb-4 md:pb-8 container"
        >
          <ExitModalContent
            id="repairproperty-exitmodal"
            exit={onExitHandler}
            btn_msg= {referral? "Return to my referral": "return_case_button"}
          />
          <Button
            onClick={onContinueHandler}
            className="pad-bot"
            disabled={buttondisable}
            id="btn_caseproperty"
          >
            {t("continue")}{" "}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>
        </div>
      </Suspense>
    </Container>
  );
};

export default ContactDetails;
