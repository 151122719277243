import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import classes from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { CreateArrearsDataType } from "../../models/UCC/UniversalCreditCard";
import { saveUCCCreateArrearsAction, createArrearsResponse } from "../../ducks/redux/actions/userChoices";

interface UCCCreateArrearsActionProps extends RouteComponentProps<any> { }

const UCCCreateArrearsAction: React.FC<UCCCreateArrearsActionProps> = (props) => {

  const summaryTextRef = useRef<any>();
  const [alertMessage, setAlertMessage] = useState<any>(null);

  const customerData = useSelector((state: any) => state?.userChoicesReducer?.uCCTenancyModifiedData);
  const customerRentData = useSelector((state: any) => state?.userChoicesReducer?.uCCRentModifiedData);
  const createArrearsResponseVal = useSelector((state: any) => state?.userChoicesReducer?.createArrearsResponse);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const textAreaRegrex = />|<|\*|&|!/img;

  useEffect(() => {
    if (createArrearsResponseVal) {
      setAlertMessage(createArrearsFailed);
    }
    
    return () => {
      dispatch(createArrearsResponse(''));
    }
  }, [createArrearsResponseVal]);

  const inputChangeHandler = () => {
    const summaryText = summaryTextRef?.current?.value;
    if (textAreaRegrex.test(summaryText) === true) {
      setAlertMessage(restrictedCharacterMsg);
    } else if (summaryText.length > 2000) {
      setAlertMessage(tooManyCharacters);
    } else {
      setAlertMessage(null);
    }
  }

  const callSaveButton = () => {
    const summaryText = summaryTextRef?.current?.value;
    if (summaryText === "") {
      setAlertMessage(emptyTextMsg);
    } else if (alertMessage != null) {
      return;
    } else {
      const createArrearsData: CreateArrearsDataType = new CreateArrearsDataType();
      createArrearsData.accountId = customerRentData?.AccountId;
      createArrearsData.payRef = customerData?.paymentReference;
      createArrearsData.transactionDate = new Date();
      createArrearsData.customerIdentifier = customerData?.customerIdentifier;
      createArrearsData.text = summaryText;
      createArrearsData.assetIdentifier = customerData?.assetIdentifier;
      dispatch(saveUCCCreateArrearsAction(createArrearsData, props));
      setAlertMessage(null);
    }
  }

  const callCancelButton = () => {
    props.history.push({
      pathname: '/customer360View',
      state: { fromUCCCreateArrearsAction: true }
    });
  }

  let restrictedCharacterMsg = <div className="error mb-8" id="custDetails-div5" role="alert">
    <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center text-black">
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("UCCRent.UC008")}</p>
      </div>
    </div>
  </div>

  let emptyTextMsg = <div className="error mb-8" id="custDetails-div5" role="alert">
    <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center text-black">
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("UCCRent.UC012")}</p>
      </div>
    </div>
  </div>

  let tooManyCharacters = <div className="error mb-8" id="custDetails-div5" role="alert">
    <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center text-black">
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("TooManyCharacters")}</p>
      </div>
    </div>
  </div>

  let createArrearsFailed = <div className="error mb-8" id="custDetails-div5" role="alert">
    <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center text-black">
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("UCCRent.CreateArrearsActionFailed")}</p>
      </div>
    </div>
  </div>

  return (
    <div className="border-t border-grey pb-12 md:pb-12 lg:pb-12 bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 md:gap-8 mt-8 px-2">
          <div className="flex flex-row d-flex bd-highlight items-center">
            <h1 className="my-0 text-purple-100 bd-highlight sm-pl-1">{t("UCCRent.CreateArrearsAction")}</h1>
          </div>

          <div className="relative px-4 pb-4 border border-grey bg-white shadow-lg rounded-lg">
            <div className=" py-4">
              <div className="w-full text-lg text-teal flex flex-col md:flex-row md:justify-between">
                <label className={`${classes.textAreaTitle} md:pb-0`} htmlFor="createArrearsTextAreaId">{t("UCCRent.UCCTextBoxTitle")}</label>
                <div>
                  <a className="btn text-teal no-underline hover:underline mr-6 cursor-pointer" data-testid="cancelButton" onClick={callCancelButton}>
                    {t("cancel")}
                  </a>
                  <a className="btn text-teal no-underline hover:underline cursor-pointer" data-testid="saveButton" onClick={callSaveButton}>
                    {t("save")}</a>
                </div>
              </div>
            </div>
            {alertMessage}

            <div className="py-2 flex justify-between border-grey">
              <textarea
                id="createArrearsTextAreaId"
                data-testid="createArrearsTextArea"
                className={`${classes.createArrearsTextArea} w-full text-sm md:text-base h-24`}
                rows={7}
                placeholder=""
                ref={summaryTextRef}
                onChange={inputChangeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default UCCCreateArrearsAction;