import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux"
import "./index.css"
import ExitModalContent from "../Modal/ExitModalContent";
import Button from "../../Button/Button";
import BackModalContent from "../Modal/BackModalContent";
import ReactQuill from 'react-quill';
import * as api from "../../api";
import "quill/dist/quill.snow.css"; 
import * as ReactBootstrap from "react-bootstrap";
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import YellowAlert from "../YellowAlert";
import gifImg from "../../assets/image/attachment.png";
import {encode} from 'html-entities';
import Loader from "react-loader-spinner";


interface MediaFilesType {
    name: string;
    size?: number;
    fileType: string;
    image:string;
    documentIdentifier: string;
}

interface failedMedia {
  id:number;
  value:boolean;
}


interface ComposeEmailProps extends RouteComponentProps<any> {
  
}

const ComposeEmail: React.FC<ComposeEmailProps> = (props:any) => {
  const allowedExtensions = process.env.REACT_APP_ALLOW_EXTENSION;
  const videoSize = process.env.REACT_APP_VIDEO_SIZE as string;
  const imgSize = process.env.REACT_APP_IMG_SIZE as string;
  const pdfSize = process.env.REACT_APP_DOC_SIZE as string;
  const charLimit = process.env.REACT_APP_EMAIL_CHARACTER as string;
  let [value, setValue] = useState('');
  const [charCount, setCharCount] = useState(0)
  const [mediaFailedCount, setMediaFailedCount] = useState<failedMedia[]>([]);
  

  const { t } = useTranslation();
  const [subject, setSubject] = useState('');
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [mediaFiles, setMediaFiles] = useState<MediaFilesType[]>([]);
  let mediaIds: string[] = [];
  let attachmentFailed: boolean = false;
  
  let totalSize = +charLimit;
  const maxEmailSize = 30000000;
  
  
  const customerId = localStorage.getItem('agent');
  const assetDataResponse = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataResponse
  );
  const addIndentation = (htmlContent: any) => {
    let res = htmlContent;
    for(let i=1;i<=10;i++){
       res = res.replaceAll('class="ql-indent-'+i+'">','>'+( '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'.repeat(i)));
    }
    return res;
  };

  const backButtonHandler = () => {
    props.history.push('/Asset360View');   
  }

  
  const callCommunicationAPI = async(mediaIds:any) => {
    const emailNotificationParam = {
      "sender": {
        "addresses":
        {
          "digitalAddresses": [
            {
              "emailAddresses": [
                {
                  "value": "Peabody360@peabody.org.uk",
                  "id": "email"
                }
              ],
              "telecomsAddresses": [
                {
                  "number": "",
                  "deviceId": "",
                  "dialingCode": "",
                  "countryCode": "",
                  "telecomsAddressType": "mobile"
                }
              ]
            }
          ]
        }
      },
      "communicationTemplateIdentifier": "pb-blockcommunication-email-template",
      "communicationType": "EMAIL",
      "content": [
    
        {
          "identifier": "email_subject",
          "value": subject 
        },
        {
          "identifier": "email_body",
          "value": encode(addIndentation(value)) 
        },
        {
          "identifier":"colleague_id",
          "value": customerId
        },
        {
          "identifier":"document_ids",
          "value": mediaIds?.toString() || ""
        }
       
      ]
    }
    try{
      await api.sendSMSNotification(assetDataResponse?.id, emailNotificationParam)
      props.history.push("/EmailConfirmation")
    }catch(e:any){
      setError("Email_Failure")
    }finally{
      setIsLoading(false)
    }
  }

  const messageSendHandler = async() => {
    const tempSize = mediaFiles?.map((mediaFile: any)=> + (mediaFile.attachedMediaSize)) 
    totalSize += tempSize?.reduce(
      (prevVal, currentValue) => prevVal + currentValue, 0);
    value = value.replace('<p>', "").replace('</p>', "").trim()
    totalSize += value?.length + subject.length;
   
   if (charCount <= +charLimit && value !== " "){
      if(totalSize <= maxEmailSize){
          if(error === "") {
              if(subject ===""){
                setError("Email_BC020");
                return 
              }
              if(value !== "" ) {
                for(let i = 0; i < mediaFiles?.length; i++){
                  const mediaParam = {
                    DocumentType: "BLOCKEMAIL",
                    Media: mediaFiles[i]?.image?.substr(mediaFiles[i]?.image?.indexOf(",") + 1),
                    Metadata: [
                      {"key": "filename", "value":mediaFiles[i].name},
                      {key: "asset-identifier", value: assetDataResponse?.id},
                      {"key":"content-type", "value": mediaFiles[i]?.fileType}
                    ],
                  };
                  try{
                    setTimeout(()=>{
                      setIsLoading(true)
                    }, 1000)
                    const response = await api.postMediaFile(
                      mediaParam
                    );
                    mediaIds.push(response?.documentIdentifier) 
                    setMediaFailedCount((failedMedia: any) => [
                      ...failedMedia,
                      {i, value:false}
                    ]);
                  }catch(e:any){
                    setMediaFailedCount((failedMedia: any) => [
                      ...failedMedia,
                      {i, value:true}
                    ]);
                    console.log(e);
                    setError("Email_Attachment_Failure")
                    setIsLoading(false)
                    attachmentFailed = true
                  }
                  
                }
              if(!attachmentFailed) callCommunicationAPI(mediaIds); 
                  
              }else {
                  setError("SMS_BC006")
              }
          }
      }else {
        setError('Email_BC032')
      }
   }else {
    setError('Email_BC036')
   } 
    
  }

  const exitButtonHandler = () => {
    props.history.goBack();
  }

  const onTextChangeHandler = (content: any, delta: any, source: any, editor: any) => {
      setValue(content)
      setCharCount(editor.getLength()-1)
      if(editor.getLength()-1 > +charLimit){
        setError("Email_BC036")
      }else{
        setError("")
      }
}

  
  const validateMediaSize = (mediaType: any) => {
    let size: number = +imgSize;
    mediaType = mediaType.toString();
    if (mediaType.split("/", 1) == "video") {
      size = +videoSize;
    } else if (mediaType.split("/", 1) == "pdf") {
      size = +pdfSize;
    } else {
      size = +imgSize;
    }
  
    return size;
  };

  
  const processMediaFile = async (
    uploadMediaArray: any,
    name: any,
    image: any,
    fileType: any
  ) => {

    
    const size = validateMediaSize(fileType);
    const attachedMediaSize = uploadMediaArray.size;

    const validExt = fileType.split("/");
    if (validExt[1] === "quicktime") {
      validExt[1] = "mov";
    } else if (validExt[1] === "x-matroska") {
      validExt[1] = "mkv";
    } else if (validExt[1] === "jpeg") {
      validExt[1] = "jpg";
    }

    if (!allowedExtensions?.includes(validExt[1])) {
      setError('Email_BC033')
      
    } else if (uploadMediaArray.size > size) {
         setError('Email_BC031')
    }else{
       setMediaFiles((currentMediaFiles: any) => [
        ...currentMediaFiles,
        { name, attachedMediaSize, fileType, image }
      ]);

    }
  }


  const handleFileChange = (event:any) => {
    const fileObj = event.target.files && event.target.files[0];
    event.target.value = ''
    setError('')
    if (!fileObj) {
      return;
    }
        const reader = new FileReader();
         reader.onloadend = () => {
          processMediaFile(fileObj, fileObj.name, reader.result, fileObj.type);
        };
          if (fileObj) {
            reader.readAsDataURL(fileObj);
          }
        
  };

  const attachmentHandler = () => {
    inputRef.current && inputRef.current.click();
  }

  const deleteAttachedMedia = (id:number) => {
    setError("")
    const updatedMedia = mediaFiles?.filter(
      (mediaFile: any, index: number) => {
        return index !== id;
      }
    );
     setMediaFiles(updatedMedia);

     const failMedia = mediaFailedCount?.filter(
      (mediaFile: any, index: number) => {
        return index !== id;
      }
    );
     setMediaFailedCount(failMedia);
  }

  const getSubjectValue = (e:any) => {
    setError('')
    setSubject(e.target.value.trim())
  } 
  
  return (
    <>
    {isLoading? (
      <div className="loader ml-4">
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={50}
        width={50}
      />
      <p className="global-txt">{t("Email_Attachment_Wait_Msg")}</p>
      <div className="w-100">

      </div>
    </div>
    ):
    (<div className="container sm:mx-1 ctn-fht">
        {/* Back Button */}
        <BackModalContent id="supp-main-backmodal" back={backButtonHandler} aria-label="backmodal" 
          SMS_back="Leave this page" SMS_msg = "Are you sure you want to go back? If you do your changes will be lost."
          btn_msg={t("Back_To_Email")}
          />
        {/* Main Page Content */}
        <div className="sm:mx-1">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-8 global-txt">
              {`${t("Email_Title")} ${assetDataResponse?.addresses?.postalAddresses[0]?.formattedAddress}`}
              </h1>
            </div>
           
        </div>

                {/* Purple alert */}
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center mb-4">
                  <div className="px-4 pr-8">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                      <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                  </div>
                  <div className="flex items-center">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("Email_BC018")}</p>
                  </div>
                </div>
                <div>
                  
          <p className="text-gray-700 text-black global-txt fw-light">
          {`${t("Email_BC028")}`}
          </p>
          <p className="text-gray-700 text-black global-txt fw-light">
          {`${t("Email_BC029")} `}
          </p>
          <p className="text-gray-700 text-black global-txt fw-light">
          {`${t("Email_BC030")} `}
          </p>
          
        </div>        

      <h6>{t('Email_BC034')}</h6>
      <input type="text" onChange={getSubjectValue} className="w-full w-less text-sm md:text-base h-28 mb-4" aria-label="composeEmail" placeholder={t("Email_BC019")}></input>
      
      <h6 className="mb-4">{t('Email_BC035')}</h6>
      <div className="app mb-4 mt-2" data-testid="email">
      <ReactQuill
       className={"w-less"}
        value={value}  
        onChange={onTextChangeHandler}
        placeholder={t("Email_BC004")}
        bounds={'.app'}
        modules ={{
          toolbar: [
            
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link', {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'} ],
            
          ]
        }}
       
        theme="snow" 
      />
      <p className="characterCount py-2">{`${charCount}/${charLimit}`}</p>
      </div>  
      <div>
      {mediaFiles?.length >= 1 && (
            <div className="mb-4 mt-4 text-black">
              <div className="w-full border border-grey">
                <ReactBootstrap.Table striped className="container-new">
                  <TableBody id="confirmation-table" className="w-full border">
                  {/* <th> */}
                  
                  {mediaFiles?.map((mediaFile: any, key: number) => {
                  return (
                    <TableRow id={"confirmation-tr1"}>
                    <TableCell id={"confirmation-tr1-td1"} align="left">
                    <div className={'imgName'} key={key}>
                    <em>
                      <img
                        src={gifImg}
                        alt={mediaFile.name}
                        className={'thumbnail'}
                      />
                    </em>
                    <em className={'thumbnailImgName'}>
                      {mediaFile.name}
                    </em>
                  </div>
                  </TableCell>
                  <TableCell align="left">
                    <em>
                      {`${(mediaFile.attachedMediaSize/1000000).toFixed(2)}MB`}
                    </em>
                  </TableCell>
                  
                  <TableCell id={"confirmation-tr1-td3"} align="right">
                  <div onClick={()=>deleteAttachedMedia(key)} onKeyPress={()=>deleteAttachedMedia(key)}>
                    <a
                      data-testid="Delete_Button"
                    >
                    <i className={mediaFailedCount[key]?.value===true? 'deleteBtn highLight': " deleteBtn"}>X</i>
                   </a>
                 </div>
                </TableCell>
                </TableRow>
                )})}
                  
                  
                  {/* </th> */}
                  </TableBody>
                 

                </ReactBootstrap.Table>

              </div>
            </div>
          )}
      </div>
      <div>
          <input
            style={{display: 'none'}}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            data-testid="attach-file"
      />
        
      <em className="attachment" onClick={attachmentHandler} onKeyPress={attachmentHandler} aria-hidden="true">
        Attach a file
      </em>

      </div> 
      
         {/* Yellow Alert */}
          { (error !== "") && <YellowAlert messageText={t(error)} {...props}/>} 
         
        {/* Exit and Send email button */}
            <div
              className="flex justify-between items-center pb-4 md:pb-8 px-2  py-2"
              id="sms-btn"
            >
              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg={t('Email_Exit')}
              />
              <Button
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={messageSendHandler}
                placeholder="send-sms"
                value="Send"
                className="button-class"
              >
                {t("Send")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
        </div>
       
    </div>)  
  }
  </>  

  )
};
export default ComposeEmail;

