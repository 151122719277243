import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  MobilePagination,
  Pagination,
  Paginationbox,
  Search,
  WebPagination,
} from "./components";
import { RouteComponentProps } from "react-router-dom";
import {
  formatDateTime,
  formatDateTimeSlash,
  formatDateWithFromCalendar,
} from "../../utils/formatting";
import moment from "moment";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { Offcanvas } from "react-bootstrap";
import TransactionType from "../../models/Accounts/TransactionType";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackEvent } from "../../appInsights/TelemetryService";

interface TransactionDetailsProps extends RouteComponentProps<any> {}
const TransactionDetails: React.FC<TransactionDetailsProps> = (props) => {
  const [totalCount, settotalCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(10);
  const [skip, setskip] = useState(0);
  const { t } = useTranslation();

  const array: any = [];

  const accountType = useSelector(
    (state: any) => state.userChoicesReducer?.accountType
  );
  const accountnumber = useSelector(
    (state: any) => state.userChoicesReducer?.accountnumber
  );
  const customerData = useSelector(
    (state: any) => state?.userChoicesReducer?.customersData
  );
  const balance = useSelector(
    (state: any) => state.userChoicesReducer?.balance
  );
  const balancetype = useSelector(
    (state: any) => state.userChoicesReducer?.banlancetype
  );
  const userChosenPropertyAddress = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenPropertyAddress
  );
  const fromComponent = useSelector(
    (state: any) => state?.userChoicesReducer?.transactionInformation
  );
  const [transactions, setTransactionList] = useState(array);

  const accountid = accountnumber;

  const [isApiFail, setApiFailMessage] = useState(false);
  const [isNoResultsSearch, setNoresultSearchMsg] = useState(false);

  const defaultfromdate = formatDateTime(
    moment(new Date()).subtract(1, "years").toString()
  );
  const [fromDate, setFromDate] = useState(defaultfromdate);

  const defaulttodate = formatDateTime(moment(new Date()).toString());
  const [toDate, setToDate] = useState(defaulttodate);
  const [invalidDate, setDateErrorMessage] = useState(false);
  const [dateFromErrorMessage, setDateFromErrorMessage] = useState(false);
  const [dateTOErrorMessage, setDateTOErrorMessage] = useState(false);

  const [fromdateValue, setDateFrom] = useState(
    formatDateTime(defaultfromdate)
  );
  const [todateValue, setDateTo] = useState(defaulttodate);

  const [isvisible, setVisibility] = React.useState("block");
  const [isErrorVisible, setErrorVisibility] = React.useState("none");

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setsearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [filter, setfilter] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const transactionTypeOptions = TransactionType.map((f) => ({
    key: f.key,
    value: f.value,
  }));

  const [defaulttransactiontype, setdefaulttransactiontype] = useState("All");

  const handleChangeTransactionType = (e: { target: { value: any } }) => {
    setfilter(true);
    setdefaulttransactiontype(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent("TransactionDetails");
    setIsLoading(true);
    setApiFailMessage(false);
    getDataFromDates(
      fromDate,
      toDate,
      resultsPerPage,
      skip,
      searchTerm,
      defaulttransactiontype
    );
  }, []);

  const handleReset = () => {
    setIsLoading(true);
    setDateFrom(defaultfromdate);
    setDateTo(defaulttodate);

    setFromDate(defaultfromdate);
    setToDate(defaulttodate);
    setresultsPerPage(10);
    setskip(0);
    setcurrentPage(0);
    setsearchTerm("");
    setdefaulttransactiontype("All");
    setDateErrorMessage(false);
    setVisibility("block");
    getDataFromDates(defaultfromdate, defaulttodate, 10, 0, "", "All");
    setIsLoading(false);
  };

  const backButtonHandler = () => {
    if (fromComponent === "acc") props.history.push("/AccountResult");
    else props.history.push("/Customer360View");
  };

  const applyFromDateFilter = (e: any) => {
    if (dateTOErrorMessage !== true) {
      setFromDate(e);
      const todayDate = moment(new Date()).toString();
      if (!e) {
        setDateErrorMessage(true);
        setDateFromErrorMessage(false);
        settotalCount(0);
        setVisibility("none");
      } else if (
        moment(e).format("YYYY-MM-DD") > moment(todayDate).format("YYYY-MM-DD")
      ) {
        setDateErrorMessage(false);
        setDateFromErrorMessage(true);
        setNoresultSearchMsg(true);
        settotalCount(0);
        setVisibility("none");
      } else if (e > toDate) {
        setDateErrorMessage(true);
        setDateFromErrorMessage(false);
        settotalCount(0);
        setVisibility("none");
      } else if (toDate > todayDate) {
        setDateErrorMessage(true);
        setDateFromErrorMessage(false);
        settotalCount(0);
        setVisibility("none");
      } else {
        setDateErrorMessage(false);
        setDateFromErrorMessage(false);
        setVisibility("block");
        getDataFromDates(
          e,
          toDate,
          resultsPerPage,
          skip,
          searchTerm,
          defaulttransactiontype
        );
      }
    }
  };
  const onChangePage = (e: any, page: number) => handleChangePage(page);

  const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
    setresultsPerPage((e.target as any).value);
    setskip(0);
    setcurrentPage(0);
    getDataFromDates(
      fromDate,
      toDate,
      (e.target as any).value,
      0,
      searchTerm,
      defaulttransactiontype
    );
  };

  const handleChangePage = (page: number) => {
    setcurrentPage(page);
    setskip(resultsPerPage * page);
    getDataFromDates(
      fromDate,
      toDate,
      resultsPerPage,
      resultsPerPage * page,
      searchTerm,
      defaulttransactiontype
    );
  };

  const getDataFromDates = async (
    datefrom: any,
    dateto: any,
    resultsPerPage: number,
    skip: number,
    searchTerm: string,
    transactiontype: string
  ) => {
    try {
      const mmntFrom = moment(datefrom);
      const datefromValue = formatDateTimeSlash(mmntFrom.toString());
      const mmntTo = moment(dateto);
      const datetoValue = formatDateTimeSlash(mmntTo.toString());
      let sortedTransactions: any = [];
      if (mmntFrom > mmntTo) {
        setApiFailMessage(false);
        setDateErrorMessage(true);
        setVisibility("none");
      } else {
        const transactionDetails = await api.getTransactionsSearch(
          accountid,
          resultsPerPage,
          skip,
          datefromValue,
          datetoValue,
          searchTerm,
          transactiontype
        );
        if (transactionDetails?.totalCount > 0) {
          setApiFailMessage(false);
          setIsLoading(false);
          let pendingTransactions: any = [];
          let nonPendingTransactions: any = [];

          transactionDetails.transactions.forEach((element: any) => {
            if (element.statuses["is-posted"]) {
              nonPendingTransactions.push(element);
            } else {
              pendingTransactions.push(element);
            }
          });
          pendingTransactions.forEach((elementPending: any) => {
            sortedTransactions.push(elementPending);
          });
          nonPendingTransactions.forEach((elementNonPending: any) => {
            sortedTransactions.push(elementNonPending);
          });
          setTransactionList(sortedTransactions);
          setVisibility("block");
          setErrorVisibility("none");
        } else {
          setIsLoading(false);
          setTransactionList(sortedTransactions);
          setVisibility("none");
          if (searchTerm != "") {
            setNoresultSearchMsg(true);
            setApiFailMessage(false);
          } else {
            if (filter != true) {
              setNoresultSearchMsg(false);
            } else {
              setNoresultSearchMsg(true);
              setApiFailMessage(false);
            }
          }
          setErrorVisibility("block");
        }
        settotalCount(transactionDetails?.totalCount);
        setfilter(false);
      }
    } catch (e) {
      setIsLoading(false);
      setApiFailMessage(true);
      setNoresultSearchMsg(false);
      setVisibility("none");
      console.error(e);
    }
  };
  const applyFromToFilter = (e: any) => {
    setToDate(e);
    const todayDate = formatDateTime(moment(new Date()).toString());
    if (!e) {
      setDateErrorMessage(true);
      settotalCount(0);
      setDateTOErrorMessage(false);
      setVisibility("none");
    } else if (
      moment(e).format("DD MMM, YYYY") >
      moment(todayDate).format("DD MMM, YYYY")
    ) {
      setDateErrorMessage(false);
      settotalCount(0);
      setDateTOErrorMessage(true);
      setNoresultSearchMsg(true);
      setDateFromErrorMessage(false);
      setVisibility("none");
    } else if (fromDate > e) {
      setDateErrorMessage(true);
      settotalCount(0);
      setDateTOErrorMessage(false);
      setVisibility("none");
    } else if (fromDate > todayDate || e > todayDate) {
      setDateErrorMessage(true);
      settotalCount(0);
      setDateTOErrorMessage(false);
      setVisibility("none");
    } else {
      setDateErrorMessage(false);
      setDateTOErrorMessage(false);
      settotalCount(0);
      setVisibility("block");
      getDataFromDates(
        fromDate,
        e,
        resultsPerPage,
        skip,
        searchTerm,
        defaulttransactiontype
      );
    }
  };

  const handleSubmit = (s: string): void => {
    if (!invalidDate) {
      setcurrentPage(0);
      setsearchTerm(s);
      getDataFromDates(
        fromDate,
        toDate,
        resultsPerPage,
        0,
        s,
        defaulttransactiontype
      );
    }
  };
  const handleResetSubmit = (s: string): void => {
    setcurrentPage(0);
    setsearchTerm("");
    getDataFromDates(
      fromDate,
      toDate,
      resultsPerPage,
      0,
      "",
      defaulttransactiontype
    );
  };

  const handleFilterSubmit = () => {
    if (!invalidDate) {
      setcurrentPage(0);
      getDataFromDates(
        fromDate,
        toDate,
        resultsPerPage,
        0,
        searchTerm,
        defaulttransactiontype
      );
    }
    setShow(false);
  };
  const fetchData = () => {
    let skipResult = 0;
    let totalValue = 0;
    if (totalCount > resultsPerPage) {
      if (skip === 0 && resultsPerPage < 998) {
        skipResult = 0;
        totalValue = 999;
        setskip(0);
      } else {
        skipResult = resultsPerPage;
        totalValue = 999 + skipResult;
        setskip(skipResult);
      }
      setresultsPerPage(totalValue);
      getDataFromDates(
        fromDate,
        toDate,
        totalValue,
        skipResult,
        searchTerm,
        defaulttransactiontype
      );
    }
  };

  return (
    <>
      <WebPagination>
        <div id="main_div_tx" className=" bg-20% bg-fixed" role="main">
          {!isLoading ? (
            <div className="container mx-auto pb-12 ">
              <div className="w-full px-4 lg:px-0 global-txt">
                <div className="mb-8 md:mb-12 mt-4">
                  <p className="text-teal m-0 text-xl">
                    <a
                      data-testid="BackBtn"
                      className="back-btn"
                      onClick={backButtonHandler}
                    >
                      <i className="fas fa-angle-left mr-2"></i>
                      <span>{t("back")}</span>
                    </a>
                  </p>
                </div>
                <h1 className="mb-1" data-testid="heading">
                  {t("Account_transactions")}
                </h1>
                <div className="w-full text-lg flex flex-col md:flex-row md:justify-between global-txt">
                  <p className="text-lg mt-2">
                    {customerData?.person.name.title}{" "}
                    {customerData?.person.name.givenName}{" "}
                    {customerData?.person.name.surname} -{" "}
                    {userChosenPropertyAddress}
                  </p>
                  <div>
                    <strong>
                      {t("Balance")} {balance} {balancetype}
                    </strong>
                  </div>
                </div>
                <div className="pb-2 md:pb-0">
                  <strong>
                    {accountType} - {accountnumber}
                  </strong>
                </div>

                <div>
                  <div className="ipad-grid flex flex-col lg:flex-row justify-between items-center py-4 border-t border-b border-grey bg-white">
                    <div className="w-full ipad-w-full md:w-5/12 lg:w-1/2 flex justify-between items-center lg:mb-0 global-txt">
                      <div className="w-1/2 ipad-w-full flex items-center">
                        <label className="w-full flex items-center md:pr-2">
                          <span className="pr-3 text-date md:text-base pr-2 pt-2">
                            {t("Tx_Fromdate")}
                          </span>
                          <input
                            data-testid="tx-fromdate"
                            id="fromdate"
                            value={fromdateValue}
                            type="date"
                            defaultValue={fromDate}
                            onBlur={(e) => applyFromDateFilter(e.target.value)}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className="border-grey w-28 md:w-full text-sm md:text-base p-2 md:p-4"
                          ></input>
                        </label>
                      </div>

                      <div className="ipad-w-full w-1/2 flex items-center pl-2 md:pr-2 sm:w-full">
                        <label className="w-full flex items-center">
                          <span className="pr-3 text-date md:text-base pt-2 pr-2 ">
                            {t("Tx_Todate")}
                          </span>
                          <input
                            data-testid="tx-todate1"
                            id="todate"
                            type="date"
                            value={todateValue}
                            defaultValue={toDate}
                            onBlur={(e) => applyFromToFilter(e.target.value)}
                            onChange={(e) => setDateTo(e.target.value)}
                            className="border-grey w-28  md:w-full text-sm md:text-base p-2 md:p-4"
                          ></input>
                        </label>
                      </div>
                    </div>

                    <div className="w-full ipad-w-full md:w-7/12 lg:w-1/2 flex justify-between items-center py-4 global-txt sm:mt-2">
                      <div
                        id="transaction-search-inputs"
                        className="w-1/2 flex items-center md:pr-2 pl-2"
                      >
                        <label className="w-full pt-2 global-txt">
                          <Search
                            className="rounded-lg-search"
                            defaultValue={searchTerm}
                            data-testid="transaction-search-box"
                            onSubmit={handleSubmit}
                            placeholder="Search"
                            onClear={handleResetSubmit}
                          />
                        </label>
                      </div>

                      <div className="w-1/2 md:mb-0 button-filter-reset flex items-center pl-8 global-txt">
                        <div className="pr-4 global-txt">
                          <a
                            data-testid="transaction-search-filter"
                            onClick={handleShow}
                            className="text-teal no-underline hover:underline global-txt"
                          >
                            <span className="msp cursor-pointer">
                              {t("Filter")}
                            </span>
                            <i className="fas fa-filter cursor-pointer pl-2"></i>
                          </a>
                        </div>

                        <div className="pr-4 global-txt">
                          <a
                            data-testid="transaction-search-Reset"
                            onClick={handleReset}
                            className="text-teal no-underline hover:underline msp global-txt"
                          >
                            <span className="cursor-pointer">{t("Reset")}</span>
                            <i className="fas fa-sync cursor-pointer pl-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-4 bg-white global-txt">
                  <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                    <div className="w-1/2 md:w-auto pr-4 md:text-left">
                      <strong>{totalCount}</strong> {t("Transaction(s)")}
                    </div>
                  </div>
                  {/* date validation messages */}

                  {!invalidDate && dateFromErrorMessage && (
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Tx_DateValidation_From")}
                        </p>
                      </div>
                    </div>
                  )}
                  {!invalidDate && dateTOErrorMessage && (
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Tx_DateValidation_To")}
                        </p>
                      </div>
                    </div>
                  )}

                  {invalidDate && (
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Tx_DateValidation_SSC004")}
                        </p>
                      </div>
                    </div>
                  )}
                  {isApiFail && !invalidDate && (
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Tx_Service_Down_SSC001")}
                        </p>
                      </div>
                    </div>
                  )}
                  {transactions.length == 0 &&
                  !invalidDate &&
                  !isNoResultsSearch ? (
                    <div id="div_errormsg" style={{ display: isErrorVisible }}>
                      <div className="w-full px-2 py-2 bg-yellow flex items-center">
                        <div className="px-4 pr-8">
                          <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            {t("Tx_Details_NotAvailable_SSC003")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    transactions.length == 0 &&
                    !invalidDate &&
                    isNoResultsSearch && (
                      <div>
                        <div className="w-full px-2 py-2 bg-yellow flex items-center">
                          <div className="px-4 pr-8">
                            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                          </div>
                          <div className="flex items-center text-black">
                            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                              {t("Tx_Details_NotAvailable_SSC006")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}

                  <div
                    id={"div_main" + 1}
                    style={{ display: isvisible }}
                    className="my-4 bg-white"
                    data-testid="main-div"
                  >
                    {/* bind data start */}
                    {transactions?.map((tx: any) => (
                      <div className="border-b border-grey p-2">
                        <div className="flex justify-between">
                          <div>
                            {" "}
                            {tx.statuses["is-posted"]
                              ? formatDateWithFromCalendar(
                                  tx.dates["transaction-date"]
                                )
                              : "-"}
                          </div>
                          <div className="text-grey-dark text-xs">
                            {parseFloat(tx["running-balance"]?.value) > 0
                              ? "£" +
                                tx["running-balance"]?.value +
                                " In credit"
                              : parseFloat(tx["running-balance"]?.value) == 0
                              ? "£" + tx["running-balance"]?.value
                              : "£" +
                                tx["running-balance"]?.value
                                  .toString()
                                  .replace("-", "") +
                                " In arrears"}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>
                            <strong>{tx["transaction-description"]}</strong>
                          </div>
                          <div>
                            <strong>
                              {tx["payment-code"] == "CR"
                                ? parseFloat(tx.amount.gross.value) > 0
                                  ? "£" + tx.amount.gross.value
                                  : "-£" +
                                    Math.abs(
                                      tx.amount.gross.value
                                        .toString()
                                        .replace(/,/g, "")
                                    ).toFixed(2)
                                : parseFloat(tx.amount.gross.value) > 0
                                ? "-£" + tx.amount.gross.value
                                : "£" +
                                  Math.abs(
                                    tx.amount.gross.value
                                      .toString()
                                      .replace(/,/g, "")
                                  ).toFixed(2)}
                            </strong>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                id="div_main"
                style={{ display: isvisible }}
                className=" main-div-pagination "
              >
                <div className="pagination flex flex-row items-center justify-end text-grey-dark text-xs md:text-sm">
                  <Paginationbox>
                    <label>
                      <Pagination
                        data-testid="tx_pagination"
                        colSpan={7}
                        count={totalCount}
                        page={currentPage}
                        rowsPerPage={resultsPerPage}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </label>
                  </Paginationbox>
                </div>
              </div>
              <Offcanvas placement="end" show={show} onHide={handleClose}>
                <div id="mySidenav" className=" shadow-lg px-2">
                  <a
                    onClick={handleClose}
                    className="closebtn cursor-pointer a-style"
                  >
                    &times;
                  </a>
                  <div className="container px-2">
                    <div className="row">
                      <Offcanvas.Header>
                        <div className="col-md-12 mt-8">
                          <h2 className="border-b border-tint text-teal border-b border-grey">
                            {" "}
                            {t("Filter")}{" "}
                          </h2>
                        </div>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="col-md-12 mt-8 bt-z">
                          <div className="pr-12 mr-8 wd-28 global-txt">
                            <strong>{t("PaymentType")}</strong>
                          </div>
                          <select
                            data-testid="repairs-search-status"
                            value={defaulttransactiontype}
                            id="repairs-search-status"
                            className="block w-full mt-1 global-txt rounded-lg border border-grey cursor-pointer"
                            onChange={handleChangeTransactionType}
                          >
                            {transactionTypeOptions.map((e, key) => {
                              return (
                                <option key={key} value={e.key}>
                                  {e.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-12 mt-8">
                          <button
                            onClick={handleFilterSubmit}
                            data-testid="repairs-search-button"
                            className=" md:min-w-50"
                          >
                            {t("Search")}
                            <i className="fas fa-search relative left-5 text-white pointer-events-none float-right pt-1"></i>
                          </button>
                        </div>
                      </Offcanvas.Body>
                    </div>
                  </div>
                </div>
              </Offcanvas>
            </div>
          ) : (
            <div className="div-loader">
              <div className="SpinnerWrapper">
                <LoadingSpinner />
              </div>
            </div>
          )}
        </div>
      </WebPagination>
      <MobilePagination>
        <div
          id="scrollableDiv"
          data-testid="scrollableDiv"
          className="mob-view-only"
          style={{
            height: 800,
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={transactions?.length}
            next={fetchData}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={<p></p>}
            scrollableTarget="scrollableDiv"
          >
            <div id="main_div_tx" className=" bg-20% bg-fixed" role="main">
              {!isLoading ? (
                <div className="container mx-auto pb-12 ">
                  <div className="w-full px-2 lg:px-0 global-txt">
                    <div className="mb-8 md:mb-12 mt-4">
                      <p className="text-teal m-0 text-xl">
                        <a
                          data-testid="custBackBtn"
                          className="back-btn"
                          onClick={backButtonHandler}
                        >
                          <i className="fas fa-angle-left mr-2"></i>{" "}
                          <span className="margin-css">{t("back")}</span>
                        </a>
                      </p>
                    </div>
                    <h1 className="mb-1" data-testid="headingTransaction">
                      {t("Account_transactions")}
                    </h1>
                    <div className="w-full text-lg flex flex-col md:flex-row md:justify-between global-txt">
                      <p className="text-lg mt-2">
                        {customerData?.person.name.title}{" "}
                        {customerData?.person.name.givenName}{" "}
                        {customerData?.person.name.surname} -{" "}
                        {userChosenPropertyAddress}
                      </p>
                      <div>
                        <strong>
                          {t("Balance")} {balance} {balancetype}
                        </strong>
                      </div>
                    </div>
                    <div className="pb-2 md:pb-0">
                      <strong>
                        {accountType} - {accountnumber}
                      </strong>
                    </div>

                    <div>
                      <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center py-4 border-t border-b border-grey bg-white">
                        <div className="w-full md:flex-row sm:flex-row md:w-1/2 lg:w-1/3 flex justify-between items-center lg:mb-0 global-txt ">
                          <div className="w-1/2 flex items-center">
                            <label className="w-full flex items-center md:pr-2">
                              <span className="pr-1 text-date md:text-base pl-0 pt-2">
                                {t("Tx_Fromdate")}
                              </span>
                              <input
                                data-testid="tx-fromdate1"
                                id="fromdate"
                                value={fromdateValue}
                                type="date"
                                defaultValue={fromDate}
                                onBlur={(e) =>
                                  applyFromDateFilter(e.target.value)
                                }
                                onChange={(e) => setDateFrom(e.target.value)}
                                className="border-grey w-34 md:w-full text-sm md:text-base p-2 md:p-4 md:w-mid"
                              ></input>
                            </label>
                          </div>

                          <div className="w-1/2 flex items-center md:pl-2 md:pr-2">
                            <label className="w-full flex items-center">
                              <span className="pr-1 text-date md:text-base pt-2 ">
                                {t("Tx_Todate")}{" "}
                              </span>
                              <input
                                data-testid="tx-todate"
                                id="todate"
                                type="date"
                                value={todateValue}
                                defaultValue={toDate}
                                onBlur={(e) =>
                                  applyFromToFilter(e.target.value)
                                }
                                onChange={(e) => setDateTo(e.target.value)}
                                className="border-grey w-34 md:w-full text-sm md:text-base p-2 md:p-4 md:w-mid"
                              ></input>
                            </label>
                          </div>
                        </div>

                        <div className="md:flex-row sm:flex-row w-full md:w-1/2 lg:w-1/2 flex justify-between items-center py-4 global-txt">
                          <div
                            id="transaction-search-inputs"
                            className="w-full sm:px-2 lg:w-3/12 flex items-center sm:mb-4 sm:pb-4 mb-0"
                          >
                            <label className="w-full">
                              <Search
                                className="block w-full mt-1 rounded-lg sm-mr-1 mx-1"
                                defaultValue={searchTerm}
                                onSubmit={handleSubmit}
                                placeholder="Search"
                                onClear={handleResetSubmit}
                              />
                            </label>
                          </div>

                          <div className="w-1/2 md:mb-0 button-filter-reset flex items-center pl-8 global-txt pb-12 sm:pb-6 md:pb-0">
                            <div className="pr-4">
                              <a
                                data-testid="transaction-search-filter"
                                onClick={handleShow}
                                className="text-teal no-underline hover:underline"
                              >
                                <span className="msp">{t("Filter")}</span>
                                <i className="fas fa-filter cursor-pointer pl-2"></i>
                              </a>
                            </div>

                            <div className="pr-4">
                              <a
                                data-testid="transaction-search-Reset1"
                                onClick={handleReset}
                                className="text-teal no-underline hover:underline msp"
                              >
                                <span className="">{t("Reset")}</span>{" "}
                                <i className="fas fa-sync cursor-pointer pl-2"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my-4 bg-white">
                      <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                        <div className="w-1/2 md:w-auto pr-4 md:text-left">
                          <strong>{totalCount}</strong> {t("Transaction(s)")}
                        </div>
                      </div>
                      {/* date validation messages */}
                      {!invalidDate && dateFromErrorMessage && (
                        <div className="w-full px-2 py-2 bg-yellow flex items-center">
                          <div className="px-4 pr-8">
                            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                          </div>
                          <div className="flex items-center text-black">
                            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                              {t("Tx_DateValidation_From")}
                            </p>
                          </div>
                        </div>
                      )}
                      {!invalidDate && dateTOErrorMessage && (
                        <div className="w-full px-2 py-2 bg-yellow flex items-center">
                          <div className="px-4 pr-8">
                            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                          </div>
                          <div className="flex items-center text-black">
                            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                              {t("Tx_DateValidation_To")}
                            </p>
                          </div>
                        </div>
                      )}
                      {invalidDate && (
                        <div className="w-full px-2 py-2 bg-yellow flex items-center">
                          <div className="px-4 pr-8">
                            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                          </div>
                          <div className="flex items-center text-black">
                            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                              {t("Tx_DateValidation_SSC004")}
                            </p>
                          </div>
                        </div>
                      )}
                      {isApiFail && !invalidDate && (
                        <div className="w-full px-2 py-2 bg-yellow flex items-center">
                          <div className="px-4 pr-8">
                            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                          </div>
                          <div className="flex items-center text-black">
                            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                              {t("Tx_Service_Down_SSC001")}
                            </p>
                          </div>
                        </div>
                      )}
                      {transactions.length == 0 &&
                      !invalidDate &&
                      !isNoResultsSearch ? (
                        <div
                          id="div_errormsg"
                          style={{ display: isErrorVisible }}
                        >
                          <div className="w-full px-2 py-2 bg-yellow flex items-center">
                            <div className="px-4 pr-8">
                              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                            </div>
                            <div className="flex items-center text-black">
                              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                {t("Tx_Details_NotAvailable_SSC003")}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        transactions.length == 0 &&
                        !invalidDate &&
                        isNoResultsSearch && (
                          <div>
                            <div className="w-full px-2 py-2 bg-yellow flex items-center">
                              <div className="px-4 pr-8">
                                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                              </div>
                              <div className="flex items-center text-black">
                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                  {t("Tx_Details_NotAvailable_SSC006")}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <div
                        id={"div_main" + 1}
                        style={{ display: isvisible }}
                        className="my-4 bg-white"
                        data-testid="main-div"
                      >
                        {/* bind data start */}
                        {transactions?.map((tx: any) => (
                          <div className="border-b border-grey p-2">
                            <div className="flex justify-between">
                              <div>
                                {" "}
                                {tx.statuses["is-posted"]
                                  ? formatDateWithFromCalendar(
                                      tx.dates["transaction-date"]
                                    )
                                  : "-"}
                              </div>
                              <div className="text-grey-dark text-xs">
                                {parseFloat(tx["running-balance"]?.value) > 0
                                  ? "£" +
                                    tx["running-balance"]?.value +
                                    " In credit"
                                  : parseFloat(tx["running-balance"]?.value) ==
                                    0
                                  ? "£" + tx["running-balance"]?.value
                                  : "£" +
                                    tx["running-balance"]?.value
                                      .toString()
                                      .replace("-", "") +
                                    " In arrears"}
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div>
                                <strong>{tx["transaction-description"]}</strong>
                              </div>
                              <div>
                                <strong>
                                  {tx["payment-code"] == "CR"
                                    ? tx.amount.gross.value > 0
                                      ? "£" + tx.amount.gross.value
                                      : "-£" +
                                        Math.abs(
                                          tx.amount.gross.value
                                            .toString()
                                            .replace(/,/g, "")
                                        ).toFixed(2)
                                    : tx.amount.gross.value > 0
                                    ? "-£" + tx.amount.gross.value
                                    : "£" +
                                      Math.abs(
                                        tx.amount.gross.value
                                          .toString()
                                          .replace(/,/g, "")
                                      ).toFixed(2)}
                                </strong>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Offcanvas placement="end" show={show} onHide={handleClose}>
                    <div id="mySidenav" className=" shadow-lg px-2">
                      <a
                        onClick={handleClose}
                        className="closebtn cursor-pointer a-style"
                      >
                        &times;
                      </a>
                      <div className="container px-2">
                        <div className="row">
                          <Offcanvas.Header>
                            <div className="col-md-12 mt-8">
                              <h2 className="border-b border-tint text-teal border-b border-grey">
                                {" "}
                                {t("Filter")}{" "}
                              </h2>
                            </div>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <div className="col-md-12 mt-8 ">
                              <div className="pr-12 mr-8 wd-28 global-txt">
                                <strong>{t("PaymentType")}</strong>
                              </div>
                              <select
                                data-testid="repairs-search-status"
                                value={defaulttransactiontype}
                                id="repairs-search-status"
                                className="block w-full mt-1 global-txt rounded-lg border border-grey cursor-pointer"
                                onChange={handleChangeTransactionType}
                              >
                                {transactionTypeOptions.map((e, key) => {
                                  return (
                                    <option key={key} value={e.key}>
                                      {e.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-md-12 mt-8">
                              <button
                                onClick={handleFilterSubmit}
                                data-testid="repairs-search-button"
                                className=" md:min-w-50"
                              >
                                {t("Search")}
                                <i className="fas fa-search relative left-5 text-white pointer-events-none float-right pt-1"></i>
                              </button>
                            </div>
                          </Offcanvas.Body>
                        </div>
                      </div>
                    </div>
                  </Offcanvas>
                </div>
              ) : (
                <div className="div-loader">
                  <div className="SpinnerWrapper">
                    <LoadingSpinner />
                  </div>
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </MobilePagination>
    </>
  );
};

export default TransactionDetails;
