import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteComponentProps
} from "react-router-dom";
import "./utils/translation";
import Header from "./storybook/Header";
import Footer from "./storybook/Footer";
import "./App.css";
import "./main.css";
import Loader from "react-loader-spinner";
import Login from "./compositions/Login";
import { setUpAppInsights } from './appInsights/TelemetryService'
import LandingView from './compositions/LandingView'
import GenericError from "./compositions/GenericError";
import RepairAsset from "./compositions/ReapirAsset";
import VulnerabilityQuestion from "./compositions/VulnerabilityQuestion";
import CovidQuestion from "./compositions/CovidQuestion";
import SupplementaryQuestion from "./compositions/SupplementaryQuestion";
import RepairLocation from "./compositions/RepairLocation";
import DuplicateRepair from "./compositions/DuplicateRepair";
import RepairAdditionalDetails from "./compositions/RepairAdditionalDetails";
import VandalismQuestion from "./compositions/VandalismQuestion"
import RepairEmergency from "./compositions/RepairEmergency";
import ConfirmationDetails from "./compositions/ConfirmationDetails";
import CustomerDetails from "./compositions/CustomerDetails";
import AppointmentScheduler from "./compositions/AppointmentScheduler";
import NoAppointment from "./compositions/NoAppointment";
import AppointmentConfirmation from "./compositions/AppointmentConfirmation";
import NoSuitableAppointment from "./compositions/NoSuitableAppointment";
import NoSuitableAppointmentConfirmation from "./compositions/NoSuitableAppointmentConfirmation";
import RepairFinalConfirmation from "./compositions/RepairFinalConfirmation";
import Customer360View from "./compositions/Customer360View";
import CustomerCard from "./compositions/CustomerCard";
import AssetCard from "./compositions/AssetCard";
import WorkCard from "./compositions/WorkCard";
import CustomerWorkOrderResultComponent from "./compositions/CustomerWorkOrderResult";
import AccountCard from "./compositions/AccountCard";
import CustomerEditProfile from "./compositions/CustomerEditProfile";
import WorkOrderChronology from "./compositions/WorkOrderChronology";
import KeyContact from "./compositions/KeyContact";
import Asset360View from "./compositions/Asset360View";
import WorkOrder360View from "./compositions/WorkOrder360View";
import AccountResult from "./compositions/AccountResult";
import AssetWorkOrderResult from "./compositions/AssetWorkOrderResult";
import TransactionDetails from "./compositions/TransactionDetails";
import PermissionError from "./compositions/PermissionError";
import Case360View from "./compositions/Cases/Case360View";
import CaseType from "./compositions/Cases/CaseType";
import SelectTenancy from "./compositions/Cases/SelectTenancy";
import CaseTypeQuestion from "./compositions/Cases/QuestionType"
import DropdownQuestion from "./compositions/Cases/QuestionType/DropdownTypeQuestion"
import RadioTypeQuestion from "./compositions/Cases/QuestionType/RadioTypeQuestion"
import TextTypeQuestion from "./compositions/Cases/QuestionType/TextTypeQuestion"
import ContactDetails from "./compositions/Cases/ContactDetails";
import CaseReview from "./compositions/Cases/CaseReview";
import CaseFinalConfirmation from "./compositions/Cases/CaseFinalConfirmation";
import CaseAsset from "./compositions/Cases/CaseAsset"
import CaseSelectCustomer from "./compositions/Cases/CaseSelectCustomer"
import SelectCustomer from "./compositions/Cases/SelectCustomer";
import CaseContactInformation from "./compositions/Cases/CaseContactInformation"
import CaseChronologyList from "./compositions/Cases/ChronologyListView";
import CaseList from "./compositions/Cases/CaseList";
import CreateArrearsAction from "./compositions/UCCCreateArrearsAction";
import AdditionalNeedAlert from "./compositions/AdditionalNeedAlert";
import ComposeSMS from "./compositions/ComposeSMS";
import SMSConfirmation from "./compositions/SMSConfirmation";
import UploadMedia from "./compositions/Cases/UploadMedia";
import { FeatureFlagsProvider } from "./compositions/contexts/FeatureFlags";
import ViewMedia from "./compositions/Cases/ViewMedia";
import CustomerConsent from "./compositions/FinancialInclusion/CustomerConsent";
import ReferralReason from "./compositions/FinancialInclusion/ReferralReason";
import AdditionalReferralDetails from "./compositions/FinancialInclusion/AdditionalReferralDetails";
import ReferralReview from "./compositions/FinancialInclusion/ReferralReview";
import ReferralConfirmation from "./compositions/FinancialInclusion/ReferralConfirmation";
import { useSelector } from "react-redux";
import RepairUploadMedia from "./compositions/RepairUploadMedia";
import RepairViewMedia from "./compositions/RepairViewMedia";
import CaseSelectAction from "./compositions/Cases/CaseSelectAction"
import CaseUpdate from "./compositions/Cases/CaseUpdate";
import CaseUpdateAction from "./compositions/Cases/CaseUpdateAction"
import SurveyConfirmationDetails from "./compositions/SurveyConfirmationDetails";
import SurveyLocation from "./compositions/SurveyLocation";
import SurveyFinalPage from "./compositions/SurveyFinalPage";
import ComposeEmail from "./compositions/ComposeEmail";
import EmailConfirmation from "./compositions/EmailConfirmation";
import { PermissionsList } from "./models/Permissions";
import BlockCommunication from "./compositions/BlockCommunication";
import NotesCard from "./compositions/NotesCard";



import DateTimeTypeQuestion from "./compositions/Cases/QuestionType/DateTimeTypeQuestion";

setUpAppInsights();

const App: React.FC<any> = (props) => {
  const [showEmailBtn, setShowEmailBtn] = useState(false);
  const loggedInStatus = localStorage.getItem('login')? true: false
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  useEffect(()=>{
    const showSMS = getPermissionsData?.indexOf(
      PermissionsList.create_asset360_block_sms
    );
    if (showSMS !== -1) {
      setShowEmailBtn(true);
    } else {
      setShowEmailBtn(false);
    }
  },[])


  let authRoutes = (
    <Switch>
         <Route
        path="/CaseUpdateAction"
        render={(props: RouteComponentProps<any>) => <CaseUpdateAction {...props} />}
      />
        <Route
        path="/CaseSelectAction"
        render={(props: RouteComponentProps<any>) => <CaseSelectAction {...props} />}
      />
        <Route
        path="/repairviewmedia"
        render={(props: RouteComponentProps<any>) => <RepairViewMedia  {...props} />}
      />
       <Route
          path="/RepairUploadMedia"
          render={(props: RouteComponentProps<any>) => (
            <RepairUploadMedia {...props} />
          )}
        />
      <Route
        path="/AdditionalNeedAlert"
        render={(props: RouteComponentProps<any>) => <AdditionalNeedAlert {...props} />}
      />
      <Route
        path="/CaseReview"
        render={(props: RouteComponentProps<any>) => <CaseReview {...props} />}
      />
       <Route path="/composeSMS"
        render={(props: RouteComponentProps<any>) => (
          <ComposeSMS {...props} />
        )}
      />
      <Route path="/EmailConfirmation"
        render={(props: RouteComponentProps<any>) => (
          <EmailConfirmation {...props} />
        )}
      />

       <Route path="/SMSConfirmation"
        render={(props: RouteComponentProps<any>) => (
          <SMSConfirmation {...props} />
        )}
      />
      <Route
        path="/Case360View"
        render={(props: RouteComponentProps<any>) => (
          <Case360View {...props} />
        )}
      />
      <Route
        path="/SelectTenancy"
        render={(props: RouteComponentProps<any>) => (
          <SelectTenancy {...props} />
        )}
      />
      <Route
        path="/Asset360View"
        render={(props: RouteComponentProps<any>) => (
          <Asset360View {...props} />
        )}
      />
      <Route
        path="/VulnerabilityQuestion"
        render={(props: RouteComponentProps<any>) => (
          <VulnerabilityQuestion {...props} />
        )}
      />
      <Route
        path="/SupplementaryQuestion"
        render={(props: RouteComponentProps<any>) => (
          <SupplementaryQuestion {...props} />
        )}
      />
      <Route
        path="/RepairAsset"
        render={(props: RouteComponentProps<any>) => <RepairAsset {...props} />}
      />
      <Route
        path="/CovidQuestion"
        render={(props: RouteComponentProps<any>) => (
          <CovidQuestion {...props} />
        )}
      />
      <Route
        path="/RepairLocation"
        render={(props: RouteComponentProps<any>) => (
          <RepairLocation {...props} />
        )}
      />
      <Route
        path="/CustomerCard"
        render={(props: RouteComponentProps<any>) => (
          <CustomerCard {...props} />
        )}
      />
      <Route
        path="/AssetCard"
        render={(props: RouteComponentProps<any>) => (
          <AssetCard {...props} />
        )}
      />
      <Route
        path="/NotesCard"
        render={(props: RouteComponentProps<any>) => (
          <NotesCard workOrderSerialNumber={""} workOrderSequenceNumber={""} {...props} />
        )}
      />
      <Route
        path="/WorkCard"
        render={(props: RouteComponentProps<any>) => (
          <WorkCard {...props} />
        )}
      />
      <Route
        path="/CustomerDetails"
        render={(props: RouteComponentProps<any>) => (
          <CustomerDetails {...props} />
        )}
      />

      <Route
        path="/DuplicateRepair"
        render={(props: RouteComponentProps<any>) => (
          <DuplicateRepair {...props} />
        )}
      />
      <Route
        path="/RepairAdditionalDetails"
        render={(props: RouteComponentProps<any>) => (
          <RepairAdditionalDetails {...props} />
        )}
      />
      <Route
        path="/VandalismQuestion"
        render={(props: RouteComponentProps<any>) => (
          <VandalismQuestion {...props} />
        )}
      />
      <Route
        path="/RepairEmergency"
        render={(props: RouteComponentProps<any>) => (
          <RepairEmergency {...props} />
        )}
      />
      <Route
        path="/TransactionDetails"
        render={(props: RouteComponentProps<any>) => (
          <TransactionDetails {...props} />
        )}
      />
      <Route
        path="/ConfirmationDetails"
        render={(props: RouteComponentProps<any>) => (
          <ConfirmationDetails {...props} />
        )}
      />
       <Route
        path="/SurveyConfirmationDetails"
        render={(props: RouteComponentProps<any>) => (
          <SurveyConfirmationDetails {...props} />
        )}
      />  
       <Route
        path="/SurveyFinalPage"
        render={(props: RouteComponentProps<any>) => (
          <SurveyFinalPage {...props} />
        )}
      />  
      <Route
        path="/AppointmentScheduler"
        render={(props: RouteComponentProps<any>) => (
          <AppointmentScheduler {...props} />
        )}
      />
      <Route
        path="/NoAppointment"
        render={(props: RouteComponentProps<any>) => (
          <NoAppointment {...props} />
        )}
      />
      <Route
        path="/AppointmentConfirmation"
        render={(props: RouteComponentProps<any>) => (
          <AppointmentConfirmation {...props} />
        )}
      />
      <Route
        path="/NoSuitableAppointment"
        render={(props: RouteComponentProps<any>) => (
          <NoSuitableAppointment {...props} />
        )}
      />
        <Route
        path="/SurveyLocation"
        render={(props: RouteComponentProps<any>) => (
          <SurveyLocation {...props} />
        )}
       />
      <Route
        path="/NoSuitableAppointmentConfirmation"
        render={(props: RouteComponentProps<any>) => (
          <NoSuitableAppointmentConfirmation {...props} />
        )}
      />
      <Route
        path="/RepairFinalConfirmation"
        render={(props: RouteComponentProps<any>) => (
          <RepairFinalConfirmation {...props} />
        )}
      />
      <Route
        path="/customer360View"
        render={(props: RouteComponentProps<any>) => (
          <Customer360View {...props} />
        )}
      />
      <Route
        path="/CustomerWorkOrder"
        render={(props: RouteComponentProps<any>) => (
          <CustomerWorkOrderResultComponent {...props} />
        )}
      />
      <Route
        path="/AccountCard"
        render={(props: RouteComponentProps<any>) => (
          <AccountCard {...props} />
        )}
      />
      <Route
        path="/AccountResult"
        render={(props: RouteComponentProps<any>) => (
          <AccountResult {...props} />
        )}
      />
      <Route
        path="/CustomerEditProfile"
        render={(props: RouteComponentProps<any>) => (
          <CustomerEditProfile {...props} />
        )}
      />
      <Route
        path="/WorkOrderChronology"
        render={(props: RouteComponentProps<any>) => (
          <WorkOrderChronology {...props} />
        )}
      />
      <Route
        path="/KeyContact"
        render={(props: RouteComponentProps<any>) => (
          <KeyContact {...props} />
        )}
      />
      <Route
        path="/WorkOrder360View"
        render={(props: RouteComponentProps<any>) => (
          <WorkOrder360View {...props} />
        )}
      />
      <Route
        path="/AssetWorkOrderResult"
        render={(props: RouteComponentProps<any>) => (
          <AssetWorkOrderResult {...props} />
        )}
      />

      <Route
        path="/CaseType"
        render={(props: RouteComponentProps<any>) => <CaseType {...props} />}
      />

      <Route
        path="/casefinalconfirmation"
        render={(props: RouteComponentProps<any>) => <CaseFinalConfirmation {...props} />}
      />
      
      <Route
        path="/CaseAsset"
        render={(props: RouteComponentProps<any>) => <CaseAsset {...props} />}
      />

      <Route
        path="/CaseSelectCustomer"
        render={(props: RouteComponentProps<any>) => <CaseSelectCustomer {...props} />}
      />
      <Route
        path="/CaseContactInformation"
        render={(props: RouteComponentProps<any>) => <CaseContactInformation {...props} />}
      />

      <Route
        path="/SelectCustomer"
        render={(props: RouteComponentProps<any>) => <SelectCustomer {...props} />}
      />
      <Route
        path="/CaseChronologyList"
        render={(props: RouteComponentProps<any>) => <CaseChronologyList {...props} />}
      />
      <Route
        path="/CreateArrearsAction"
        render={(props: RouteComponentProps<any>) => (
          <CreateArrearsAction {...props} />
        )}
      />
      <Route
       path="/BlockCommunication"
       render={(props: RouteComponentProps<any>) => (
         <BlockCommunication {...props} />
       )}
     />
      <Route
        path="/CaseList"
        render={(props: RouteComponentProps<any>) => <CaseList {...props} />}
      />
       <Route path='/CustomerConsent' render={(props: RouteComponentProps<any>) => <CustomerConsent {...props} />} />
      <Route path='/referralreason' render={(props: RouteComponentProps<any>) => <ReferralReason {...props} />} />
      <Route path='/referraladditionaldetails' render={(props: RouteComponentProps<any>) => <AdditionalReferralDetails {...props} />} />
      <Route path= '/referralreview' render={(props: RouteComponentProps<any>) => <ReferralReview {...props} /> } />
      <Route path= '/referralConfirmation' render={(props: RouteComponentProps<any>) => <ReferralConfirmation {...props} /> } /> 
      {showEmailBtn && <Route path= '/composeEmail'render={(props: RouteComponentProps<any>) => <ComposeEmail {...props} />} />}  
      <FeatureFlagsProvider>

        <Route
          path="/CaseQuestionnaireRadio"
          render={(props: RouteComponentProps<any>) => (
            <RadioTypeQuestion {...props} />
          )}
        />
        <Route
          path="/CaseQuestionnaireText"
          render={(props: RouteComponentProps<any>) => (
            <TextTypeQuestion {...props} />
          )}
              />
              <Route
                  path="/CaseQuestionnaireDateTime"
                  render={(props: RouteComponentProps<any>) => (
                      <DateTimeTypeQuestion {...props} />
                  )}
              />
        <Route
          path="/ContactDetails"
          render={(props: RouteComponentProps<any>) => (
            <ContactDetails {...props} />
          )}
        />
        <Route
          path="/CaseQuestionnaireDropdown"
          render={(props: RouteComponentProps<any>) => (
            <DropdownQuestion {...props} />
          )}
        />
        <Route
          path="/CaseQuestionnaire"
          render={(props: RouteComponentProps<any>) => (
            <CaseTypeQuestion {...props} />
          )}
        />


        <Route
          path="/uploadmedia"
          render={(props: RouteComponentProps<any>) => (
              <UploadMedia {...props} />
          )}
        />

        <Route
          path="/viewmedia"
          render={(props: RouteComponentProps<any>) => <ViewMedia {...props} />}
        />
          <Route
        path="/SurveyAsset"
        render={(props: RouteComponentProps<any>) => (
          <RepairAsset {...props} />
        )}
      />
         <Route
        path="/addupdate"
        render={(props: RouteComponentProps<any>) => <CaseUpdate />}
      />

        </FeatureFlagsProvider>
    </Switch>
  )

  let routes = (
    <Switch>
      
      <Route
        path="/Login"
        render={(props: RouteComponentProps<any>) => (
          <Login {...props} />
        )}
      />
       <Route
        path="/LandingView"
        render={(props: RouteComponentProps<any>) => (
          <LandingView {...props} />
        )}
      />
       {/* <Route path="/" exact component={Login} />
      
      {loggedInStatus && authRoutes}  */}
  
      <Route
        path="/GenericError"
        render={(props: RouteComponentProps<any>) => (
          <GenericError {...props} />
        )}
      />
      <Route
        path="/PermissionError"
        render={(props: RouteComponentProps<any>) => (
          <PermissionError {...props} />
        )}
      />

   <Route path="/" exact component={Login} /> 
      {loggedInStatus && authRoutes}  
      <Redirect to="/" />
    </Switch>
  )



  return (
    <BrowserRouter>
      <Header />
      <Suspense fallback={Loader}>{routes}</Suspense>
      <Footer name="User" />
    </BrowserRouter>
  )

}

export default App;
