export const CaseStatusFilter = [
    {
      key: "All",
      value: "",
    },
    {
      key: "Active",
      value: "Active",
    },
    {
      key: "Cancelled",
      value: "Cancelled",
    },
    {
      key: "Completed",
      value: "Resolved",
    },    
  ];
  export default CaseStatusFilter;
  