export const WorkOrderPropertyFilter= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Property',
        value: 'Property',
    }, 
    {
        key: 'Divided block',
        value: 'Divided block',
    }, 
    {
        key: 'Block',
        value: 'Block',
    },
    {
        key: 'Estate',
        value: 'Estate',
    }
    
    
     
  ]