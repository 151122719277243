import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux"
import Button from "../../Button/Button";

interface ComposeSMS extends RouteComponentProps<any> {
  
}

const SMSConfirmation: React.FC<ComposeSMS> = (props:any) => {

  const { t } = useTranslation();

  const buttonClickHandler = () => {
    props.history.push("/Asset360View");
  }

  const assetDataResponse = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataResponse
  );
  
  return (
    <div id="repFinalConfirm-container" role="main" className="container sm:px-2 ctn-fht">
    <div
      id="repFinalConfirm-div1"
       className="h5 repair-text mt-5"
    >
      {" "}
      <h1 className="text-purple-100 text-2xl mb-2 global-txt">{t("SMS_BC011")}</h1> 
    </div>
    <div id="repFinalConfirm-div2">
        <p id="repFinalConfirm-p" className="repair-text global-txt">
          {t('SMS_BC012a')}
        </p> {t('SMS_BC012b')}<p>

        </p>
    </div>

      <div className="repair-text">
      <div className="border border-grey rounded-lg px-6 mb-6 md:mb-12 global-txt">
          <p className="mt-4">
          {t("SMS_BC013")} <br/><strong>{assetDataResponse?.addresses?.postalAddresses[0]?.formattedAddress}</strong>
          </p>
          <p>
            {t("SMS_BC014")} <br/><strong>Sent</strong>
          </p>
        </div>
     </div>
        
    <div
      id="repFinalConfirm-main-btn"
      className="button-positions flex justify-between items-center pb-4 md:pb-8 flex-wrap text-center pad-bot my-lg-2 btn-left"
    >
     <div></div> 
      <Button
        id="btn_repFinalConfirm_finish"
        data-testid="finishSMS"
        onClick={buttonClickHandler}
        className="button-class mt-8"
      >
        {t("SMS_Finish")}
        <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
      </Button>
    </div>
  
  </div>   

  )
};
export default SMSConfirmation;