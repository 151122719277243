import React, { useEffect, useRef, useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import {SearchInput } from './components'
import SearchSection from './Section'

type SearchProps = {
  className?: string
  defaultValue?: string
  onChange?: (s: string) => any
  onClear?: (s: string) => any
  onSubmit: (s: string) => any
  placeholder?: string
  style?: object
  value?: string
  isDisabled?: boolean | true;
  datatestid?: any
}

const Search: React.FC<SearchProps> = ({
  className,
  defaultValue = '',
  onChange,
  onClear,
  onSubmit,
  placeholder,
  style,
  value,
  isDisabled,
  datatestid
}) => {
  const [localValue, setLocalValue] = useState(defaultValue)
  const currentValue = value ?? localValue
  const prevDefaultValue = useRef(defaultValue)
  useEffect(() => {
    if (prevDefaultValue.current !== defaultValue) {
      setLocalValue(defaultValue)
      prevDefaultValue.current = defaultValue
    }
  }, [defaultValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit(currentValue)
    }
  }
  const handleSearchClick = (e: React.MouseEvent) => {
    if (currentValue === 'Flenderson') {
      throw new Error('Testing stack trace 12345')
    }
    onSubmit(currentValue)
  }

  const resetSearch = (e: React.MouseEvent) => {
    setLocalValue('')
    if (onClear) {
      onClear(currentValue)
    }
  }

  return (
    <SearchInput
      className={className}
      defaultValue={defaultValue}
      data-bdd="search"
      placeholder={placeholder ?? 'Search'}
      value={currentValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={() => onSubmit(currentValue)}
      style={{ ...style }}
      data-testid={datatestid}
      endAdornment={
          <SearchSection currentValue={currentValue} resetSearch={resetSearch} handleSearchClick={ handleSearchClick}/>
      }
      labelWidth={0}
      disabled={isDisabled}
    />
  )
}

export default Search
