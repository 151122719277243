import MuiInputLabel from '@material-ui/core/InputLabel'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormControlLabelTop,
  InputLabel,
  ParentMenuItem,
  Select,
  SubMenuItem,
} from './components'

import { FONT_SIZE } from '../../theme'

const { regular } = FONT_SIZE

const useStyles = makeStyles((theme: any) => ({
  root: {
    fontSize: regular,
  },
}))

type Option = {
  key: string
  value: string
  children?: Option[]
}

export type Options = Option[]

type GroupedOptions = {
  [key: string]: {
    options: Option[]
    order?: number
  }
}

type SelectInputProps = {
  className?: string
  id: string
  displayEmpty?: boolean
  labelTop?: boolean
  label?: string
  defaultValue: string
  disabled?: boolean
  onChange: (e: string) => any
  options: Options | GroupedOptions
  fullWidth?: boolean
  style?: object
  variant?: 'standard' | 'outlined' | 'filled'

  // Other props get passed through to MUI Select
  [key: string]: any
}

const SelectInput: React.FC<SelectInputProps> = ({
  className,
  label,
  labelTop,
  largeText,
  displayEmpty,
  defaultValue,
  disabled,
  fullWidth,
  onChange,
  style,
  id,
  options,
  variant,
  ...rest
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { value: val } = e.target
    if (!val) return
    setValue(val)
    setOpen(false)
    onChange(val)
  }

  const renderOptions = (opts: Options) => {
    const isNestedSelect = opts.some(o => !!o.children)
    const Item = isNestedSelect ? ParentMenuItem : MenuItem
    return opts.map((o: Option) => {
      const items = [
        <Item
          disabled={displayEmpty && o.value === 'disabled'}
          key={o.value}
          value={o.value}
          data-bdd={`__option-${o.value}`}
        >
          {o.key}
        </Item>,
      ]
      if (o.children) {
        items.push(
          ...o.children.map((c: Option) => (
            <SubMenuItem key={c.value} value={c.value} data-bdd={`__option${c.value}`}>
              {c.key}
            </SubMenuItem>
          ))
        )
      }

      return items
    })
  }

  const SelectLabel = variant === 'outlined' ? InputLabel : MuiInputLabel
  const SelectElement = variant === 'outlined' ? Select : MuiSelect

  const SelectWrapper = labelTop ? FormControlLabelTop : FormControl

  return (
    <SelectWrapper
      data-bdd={`${id}-wrapper`}
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      disabled={disabled}
    >
      {label && (
        <SelectLabel className={classes.root} htmlFor={id} shrink={true}>
          {label}
        </SelectLabel>
      )}
      <SelectElement
        data-bdd={id}
        id={id}
        value={value}
        onChange={handleChange}
        onOpen={handleClick}
        onClose={handleClose}
        open={open}
        MenuProps={{
          MenuListProps: {
            // @ts-ignore ( https://github.com/mui-org/material-ui/issues/20160 )
            'data-bdd': `${id}-list`,
          },
        }}
        fullWidth={fullWidth}
        style={{ ...style }}
        {...rest}
      >
        {Array.isArray(options)
          ? renderOptions(options)
          : Object.keys(options)
              .sort((o1: any, o2: any) => (o1.order < o2.order ? -1 : 1))
              .map(key => [
                <ListSubheader key={key} color="primary">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </ListSubheader>,
                renderOptions(options[key].options),
              ])}
      </SelectElement>
    </SelectWrapper>
  )
}

export default SelectInput
