import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface AlertDetailProps extends RouteComponentProps<any> {
    messageText: string;
}

const YellowAlert: React.FC<AlertDetailProps> = (props) => {
const { t } = useTranslation();
  return (
    <div    
    data-testid="alert-warning"
    className="error mb-8 py-4"
    role="alert"
    placeholder="alert-message"
  >
    <div className="w-less px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center">
        <p
          data-testid="locatioAlert_0"
          className="w-full text-left my-2 font-AvantGardeGothic-Md"
        > 
          {t(`${props.messageText}`)}
        </p>
      </div>
    </div>
</div>
  )
};
export default YellowAlert;