import React, { useEffect, useState, Suspense, useCallback,useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FeatureFlags } from "../../contexts/FeatureFlags";
import {
  CASE_ADDITIONAL_CASE_FIELD,
  CASE_COUNTER,
  CASE_CREATION_ADDITINALINFO,
  CASE_CREATION_FILTERED_QUESTION,
  CASE_CREATION_ID,
  CASE_SUBTYPE,
  CASE_SUBTYPE_CATEGORY,
  CONTACT_INFORMATION_VALUE,
  IS_CASE_MEDIA_ALLOWED,
  IS_CASE_MEDIA_CHECK,
} from "../../../ducks/redux/actionTypes";
import "./index.css";
import {
  COMPLEX_CASE_SERVICE_COMPLAINT_Q3
} from "./Question";
import { ComplexCaseTypes } from "../../../models/Case/ComplexCase";
import { BackValue } from "../../../models/shared/Enum";
import { userChosenContactDetails, userChosenContactName, userChosenContactPhone, userChosenRelationDetails } from "../../../ducks/redux/actions/userChoices";

const Loader = React.lazy(() => import("react-loader-spinner"));

const TEXT = "Text";
const RADIO = "Radio";
const LIST = "List";
const DATETIME = "DateTime";

interface QuestionTypeProps extends RouteComponentProps<any> { }

const CaseTypeQuestion: React.FC<QuestionTypeProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location: any = useLocation();
  const [editCounter, setEditCounter] = useState(parseInt(location?.state?.id));
  const { featureMedia }: any = useContext(FeatureFlags);


  const getAllData = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationMasterData
  );

  let count =
    useSelector((state: any) => state.caseCreationReducer.counter) || 0;

  const descData = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const caseSubType = useSelector(
    (state: any) => state.caseCreationReducer.caseSubType
  );

  const caseSubTypeCategory = useSelector(
    (state: any) => state.caseCreationReducer.caseSubTypeCategory
  );

  let selectedTenacyType = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  const isCaseMediaAllowed = useSelector(
    (state:any) => state?.userChoicesReducer?.isCaseMediaAllowed
  );
  const isEdit = useSelector(
    (state: any) => state?.caseCreationReducer?.isCaseEdit
  );

  useEffect(() => {
    getAllData?.map((data: any) => {
      if (data.description === descData) {
        dispatch({
          type: CASE_CREATION_ADDITINALINFO,
          payload: data.additionalInfo,
        });

        dispatch({
          type: IS_CASE_MEDIA_ALLOWED,
          payload: data.isCaseMediaAllowed,
        });
       
        dispatch({ type: CASE_CREATION_ID, payload: data.id });
        if (caseSubType === "" || caseSubType === data.subType) {
          dispatch({ type: CASE_SUBTYPE, payload: data.subType });
        }

        if (caseSubTypeCategory === "" || caseSubTypeCategory === data.subTypeCategory) {
          dispatch({ type: CASE_SUBTYPE_CATEGORY, payload: data.subTypeCategory });
        }


        if (
          data.additionalCaseCreationFields !== null &&
          data.additionalCaseCreationFields &&
          data.additionalCaseCreationFields[0]?.fieldName === "pea_casecategory"
        ) {
          dispatch({
            type: CASE_ADDITIONAL_CASE_FIELD,
            payload: data.additionalCaseCreationFields,
          });
        }
        loadQuestionScreen(data.additionalInfo);
      }
    });
  }, []);

  const loadQuestionScreen = useCallback(
    (questionData: any) => {
      if (editCounter >= 0) {
        count = editCounter;
      }
      if (questionData && questionData[count]?.dependsOn != null && questionData[count]?.label === COMPLEX_CASE_SERVICE_COMPLAINT_Q3) {
        dispatch({ type: CASE_COUNTER, payload: count });
        if (caseSubType === ComplexCaseTypes.Complaint) {
          history.push("/CaseQuestionnaireRadio");
        } else if (caseSubType === ComplexCaseTypes.ServiceRecovery) {
          history.push("/CaseQuestionnaireDropdown");
        }
      } else {

        if (questionData && count !== 0) {
          let filteredQuestions: any;
          const allIdsWhichAreMultiple = questionData
          .map((question: any) => question.questionId.split("_")[0]) // Extracting IDs
          .filter((id: any, index: any, array: any) => array.indexOf(id) !== index); // Filtering duplicates
         
          if (allIdsWhichAreMultiple.length) {
            const filteredQuestions = questionData.filter((question: any) => {
              if (allIdsWhichAreMultiple.includes(question.questionId.split("_")[0])) {
                if (!question.dependsOn) {
                  return true;
                }

                for (const dependency of question.dependsOn) {
                  const isDependent = caseSelectedQuestionIdsAnswers.some((answer: any) => {
                    return dependency.id === answer.questionId && dependency.responseId === answer.answer;
                  });
                  if (isDependent) {
                    return true;
                  }
                }
                return false;
              }
              return true;
            });

            if (filteredQuestions.length > 0) {
              questionData = filteredQuestions;
            }
          }
          filteredQuestions = filterQuetionWhenNoDuplicate(questionData, caseSelectedQuestionIdsAnswers);

          if (filteredQuestions.length > 0) {
            questionData = filteredQuestions;
          }
        }

        dispatch({ type: CASE_CREATION_FILTERED_QUESTION, payload: questionData });
        
        if (
          questionData &&
          questionData.length > count &&
          questionData[count]?.isEnabled === "true"
        ) {
          dispatch({ type: CASE_COUNTER, payload: count });   

          if (questionData[count].type === RADIO) {
            history.push("/CaseQuestionnaireRadio");
          } else if (questionData[count].type === LIST) {
            history.push("/CaseQuestionnaireDropdown");
          } else if (questionData[count].type === TEXT) {
            history.push("/CaseQuestionnaireText");
          }else if (questionData[count].type === DATETIME) {
            history.push("/CaseQuestionnaireDateTime");
          }
        } else {
            if(featureMedia === "true" && isCaseMediaAllowed === true){
              dispatch({ type: IS_CASE_MEDIA_CHECK, payload: featureMedia });
              const locationState = location.state || {};
              const previousPageCounter = locationState.previousPageCounter;
              props.history.push({
                  pathname: '/uploadmedia',
                  state: { ...locationState, previousPageCounter }
              });
              
            }else {
              if (backValue == BackValue.Customer360View  ) {
                dispatch({ type: IS_CASE_MEDIA_CHECK, payload: 'false' });
                if(isEdit) {
                dispatch(userChosenContactDetails(""))
                dispatch(userChosenContactName(""))
                dispatch(userChosenContactPhone(""))
                dispatch(userChosenRelationDetails(""))
                 }
                history.push("/ContactDetails");
              }
              else if ((backValue == BackValue.Asset360View || backValue == BackValue.Case360View) && selectedTenacyType == 'Property') {
                dispatch({ type: CONTACT_INFORMATION_VALUE })
                props.history.push("/ContactDetails");
              }
              else if ((backValue == BackValue.Asset360View || backValue == BackValue.Case360View) && selectedTenacyType != 'Property') {
                if(isEdit){
                  dispatch({ type: CONTACT_INFORMATION_VALUE});
                }
                props.history.push("/CaseContactInformation");
              }
            }
        }
      }
    },
    [count]
  );

  function filterQuetionWhenNoDuplicate(questionData: any, caseSelectedQuestionIdsAnswers:any){
    return questionData.filter((question: any) => {
      if (question.dependsOn !== null) {
        for (const dependency of question.dependsOn) {
          const isDependent = caseSelectedQuestionIdsAnswers.some((answer: any) => {
            return dependency.id === answer.questionId && dependency.responseId === answer.answer;
          });
          if (isDependent) {
            return true;
          }
        }
        return false;
      }
      else {
        return true;
      }
    });
  }


  const backButtonHandler = () => {
    history.push("/CaseType");
  };

  return (
    <div className="container sm:mx-1 ctn-fht" id="reploc-container" role="main">
      <Suspense
        fallback={
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={2000} //3 secs
          />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>
        <div className="h5 sm:mx-1" id="repreason-div1">
          <p
            className="text-purple-100 text-2xl mb-0 global-txt"
            data-testid="title"
          >
            {descData}
          </p>
        </div>
        <br />

        {/* === Displaying question here === */}

        {/* === End question */}
      </Suspense>
    </div>
  );
};

export default CaseTypeQuestion;
