import { withStyles } from '@material-ui/core'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import { colors } from '../../theme'

const { primaryText } = colors

export const FormControlLabelTop = withStyles({
  root: {
    flexDirection: 'column',
    '& label' : {
      top: '0',
      marginBottom: '10px',
    }
  }
})(MuiFormControl)

export const FormControl = withStyles({
  root: {
    flexDirection: (props: any) => (props.variant === 'outlined' ? 'row' : 'column'),
    '& > div': {
      width: (props: any) => (props.fullWidth ? '100%' : 'auto'),
    }
  },
})(MuiFormControl)

export const InputLabel = withStyles({
  root: {
    position: 'relative',
    display: 'inline-flex',
    top: '15px',
    transform: 'none!important',
    fontSize: '.8rem',
    marginRight: '10px',
    color: `${primaryText}`,
  },
})(MuiInputLabel)

export const Select = withStyles({
  root: {
    padding: '.7rem 1.4rem .7rem .7rem',
  },
})(MuiSelect)

export const ParentMenuItem = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(MenuItem)

export const SubMenuItem = withStyles({
  root: {
    paddingLeft: '2rem',
  },
})(MenuItem)
