import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio } from "@material-ui/core";
import * as api from "../../api";
import {
  updateDashboardForm,
  fetchRepairReasons,
  fetchRepairReasonsSelect,
  fetchRepairReasonsInProgress
} from "../../ducks/redux/actions/dashboardReducer.actions";
import RepairReason2 from "./index2";
import "./styles.css";

import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { CurrentRepair } from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Address from "../../models/Customer/Address";
import CustomerName from "../../models/Customer/CustomerName";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import {
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenDuplicateQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  resumeDraftRepair,
  backToRepairAsset,
  userCreatedNewRepair,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll,
} from "../../ducks/redux/actions/userChoices";
import { RepairPriority } from "../../models/Repair/CreateRepair";


interface RepairReasonProps extends RouteComponentProps<any> {}

const RepairReason: React.FC<RepairReasonProps> = (props) => {
  const dispatch = useDispatch();

  //To track radio button selection for RepairProperty--Start here---

  //-----------Ends here-----------//
  enum RepairReportingMethods {
    "Telephone" = 1,
    "Email" = 2,
    "Social media" = 3,
    "Customer Self-Serve" = 4,
    "Colleague Self-Serve" = 5,
  }
  const [selectedreportingMethod, setSelectedreportingMethod] = useState(
    "Colleague Self-Serve"
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isDisabledOrbis, setButtonDisabledOrbis] = useState(false);
  const [isDisabled, setButtonDisabled] = useState(false);
  const [chosenHL, setChosenHL] = useState("");
  const [locatioAlert, setLocatioAlert] = useState<string[]>([]);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  
  let alertMstArray: any[] = [];
  const addresses = useSelector(
    (state: any) => state.addresses?.customerassociatedassets
  );

  const fetchedRepairReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.fetchedRepairReasons
  );
  const userTypedRepairReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  const userChosenPropertyType = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );

  const userChosenRepairLocationId = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenRepairLocationId
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  const selectedRepairReasonObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject
  );

  const userChosenUPRN = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenUPRN
  );

  const tenanciesData = useSelector(
    (state: any) => state.addresses.customertenancies
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );

  const assetId = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.customerID?.assetId
  );

  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const selectedproperty = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyType
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? 
        state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  
  const selectSorValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenSOR
  );
  

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );

  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const userChosenResumeRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenResumeRepair
  );

  const loadDuplicateRepairPageValue = useSelector(
    (state: any) => state.userChoicesReducer.loadDuplicateRepairPage
  );
  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );

  const isCreateEnhancedHubRepairPermissions = useSelector(
    (state: any) => state?.userChoicesReducer?.createEnhancedHubRepairPermissions
  ); 
 
  
  const workOrderRepair = new CurrentRepair();
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const _premisesTypeId: any =
    PremisesTypeId[selectedproperty as keyof typeof PremisesTypeId];
  workOrderRepair.repairReason = selectedRepairReasonObject[0]?.description;
  workOrderRepair.description = selectedRepairReasonObject[0]?.description;
  workOrderRepair.premisesId = assetId;
  if (selectedproperty === "Divided Block") {
    workOrderRepair.premisesTypeId = "5";
  } else {
    workOrderRepair.premisesTypeId = _premisesTypeId;
  }
  workOrderRepair.customerIdentifier = customerID;
  
  workOrderRepair.isEmergency =  priority == RepairPriority.Emergency ? true : false;
  workOrderRepair.premisesUniqueReference = userChosenUPRN;
  const _address = new Address();
  _address.addressLine1 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
  _address.addressLine2 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
  _address.addressLine3 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
  workOrderRepair.premisesAddress = _address;
  workOrderRepair.sorCode = selectSorValue?.length > 0 ? selectSorValue : selectedRepairReasonObject[0]?.sors[0]?.sorCode;
  workOrderRepair.customerName = new CustomerName({
    title: customerDetails?.person?.name?.title,
    givenName: customerDetails?.person?.name?.givenName,
    surname: customerDetails?.person?.name?.surname,
  });
  workOrderRepair.reportingMethodId =
    RepairReportingMethods[
      selectedreportingMethod as keyof typeof RepairReportingMethods
    ];
  workOrderRepair.reportingMethod = selectedreportingMethod;

  workOrderRepair.notifyAll = false;
  workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
  workOrderRepair.eligibilityOutcomes = [];
  workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
  workOrderRepair.repairLocationId = userChosenRepairLocationId;
  workOrderRepair.repairCause = "General Wear and Tear";
  workOrderRepair.isCatalyst = isCatalyst;

  workOrderRepair.repairCauseId =
    RepairCauseId[workOrderRepair.repairCause as keyof typeof RepairCauseId];
  const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
  const woRequestParameters = _worksOrderRequest.createWorkOrderParameter(
    workOrderRepair
  );

  const CreateWorkOrder = async (result:boolean) => {   
    try {
     setIsLoading(true);
     woRequestParameters.isEmergency  =  RepairPriority.Emergency ? true : false;
      if (!chosenHL) {
        woRequestParameters.repairReason = "";
        woRequestParameters.description = "";
      }

      const apiResponse = await api.createWorkOrderRepair({
        ...woRequestParameters,
      });

      if(selectSorValue?.length > 0) {
        workOrderRepair.sorCode = selectSorValue;
      }

      if (apiResponse?.location.length > 0 ) {
        const workorderLocation = apiResponse?.location.split("/");
        workOrderRepair.id = workorderLocation[4];
        workOrderRepair.worksOrderIdentifier = workorderLocation[4];
        dispatch(workOrderRepairObject(workOrderRepair));
        dispatch(userCreatedNewRepair(workOrderRepair));
        const worksOrder = new WorksOrderRequest(workOrderRepair);
        dispatch(workOrderData(worksOrder))
        if(!result)
        {
        props.history.push("/SupplementaryQuestion");
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

  };

  const UpdateWorkOrder = async (result:boolean) => {
    try {
      setIsLoading(true);
      if (workOrderObject.id.trim().length > 0) {
        const getWorkOrderResult: any = await api.getRepair(workOrderObject.id);
        if (getWorkOrderResult?.eTag?.toString()) {
          // Cleanup required - Tech Debt
          const deleteWorkOrderResponse: any = await api.deleteWorkOrder(
            workOrderObject.id,
            getWorkOrderResult?.eTag?.toString()
          );
          const workOrderRepair = new CurrentRepair();
          enum RepairCauseId {
            "General Wear and Tear" = 1,
            "Vandalism or Damage" = 4,
          }
          const _premisesTypeId: any =
            PremisesTypeId[selectedproperty as keyof typeof PremisesTypeId];
          workOrderRepair.repairReason =
            selectedRepairReasonObject[0]?.description;

          workOrderRepair.description =
            selectedRepairReasonObject[0]?.description;

          workOrderRepair.premisesId = assetId;
          if (selectedproperty === "Divided Block") {
            workOrderRepair.premisesTypeId = "5";
          } else {
            workOrderRepair.premisesTypeId = _premisesTypeId;
          }
          workOrderRepair.customerIdentifier = customerID;
          workOrderRepair.isEmergency = priority == RepairPriority.Emergency ? true : false;
          workOrderRepair.premisesUniqueReference = userChosenUPRN;
          const _address = new Address();
          _address.addressLine1 =
            customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
          _address.addressLine2 =
            customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
          _address.addressLine3 =
            customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
          workOrderRepair.premisesAddress = _address;
          workOrderRepair.sorCode =
            selectSorValue?.length > 0 ? selectSorValue :  selectedRepairReasonObject[0]?.sors[0]?.sorCode;;
          workOrderRepair.customerName = new CustomerName({
            title: customerDetails?.person?.name?.title,
            givenName: customerDetails?.person?.name?.givenName,
            surname: customerDetails?.person?.name?.surname,
          });
          workOrderRepair.reportingMethodId =
            RepairReportingMethods[
              selectedreportingMethod as keyof typeof RepairReportingMethods
            ];
          workOrderRepair.reportingMethod = selectedreportingMethod;

          workOrderRepair.notifyAll = false;
          workOrderRepair.eTag = customerDetails?.eTag; // Need to verify
          workOrderRepair.eligibilityOutcomes = [];
          workOrderRepair.assetIdentifier = tenanciesData?.premisesIds;
          workOrderRepair.repairLocationId = userChosenRepairLocationId;
          workOrderRepair.repairCause = "General Wear and Tear";
          workOrderRepair.isCatalyst = isCatalyst;

          workOrderRepair.repairCauseId =
            RepairCauseId[
              workOrderRepair.repairCause as keyof typeof RepairCauseId
            ];
          const _worksOrderRequest = new WorksOrderRequest(workOrderRepair);
          const woRequestParameters = _worksOrderRequest.createWorkOrderParameter(
            workOrderRepair
          );
          woRequestParameters.repairReason =
            selectedRepairReasonObject[0]?.description;
          woRequestParameters.description =
            selectedRepairReasonObject[0]?.description;

          // await api.createWorkOrderRepair(
          //   woRequestParameters,
          //   workOrderRepair.id,
          //   "eTag"
          // );
         
          const apiResponse = await api.createWorkOrderRepair({
            ...woRequestParameters,
          });

          if (apiResponse?.location.length > 0) {
            const workorderLocation = apiResponse?.location.split("/");
            workOrderRepair.id = workorderLocation[4];
            workOrderRepair.worksOrderIdentifier = workorderLocation[4];
            workOrderRepair.repairReason =
              selectedRepairReasonObject[0]?.description;
            workOrderRepair.description =
              selectedRepairReasonObject[0]?.description;
            dispatch(workOrderRepairObject(workOrderRepair));
            dispatch(userCreatedNewRepair(workOrderRepair));
            const worksOrder = new WorksOrderRequest(workOrderRepair);
            dispatch(workOrderData(worksOrder))
            if(!result)
            {
            props.history.push("/SupplementaryQuestion");
            }
          }
        }
      } else {
        const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
        const woRequestParameters = _worksOrderRequest.updateWorkOrderParameter(
          workOrderObject
        );
        woRequestParameters.repairReason =
          selectedRepairReasonObject[0]?.description;
        woRequestParameters.description =
          selectedRepairReasonObject[0]?.description;

        await api.updateWorkOrderRepair(
          woRequestParameters,
          workOrderObject.id,
          "eTag"
        );

        workOrderObject.repairReason =
          selectedRepairReasonObject[0]?.description;
        workOrderObject.description =
          selectedRepairReasonObject[0]?.description;
        dispatch(workOrderRepairObject(workOrderObject));
        dispatch(userCreatedNewRepair(workOrderRepair));
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    //Resetting Repair Reason type ahead
    dispatch(fetchRepairReasons([]));
    dispatch(fetchRepairReasonsSelect([]));
    
    (async function () {
      try {
        const premiseType =
          userChosenPropertyType === "Block" ||
          userChosenPropertyType === "Estate" ||
          userChosenPropertyType === "Divided Block" ||
          userChosenPropertyType === "Divided block" ||
          userChosenPropertyType === "Estate Area"
            ? "Communal" 
            : userChosenPropertyType;
          
          if(userTypedRepairReasons != null && userTypedRepairReasons.length > 0) {
            dispatch(fetchRepairReasonsInProgress(true))
            const responsedata = await api.searchRepairReasons(
              userTypedRepairReasons,
              premiseType,
              isCatalyst
            );

            dispatch(fetchRepairReasonsSelect(responsedata));
            
            const extractedReasons: string[] = [];
    
            responsedata.map((eachData, key) => {
              const { description } = eachData;
              extractedReasons.push(description);
            });
            
            dispatch(fetchRepairReasons(extractedReasons));
            dispatch(fetchRepairReasonsInProgress(false))
          }
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  }, [addresses, userTypedRepairReasons]);

  useEffect(() => {
    alertMstArray = [];
    setLocatioAlert([]);
    dispatch(userCreatedNewRepair(null));
  }, [userChosenRepairLocationId]);

  //redux data to check radio button selection
  const updateSelection = (e: any) => {
    dispatch(
      updateDashboardForm({
        selected: e.target.value,
      })
    );
  };
  //ends here

  let fieldsArray: any[] = [];
  addresses?.forEach((address: any) => {
    const addressValue =
      address?.addresses?.postalAddresses[0]?.formattedAddress;

    fieldsArray.push(
      <FormControlLabel
        value={addressValue}
        control={<Radio onChange={updateSelection} />}
        label={addressValue}
      />
    );
  });

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false));
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = (e : any) => {  
    dispatch(userChosenSaveExit(true))
    dispatch(backToRepairAsset(false));
    (workOrderObject == null ? CreateWorkOrder(true) : UpdateWorkOrder(true)).then(() =>
    props.history.push(`/WorkOrder360View/${
      _worksOrderRequest?.identifiers?.worksOrderIdentifier ? 
        _worksOrderRequest?.identifiers?.worksOrderIdentifier: 
        workOrderRepair.worksOrderIdentifier
      }`)
    )
    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
    
  };

  const getMandatoryData = (data: any) => {
    setChosenHL(data);
  };

  const callPageHandler = () => {
    
    setButtonDisabled(true);
    if (resumeDraftRepairVal) {
      const { repairLocationId, repairReason } = userResumeDraftDataCache;
      if (
        userChosenRepairLocationId !== repairLocationId ||
        chosenHL !== repairReason
      ) {
        dispatch(userChosenNotifyAll(null)); 
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenSupplementaryQuestions(""));
        dispatch(userChosenEmergencyQuestion(""));
        dispatch(userChosenDuplicateQuestion(""));
        if (!IsRecall) {
          dispatch(userChosenAdditionalDescription(""));
          dispatch(userChosenVandalismQuestion("No"));
          dispatch(userChosenVandalismAdditionalInformation(""));
        }
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (
      !chosenHL &&
      (userChosenRepairLocationId == 0 ||
        userChosenRepairLocationId === "No option selected")
    ) {
      alertMstArray.push(t("CPR007"));
      alertMstArray.push(t("CPR008"));
      setLocatioAlert(alertMstArray);
      return;
    } else if (
      userChosenRepairLocationId == 0 ||
      userChosenRepairLocationId === "No option selected"
    ) {
      alertMstArray.push(t("CPR007"));
      setLocatioAlert(alertMstArray);
      return;
    } else if (!chosenHL || (!chosenHL && userChosenRepairLocationId)) {
      alertMstArray.push(t("CPR008"));
      setLocatioAlert(alertMstArray);
      return;
    }

    alertMstArray = [];
    setLocatioAlert([]);
    //logic to create/update workorder
    
    if(!userChosenResumeRepairValue && !loadDuplicateRepairPageValue)
    {
        workOrderObject == null ? CreateWorkOrder(false) : UpdateWorkOrder(false);
    }
    else
    {
      props.history.push("/SupplementaryQuestion");
    }
  };

  

  return (
    <>
      <div
        className="ml-sm-5 pl-sm-5 pt-2 repair-text container"
        id="repreason-main-div"
      >
        <RepairReason2
          suggestions={fetchedRepairReasons}
          setButtonDisabledOrbis = {setButtonDisabledOrbis}
          setButtonDisabled={setButtonDisabled}
          locatioAlert={locatioAlert}
          getMandatoryData={getMandatoryData}
         
          {...props}
        />


          
      </div>
      
      <div
        className="flex justify-between items-center pb-4 md:pb-8 container"
        id="repreason-main-btn"
      >
        <ExitModalAndSave
          id="repreason-exitmodal"
          exit={exitButtonHandler}         
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
        <Button
          id="btn_repairreason"
          type="button"
          data-testid="btncontinue"
          name="btncontinue"
          disabled={isDisabled || isDisabledOrbis}
          onClick={callPageHandler}
          placeholder="btncontinue"
          value="Continue"
          className="button-class"
        >
          {t("continuen_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </>
  );
};

export default RepairReason;
