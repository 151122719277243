export const sortableFields = [
  {
    displayName: 'Best match',
    sortableKey: 'search.score() desc',
  },
  {
    displayName: 'Name A to Z',
    sortableKey: 'surname',
  },
  {
    displayName: 'Name Z to A',
    sortableKey: 'surname desc',
  }
]
  export default sortableFields
  