
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { RouteComponentProps } from "react-router-dom";

interface PermissionErrorProps extends RouteComponentProps<any> {
}

const PermissionError: React.FC<PermissionErrorProps> = (props) => { 
    const { t } = useTranslation();

  const exitButtonHandler = () => {      
    props.history.push("/LandingView")
  };

 

  return (
    <div className="gen-error container mt-perc sm:mx-1 ctn-fht">
      <div className="error" role="alert" id="repair-asset-alert">
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center">           
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t("Permission_Error")}
              </p>           
          </div>
        </div>
      </div>
      <div
        className="flex justify-between items-center pb-4 md:pb-8 mt-perc"
        id="permission-exit-btn"
      >
         <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
                <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 ">
                    <a data-testid="permissionBackBtn" className="back-btn" onClick={exitButtonHandler}>
                        <i className="fas fa-angle-left mr-2"></i> 
                        <span>{t("exit_button")}</span>
                    </a>
                </p>
            </div>       
      </div>
    </div>

  )

}
export default PermissionError;