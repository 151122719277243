import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import ExitModalContent from "../../Modal/ExitModalContent";
import Button from "../../../Button/Button";
import * as api from "../../../api";
import "./index.css"
import { FinancialReferralReasonRes, clearReferralInformation, comingFromReferralPage } from "../../../ducks/redux/actions/FinancialReferral";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FinancialReferralReason from "../../../models/FinancialInclusion/ReferralReason";
import LoadingSpinner from "../../../storybook/LoadingSpinner";





interface FinancialReason extends RouteComponentProps<any> {
  
}

const ReferralReason: React.FC<FinancialReason> = (props:any) => {
   
  let tempReferralReasons:string[]=[];
  let [referralReasons, setReferralReasons] = useState(tempReferralReasons)
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [error, setError] = useState("");
  let data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)
  let [reasonChecked, setReasonChecked] = useState<string[]>(data?.ReferralReason);
  const [isLoader, setIsLoader] = useState(true);
  let keyValuePairs: any = "";
  let arrData : string[] = [];


  


  useEffect(() => {
    const fetchReferralReason = async () => {
      try{
        const responseData = await api.getFinancialReferralReasonData()
           if (responseData) {
            keyValuePairs = responseData[0]?.namespace?.keyValues;
            for (const _data of Object.values(keyValuePairs)) {
              let obj=(_data as FinancialReferralReason);
              if ( obj.displayOnP360  == true ) {
                arrData.push(obj.referralReason)
              }
            }
            setReferralReasons(arrData)
            setReasonChecked(data?.ReferralReason)      
            setIsLoader(false)
          }    
      }catch(e:any){
        console.log("Error:", e)
      }
        
    };
    if(data.referralFor) {
      fetchReferralReason()
    }else {
      props.history.push('/CustomerConsent')
    }
    
    
  },[])
  const exitButtonHandler = () => {
    dispatch(clearReferralInformation(null))
    props.history.push('/Customer360View');
  }
  const onBackHandler = () => {
    props.history.push('/CustomerConsent');
  }

  

  const onContinueHandler = () => {
    if(reasonChecked?.length === 0){
      setError("Financial_FI008a")
    }else {
      dispatch(FinancialReferralReasonRes(reasonChecked))
      dispatch(comingFromReferralPage(true))
      props.history.push('/referraladditionaldetails')
    }
  }

  // istanbul ignore next()
 const handleChange = (event:any) => {
  setError("")
  if(event.target.checked){
    reasonChecked.push(event.target.name) 
    dispatch(FinancialReferralReasonRes(reasonChecked))
  }
  else{
    const index = reasonChecked.indexOf(event.target.name);
    if (index > -1) { 
      reasonChecked.splice(index, 1); 
    }
    dispatch(FinancialReferralReasonRes(reasonChecked))
  }  
 }

  
  return (
    <div className="container sm:mx-1 ctn-fht">
        {/* Back Button */}
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 px-2" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={onBackHandler} >
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>

        {/* Main Page Content */}
        <div className="sm:mx-1 px-2">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
                 {t("Referral_Reason")} 
              </h1>
            </div> 
        </div>
        { isLoader?
           <div className="div-loader">
           <div className="SpinnerWrapper">
               <LoadingSpinner />
           </div>
           </div>:
        
          <Box data-testid="reasondata">
            <FormControl sx={{ m: 1 }} variant="standard">
            
              <FormGroup>
              {
                referralReasons?.slice(0,(Math.ceil(referralReasons.length/2))).map((item : string, index:number) => (
                  <FormControlLabel
                   htmlFor="first-checkBox"
                    control={
                      <Checkbox checked={data?.ReferralReason?.includes(item)} onChange={handleChange} name={item} />
                    }
                    label={item}
                    key={index}
                  />
                ))
              }
              </FormGroup>
            
            </FormControl>
            <FormControl
              sx={{ m: 1 }}
              variant="standard"
            >   
              <FormGroup className="MobileView">
              {
                referralReasons?.slice((Math.ceil(referralReasons.length/2))).map((item : string, index:number) => (
                  <FormControlLabel  
                  htmlFor="second-checkBox"    
                    control={
                      <Checkbox checked={data?.ReferralReason?.includes(item)} onChange={handleChange} name={item}  />
                    }
                    label={item}
                    key={index}
                  />
                ))
              }
              </FormGroup>
              
            </FormControl>
          </Box>   
        }
        {/* Yellow Alert */}

           {error && <div    
                data-testid="alert-warning"
                className="error mb-8 py-4 px-2"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                       {t(error)}
                    </p>
                  </div>
                </div>
         </div>}
       
        {/* Exit and Continue SMS button */}
            <div
              className="flex justify-between items-center pb-4 md:pb-8 px-2"
              id="sms-btn"
            >
              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg="Return to my referral"
              />
              <Button
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={onContinueHandler}
                placeholder="send-sms"
                value="Send"
                className="button-class"
              >
                {t("Continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
        </div>
       
    </div>    

  )
};
export default ReferralReason;