import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useSelector } from "react-redux"

interface KeyContactAssetCardProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerResponseDetails[];
}
const KeyContactAssetCard: React.FC<KeyContactAssetCardProps> = (props) => {
  const { t } = useTranslation();
  const [regionalHousingManager, setRegionalHousingManager] = useState("");
  const [areaHousingManager, setAreaHousingManager] = useState("");
  const [assistantHeadOfNeighbourhoods, setAssistantHeadOfNeighbourhoods] = useState("");
  const [neighbourhoodManager, setNeighbourhoodManager] = useState("");

  const keyContact = useSelector(
    (state: any) => state?.userChoicesReducer?.keyContacts
  );

  useEffect(() => {
    trackEvent("KeyContactAssetCard");
    setRegionalHousingManager(titleCase(keyContact.regionalHousingManager));
    setAreaHousingManager(titleCase(keyContact.areaHousingManager));
    setAssistantHeadOfNeighbourhoods(titleCase(keyContact.assistantHeadOfNeighbourhoods));
    setNeighbourhoodManager(titleCase(keyContact.neighbourhoodManager));
  }, [keyContact]);

  function titleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
}

  return (
    <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card">
      <div>

        <div className="px-4 py-2 border-b border-grey">
          <div className="mt-2 mb-2 flex flex-row d-flex bd-highlight items-center">
            <i className="fas fa-key text-teal text-2xl mr-6 bd-highlight"></i>
            <h2 className="my-0 text-purple-100 bd-highlight">{t("Key_contacts")}</h2>
          </div>
        </div>

        <div className="px-4">
          <div className="py-2 flex justify-between mt-2 border-b border-grey">
            <div className="pr-4">{t("regionalHousingManager")}
            </div>
            <div className="text-right text-capitalize"> {regionalHousingManager || "-"}
            </div>
          </div>
        
          <div className="py-2 flex justify-between border-b border-grey">
            <div className="pr-4">{t("areaHousingManager")}
            </div>
            <div className="text-right text-capitalize">  {areaHousingManager || "-"}</div>
          </div>
          <div className="py-2 flex justify-between border-b border-grey">
            <div className="pr-4">{t("assistantHeadOfNeighbourhoods")}
            </div>
            <div className="text-right text-capitalize">  {assistantHeadOfNeighbourhoods || "-"}</div>
          </div>
          <div className="py-2 flex justify-between border-b border-grey">
            <div className="pr-4">{t("neighbourhood")}
            </div>
            <div className="text-right text-capitalize">  {neighbourhoodManager || "-"}</div>
          </div>
        </div>

      </div>
    </div>
  )
};
export default KeyContactAssetCard;