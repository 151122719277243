import React from "react";
// import "./Button.css";

const Button = (props: any) => (
  <div
    className="ml-auto mr-sm-5 mr-sm-5 pad-bot mt-sm-2"
    id="global-btn-id"
  >
    <button
      disabled={props.disabled}
      className={props.className}
      id={props.id}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  </div>
);

export default Button;
