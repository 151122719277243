import React, { lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import {
  addressToString,
  formatDateWithFromCalendar,
  isNullOrUndefined,
} from "../../../utils/formatting";
import LoadingSpinner from "../../../storybook/LoadingSpinner";
import {
  MobilePagination,
  Pagination,
  Paginationbox,
  WebPagination,
  Search,
} from "./component";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerData,
} from "../../../models/Customer/CustomerResponse";
import { PremisesAddress } from "../../../models/shared/Address/PremisesAddress";
import { getCustomerId, saveCustomerphoneNumber } from "../../../ducks/redux/actions/getAddress";
import { backToCaseAsset, userChosenContactDetails, userChosenContactName, userChosenContactPhone, userChosenCustomerID, userChosenRelationDetails } from "../../../ducks/redux/actions/userChoices";
import { CASE_CUSTOMER_NAME, CASE_CUSTOMER_SEARCH, CASE_TENANCY_ID, CASE_TENANCY_TYPE } from "../../../ducks/redux/actionTypes";
import { BackValue } from "../../../models/shared/Enum";
import './index.css';
interface CaseSelectCustomerProps extends RouteComponentProps<any> {
  isVisibleForAsset?: boolean;
}
const Button = lazy(() => import("../../../Button/Button"));
const ExitModalContent = lazy(() => import("../../Modal/ExitModalContent"));
const CaseSelectCustomerComponent: React.FC<CaseSelectCustomerProps> = (
  props
) => {
  const array: CustomerData[] = [];
  const [customerList, setCustomerList] = useState(array);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isSearchValid, setIsSearchValid] = useState(true);

  const [validationMessage, setValidationMessage] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [showError, setShowError] = useState("");
  const dispatch = useDispatch();

  const searchTextCountValue = useSelector(
    (state: any) => state.userChoicesReducer?.searchTextCountValue
  );
  const caseCustomerSearch = useSelector(
    (state: any) => state.caseCreationReducer?.caseCustomerSearch
  );
  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  )
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const editButtonCheck = useSelector(
    (state: any) => state?.caseCreationReducer?.editButtonClick
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      if(caseCustomerSearch){
        setShowError('')
        setSearchText(caseCustomerSearch)
        setCustomerId(customerIdentifier)
        setIsLoading(true);
        updateCustomerData(caseCustomerSearch, resultsPerPage, 0);
      }
    })();
  }, []);

  const getCustomerData = (queryParams: any) => {
    (async () => {
      try {
        const data = await api.getCustomerData(queryParams);
        setCustomerList(data.models);
        setTotalCount(data?.totalCount);
        if(data.totalCount == 0){
          setIsSearchValid(false);
          setValidationMessage(t("customers_search_no_results"))
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  };

  const updateCustomerData = (searchText: any, limit: any, skip: any) => {
    const validationMsg = getValidationMessage(searchText);
    if (validationMsg.length > 0) {
      setValidationMessage(validationMsg);
      setIsSearchValid(false);
    } else {
      setIsSearchValid(true);
      (async () => {
        setIsLoading(true);
        const queryParams = {
          searchText: searchText,
          limit: limit,
          skip: skip,
          orderBy: "",
          uprn: "",
          customerType: t("active_customer"),
          tenancyType: ""
        };
        getCustomerData(queryParams);
      })();
    }
    
  };

  const getValidationMessage = (search: any) => {
    if(isNullOrUndefined(search)){
      return t("blank_search_criteria")
    }
    if (/[^0-9a-zA-Z]/.test(search.substring(0, 1)))
      return t("invalid_search_criteria");
  
    if (
      search?.includes("*") &&
      search.substring(0, search.indexOf("*")).length < searchTextCountValue
    )
      return (
        t("minimum_number_of_characters1") +
        searchTextCountValue +
        t("minimum_number_of_characters2")
      );

    if (search?.length < searchTextCountValue)
      return t("minimum_number_of_characters");

    return "";
  };

  const onChangePage = (e: any, page: number) => handleChangePage(page);

  const handleChangePage = (page: number) => {
    setIsLoading(true);
    setCurrentPage(page);
    setSkip(resultsPerPage * page);
    updateCustomerData(searchText, resultsPerPage, resultsPerPage * page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
    setIsLoading(true);
    setResultsPerPage((e.target as any).value);
    setSkip(0);
    setCurrentPage(0);
    updateCustomerData(searchText, (e.target as any).value, 0);
  };

  const fetchData = () => {
    let skipResult = 0;
    let totalValue = 0;
    if (skip === 0 && resultsPerPage < 998) {
      skipResult = 0;
      totalValue = 999;
      setSkip(0);
    } else {
      skipResult = resultsPerPage;
      totalValue = 999 + skipResult;
      setSkip(skipResult);
    }
    setResultsPerPage(totalValue);
    updateCustomerData(searchText, totalValue, skipResult);
  };

  const handleSubmit = (search: string) => {
    if(searchText != search || isNullOrUndefined(search)){
    setSearchText(search);
    setCurrentPage(0);
    setResultsPerPage(10);
    setSkip(0);
    setTotalCount(0);
    setCustomerList([]);
    setCustomerId("");
    dispatch({ type: CASE_CUSTOMER_SEARCH, payload: search })
    updateCustomerData(search,10,0)
  }
  };

  const handleResetSubmit = () => {
    setSearchText("");
    setCustomerList([]);
    setTotalCount(0);
    setCustomerId("");
    setShowError('');
  };

  const goToSelectCustomer = async (customer?: CustomerData) =>{
   if(customer?.id){
   setCustomerId(customer?.id)
   const customerResponse = await api.getCustomer(customer?.id);
  const activeTenancyDetails = customerResponse?.tenanciesSummaries.filter(
    (ts: { tenancySummary
      : { statuses: any[]; }; }) =>
      ts.tenancySummary
      .statuses.find(
        (s) => s.type === "isTenancyActive"
      )?.value === true
  );

   dispatch({type: CASE_TENANCY_ID, payload: activeTenancyDetails[0].tenancySummary?.tenancyId })
   dispatch({type: CASE_TENANCY_TYPE, payload: activeTenancyDetails[0].tenancySummary?.tenancyType})
  dispatch(getCustomerId(customer?.id))
  const fullName= customerResponse?.person?.name?.title + " " + customerResponse?.person?.name?.givenName + " " + customerResponse?.person?.name?.surname
  dispatch({type: CASE_CUSTOMER_NAME, payload: fullName})
  dispatch(
    saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses)
  );
   }
   setShowError('');
  }
  const exitButtonHandler = () => {
    dispatch(backToCaseAsset(false));
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  const onContinueHandler = () => {
    if(!editButtonCheck) {
    dispatch(userChosenContactDetails(""))
    dispatch(userChosenContactName(""))
    dispatch(userChosenContactPhone(""))
    dispatch(userChosenRelationDetails(""))
    }
    if(isNullOrUndefined(customerId)){
      setShowError(t("OPC043"));
    }
    else{
      setShowError('');
    props.history.push("/ContactDetails")
    }
  };
  const backButtonHandler = () => {
    props.history.push("/CaseContactInformation")
  };

  return (
    <div className="container ctn-fht mx-auto pb-12 my-5 global-txt">
      <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
      <div className="flex flex-row d-flex"></div>
      <h1 className="sm:px-3 sm:font">{t("OPC042")}</h1>
      <div className="border-b border-grey items-center py-2">
        <div className="sm:px-3 w-3/4 md:text-left pt-2 pb-0 pl-1 global-txt">
          {t("SearchType")}
        </div>
        <div className=" flex items-center mt-2">
                <div className="w-full sm:px-2 sm:w-5/12  md:w-3/12 lg:w-3/12 flex items-center mb-2">
                  <label className="w-full">
                    <select
                      className="block w-full mt-1 rounded-lg border border-grey mx-1 sm:mx-0"
                      data-testid="dropDownLocation" disabled
                    >
                      <option>{"Customer"} </option>
                      
                    </select>
                  </label>
                </div>

                <div className="w-full sm:pl-2 sm:w-7/12  md:w-6/12 lg:w-6/12 lg:mx-4 flex items-center mb-2 ">
                  <div
                    id="custom-search-input"
                    className="block w-full mt-1 sm-mr-1 mx-1"
                  >
                    <div className="input-group w-full" id="searchId">
                      <label className="w-full">
                      <Search
                  className="border border-grey rounded-lg-search w-full"
                  defaultValue={searchText}
                  datatestid="search-text-button"
                  onSubmit={handleSubmit}
                  placeholder={t("seach_placeholder_txt")}
                  onClear={handleResetSubmit}
                />
                      </label>
                    </div>
                  </div>
                </div>
           </div>  
             
      </div>

      <WebPagination>
        <br/>
     {totalCount>0 ?( <div className="w-full md:w-5/12 lg:w-1/3 flex items-center">
           <div className="w-1/2 md:w-auto pr-4 md:text-left ml-2"><strong>{totalCount}</strong> {t("Customer(s)")}</div>
      </div> ): null}
        <div className="w-full global-txt">
          <br></br>
          <div className="padd-lr" role="main">
            {!isLoading ? (
              <div className="container mx-auto">
                {!customerList?.length? (
                  
                  <div className="w-full mt-4">
                    <div>
                      
                      
                        {!isSearchValid ? (
                          <div className="w-full px-4 py-2 bg-yellow flex items-center">
                            <div className="px-4 pr-8">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                          className="text-brick-70 w-6 h-6"
                        >
                          <path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path>
                        </svg>
                      </div>
                          <div className="flex items-center">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            {validationMessage}
                          </p>
                          </div>
                          </div>
                        ) : null}
                      
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      {/* ====== <!-- count --> ==== */}

                      <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4"></div>
                    </div>

                     {customerList.map((customer) => (
                      <div className="w-full desk-view-only">
                        <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                          <div className="bg-peach px-4 py-2 rounded-t-lg">
                            <div className="flex flex-row justify-between items-center">
                              <div className="my-0 text-lg">
                                <div className="my-0 text-lg text-black cursor-pointer">
                                  <strong>
                                    {customer?.givenName +
                                      " " +
                                      customer?.surname}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                            <div className="mb-2">
                              <strong>{t("address_txt")}</strong> <br></br>
                              {addressToString(
                                customer.tenancyModels[0]
                                  ?.premisesAddress as PremisesAddress
                              )}
                            </div>
                            <div className="mb-2 md:ml-4">
                              <strong>{t("tenancy_txt")}</strong>
                              <br></br>
                              {`${customer?.tenancyModels[0]?.customerTenancyTypeGroup}`}
                            </div>

                            <div className="mb-2">
                              <strong>{t("DOB_txt")}</strong>
                              <br></br>
                              {formatDateWithFromCalendar(customer.dateOfBirth)}
                            </div>

                            <div className="mb-2 md:ml-4">
                              <strong>{t("status_txt")}</strong>
                              <br></br>
                              {customer?.tenancyModels[0]?.isTenancyActive
                                ? `${t("active_txt")}`
                                : `${t("former_txt")}`}
                            </div>
                            <div className="mb-2 cursor-pointer">
                              {(customerId ==customer?.id) ? "Select Customer": <a data-testid={"web" + customer?.id} className = "cursor-pointer" onClick={() => goToSelectCustomer(customer)}>Select Customer</a>}
                             
                            </div>
                          </div>

                          <div className="px-4 md:flex md:justify-between"></div>
                        </div>
                      </div>
                    ))}
                   {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
        )}
        
                     <div
              className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
              id="repreason-main-btn"
            >
             
              <ExitModalContent
                data-testid="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
              />
              <Button
                data-testid="continueWeb"
                onClick={onContinueHandler}
                className="button-class"
                
                
              >
                {t("continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
                    <div className="page-mar">
                      <Paginationbox>
                        <label>
                          <Pagination
                            className=" ml-4 "
                            colSpan={7}
                            count={totalCount}
                            page={currentPage}
                            rowsPerPage={resultsPerPage}
                            rowsPerPageOptions={[10, 20, 100]}
                            onPageChange={onChangePage}
                            data-testid="WorkOrderPaginationChange"
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </label>
                      </Paginationbox>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="div-loader">
                <div className="SpinnerWrapper">
                  <LoadingSpinner />
                </div>
              </div>
            )}
          </div>
        </div>
      </WebPagination>
      <MobilePagination>
        <br/>
      {totalCount>0 ?( <div className="w-full md:w-5/12 lg:w-1/3 flex items-center">
           <div className="w-1/2 md:w-auto pr-4 md:text-left ml-4"><strong>{totalCount}</strong> {t("Customer(s)")}</div>
      </div> ): null}
        <div
          id="scrollableDiv"
          data-testid="scrollableDiv"
          className="mob-view-only"
          style={{
            height: 800,
            overflowY: "scroll",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={customerList?.length}
            next={fetchData}
            style={{ display: "flex", flexDirection: "column" }}
            hasMore={true}
            loader={<p></p>}
            scrollableTarget="scrollableDiv"
          >
            <div className="w-full global-txt">
              <br></br>
              <div className="padd-lr" role="main">
                {!isLoading ? (
                  <div className="container mx-auto px-3">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      {/* ====== <!-- count --> ==== */}
                    </div>

                    {!customerList?.length ? (
                  
                      <div className="w-full mt-4">
                        <div >
                          
                          
                          
                            {!isSearchValid ? (
                              <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                <div className="px-4 pr-8">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              fill="currentColor"
                              className="text-brick-70 w-6 h-6"
                            >
                              <path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path>
                            </svg>
                          </div>
                              <div className="flex items-center">
                              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                {validationMessage}
                              </p>
                              </div>
                              </div>
                            ) : null}
                         
                        </div>
                      </div>
                    ) : (
                      customerList.map((customer) => (
                        <div className="w-full desk-view-only">
                          <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                            <div className="bg-peach px-4 py-2 rounded-t-lg">
                              <div className="flex flex-row justify-between items-center">
                                <div className="my-0 text-lg">
                                  <div className="my-0 text-lg text-black cursor-pointer">
                                    <strong>{customer?.givenName +
                                      " " +
                                      customer?.surname}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                              <div className="mb-2">
                                <strong>{t("address_txt")}</strong> <br></br>
                                {addressToString(
                                  customer.tenancyModels[0]
                                    ?.premisesAddress as PremisesAddress
                                )}
                              </div>
                              <div className="mb-2 md:ml-4">
                                <strong>{t("tenancy_txt")}</strong>
                                <br></br>
                                {`${customer?.tenancyModels[0]?.customerTenancyTypeGroup}`}
                              </div>

                              <div className="mb-2">
                                <strong>{t("DOB_txt")}</strong>
                                <br></br>
                                {formatDateWithFromCalendar(
                                  customer.dateOfBirth
                                )}
                              </div>

                              <div className="mb-2 md:ml-4">
                                <strong>{t("status_txt")}</strong>
                                <br></br>
                                {customer?.tenancyModels[0]?.isTenancyActive
                                  ? `${t("active_txt")}`
                                  : `${t("former_txt")}`}
                              </div>
                              <div className="mb-2 cursor-pointer">
                              {(customerId ==customer?.id)? "Select Customer": <a  onClick={() => goToSelectCustomer(customer)}>Select Customer</a>}
                             
                            </div>
                            </div>

                            <div className="px-4 md:flex md:justify-between"></div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ) : (
                  <div className="div-loader">
                    <div className="SpinnerWrapper">
                      <LoadingSpinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </InfiniteScroll>
        </div>
        {showError && (
          <div
            data-testid="alert-warning"
            className="error mb-8 sm:mx-1"
            role="alert"
            placeholder="alert-message"
          >
            <div className="w-full px-2 py-2 bg-yellow flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
              </div>
              <div className="flex items-center">
                <p
                  data-testid="locatioAlert_0"
                  className="w-full text-left my-2 font-AvantGardeGothic-Md"
                >
                  {" "}
                  {t(showError)}
                </p>
              </div>
            </div>
          </div>
        )}
        <div
              className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
              id="repreason-main-btn"
            >
              <ExitModalContent
                data-testid="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
              />
              <Button
                onClick={onContinueHandler}
                className="button-class"
                data-testid="continueMobile"
              >
                {t("continuen_button")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
      </MobilePagination>
        
    </div>
  );
};

export default CaseSelectCustomerComponent;
