import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { addressToStringDetails } from "../../utils/formatting";
import { trackEvent } from "../../appInsights/TelemetryService";
import CustomerCardDetails from "../../models/Customer/CustomerCardDetail";
import { useDispatch, useSelector } from "react-redux";
import { userChosenCustomerID } from "../../ducks/redux/actions/userChoices";
import { BackValue } from "../../models/shared/Enum";


interface CustomerAssetCardProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerCardDetails[];
}
const CustomerAssetCard: React.FC<CustomerAssetCardProps> = (props) => {
  const { t } = useTranslation();
  let customerListResponse: CustomerCardDetails[] = [];
  const [customerListData, setCustomerListData] = useState(customerListResponse);
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  useEffect(() => {
    trackEvent("CustomerAssetCard");
    if (props.customerAssetList != undefined){
      if(props.customerAssetList.length < 3){
      setCustomerListData(props.customerAssetList);
      }
      else
      {
        let custData: CustomerCardDetails[] = [];
        props.customerAssetList?.forEach((cus: any) => {
            if(cus.tenancyModels[0].isMainTenant === true){
              console.log("cus.tenancyModels.isMainTenant",cus.id);
              custData.push(cus);
            }
          });
        setCustomerListData(custData);
      }
    }
  }, [props.customerAssetList]);

  const dispatch = useDispatch();
  
  const goToCustomer360Page = (id: string | undefined) => {
    
    dispatch(userChosenCustomerID(id));
    props.history.push('/Customer360View');
  }
  return (
    <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4 px-4">
      {
        <div>
          <div className="px-4 py-2 mt-2 border-b border-grey">
            <div className="flex flex-row d-flex bd-highlight items-center">
              <i className="fas fa-user-circle text-teal text-2xl mr-6 bd-highlight"></i>
              <h2 className="my-0 text-purple-100 bd-highlight">{backValue===BackValue.Case360View? t("Case_Customer"): (customerListData?.length===1) ? t("Case_Customer"):t("Customers")}</h2>

            </div>
          </div>
          {(customerListData != undefined) ? customerListData.map((customer: any) => (
            <div className="px-4 mt-4">

              <div className="border border-grey rounded-lg mb-4">
                <div className="bg-peach px-4 py-2 rounded-t-lg ">
                  <div className="flex flex-row justify-between items-center">
                    <div className="my-0 text-lg">
                      <a data-testId="customer_name" onClick={() => goToCustomer360Page(customer?.id)} className="text-dark underline cursor-pointer">
                        <strong>{customer?.givenName + " " + customer?.surname}
                        </strong></a></div>
                  </div>
                </div>
                <div className="px-4 py-2 flex justify-between border-b border-grey">
                  <div className="pr-4">{t("email")}
                  </div>
                  <div className="text-right"> 
                  {(customer?.email !== "" && customer?.email !== undefined && customer?.email !== null)?(
                  <>
                    {customer?.email?.length > 20 ?
                    (<span className="word">{customer?.email}</span>):  (<span className="">{customer?.email}</span>)
                    }
                   </>
                    ):
                  <span>-</span>
                  }
                   </div>
                </div>

                <div className="px-4 py-2 flex justify-between border-b border-grey">
                  <div className="pr-4">{t("Mobile")}
                  </div>
                  <div className="text-right">
                    {customer.mobilePhone || "-"}
                  </div>
                </div>

                <div className="px-4 py-2 flex justify-between border-b border-grey">
                  <div className="pr-4">{t("Home")}
                  </div>
                  <div className="text-right">  {customer?.homePhone || "-"}</div>
                </div>

                <div className="px-4 py-2 flex justify-between border-b border-grey">
                  <div className="pr-4">{t("Business")}
                  </div>
                  <div className="text-right">{customer?.workPhone || "-"}</div>
                </div>
                <div className="px-4 py-2 flex justify-between border-b border-grey">
                  <div className="pr-4">{t("Postal_address")}
                  </div>
                  <div className="text-right">{customer?.tenancyModels?.length > 0 ? addressToStringDetails(customer.tenancyModels[0].premisesAddress) || '-' : addressToStringDetails(customer.tenancyModels) || '-'}</div>
                </div>

              </div>
            </div>
          )) : null}
        </div>}
    </div>
  )
};
export default CustomerAssetCard;